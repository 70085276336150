var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.addSetting,
            size: "medium",
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                required: true,
                label: _vm.$i18n.t("common.LandingPageDisplay"),
                prop: "pageStatus"
              }
            },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce669" },
                model: {
                  value: _vm.addSetting.pageStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.addSetting, "pageStatus", $$v)
                  },
                  expression: "addSetting.pageStatus"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.LandingPageActivityName"),
                prop: "pageName"
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  "show-word-limit": "",
                  maxlength: "100",
                  placeholder: _vm.$i18n.t("common.LandingPageActivityName")
                },
                model: {
                  value: _vm.addSetting.pageName,
                  callback: function($$v) {
                    _vm.$set(_vm.addSetting, "pageName", $$v)
                  },
                  expression: "addSetting.pageName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.ActivityDescription") } },
            [
              _c("wangEditer", {
                ref: "wangEditer",
                staticStyle: { "margin-top": "10px" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancelCustomDialog()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirmCustomDialog()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }