<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                clearable
                v-model="query.userId"
                :placeholder="$i18n.t('common.UserID')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
            ></el-input>
            <dataSelect
                style="width: 180px"
                class="filter-item"
                v-model="query.platform"
                clearable
                :codeType="'platforms'"
                :placeholder="$i18n.t('common.platform')"
            ></dataSelect>
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-date-picker
                class="filter-item"
                v-model="query.logintimeStartAndEnd"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :range-separator="$i18n.t('common.to')"
                :start-placeholder="$i18n.t('common.starttime')"
                :end-placeholder="$i18n.t('common.Endtime')"
                align="right"
                :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>

            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.status"
                clearable
                :codeType="'problem_status'"
                placeholder="问题状态"
            ></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">
                {{ $i18n.t('common.query') }}
            </el-button>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                {{ $i18n.t('common.Reset') }}
            </el-button>
            <el-button
                class="filter-item"
                icon="el-icon-search"
                type="primary"
                @click="handleExport"
                style="margin-left: 10px"
                size="small"
                v-loading.fullscreen.lock="fullscreenLoading"
            >
                {{ $i18n.t('common.export') }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column :label="$i18n.t('common.UserID')" prop="userId" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Creationtime')" prop="createTime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.country')" prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    <span>
                        {{ $dict.getDictValue('countrys', row.countryCode) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.PageAddress')" prop="webAddress" align="center"></el-table-column>
            <el-table-column :label="$i18n.t('common.ErrorLogs')" prop="error" align="center">
                <template slot-scope="{ row }">
                    <el-tag
                        size="medium"
                        style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                        @click="handleUpdate(row)"
                        >{{ row.error }}</el-tag
                    >
                </template>
            </el-table-column>

            <el-table-column label="问题状态" prop="status" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.status == 0" style="color: #1081de">
                        {{ $i18n.t('common.New') }}
                    </span>
                    <span v-if="row.status == 1" style="color: #ff9500">
                        {{ $i18n.t('common.InProgress') }}
                    </span>
                    <span v-if="row.status == 2" style="color: #10bb27">
                        {{ $i18n.t('common.Resolved') }}
                    </span>
                    <span v-if="row.status == 3" style="color: #333333">
                        {{ $i18n.t('common.CanBeIgnored') }}
                    </span>
                    <span v-if="row.status == 4" style="color: #37679f">
                        {{ $i18n.t('common.Published') }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.OperatingTime')" prop="updateTime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.updateTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Operator')" prop="updateByName" align="center"></el-table-column>
            <el-table-column :label="$i18n.t('common.Remarks')" prop="remark" align="center"></el-table-column>

            <el-table-column :label="$i18n.t('common.operation')" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">
                        {{ $i18n.t('common.reply') }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            style="margin-top: 10px"
            background
            v-show="total > 0"
            @current-change="doQueryList"
            :current-page="query.page"
            :page-size="query.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>

        <el-dialog title="查看日志详情" :visible.sync="diologVisible" v-if="diologVisible" width="1000px" :close-on-click-modal="false">
            <el-descriptions class="margin-top" :column="2" size="medium" border>
                <el-descriptions-item :label="$i18n.t('common.UserID')" :labelStyle="{ width: '100px' }">{{
                    showItem.userId
                }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('common.Creationtime')" :labelStyle="{ width: '100px' }">{{
                    new Date(showItem.createTime).toLocaleString()
                }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('common.IPaddress')" :labelStyle="{ width: '100px' }">{{
                    showItem.ipAddress
                }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('common.platform')" :labelStyle="{ width: '100px' }">{{
                    showItem.platform
                }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('common.country')" :labelStyle="{ width: '100px' }">
                    {{ $dict.getDictValue('countrys', showItem.countryCode) }}</el-descriptions-item
                >
                <el-descriptions-item :label="$i18n.t('common.PageURL')" :labelStyle="{ width: '100px' }">{{
                    showItem.webAddress
                }}</el-descriptions-item>
                <el-descriptions-item label="错误堆栈" :labelStyle="{ width: '100px' }">
                    {{ showItem.error }}
                </el-descriptions-item>
            </el-descriptions>

            <el-form :model="addWebLog" label-position="left" style="margin-top: 20px" label-width="100px">
                <el-form-item label="问题状态">
                    <dataSelect
                        style="width: 200px"
                        v-model="addWebLog.status"
                        clearable
                        :codeType="'problem_status'"
                        placeholder="问题状态"
                    ></dataSelect>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Remarks')">
                    <el-input
                        v-model="addWebLog.remark"
                        placeholder="请填写备注"
                        type="textarea"
                        maxlength="500"
                        rows="5"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm') }} </el-button>
                <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import moment from 'moment';

function initAddBean() {
    const addBean = {
        webLogId: null,
        status: null,
        remark: null
    };
    return addBean;
}

export default {
    name: 'WebLogTable',
    components: {
        Pagination,
        DataSelect,
        moment
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            old: initAddBean(),
            addWebLog: initAddBean(),
            query: {
                page: 1,
                limit: 10,
                userId: null,
                platform: null,
                logintimeStartAndEnd: null,
                countryCode: null
            },
            selectItems: [],
            formTitle: '',

            value: '',
            showItem: {},
            diologVisible: false,
            fullscreenLoading: false,
        };
    },
    created() {
        this.handleQuery();
    },
    methods: {
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page) {
            this.query.page = page;
            this.listLoading = true;
            if (this.query.logintimeStartAndEnd != null) {
                this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                // this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).format('YYYY-MM-DD HH:mm:ss');
                // this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            this.$store
                .dispatch('getWebLogPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            //this.formTitle =  this.$i18n.t("common.edit");
            this.formTitle = '查看日志详情';
            this.old = row;
            this.showItem = row;
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addWebLog[key] = this.old[key];
            }
            this.addWebLog.status = this.addWebLog.status + '';
            console.log(this.addWebLog);

            this.diologVisible = true;
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.addWebLog = initAddBean();
        },
        closeForm() {
            this.diologVisible = false;
            this.handleQuery();
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            this.addWebLog.status = parseInt(this.addWebLog.status);
            console.log(this.addWebLog);
            this.$store.dispatch('editWebLog', this.addWebLog).then((data) => {
                this.diologVisible = false;
                this.handleQuery();
                this.$message({
                    message: this.$i18n.t('common.Successfullyedited'),
                    type: 'success'
                });
            });
        },
        /**
         * 导出excel
         */
         handleExport() {
            this.query.fileName = 'webLog.xlsx';
            this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportWebLogExcel', this.query).then((res) => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Exportsuccessful')
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
    }
};
</script>
<style scoped>
.filter-container {
    padding: 20px;
}

.filter-item {
    margin-left: 10px;
}
</style>
