<template>
    <div>
        <el-form-item :label="$t('common.EnabledOrNot')" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="formData.status"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('common.PromotionCountry')" class="must" :label-width="formLabelWidth">
            <div>
                <el-checkbox-group v-model="formData.countryCode">
                    <el-checkbox
                            @change="(val) => handleTabCode(val, item)"
                            v-for="(item, index) in formData.countryCodes"
                            :key="index"
                            :label="item"
                    >{{ $dict.getDictValue('countrys', item) }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </el-form-item>
        <div v-if="formData.promotionRuleCountrys && formData.promotionRuleCountrys.length > 0">
            <el-tabs v-model="formData.activeName" @tab-click="changeCountrys" class="country-tabs">
                <el-tab-pane
                        v-for="(item, index) in formData.promotionRuleCountrys"
                        :key="index"
                        :label="$dict.getDictValue('countrys', item.countryCode)"
                        :name="item.countryCode"
                >
                    <el-form :model="item" ref="variantFrom" :rules="rulesP">
                        <el-form-item :label="$t('common.Promotiontime')" prop="time"
                                      :label-width="formLabelWidth">
                            <el-date-picker
                                    v-model="item.time"
                                    clearable
                                    @input="changeCheckList"
                                    type="datetimerange"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    :range-separator="$t('common.to')"
                                    :start-placeholder="$t('common.Startdate')"
                                    :end-placeholder="$t('common.Enddate')"
                                    :default-time="['00:00:00','23:59:59']"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item :label="$t('common.Displaypromotionalprices')" :label-width="formLabelWidth">
                            <el-radio-group v-model="item.flagShowPromotionPrice"
                                            @change="(val) => handlePriceTypep(val, item, index)">
                                <el-radio :label="false">{{ $t('common.No') }}</el-radio>
                                <el-radio :label="true">{{ $t('common.Yes') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('common.PromotionPrice')" :label-width="formLabelWidth">
                            <el-radio-group v-model="item.promotionType"
                                            @change="(val) => handlePriceTypep(val, item, index)">
                                <el-radio label="Discount">{{ $t('common.discount') }}</el-radio>
                                <el-radio label="Price">{{ $t('common.price') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                                v-if="item.promotionType == 'Discount'"
                                label=""
                                prop="promotionValue"
                                :label-width="formLabelWidth"
                        >
                            <span> - </span>
                            <el-input
                                    @input="changeCheckList"
                                    oninput="value=value.replace(/[^0-9.]/g,'')"
                                    @blur="handleDiscount(item)"
                                    v-model="item.promotionValue"
                                    :placeholder="$t('common.discount')"
                                    :style="{ width: inputWidth }"
                            ></el-input>
                            % off
                        </el-form-item>
                        <el-form-item
                                v-else-if="item.promotionType == 'Price'"
                                label=""
                                prop="promotionValue"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    @input="changeCheckList"
                                    @blur="handlePricep(item)"
                                    v-model="item.promotionValue"
                                    oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                                    :placeholder="$t('common.PleaseEnterThePrice')"
                                    :style="{ width: inputWidth }"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                                :label="item.promotionType == 'Discount' ? $t('common.Promotionprice1') : $t('common.PromotionDiscount')"
                                :label-width="formLabelWidth"
                        >
                            <div v-show="item.promotionValue">
                                <div v-if="isEdit">
                                    <div v-show="item.promotionType == 'Discount'">
                                        {{ item.discount }}
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-show="item.promotionType == 'Discount'">
                                        {{ Discount ? Discount : 0 }}
                                    </div>
                                </div>
                                <div v-show="item.promotionType == 'Price'">
                                    {{ item.Price }}
                                </div>
                            </div>
                            <div v-show="!item.promotionValue">
                                <div v-show="item.promotionType == 'Discount'">
                                    {{ discountTip }}
                                </div>
                                <div v-show="item.promotionType == 'Price'">
                                    {{ priceTip }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('common.Grossmargin')" :label-width="formLabelWidth">
                            <div v-show="item.promotionValue">
                                {{ item.profit }}%
                            </div>
                            <div v-show="!item.promotionValue">
                                <div v-show="item.promotionType == 'Discount'">
                                    {{ discountTip }}
                                </div>
                                <div v-show="item.promotionType == 'Price'">
                                    {{ priceTip }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('common.Promotionquantity')"  prop="promotionCount"
                                      :label-width="formLabelWidth">
                            <el-input
                                    @input="changeCheckList"
                                    v-model="item.promotionCount"
                                    oninput="value=value.replace(/[^0-9]/g,'')"
                                    @blur="handlePromotionCount(item)"
                                    :placeholder="$t('common.PleaseEnterThePromotionalQuantity')"
                                    class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.VirtualQuantity')" prop="virtualQty"
                                      :label-width="formLabelWidth">
                            <el-input
                                @input="changeCheckList"
                                v-model="item.virtualQty"
                                oninput="value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                                :placeholder="$t('common.PleaseEnterVirtualQuantity')"
                                class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label-width="80px" class="valid-data-form-item">
                            <span slot="label" style="color: red;">
                                {{ $t('common.Verification') }}
                            </span>
                            <span style="font-size: 12px;">
                                <span>{{ $t('common.PromotionQuantityPackageQuantity') }}:</span>
                                <span class="margin-5">{{ calDivisorValue(item.promotionCount, getGoodsQuantity(item, formData.pageDataSelectList[0].goodsSpecials)) }}</span>
                                <span class="margin-10">{{ $t('common.VirtualQuantityPackageQuantity') }}:</span>
                                <span class="margin-5">{{ calDivisorValue(item.virtualQty, getGoodsQuantity(item, formData.pageDataSelectList[0].goodsSpecials)) }}</span>
                                <span class="margin-10">{{ $t('common.VirtualSalesVolume') }}:</span>
                                <span class="margin-5">{{ calVirtualSales(item, true) }}</span>
                                <span class="margin-10">{{ $t('common.VirtualSalesVolumePackageQuantity') }}:
                                </span><span class="margin-5">{{ calDivisorValue(calVirtualSales(item, true), getGoodsQuantity(item, formData.pageDataSelectList[0].goodsSpecials)) }}</span>
                            </span>
                        </el-form-item>

                        <el-form-item :label="$t('common.NumberOfUserPurchases')" prop='userBuy' :label-width='formLabelWidth'>
                            <el-input
                                    @input="changeCheckList"
                                    v-model="item.userBuy"
                                    :placeholder="$t('common.AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods')"
                                    :disabled='true'
                                    class="input-width"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.Freeshippingornot')" prop="freeDelivery"
                                      :label-width="formLabelWidth">
                            <el-radio-group v-model="item.freeDelivery" @change="changeCheckList">
                                <el-radio :label="true">{{ $t('common.Yes') }}</el-radio>
                                <el-radio :label="false">{{ $t('common.No') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('common.Doyousupporttheuseofcoupons')" :label-width="formLabelWidth">
                            <el-radio-group v-model="item.coupon">
                                <el-radio :label="true">{{ $t('common.Yes') }}</el-radio>
                                <el-radio :label="false">{{ $t('common.No') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('common.Isitaflashkill')" :label-width="formLabelWidth">
                            <el-radio-group v-model="item.seckill" @change="changeCheckList">
                                <el-radio :label="true">{{ $t('common.Yes') }}</el-radio>
                                <el-radio :label="false">{{ $t('common.No') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('common.SoldOutOrNot')" :label-width="formLabelWidth">
                            <el-radio-group v-model="item.soldOut" @change="changeCheckList">
                                <el-radio :label="true">{{ $t('common.Yes') }}</el-radio>
                                <el-radio :label="false">{{ $t('common.No') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('common.Secondkillsorting')" v-show="item.seckill"
                                      :label-width="formLabelWidth">
                            <el-input
                                    v-model="item.sort"
                                    oninput="value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                                    :placeholder="$t('common.Topwithlargenumbers')"
                                    class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.Purchaserestrictionsforthesameuser')"  prop="userRestrictions"
                                      :label-width="formLabelWidth">
                            <el-input
                                    v-model="item.userRestrictions"
                                    @blur="handleOrderPurchaseLimit(item)"
                                    oninput="value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                                    :placeholder="$t('common.PleaseEnterThePurchaseLimitForTheSameUser')"
                                    class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.Singleorderlimit')"  prop="orderRestrictions"
                                      :label-width="formLabelWidth">
                            <el-input
                                    v-model="item.orderRestrictions"
                                    @blur="handleOrderPurchaseLimit(item)"
                                    oninput="value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                                    :placeholder="$t('common.PleaseEnterThePurchaseLimitForASingleOrder')"
                                    class="input-width"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import { calVirtualSales, getComputePrice, getGoodsQuantity, handleOrderPurchaseLimit } from '@/utils/promotion';

export default {
    components: {
        FilterContainer,
        FormTableDialog
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        priceSetting: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            priceTip: this.$i18n.t('common.AutomaticallyCalculateAfterEnteringPrice'),
            discountTip: this.$i18n.t("common.AutomaticallyCalculateAfterEnteringTheDiscount"),
            ossHost: process.env.VUE_APP_OSS_Head,
            loading: false,
            Discount: null,
            Price: null,
            profit: null,
            promotionCount: null,
            promotionType: 'Discount',
            freeDelivery: false,
            checkLowestPrice: [],
            getPricing: [],
            inputWidth: '70%',
            formLabelWidth: '150px',
            form: {},
            //促销校验
            rulesP: {
                time: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectadate"),
                        trigger: 'change'
                    }
                ],
                promotionValue: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseentercontent"),
                        trigger: 'blur'
                    }
                ],
                promotionCount: [
                    {
                        required: true,
                        message: this.$i18n.t("common.PleaseEnterTheQuantity"),
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[1-9]\d*$/, message: this.$i18n.t("common.QuantityGreaterThan0"), trigger: 'blur'
                    }
                ],
                userRestrictions: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Purchaserestrictionsforthesameuser"),
                        trigger: 'blur'
                    }
                ],
                orderRestrictions: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Singleorderlimit"),
                        trigger: 'blur'
                    }
                ],
            }
        };
    },
    methods: {
        initData() {
            if (this.formData && this.formData.promotionRuleCountrys && this.formData.promotionRuleCountrys.length > 0) {
                this.formData.activeName = this.formData.promotionRuleCountrys[0].countryCode
                //初始化数据
                this.formData.promotionRuleCountrys.forEach(item => {
                    if (item.promotionType == 'Discount') {
                        this.handleDiscount(item)
                    } else if (item.promotionType == 'Price') {
                        this.handlePricep(item)
                    }
                    this.handlePromotionCount(item);
                    this.handleOrderPurchaseLimit(item);
                })
            } else {
                this.formData.activeName = ''
            }

            this.$forceUpdate();
        },
        //选择国家
        changeCountrys(tab, event) {
            let rowData = {}
            let idx = -1
            if (this.isEdit) {
                this.rowCountrys = this.formData.promotionRuleCountrys.filter((item, index) => {
                    if (tab.name == item.countryCode) {
                        idx = index
                        return item;
                    }
                });
                rowData = JSON.parse(JSON.stringify(this.rowCountrys[0]))
            } else {
                rowData = JSON.parse(JSON.stringify(this.formData.promotionRuleCountrys[tab.index]))
            }
            this.handlePricep(rowData);
            this.handleDiscount(rowData, idx);
            this.$forceUpdate()
        },
        //促销限制单笔订单value
        handleOrderPurchaseLimit,
        handlePriceTypep(val, row, index) {
            this.handleDiscount(row);
            this.handlePricep(row);
        },
        //处理促销优惠价格
        handlePricep(row) {
            this.formData.pageDataSelectList[0].prices.forEach((item, index) => {
                let computePrice = getComputePrice(item)

                if (row.countryCode == item.countryCode) {
                    if (row.promotionValue >= computePrice) {
                        this.formData.promotionRuleCountrys.forEach((item2, index2) => {
                            if (row.countryCode == item2.countryCode) {
                                if (item2.promotionType == 'Price') {
                                    item2.promotionValue = computePrice.toFixed(2);
                                }
                                this.Price = computePrice - item2.promotionValue;
                                this.Price = this.Price.toFixed(2);
                                item2.Price = computePrice - item2.promotionValue;
                                this.calculateProfit(row, item, "Price", 0, index2)
                            }
                        })
                    } else if (row.promotionValue < computePrice) {
                        this.formData.promotionRuleCountrys.forEach((item2, index2) => {
                            if (row.countryCode == item2.countryCode) {
                                this.Price = computePrice - row.promotionValue;
                                this.Price = this.Price.toFixed(2);
                                item2.Price = this.Price;
                                this.calculateProfit(row, item, "Price", 0, index2)
                            }
                        })
                    }
                }
            });
            this.$forceUpdate();
        },
        //处理折扣价格
        handleDiscount(row, idx) {
            let promotionValue = row.promotionValue - 0;
            let resultPrice = null;
            row.promotionValue = promotionValue.toFixed(1);
            if (row.promotionValue > 100) {
                row.promotionValue = 100;
            }
            this.formData.pageDataSelectList[0].prices.forEach((item, index) => {
                if (row.countryCode == item.countryCode) {
                    let computePrice = getComputePrice(item)

                    resultPrice = computePrice
                    this.calculateProfit(row, item, "Discount", computePrice, idx);
                }
            });

            this.formData.promotionRuleCountrys.forEach((item2, index2) => {
                if (row.countryCode == item2.countryCode) {
                    this.Discount = (resultPrice - (row.promotionValue / 100) * resultPrice).toFixed(2);
                    item2.discount = (resultPrice - (row.promotionValue / 100) * resultPrice).toFixed(2)
                }
            })

            this.$forceUpdate();
        },
        // 计算毛利率
        calculateProfit(row, priceDto, type, resultPrice, index) {
            //type = Discount、Price
            if (type !== row.promotionType) {
                return
            }
            const feightFee = priceDto.feightFee
            const originalPrice = priceDto.originalPriceSaleDouble
            // 最终促销价格
            let promotionValue = row.promotionValue
            if (type === 'Discount') {
                promotionValue = (resultPrice * (1 - row.promotionValue / 100)).toFixed(2);
            }
            console.log(row.countryCode, row.promotionValue)
            if (this.priceSetting && this.priceSetting.length > 0) {
                this.setProfit(row, promotionValue, feightFee, originalPrice, index, this.priceSetting)
            } else {
                //解决毛利率价格设置数组为空情况
                this.$store.dispatch('listPriceSettings').then((res) => {
                    this.setProfit(row, promotionValue, feightFee, originalPrice, index, res);
                });
            }
        },
        //设置毛利率
        setProfit(row, promotionValue, feightFee, originalPrice, index, priceSetting) {
            priceSetting.forEach((ps) => {
                if (ps.countryCode === row.countryCode) {
                    // promotionValue = 0 ，毛利率返回0
                    if (promotionValue > 0) {
                        const taxRate = ps.taxRate / 100
                        const commission = ps.commission / 100
                        // 计算毛利率（和后端公式保持一致）
                        this.profit = (1 - (taxRate / (taxRate + 1)).toFixed(7) - commission - ((feightFee + originalPrice) / promotionValue).toFixed(7)) * 100
                        row.profit = this.profit.toFixed(2)
                    } else {
                        this.profit = 0
                        row.profit = this.profit.toFixed(2)
                    }
                    console.debug("毛利率计算结果:" + this.profit.toFixed(2))

                    if (index >= 0) {
                        this.formData.promotionRuleCountrys[index].profit = row.profit
                    }
                    this.$forceUpdate();
                }
            });
        },
        //促销数量
        handlePromotionCount(row) {
            this.formData.pageDataSelectList[0].goodsSpecials.forEach(item => {
                if (row.countryCode == item.countryCode) {
                    if (row.promotionCount && row.promotionCount >= item.ecomRemainQty) {
                        row.promotionCount = item.ecomRemainQty;
                    }
                    //计算用户购买数
                    if (row.promotionCount != null) {
                        row.userBuy = Math.floor(row.promotionCount / item.saleDoubleEbp)
                    }
                    this.$forceUpdate();
                }
            });
        },
        //变更国家Tab
        handleTabCode(val, countryCode) {
            let arr = [];
            this.formData.promotionRuleCountrys.forEach((item, index) => {
                arr.push(item.countryCode);
            });
            if (val == true) {
                const data = {
                    promotionId: '',
                    goodId: this.formData.pageDataSelectList[0].goodsId,
                    beginTime: '',
                    endTime: '',
                    promotionType: 'Discount',
                    promotionValue: null,
                    promotionCount: null,
                    userBuy: null,
                    sort: 0,//秒杀排序
                    countryCode: countryCode,
                    time: [],
                    flagShowPromotionPrice: false,
                    seckill: false,//是否秒杀
                    soldOut: false,//是否售罄
                    freeDelivery: false,
                    status: false,
                    coupon: false,
                    userRestrictions: null,
                    orderRestrictions: null
                };
                if (arr.indexOf(countryCode) == -1) {
                    this.formData.promotionRuleCountrys.push(data);
                }
            } else {
                this.formData.promotionRuleCountrys.forEach((item, index) => {
                    if (countryCode == item.countryCode) {
                        this.formData.promotionRuleCountrys.splice(index, 1);
                    }
                });
            }

            if (this.formData.promotionRuleCountrys.length == 1) {
                this.formData.activeName = this.formData.promotionRuleCountrys[0].countryCode;
            } else if (this.formData.promotionRuleCountrys.length > 1) {
                this.formData.activeName = this.formData.countryCode[0];
            } else {
                this.formData.activeName = ''
            }
        },
        changeCheckList() {
            this.$forceUpdate();
        },
        //解决老用户促销tab初始化未被选中问题
        refreshTabsActive() {
            if (!this.formData.promotionRuleCountrys || this.formData.promotionRuleCountrys.length == 0) {
                this.formData.activeName = ''
            } else {
                this.formData.activeName = this.formData.activeName
            }
            this.$forceUpdate()
        },
        //校验表单数据
        validateForm() {
            let validList = [];
            if (this.$refs['variantFrom'] && this.$refs['variantFrom'].length > 0) {
                this.$refs['variantFrom'].forEach(item => {
                    item.validate((valid) => {
                        if (valid) {
                            validList.push(1);
                        } else {
                            validList.push(0);
                        }
                    });
                });
            }
            if (validList.indexOf(0) <= -1) {
                return true
            }
            return false
        },
        //获取数量装
        getGoodsQuantity,
        //获取虚拟销售数
        calVirtualSales,
        //计算除数值
        calDivisorValue(divisor, dividend) {
            if(!divisor || !dividend) {
                return 0;
            }
            return Math.floor(divisor / dividend);
        }

    },
    mounted() {
        this.initData()
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}
/deep/ .country-tabs .el-tabs__header {
    margin-left: 30px;
    margin-right: 30px;
}

.valid-data-form-item {
    margin-left: 70px;
    margin-right: 25%;
    border: 1px solid black;
    background-color: gainsboro;
}

.margin-5 {
    margin-left: 5px;
}

.margin-10 {
    margin-left: 10px;
}
</style>