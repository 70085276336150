<template>
    <div class="app-container" style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-input
                v-model="query.barCode"
                :placeholder="`SKU/EAN/`+$i18n.t('common.coding')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[%]/g,'')"
                clearable
            ></el-input>
            <el-input
                v-model="query.goodId"
                :placeholder="$i18n.t('common.ProductID1')"
                style="width: 200px"
                class="filter-item"
                oninput="value=value.replace(/[^0-9]/g,'')"
                maxlength="19"
                controls-position="right"
                clearable
            ></el-input>
            <el-input
                v-model="query.commentId"
                :placeholder="$i18n.t('common.CommentID')"
                style="width: 200px"
                class="filter-item"
                oninput="value=value.replace(/[^0-9]/g,'')"
                maxlength="19"
                controls-position="right"
                clearable
            ></el-input>

            <el-input
                v-model="query.email"
                clearable
                :placeholder="$i18n.t('common.Useremail')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[%]/g,'')"
            ></el-input>
            <!--
            <dataSelect
                style="width: 150px"
                class="filter-item"
                v-model="query.brandCode"
                clearable
                :codeType="'brand'"
                placeholder="品牌"
            ></dataSelect>
            -->
            <el-select v-model="query.score" style="width: 150px" :placeholder="$i18n.t('common.RatingStarRating')" clearable class="filter-item">
                <el-option v-for="index in 5" :key="index" :label="index + $i18n.t('common.star0')" :value="index"></el-option>
            </el-select>

            <!--            <el-select v-model="query.IsPurchase" style="width: 150px" placeholder="是否有购买订单" clearable-->
            <!--                       class="filter-item">-->
            <!--                <el-option label="是" :value="true"></el-option>-->
            <!--                <el-option label="否" :value="false"></el-option>-->
            <!--            </el-select>-->

            <dataSelect
                style="width: 150px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-select v-model="query.status" :placeholder="$i18n.t('common.Auditstatus')" style="width: 150px" clearable class="filter-item">
                <el-option :label="$i18n.t('common.Unaudited')" value="NOT_APPROVED"></el-option>
                <el-option :label="$i18n.t('common.Passed')" value="ADOPT"></el-option>
                <el-option :label="$i18n.t('common.Failed')" value="FAIL"></el-option>
            </el-select>

            <dataSelect
                style="width: 150px"
                class="filter-item"
                v-model="query.source"
                clearable
                :codeType="'comment_source'"
                :placeholder="$i18n.t('common.source')"
            ></dataSelect>
            <el-date-picker
                v-model="dateArr"
                class="filter-item"
                type="datetimerange"
                :picker-options="pickerOptions"
                :range-separator="$i18n.t('common.to')"
                :start-placeholder="$i18n.t('common.Commentstartdate')"
                :end-placeholder="$i18n.t('common.CommentEndDate')"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                align="right"
                :default-time="['00:00:00','23:59:59']"
            >
            </el-date-picker>


            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t('common.search') }}</el-button>

            <el-button class="filter-item" style="margin-left: 10px" type="success" icon="el-icon-edit" @click="handleClearQuery()">
                {{ $i18n.t('common.Reset') }}
            </el-button>
            <el-button class="filter-item" style="margin-left: 10px" type="primary" @click="handleDeleteBatch"> {{ $i18n.t('common.Batchreview') }} </el-button>

            <el-button class="filter-item" style="margin-left: 10px" type="primary" :disabled="delBatchDisabled" @click="handleDelBatch"> {{ $i18n.t('common.Batchdeletion') }} </el-button>

            <el-button icon="el-icon-upload2" style="float: right;margin-right: 30px; margin-top: 10px" type="primary" @click="handleImport"> {{ $i18n.t("common.BatchInput") }} </el-button>

            <el-button  class="filter-item" style="float: right;" type="primary" @click="handleDownloadTemplate"> {{ $i18n.t('common.DownloadImportTemplate') }} </el-button>

        </div>

        <el-table
            v-loading="listLoading"
            :data="list"
            @selection-change="selectionChange"
            border
            stripe
            ref="multipleTable"
            style="width: 100%; margin-top: 10px"
            height="calc(100% - 170px)"
        >
            <el-table-column type="selection" width="50" />
            <el-table-column label="ID" prop="commentid" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.commentId }}</span>
                </template>
            </el-table-column>
            <!--
            <el-table-column label="品牌" prop="brandCode" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.brandCode }}</span>
                </template>
            </el-table-column>
            -->
            <el-table-column :label="$i18n.t('common.Productimage')" prop="commentid" align="center" width="120px">
                <template slot-scope="{ row }" v-if="row.goodsListDto != null">
<!--                    <el-image-->
<!--                        style="width: 100px; height: 100px"-->
<!--                        :src="ossHost + '/' + row.goodsListDto.imageUrl"-->
<!--                        fit="fit"-->
<!--                    ></el-image>-->
                    <listImage :imgUrl="row.goodsListDto.imageUrl"></listImage>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.ProductInformation')" prop="commentid" align="center" width="200px">
                <template slot-scope="{ row }" v-if="row.goodsListDto != null">
                    <span>{{ $i18n.t('common.name') }}:{{ row.goodsListDto.title }}</span>
                    <div>{{ $i18n.t('common.ProductID1') }}:{{ row.goodsListDto.goodsId }}</div>
                    <div>{{ $i18n.t('common.barcode') }}:{{ row.goodsListDto.barCode }}</div>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.Evaluationcontent')" prop="content" align="center" width="250px">
                <template slot-scope="{ row }">
                    <span>
                        <el-rate v-model="row.score" disabled show-score text-color="#ff9900" score-template="{value}"> </el-rate>

                        <span>{{ row.content }}</span>
                    </span>
                    <div>
                        <span @click="checkImg(row)" v-if="row.imgFileList && row.imgFileList.length > 0">
                            {{ row.imgFileList != null ? row.imgFileList.length : 0 }}{{ $i18n.t('common.picture') }}
                        </span>
                        <span v-else> 0 {{ $i18n.t('common.picture') }} </span>
                        <span @click="checkVideo(row)" v-if="row.videoFileList && row.videoFileList.length > 0">
                            {{ row.videoFileList != null ? row.videoFileList.length : 0 }}{{ $i18n.t('common.video') }}
                        </span>
                        <span v-else> 0 {{ $i18n.t('common.video') }} </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.ReviewerId')" prop="auditby" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.auditBy }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.user')" prop="auditby" align="center" width="200px">
                <template slot-scope="{ row }">

                    <div v-if="row.source == 'manualimport'">
                        <div>{{ $i18n.t('common.UserName') }}:{{ row.userName }}</div>
                        <div>{{ $i18n.t('common.country') }}:{{ row.countryCode }}</div>
                    </div>

                    <div v-if="row.source != 'manualimport'">
                        <div>ID:{{ row.userId }}</div>
                        <div>{{ $i18n.t('common.mailbox') }}:{{ row.userEmail }}</div>
                        <div>{{ $i18n.t('common.country') }}:{{ row.countryCode }}</div>
                    </div>

                </template>
            </el-table-column>

            <!--            <el-table-column label="购买订单" prop="source" align="center" width="150px">-->
            <!--                <template slot-scope="{ row }">-->
            <!--                    <div v-if="row.orderDto != null">-->
            <!--                        <div>-->
            <!--                            支付时间:-->
            <!--                            {{-->
            <!--                                row.orderDto.paymentTime != null ? (row.orderDto.paymentTime | formatDate ) : '暂未支付'-->
            <!--                            }}-->
            <!--                        </div>-->
            <!--                        <div>支付金额: {{ row.orderDto.totalAmt }}</div>-->
            <!--                        <div>{{ row.orderDto != null ? row.orderDto.orderNo : '暂无订单' }}</div>-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--            </el-table-column>-->

            <el-table-column :label="$i18n.t('common.source')" prop="source" align="center">
                <template v-slot="{ row }">
                    <dataText class="filter-item" v-model="row.source" :codeType="'comment_source'"></dataText>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Auditstatus')" prop="status" align="center">
                <template slot-scope="{ row }">
                    <el-tag :type="row.status == 'ADOPT' ? 'success' : row.status == 'FAIL' ? 'danger' : 'warning'">
                        {{ row.status == 'ADOPT' ? $i18n.t('common.adopt') : row.status == 'FAIL' ? $i18n.t('common.Notpassed') : $i18n.t('common.Pendingreview') }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Evaluationtime')" prop="createTime" align="center" width="150px">
                <template slot-scope="{ row }">
                    <span>{{ row.createTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Audittime')" prop="audittime" align="center" width="150px">
                <template slot-scope="{ row }">
                    <span>{{ row.auditTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.reason')" prop="description" align="center" width="300">
                <template slot-scope="{ row }">
                    <span>{{ row.description }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.ReviewerName')" prop="auditbyname" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.auditByName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" width="240" fixed="right">
                <template slot-scope="{ row }">

                    <el-button type="primary" size="mini" @click="handleEdit(row)" class="table-inner-button">{{ $i18n.t('common.edit') }} </el-button>

                    <el-button type="primary" size="mini" @click="handleAudit(row)" class="table-inner-button">{{ $i18n.t('common.Auditing') }} </el-button>

                    <el-button type="danger" size="mini" @click="handleDel(row)" :disabled="!(row.source == 'amazon' || row.source == 'manualimport') " class="table-inner-button">{{ $i18n.t('common.delete') }} </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <pagination
            v-show="total > 0"
            :total="total"
            :pageNo="query.current"
            :pageSize.sync="query.limit"
            @pagination="doQueryList"
        /> -->

        <div  class="pagination-container">
            <el-pagination
            :background="background"
            :current-page="query.current"
            :page-size="query.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="pageSizes"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            />
        </div>



        <el-dialog :title="$i18n.t('common.Auditing')" :visible.sync="dialogFormVisible" width="600px">
            <el-form :model="form">
                <el-form-item :label="$i18n.t('common.Numberofselectedcomments')">
                    <span>{{ selectItems.length }}</span>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.CommentID')">
                    <el-tag style="margin-right: 5px" v-for="(item, key) in selectItems" :key="key">
                        {{ item.commentId }}
                    </el-tag>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.result')">
                    <el-radio v-model="form.status" label="ADOPT">{{ $i18n.t('common.adopt') }}</el-radio>
                    <el-radio v-model="form.status" label="FAIL">{{ $i18n.t('common.Notpassed') }}</el-radio>
                </el-form-item>

                <el-form-item :label="$i18n.t('common.reason')">
                    <el-input
                        style="width: 500px"
                        type="textarea"
                        :placeholder="$i18n.t('common.Pleaseentercontent')"
                        v-model="form.description"
                        maxlength="300"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button type="primary" @click="audit">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="$i18n.t('common.Picturepreview')" :visible.sync="dialogImgVisible" width="30%">
            <div v-for="(item, index) in imgList" :key="index">
                <el-image :src="item.fileUrl" fit="fit"></el-image>
            </div>
            <!--            <span slot="footer" class="dialog-footer">-->
            <!--                <el-button @click="dialogImgVisible = false">取 消</el-button>-->
            <!--                <el-button type="primary" @click="dialogImgVisible = false">确 定</el-button>-->
            <!--            </span>-->
        </el-dialog>

        <el-dialog :title="$i18n.t('common.VideoPreview')" :visible.sync="dialogVideoVisible" width="30%">
            <div v-for="(item, index) in videoList" :key="index">
                <video style="width: 100%" :src="item.fileUrl" controls autoplay></video>
            </div>
            <!--            <span slot="footer" class="dialog-footer">-->
            <!--                <el-button @click="dialogVideoVisible = false">取 消</el-button>-->
            <!--                <el-button type="primary" @click="dialogVideoVisible = false">确 定</el-button>-->
            <!--            </span>-->
        </el-dialog>


        <el-dialog :title='$i18n.t("common.BatchInputOfProductComments")' :visible.sync="importFormVisible" v-loading="importFormLoading" width="400px">
            <el-upload
                    drag
                    :action="importUrl"
                    :on-success="onImportSuccess"
                    :on-progress="onImportProgress"
                    :on-error="onImportError"
                    :show-file-list="false"
                    :headers="headerData"
                    accept=".xlsx, .xls"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">{{ $i18n.t("common.DragtheExcelfilehere_orclickonupload") }}</div>
            </el-upload>
        </el-dialog>




        <el-dialog :title='$i18n.t("common.BatchInputOfProductComments")'
                   :visible.sync="excelDataVisible"
                   v-loading="excelDataLoading"
                   :close-on-click-modal="false">

            <el-table
                    :data="excelData"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column prop="id" :label="$i18n.t('common.Serialnumber')"></el-table-column>
                <el-table-column prop="barCode" label="EAN"></el-table-column>
                <el-table-column prop="countryCode" :label="$i18n.t('common.country')"></el-table-column>
                <el-table-column prop="userName" :label="$i18n.t('common.UserName')"></el-table-column>
                <el-table-column prop="content" :label="$i18n.t('common.Comments')"></el-table-column>
                <el-table-column prop="score" :label="$i18n.t('common.StarRatingOfTheComment')"></el-table-column>
                <el-table-column prop="createTime" :label="$i18n.t('common.CommentTime')" width="150px"></el-table-column>
                <el-table-column prop="fileCount" :label="$i18n.t('common.NumberOfAttachments')">
                    <template slot-scope="{ row }">
                        <a @click="showFileInfo(row)" style="color:blue;cursor:pointer">{{ row.fileCount }}</a>
                    </template>
                </el-table-column>
                <el-table-column :label="$i18n.t('common.operation')" align="center" width="130px" fixed="right">
                    <template slot-scope="{ row }">
                        <div style="display: flex;justify-content:flex-start;margin-bottom:20px;flex-wrap: wrap;">
                            <oss-upload itT='itTV' accept="jpg, .jpeg, .png, .mp4" :imgInfo.sync="fileInfo" style="margin-left:10px">
                                <el-button @click="setGoodsIdAndKey(row)"  type="success" size="small">{{ $i18n.t('common.UploadAttachment') }}</el-button>
                            </oss-upload>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="dialog-footer" style="display: flex; justify-content: center;margin-top: 50px;">
                <el-button style="width:15%;" type="primary" @click="batchImport()">{{ $i18n.t('common.confirm1') }}</el-button>
                <el-button style="width:15%;" @click="closeExcelDataDialog()">{{ $i18n.t('common.cancel') }}</el-button>
            </div>

        </el-dialog>


        <el-dialog :title='$i18n.t("common.Appendix")' :visible.sync="fileDataVisible" v-loading="fileDataLoading">

            <el-table
                    :data="fileData"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column prop="id" :label="$i18n.t('common.Serialnumber')"></el-table-column>
                <el-table-column prop="fileName" :label="$i18n.t('common.FileName')"></el-table-column>

                <el-table-column :label="$i18n.t('common.operation')" align="center" width="130px" fixed="right">
                    <template slot-scope="{ row }">
                        <el-button type="primary" size="mini" @click="deleteFile(row)" class="table-inner-button">{{ $i18n.t('common.delete') }} </el-button>
                    </template>
                </el-table-column>
            </el-table>

        </el-dialog>


        <el-dialog :title="$t('common.edit')" :visible.sync="dialogImgAndVideoVisible" :before-close="closeImgAndVideoDialog2" :close-on-click-modal="false">
			<div v-if="dialogImgAndVideoVisible">
				<ImageAndVideoDialog  :imageList=imageList :formData=formData   @closeDialog='closeImgAndVideoDialog'  />
			</div>        
        </el-dialog>

    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';
import cache from '@/utils/cache';
import Config from '@/utils/config';
import OssUpload from '@/components/common/OssUpload.vue';
import ImageAndVideoDialog from './components/ImageAndVideoDialog.vue';
import listImage from '@/components/common/ListImage.vue';
import Scene from '@/utils/scene';

export default {
    name: 'CommentTable',
    components: {
        ImageAndVideoDialog,
        Pagination,
        dataText,
        DataSelect,
        OssUpload,
        listImage
    },
    data() {
        return {
            autoScroll: true,
            background: true,
            pageSizes: [10, 20, 30, 50],
            delBatchDisabled: false,
            list: [],
            total: 0,
            listLoading: true,
            commentid: null,
            query: {
                current: 1,
                limit: 20,

                goodsName: null,
                barCode: null,
                brandCode: null,
                commentId: null,

                userId: null,
                score: null,
                endTime: '',
                beginTime: '',
                IsPurchase: null,
                countryCode: null,
                status: null,
                source: null
            },
            selectItems: [],
            dateArr: [],
            imgList: [],
            videoList: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            dialogFormVisible: false,
            dialogImgVisible: false,
            dialogVideoVisible: false,

            form: {
                status: 'ADOPT',
                description: ''
            },

            ossSignature: {},

            // 评论图片、视频
            dialogImgAndVideoVisible: false,
            formData: {},
            imageList:[],

            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Lastmonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.LastThreeMonths"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },

            importFormLoading: false,
            importFormVisible: false,
            importUrl: process.env.VUE_APP_URL + '/b2c/v1.0/comment/analysisCommentExcel',
            headerData: {
                Authorization: cache.getStr(Config.accessToken),
                tenantid: Scene.getTenantId(),
            },
            excelDataVisible: false,
            excelDataLoading: false,
            excelData: [],
            //阿里云文件信息
            fileInfo: {},
            goodId: 0,
            fileInfoMapKey: "",
            //所有上传的文件信息
            fileInfoMap: new Map(),
            fileDataVisible: false,
            fileDataLoading: false,
            fileData: [],
            //批量导入提交数据
            importCommentReqData: []

        };
    },
    created() {
        this.getSignature();
        this.routerHandler();
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/comment/list') {
                this.routerHandler();
            }
            // console.log('to.query.barCode', to.query.barCode);
            // if (to.query.barCode) {
            //     setTimeout(() => {
            //         this.$set(this.query, 'barCode', to.query.barCode);
            //         this.doQueryList({pageNo: 1});
            //         this.$forceUpdate();
            //         console.log('$route', this.query.barCode);
            //     }, 1);
            // }
        },
        fileInfo: {
            handler(newVal) {
                if (newVal) {

                    newVal.goodId = this.goodId;
                    newVal.fileInfoMapKey = this.fileInfoMapKey;
                    if (this.fileInfoMap.has(newVal.fileInfoMapKey)) {

                        this.fileInfoMap.get(newVal.fileInfoMapKey).push(newVal);
                    }else {
                        this.fileInfoMap.set(newVal.fileInfoMapKey, new Array(newVal));
                    }

                    this.excelData.forEach((row) => {

                        var key = row.id + "_" + row.goodId;
                        if (key === newVal.fileInfoMapKey) {
                            row.fileCount = this.fileInfoMap.get(newVal.fileInfoMapKey).length;
                        }
                    })
                }
            }
        },

        deep: true
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            var email = this.$route.params.email;
            if (email) {
                this.query.email = email;
            }
            var barCode = this.$route.params.barCode;
            if (barCode) {
                this.query.barCode = barCode;
            }
            console.log(this.query);
            this.doQueryList({ pageNo: 1 });
        },
        //获取OSS签名
        getSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2b'
                })
                .then((res) => {
                    this.ossSignature = res;
                    console.log(res);
                });
        },
        checkImg(row) {
            console.log(row);
            console.log(this.imgList);
            this.imgList = row.imgFileList;
            this.dialogImgVisible = true;
        },
        checkVideo(row) {
            console.log(row);
            console.log(this.videoList);
            this.videoList = row.videoFileList;
            this.dialogVideoVisible = true;
        },
        handleAudit(row) {
            this.$refs.multipleTable.clearSelection();
            this.handleClearForm();

            this.$refs.multipleTable.toggleRowSelection(row);

            this.form.status = row.status != null ? row.status : 'FAIL';
            this.form.description = row.description;
            this.dialogFormVisible = true;
        },
        handleEdit(row) {
            this.$refs.multipleTable.clearSelection();
            this.$refs.multipleTable.toggleRowSelection(row);

            let param = {
                commentId: row.commentId
            }
            this.$store.dispatch('getCommentFile', param).then((res) => {
                this.imageList=[];
                for( var i = 0; i < res.length; i++ ){
                    let item = {
                        "fileUrl": res[i].fileUrl,
                        "fileType": res[i].fileType
                    }
                    this.imageList.push(item)
                }
                console.log('init',this.imageList)
                this.dialogImgAndVideoVisible = true;
                this.formData = row;
            });
            
        },
        closeImgAndVideoDialog2(){
            this.closeImgAndVideoDialog(false)
        },
        closeImgAndVideoDialog(status){
            // console.log('closeImgAndVideoDialog',status)
            // 因 评论查询慢 所以关闭编辑页面 不进行刷新 编辑页面 只改评论的图片视频在列表并没有展示或使用 所以关闭页面 不必再查询
            this.dialogImgAndVideoVisible = false;
            this.formData={};
            
            if(status){
                this.doQueryList({});
            }

        },
        audit() {
            if(this.form.status == 'NOT_APPROVED'){
                this.$message.warning(this.$i18n.t('common.VideoPreview'));
                return;
            }
            let ids = [];
            for (const key in this.selectItems) {
                const element = this.selectItems[key];
                ids.push(element.commentId);
            }
            this.form.ids = ids;
            this.$store.dispatch('commentBatchAudit', this.form).then(() => {
                this.$message({
                    message: this.$i18n.t("common.Auditsuccessful"),
                    type: 'success'
                });
                this.dialogFormVisible = false;
                this.doQueryList({ pageNo: 1 });
            });
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            console.log(val);
            this.selectItems = val;

            this.delBatchDisabled = false;
            val.forEach(item=>{
                if(item.source == 'b2c'){
                    this.delBatchDisabled = true;
                }
            })
        },
        // 删除
        handleDel(row){
            this.$confirm( this.$i18n.t("common.DeleteOrNot" ) + '?',   this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                let data = []
                data.push(row.commentId)
                this.$store.dispatch('deleteBatchComment', {
                    commentIds: data
                }).then(res => {
                    let totalPage = Math.ceil((this.total - 1) / this.query.limit)
                    let currentPage = this.query.current > totalPage ? totalPage : this.query.current
                    let page = currentPage < 1 ? 1 : currentPage
                    this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
                    this.doQueryList({ pageNo: page });
                });
            }).catch(() => {
            });
        },
        // 批量删除
        handleDelBatch(){
            this.$confirm( this.$i18n.t("common.DeleteOrNot") + '?',   this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                let data = [];
                this.selectItems.forEach(item=>{
                    data.push(item.commentId)
                })
                this.$store.dispatch('deleteBatchComment', {
                    commentIds: data
                }).then(res => {
                    let totalPage = Math.ceil((this.total - data.length) / this.query.limit)
                    let currentPage = this.query.current > totalPage ? totalPage : this.query.current
                    let page = currentPage < 1 ? 1 : currentPage
                    this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
                    this.doQueryList({ pageNo: page });
                });
            }).catch(() => {
            });
        },
        // 下载导入模板
        handleDownloadTemplate(){
            let fileName= this.$i18n.t("common.ProductCommentsInputTemplate") + '.xlsx';
            this.$store.dispatch('downloadTemplateComment', { fileName: fileName }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Downloadsuccessful")
                });
            });
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.query.beginTime = ''
            this.query.endTime = ''
            if (this.dateArr != null && this.dateArr.length != 0) {
                this.query.beginTime = this.dateArr[0];
                this.query.endTime = this.dateArr[1];
            }

            this.doQueryList({ pageNo: 1 });
        },

        handleSizeChange(pageSize) {
            this.doQueryList({ pageNo: this.pageNo, pageSize: pageSize })
         
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
            },
        handleCurrentChange(pageNo) {
            this.doQueryList({ pageNo: pageNo, pageSize: this.pageSize })
           
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
        },

        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.limit = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getCommentPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$common
                .confirm(this.$i18n.t("common.Areyousuretodelete"))
                .then(() => commentApi.deleteById(row.commentid))
                .then(() => {
                    this.$common.showMsg('success', this.$i18n.t("common.Successfullydeleted"));
                    return this.doQueryList({ pageNo: 1 });
                });
        },
        /**
         * 批量审核记录
         */
        handleDeleteBatch() {
            if (this.selectItems.length <= 0) {
                this.$message.warning(this.$i18n.t("common.Pleaseselectacomment"));
                return;
            }
            this.handleClearForm();
            this.dialogFormVisible = true;
        },

        handleClearQuery() {
            let params = {
                current: 1,
                limit: 10,

                paramsId: null,
                brandCode: null,
                commentId: null,
                email: null,
                score: null,
                endTime: '',
                beginTime: '',
                IsPurchase: null,
                countryCode: null,
                status: null,
                source: null
            };
            this.dateArr = [];
            this.query = params;
            this.handleQuery();
        },
        handleClearForm() {
            this.form = {
                status: 'ADOPT',
                description: ''
            };
        },
        /**
         * 打开导入表单
         */
        handleImport() {
            this.importFormVisible = true;
        },
        onImportProgress(event, file, fileList) {
            this.importFormLoading = true;
        },
        onImportError(error, file, fileList) {
            this.$message({
                showClose: false,
                message: this.$i18n.t("common.Fileimportfailed_pleasecheckthefilecontent"),
                type: 'error'
            });
            this.importFormLoading = false;
            this.$common.showNotifyError(JSON.parse(error.message));
        },
        onImportSuccess(response, file, fileList) {
            this.importFormVisible = false;
            this.importFormLoading = false;
            this.excelDataVisible = true;
            this.excelData = response;
            this.fileData = [];
            this.fileInfoMap = new Map();
        },
        setGoodsIdAndKey(row) {
            this.goodId = row.goodId;
            this.fileInfoMapKey = row.id + "_" + row.goodId;
        },
        showFileInfo(row) {
            this.setGoodsIdAndKey(row);
            this.fileDataVisible = true;
            var fileInfos = this.fileInfoMap.get(row.id + "_" + row.goodId);
            this.fileData = [];
            var i = 1;
            fileInfos.forEach((fileInfo) => {

                this.fileData.push({

                    id: i,
                    imageUrl: fileInfo.imageUrl,
                    fileName: fileInfo.fileName,
                    goodId: fileInfo.goodId
                });
                i = i + 1;
            })
        },
        deleteFile(row) {

            var fileData = this.fileInfoMap.get(this.fileInfoMapKey);

            var newFileData = fileData.filter((item) => {

                return item.imageUrl !== row.imageUrl;
            });
            var i = 1;
            newFileData.forEach((item) =>{
                item.id = i;
                i = i+1;
            })

            this.fileData = newFileData;
            this.fileInfoMap.set(this.fileInfoMapKey, newFileData);

            this.excelData.forEach((item) => {

                if (item.id + "_" + item.goodId === this.fileInfoMapKey) {
                    item.fileCount = this.fileInfoMap.get(this.fileInfoMapKey).length;
                }
            })
        },
        closeExcelDataDialog() {
            this.excelDataVisible = false;
        },
        batchImport() {

            this.importCommentReqData = [];
            this.excelData.forEach((item) => {

                var fileInfo = this.fileInfoMap.get(item.id + "_" + item.goodId);
                if (fileInfo) {

                    var importCommentFileDtoList = [];
                    fileInfo.forEach((row) =>{

                        var fileType = "";
                        if ("图片" == row.type) {
                            fileType = "Image";
                        }

                        if ("视频" == row.type) {
                            fileType = "Video";
                        }

                        importCommentFileDtoList.push({

                            fileUrl: row.imageUrl,
                            fileType: fileType
                        });
                    })
                    item.importCommentFileDtoList = importCommentFileDtoList;
                }

                this.importCommentReqData.push(item);
            })

            if (this.importCommentReqData.length === 0) {

                return;
            }

            this.$store.dispatch('commentBatchImport', this.importCommentReqData).then(() => {
                this.$message({
                    message: this.$i18n.t("common.ImportSuccessful"),
                    type: 'success'
                });
                this.excelDataVisible = false;
                this.doQueryList({ pageNo: 1 });
            });

        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
    margin-top: 10px;
}

.pagination-container {
  background: #fff;
  padding: 20px 16px;
}
.pagination-container.hidden {
  display: none;
}
</style>
