var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "200px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                      oninput: "value=value.replace(/[%]/g,'')"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.idFuzz,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "idFuzz", $$v)
                      },
                      expression: "query.idFuzz"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t("common.ActivityName")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.giftActivityName,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "giftActivityName", $$v)
                      },
                      expression: "query.giftActivityName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t("common.LandingPageActivityName")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.pageName,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "pageName", $$v)
                      },
                      expression: "query.pageName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: false,
                        placeholder: _vm.$i18n.t("common.country"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCode", $$v)
                        },
                        expression: "query.countryCode"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      clearable: "",
                      type: "datetime",
                      placeholder: _vm.$i18n.t("common.Startdate")
                    },
                    model: {
                      value: _vm.query.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "startTime", $$v)
                      },
                      expression: "query.startTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      clearable: "",
                      type: "datetime",
                      "picker-options": _vm.pickerOptions,
                      placeholder: _vm.$i18n.t("common.Enddate"),
                      "default-time": "['23:59:59']"
                    },
                    model: {
                      value: _vm.query.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "endTime", $$v)
                      },
                      expression: "query.endTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      codeType: "activity_status",
                      placeholder: _vm.$i18n.t("common.Activitystatus")
                    },
                    model: {
                      value: _vm.query.activityStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "activityStatus", $$v)
                      },
                      expression: "query.activityStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.Enableornot")
                      },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Enable"),
                          value: "1"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Disabled"),
                          value: "0"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$i18n.t("common.SuperimposedEvents")
                      },
                      model: {
                        value: _vm.query.isOverlay,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "isOverlay", $$v)
                        },
                        expression: "query.isOverlay"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.yes"), value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$i18n.t("common.Add1")) +
                  "\n            "
              )
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: { click: _vm.handleGiftActivitySort }
            },
            [
              _vm._v(
                "\n\n                " +
                  _vm._s(_vm.$i18n.t("common.ActivitySorting")) +
                  "\n            "
              )
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: { click: _vm.handleGiftActivityPublicityChart }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$i18n.t("common.GiftsTopicManagement")) +
                  "\n            "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            height: "calc(100% - 230px)",
            data: _vm.list.list,
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.GiveawayEventID"),
              align: "center",
              width: "160px",
              prop: "giftActivityId"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.GiveawayEventName"),
              prop: "giftActivityName",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.LandingPageActivityName"),
              prop: "pageName",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ProductCategory"),
              prop: "productNum",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.country"),
              prop: "countryCode",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$dict.getDictValue(
                            "countrys",
                            scope.row.countryCode
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ActivityMode"),
              prop: "activityType",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$dict.getDictValue(
                            "gift_activity_type",
                            scope.row.activityType
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.TotalNumberOfActivities"),
              prop: "allActivityNum",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.GiftsGivenQuantity"),
              prop: "allQuantityGiven",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.starttime"),
              prop: "startTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Endtime"),
              prop: "endTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Activitystatus"),
              prop: "activityStatus",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.activityStatus == 2
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Upcoming")))
                        ])
                      : _vm._e(),
                    row.activityStatus == 1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Inprogress")))
                        ])
                      : _vm._e(),
                    row.activityStatus == 0
                      ? _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Ended")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("common.EnabledStatus"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _c(
                        "span",
                        {
                          style: { color: scope.row.status ? "green" : "red" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                scope.row.status
                                  ? _vm.$t("common.Enable")
                                  : _vm.$t("common.Disabled")
                              ) +
                              "\n                        "
                          )
                        ]
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.SuperimposedEvents"),
              prop: "isOverlay",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.isOverlay == 1
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.isOverlay == 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateByName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.updateByName
                      ? _c("span", [_vm._v(_vm._s(row.updateByName))])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.SystemAutoUpdate")))
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              "show-overflow-tooltip": "",
              width: "250px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [
                        row.activityStatus == 0
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$i18n.t("common.check")))
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$i18n.t("common.edit")))
                            ])
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handlePageSetting(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.ThemeConfiguration"))
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleGiftSort(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.GiftSorting"))
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: row.activityStatus != 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteSingle(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$i18n.t("common.delete"))
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.addVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "710px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.addGiftActivity,
                    "label-position": "right",
                    size: "small",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: true,
                        label: _vm.$i18n.t("common.Enableornot"),
                        prop: "status"
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce669" },
                        model: {
                          value: _vm.addGiftActivity.status,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "status", $$v)
                          },
                          expression: "addGiftActivity.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.ActivityName"),
                        prop: "giftActivityName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "100",
                          placeholder: _vm.$i18n.t("common.ActivityName")
                        },
                        model: {
                          value: _vm.addGiftActivity.giftActivityName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addGiftActivity,
                              "giftActivityName",
                              $$v
                            )
                          },
                          expression: "addGiftActivity.giftActivityName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.LandingPageActivityName"),
                        prop: "pageName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "100",
                          placeholder: _vm.$i18n.t(
                            "common.LandingPageActivityName"
                          )
                        },
                        model: {
                          value: _vm.addGiftActivity.pageName,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "pageName", $$v)
                          },
                          expression: "addGiftActivity.pageName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Activitytime"),
                        prop: "startTime",
                        required: true
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: "",
                          type: "datetime",
                          disabled:
                            _vm.formTitle == _vm.$i18n.t("common.edit") &&
                            _vm.addGiftActivity.activityStatus == 1,
                          placeholder: _vm.$i18n.t("common.Startdate"),
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.addGiftActivity.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "startTime", $$v)
                          },
                          expression: "addGiftActivity.startTime"
                        }
                      }),
                      _c("span", [_vm._v(" - ")]),
                      _c("el-date-picker", {
                        attrs: {
                          clearable: "",
                          type: "datetime",
                          "picker-options": _vm.pickerOptions,
                          placeholder: _vm.$i18n.t("common.Enddate"),
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "['23:59:59']"
                        },
                        model: {
                          value: _vm.addGiftActivity.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "endTime", $$v)
                          },
                          expression: "addGiftActivity.endTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: true,
                        label: _vm.$i18n.t("common.country"),
                        prop: "countryCode"
                      }
                    },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          disabled:
                            _vm.formTitle == _vm.$i18n.t("common.edit") &&
                            _vm.addGiftActivity.activityStatus == 1,
                          clearable: "",
                          codeType: "countrys",
                          placeholder: _vm.$i18n.t("common.country")
                        },
                        on: { change: _vm.countryChange },
                        model: {
                          value: _vm.addGiftActivity.countryCode,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "countryCode", $$v)
                          },
                          expression: "addGiftActivity.countryCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: true,
                        label: _vm.$i18n.t("common.ActivityMode"),
                        prop: "activityType"
                      }
                    },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "gift_activity_type",
                          placeholder: _vm.$i18n.t("common.ActivityMode")
                        },
                        model: {
                          value: _vm.addGiftActivity.activityType,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "activityType", $$v)
                          },
                          expression: "addGiftActivity.activityType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.ActivityRating"),
                        prop: "activityRating"
                      }
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "input_number",
                        attrs: {
                          disabled:
                            _vm.formTitle == _vm.$i18n.t("common.edit") &&
                            _vm.addGiftActivity.activityStatus == 1,
                          min: 1,
                          max: 10
                        },
                        on: { change: _vm.handleRatingChange },
                        nativeOn: {
                          keydown: function($event) {
                            return _vm.channelInputLimit.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.addGiftActivity.activityRating,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "activityRating", $$v)
                          },
                          expression: "addGiftActivity.activityRating"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "my-form" },
                        _vm._l(_vm.addGiftActivity.activityRating, function(
                          num,
                          index
                        ) {
                          return _c(
                            "el-form",
                            { key: num },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "my-form-item",
                                  attrs: {
                                    prop: String(num),
                                    rules: _vm.ratingRules.activityRating
                                  }
                                },
                                [
                                  _c("span", {
                                    staticStyle: { "margin-right": "10px" },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.indexIcon(num))
                                    }
                                  }),
                                  _c("el-input", {
                                    staticClass: "my-input",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^0-9.]/g,'')",
                                      placeholder: _vm.$i18n.t(
                                        "common.Orderamount"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.addGiftActivity.giftActivityRatings[
                                          num - 1
                                        ].orderAmt,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addGiftActivity
                                            .giftActivityRatings[num - 1],
                                          "orderAmt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addGiftActivity.giftActivityRatings[num - 1].orderAmt"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                -\n                                "
                                  ),
                                  _c("el-input", {
                                    staticClass: "my-input",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                                      placeholder: _vm.$i18n.t(
                                        "common.GiftsQuantity"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.addGiftActivity.giftActivityRatings[
                                          num - 1
                                        ].giftNum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addGiftActivity
                                            .giftActivityRatings[num - 1],
                                          "giftNum",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addGiftActivity.giftActivityRatings[num - 1].giftNum"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.SuperimposedEvents"),
                        required: true
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce669" },
                        model: {
                          value: _vm.addGiftActivity.isOverlay,
                          callback: function($$v) {
                            _vm.$set(_vm.addGiftActivity, "isOverlay", $$v)
                          },
                          expression: "addGiftActivity.isOverlay"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Gifts"),
                        required: true
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.chooseGift }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.SelectAGift")))]
                      ),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.addGiftActivity.giftActivityDetails
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t("common.Serialnumber"),
                              type: "index"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t("common.barcode"),
                              prop: "barCode",
                              width: "120px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t("common.ProductName"),
                              prop: "title",
                              width: "100px",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t("common.CurrentInventory"),
                              prop: "ecomRemainQty"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t(
                                "common.TotalNumberOfActivities"
                              ),
                              prop: "activityNum"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          size: "small",
                                          controls: false,
                                          oninput:
                                            "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                                          min: 1
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeValue()
                                          }
                                        },
                                        model: {
                                          value: scope.row.activityNum,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "activityNum",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.activityNum"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4020428622
                            )
                          }),
                          _vm.formTitle == _vm.$i18n.t("common.edit")
                            ? _c("el-table-column", {
                                attrs: {
                                  label: _vm.$i18n.t(
                                    "common.GiftsGivenQuantity"
                                  ),
                                  prop: "quantityGiven"
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$i18n.t("common.state"),
                              prop: "enableStatus"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              color: scope.row.enableStatus
                                                ? "green"
                                                : "red"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  scope.row.enableStatus
                                                    ? _vm.$t("common.Enable")
                                                    : _vm.$t("common.Disabled")
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1997116771
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _vm.formTitle != _vm.$i18n.t("common.check")
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.closeForm()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$i18n.t("common.cancel")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.formTitle != _vm.$i18n.t("common.check")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled:
                              _vm.formTitle == _vm.$i18n.t("common.check")
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSubmit()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.confirm")) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.formTitle == _vm.$i18n.t("common.check")
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.closeForm()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.close")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.giftActivityPublicityChartVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.giftActivityPublicityChartTitle,
                visible: _vm.giftActivityPublicityChartVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftActivityPublicityChartVisible = $event
                }
              }
            },
            [
              _c("giftActivityPublicityChart", {
                ref: "giftActivityPublicityChart",
                on: {
                  closeGiftActivityPublicityChart:
                    _vm.closeGiftActivityPublicityChart
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.giftActivitySortVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.giftActivitySortTitle,
                visible: _vm.giftActivitySortVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftActivitySortVisible = $event
                }
              }
            },
            [
              _c("giftActivitySort", {
                ref: "giftActivitySort",
                on: {
                  closeGiftActivityPublicityChart: _vm.closeGiftActivitySort
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.giftActivitySortVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.giftActivitySortTitle,
                visible: _vm.giftActivitySortVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftActivitySortVisible = $event
                }
              }
            },
            [
              _c("giftActivitySort", {
                ref: "giftActivitySort",
                attrs: { langsArr: _vm.langsArr },
                on: { closeGiftActivitySort: _vm.closeGiftActivitySort }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.giftVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Pleaseselectaproduct"),
                visible: _vm.giftVisible,
                "close-on-click-modal": false,
                width: "1200px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftVisible = $event
                },
                close: function($event) {
                  return _vm.closeGiftForm()
                }
              }
            },
            [
              _c("GiftDialog", {
                ref: "giftDialog",
                on: {
                  getGiftList: _vm.getGiftList,
                  closeGiftForm: _vm.closeGiftForm
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.giftSortVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.EventGiftsSorting"),
                visible: _vm.giftSortVisible,
                "close-on-click-modal": false,
                width: "700px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftSortVisible = $event
                },
                close: function($event) {
                  return _vm.closeGiftSort()
                }
              }
            },
            [
              _c("giftSort", {
                ref: "giftSort",
                on: { closeGiftSort: _vm.closeGiftSort }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.giftPageSettingVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.ThemeConfiguration"),
                visible: _vm.giftPageSettingVisible,
                "close-on-click-modal": false,
                width: "700px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.giftPageSettingVisible = $event
                },
                close: function($event) {
                  return _vm.closeGiftPageSetting()
                }
              }
            },
            [
              _c("giftPageSetting", {
                ref: "giftPageSetting",
                on: { closeGiftPageSetting: _vm.closeGiftPageSetting }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }