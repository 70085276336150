var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formdata", attrs: { model: _vm.formdata, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.PreSaleItems"),
                prop: "goodsId",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: _vm.$t("common.Barcode"), disabled: "" },
                model: {
                  value: _vm.formdata.barCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "barCode", $$v)
                  },
                  expression: "formdata.barCode"
                }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    type: "primary",
                    disabled:
                      this.formdata.preSaleStatus == "UnderWay" ||
                      _vm.disabledView
                  },
                  on: { click: _vm.changeGoods }
                },
                [_vm._v(_vm._s(_vm.$t("common.Selectproduct")))]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.PreSaleCountries"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "180px" },
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$i18n.t("common.Pleaseselectacountry"),
                    disabled:
                      this.formdata.preSaleStatus == "UnderWay" ||
                      _vm.disabledView
                  },
                  on: { change: _vm.changeCountry },
                  model: {
                    value: _vm.formdata.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formdata, "countryCode", $$v)
                    },
                    expression: "formdata.countryCode"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.name,
                      value: item.code,
                      disabled: item.disabled
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Activitytime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetime",
                  disabled:
                    this.formdata.preSaleStatus == "UnderWay" ||
                    _vm.disabledView,
                  placeholder: _vm.$i18n.t("common.Startdate")
                },
                on: {
                  change: function($event) {
                    return _vm.handleTime()
                  }
                },
                model: {
                  value: _vm.formdata.preSaleStartTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "preSaleStartTime", $$v)
                  },
                  expression: "formdata.preSaleStartTime"
                }
              }),
              _c("span", [_vm._v(" - ")]),
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetime",
                  disabled: _vm.disabledView,
                  "picker-options": _vm.pickerOptions,
                  placeholder: _vm.$i18n.t("common.Enddate"),
                  "default-time": "['23:59:59']"
                },
                on: {
                  change: function($event) {
                    return _vm.handleTime()
                  }
                },
                model: {
                  value: _vm.formdata.preSaleEndTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "preSaleEndTime", $$v)
                  },
                  expression: "formdata.preSaleEndTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.PresalePrice"),
                prop: "preSaleValue",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabledView },
                  model: {
                    value: _vm.formdata.preSaleDiscountType,
                    callback: function($$v) {
                      _vm.$set(_vm.formdata, "preSaleDiscountType", $$v)
                    },
                    expression: "formdata.preSaleDiscountType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "Discount" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.discount")))
                  ]),
                  _c("el-radio", { attrs: { label: "Price" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.price")))
                  ])
                ],
                1
              ),
              _c("el-input", {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: {
                  disabled: _vm.disabledView,
                  placeholder: _vm.$t("common.PleaseEnter")
                },
                on: {
                  blur: function($event) {
                    return _vm.handlePreSaleValue()
                  }
                },
                model: {
                  value: _vm.formdata.preSaleValue,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "preSaleValue", $$v)
                  },
                  expression: "formdata.preSaleValue"
                }
              }),
              _c(
                "span",
                { staticStyle: { width: "120px", "margin-left": "10px" } },
                [
                  _vm._v(
                    _vm._s(_vm.$t("common.PreSalePrice")) +
                      ":" +
                      _vm._s(_vm.formdata.preSalePrice)
                  )
                ]
              ),
              _c(
                "span",
                { staticStyle: { width: "120px", "margin-left": "10px" } },
                [
                  _vm._v(_vm._s(_vm.$t("common.FixedPrice")) + ":"),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(
                      _vm._s(Number(_vm.formdata.fixedPrice).toFixed(2)) + " "
                    )
                  ])
                ]
              ),
              _c(
                "span",
                { staticStyle: { width: "120px", "margin-left": "10px" } },
                [
                  _vm._v(_vm._s(_vm.$i18n.t("common.price1")) + ":"),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(
                      _vm._s(Number(_vm.formdata.salePriceEbp).toFixed(2)) + " "
                    )
                  ])
                ]
              ),
              _c(
                "span",
                { staticStyle: { width: "120px", "margin-left": "10px" } },
                [
                  _vm._v(_vm._s(_vm.$i18n.t("common.fixedprice")) + ":"),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(
                      _vm._s(Number(_vm.formdata.resultPrice).toFixed(2)) + " "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.PresaleQuantity"),
                        prop: "preSaleQuantity",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          disabled: _vm.disabledView,
                          placeholder: _vm.$t("common.PleaseEnter")
                        },
                        on: {
                          blur: function($event) {
                            return _vm.handlePreSaleQuantity()
                          }
                        },
                        model: {
                          value: _vm.formdata.preSaleQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.formdata, "preSaleQuantity", $$v)
                          },
                          expression: "formdata.preSaleQuantity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.VirtualQuantity"),
                        "label-width": "100px"
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "预售活动时间至少大于2天，活动数量大于50 才可以设置虚拟活动数量功能",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-warning" })]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "150px", "margin-left": "10px" },
                        attrs: {
                          disabled: _vm.disabledView || _vm.virtualQtyDisabled,
                          placeholder: _vm.$t("common.PleaseEnter"),
                          oninput:
                            "value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                        },
                        on: { input: _vm.calVirtualSales },
                        model: {
                          value: _vm.formdata.virtualQty,
                          callback: function($$v) {
                            _vm.$set(_vm.formdata, "virtualQty", $$v)
                          },
                          expression: "formdata.virtualQty"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.VirtualSalesVolume"),
                        "label-width": "80px"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formdata.virtualSalesVolume))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.PreSaleDeposit"),
                prop: "depositType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled:
                      this.formdata.preSaleStatus == "UnderWay" ||
                      _vm.disabledView
                  },
                  on: {
                    input: function(val) {
                      return _vm.handleDepositType(val)
                    }
                  },
                  model: {
                    value: _vm.formdata.depositType,
                    callback: function($$v) {
                      _vm.$set(_vm.formdata, "depositType", $$v)
                    },
                    expression: "formdata.depositType"
                  }
                },
                [
                  _c(
                    "el-radio",
                    { attrs: { label: "DEPOSIT_AND_FINAL_PAYMENT" } },
                    [_vm._v(_vm._s(_vm.$t("common.ByProportion")))]
                  ),
                  _c("el-radio", { attrs: { label: "FULL_PAYMENT" } }, [
                    _vm._v(_vm._s(_vm.$t("common.ByFullPayment")))
                  ])
                ],
                1
              ),
              _vm.formdata.depositType == "DEPOSIT_AND_FINAL_PAYMENT"
                ? _c(
                    "span",
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px", "margin-left": "10px" },
                        attrs: {
                          disabled:
                            this.formdata.preSaleStatus == "UnderWay" ||
                            _vm.disabledView,
                          placeholder: _vm.$t("common.PleaseEnter")
                        },
                        on: {
                          blur: function($event) {
                            return _vm.handleDepositValue()
                          }
                        },
                        model: {
                          value: _vm.formdata.depositValue,
                          callback: function($$v) {
                            _vm.$set(_vm.formdata, "depositValue", $$v)
                          },
                          expression: "formdata.depositValue"
                        }
                      }),
                      _vm._v("%\n                ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.AssociatedAGBSOnthewayOrders"),
                prop: "agbsInTransitOrderNo",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  disabled: _vm.disabledView,
                  placeholder: _vm.$t("common.PleaseEnterTheDocumentCode")
                },
                model: {
                  value: _vm.formdata.agbsInTransitOrderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "agbsInTransitOrderNo", $$v)
                  },
                  expression: "formdata.agbsInTransitOrderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.EstimatedDeliveryTime"),
                prop: "expectedShipmentTime",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.disabledView,
                  type: "datetime",
                  placeholder: _vm.$t("common.SelectTime")
                },
                model: {
                  value: _vm.formdata.expectedShipmentTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "expectedShipmentTime", $$v)
                  },
                  expression: "formdata.expectedShipmentTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.FinalPaymentDeadline"),
                prop: "finalPaymentDeadlineTime",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  disabled:
                    _vm.formdata.finalPaymentDeadlineTimeDisabled ||
                    _vm.disabledView,
                  placeholder: _vm.$t("common.SelectTime")
                },
                model: {
                  value: _vm.formdata.finalPaymentDeadlineTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "finalPaymentDeadlineTime", $$v)
                  },
                  expression: "formdata.finalPaymentDeadlineTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.PreSaleShippingFee"),
                prop: "freightFeeType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabledView },
                  on: {
                    input: function(val) {
                      return _vm.handleFreightFeeType(val)
                    }
                  },
                  model: {
                    value: _vm.formdata.freightFeeType,
                    callback: function($$v) {
                      _vm.$set(_vm.formdata, "freightFeeType", $$v)
                    },
                    expression: "formdata.freightFeeType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "Fixed" } }, [
                    _vm._v(_vm._s(_vm.$t("common.FixedShippingFee")))
                  ]),
                  _c("el-radio", { attrs: { label: "Condition" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("common.FreeShippingSubjectToConditions"))
                    )
                  ])
                ],
                1
              ),
              _c(
                "span",
                { staticStyle: { width: "100px", "margin-left": "10px" } },
                [_vm._v(_vm._s(_vm.$t("common.Basicfreight")))]
              ),
              _c("el-input", {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: {
                  disabled: _vm.disabledView,
                  placeholder: _vm.$t("common.Pleaseentertheshippingcost")
                },
                model: {
                  value: _vm.formdata.freightFee,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "freightFee", $$v)
                  },
                  expression: "formdata.freightFee"
                }
              }),
              _vm.formdata.freightFeeType == "Condition"
                ? _c(
                    "span",
                    { staticStyle: { width: "100px", "margin-left": "10px" } },
                    [_vm._v(_vm._s(_vm.$t("common.OrderPrice")) + " ≥")]
                  )
                : _vm._e(),
              _vm.formdata.freightFeeType == "Condition"
                ? _c("el-input", {
                    staticStyle: { width: "150px", "margin-left": "10px" },
                    attrs: {
                      disabled: _vm.disabledView,
                      placeholder: _vm.$t("common.PleaseEnter")
                    },
                    model: {
                      value: _vm.formdata.freeFreightFeePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formdata, "freeFreightFeePrice", $$v)
                      },
                      expression: "formdata.freeFreightFeePrice"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.operationType != "view"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      return _vm.closePreSaleDialog(false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              )
            : _vm._e(),
          _vm.operationType != "view"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
              )
            : _vm._e(),
          _vm.operationType == "view"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      return _vm.closePreSaleDialog(false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.goodsDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.goodsDialogVisible,
                width: "1200px",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.goodsDialogVisible = $event
                },
                close: _vm.closeGooodsDialog
              }
            },
            [
              _c("goods-dialog", {
                ref: "goodsDialog",
                attrs: { defaultDictionary: _vm.defaultDictionary },
                on: { handleSelectGoods: _vm.handleSelectGoods }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }