var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "formItem", attrs: { model: _vm.formItem } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.ProductID"),
                    prop: "palletName",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [[_c("span", [_vm._v(_vm._s(_vm.formItem.palletName))])]],
                2
              ),
              _vm._l(_vm.languageList, function(item) {
                return _c(
                  "el-form-item",
                  {
                    key: item.id,
                    attrs: {
                      label: item.name,
                      prop: item.palletName,
                      "label-width": _vm.formLabelWidth
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        "show-word-limit": "",
                        maxlength: "100",
                        placeholder: _vm.$i18n.t(
                          "common.Pleaseentertheproducttitle"
                        )
                      },
                      on: {
                        input: function($event) {
                          return _vm.forceUpdate($event)
                        }
                      },
                      model: {
                        value: item.palletName,
                        callback: function($$v) {
                          _vm.$set(item, "palletName", $$v)
                        },
                        expression: "item.palletName"
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialogTranslate(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("formItem")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }