<template>
    <div>

        <el-form
                ref="dataForm"
                label-position="left"
                size="small"
                label-width="110px"
                style="margin-left: 50px"
        >

            <el-tabs v-model='activeName' @tab-click="handleTabClick">
                <el-tab-pane v-for='(item,index) in langsArr' :key='index' :label='item.langName' :name='item.code'>

                    <el-form-item :label="$i18n.t('common.ActivityMode')" prop="activityType">
                        <dataSelect
                                style="width: 180px"
                                class="filter-item"
                                v-model="item.activityType"
                                :codeType="'gift_activity_type'"
                                @change='handleChange'
                        ></dataSelect>
                    </el-form-item>


                    <div>

                        <el-form-item :label="$i18n.t('common.ActivitySorting')" prop="sort">

                            <el-table
                                    :data="item.tableData"
                                    :class="item.lang"
                                    border
                                    fit
                                    highlight-current-row>
                                <el-table-column
                                        prop="sort"
                                        :label="$i18n.t('common.DragToSort')"
                                        align="center">
                                    <i class=" el-icon-sort drag-btn"></i>
                                </el-table-column>
                                <el-table-column
                                        prop="giftActivityName"
                                        :label="$i18n.t('common.ActivityName')"
                                        align="center">
                                </el-table-column>
                                <el-table-column
                                        prop="pageName"
                                        :label="$i18n.t('common.LandingPageActivityName')"
                                        align="center">
                                </el-table-column>
                                <el-table-column
                                        prop="giftActivityId"
                                        :label="$i18n.t('common.ActivityCode')"
                                        align="center">
                                </el-table-column>
                            </el-table>

                        </el-form-item>

                    </div>
                </el-tab-pane>
            </el-tabs>


        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{ $i18n.t('common.cancel') }}</el-button>
            <el-button type="primary" @click="saveGiftActivitySort">{{ $i18n.t('common.confirm1') }}</el-button>
        </div>

    </div>
</template>


<script>

import dataSelect from '@/components/common/DataSelect.vue';
import Sortable from "sortablejs";


export default {
    components: { dataSelect },
    name: 'giftActivitySort',
    props: {
        langsArr: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            langName: '',
            activeName: '0'
        }
    },
    created() {
        document.body.ondrop = function(event){
            event.preventDefault();
            event.stopPropagation();
        }
    },
    mounted() {

        this.initTableData();
    },
    methods: {

        initTableData() {

            var params = {
                activityType: 'Order_Gift'
            };
            this.$store
                .dispatch('listGiftActivitySortVO', params)
                .then((res) => {

                    this.langsArr.forEach((x) => {
                        x.tableData = [];
                        if (this.langName === '') {
                            this.langName = x.langName;
                        }
                        res.forEach((x2) => {
                            if (x.lang === x2.countryCode) {
                                x.tableData.push(x2);
                            }
                        });
                    });
                    this.langsArr.forEach(x => {

                        this.rowDrop(x.tableData, x.lang);
                    })
                })
                .finally(() => {});

        },
        // 行拖拽
        rowDrop(tableData, code) {

            this.$nextTick(() => {
                this.sortable1 = Sortable.create(

                    document.querySelector("." + code +  " .el-table__body-wrapper tbody"),
                    {
                        handle: ".drag-btn",
                        onEnd: ({ newIndex, oldIndex }) => {
                            tableData.splice(
                                newIndex,
                                0,
                                tableData.splice(oldIndex, 1)[0]
                            );
                            var newArray = tableData.slice(0);
                            tableData = [];
                            this.$nextTick(function () {
                                tableData = newArray;
                            });
                        },
                    }
                );
            });
        },
        saveGiftActivitySort() {

            var params = [];

            this.langsArr.forEach(x => {

                if (x.tableData.length > 0) {
                    params.push(x.tableData);
                }
            })

            this.$store.dispatch('updateGiftActivitySort', params).then(() => {
                this.$message({
                    message: this.$i18n.t("common.Successfullysaved"),
                    type: 'success'
                });
                this.closeDialog();
            });

        },
        closeDialog() {

            this.$emit('closeGiftActivitySort');
        },
        handleChange(data) {

            let countryCode = '';

            this.langsArr.forEach(x => {

                if (x.langName === this.langName) {
                    countryCode = x.lang;
                }
            })

            var params = {
                activityType: data,
                countryCode: countryCode
            };

            this.$store
                .dispatch('listGiftActivitySortVO', params)
                .then((res) => {

                    this.langsArr.forEach((x) => {

                        if (x.lang === countryCode) {

                            x.tableData = res;
                            this.rowDrop(x.tableData, x.countryCode);
                        }

                    });
                })
                .finally(() => {});

        },
        handleTabClick(data) {

            this.langName = data.label;
        }

    }

};



</script>

<style>

    .drag-btn{
        cursor: pointer;
    }
    .dialog-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>