<template>
    <div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :defaultListQuery="defaultListQuery" :placeholder="placeholder" :dictionary="dictionary" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 10px 0px 10px 20px;">
			<el-button @click="add" type="success" size="small">{{$i18n.t("common.HomepageConfiguration")}}</el-button>

			<el-button @click="addSite" type="success" size="small">{{$i18n.t("common.Add1")}} </el-button>
		</div>
        <el-table
            ref="tableData"
			v-loading="listloading"
            :data="pageData.list"
			height="calc(100% - 170px)"
            border
            style="width: 100%"         
        >
            <el-table-column prop="siteNavigationId" label="ID" align="center" show-overflow-tooltip> </el-table-column>

			<!--
			<el-table-column prop="brandCode" :label='$i18n.t("common.brand")'  align="center" show-overflow-tooltip>

				<template slot-scope="scope">
				    <div>{{$dict.getDictValue('brand',scope.row.brandCode)}}</div>
				</template>	
			</el-table-column>

			-->
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>

				<template slot-scope="scope">
				    <div>{{$dict.getDictValue('countrys',scope.row.countryCode)}}</div>
				</template>			
			</el-table-column>
			<el-table-column prop="title"  :label='$i18n.t("common.Navigationcontent")'  align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="createTime"  :label='$i18n.t("common.Creationtime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
				    <div>{{scope.row.createTime | formatDate}}</div>
				</template>	
			</el-table-column>
			<el-table-column prop="updateTime" :label='$i18n.t("common.Updatetime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
				    <div>{{scope.row.updateTime | formatDate}}</div>
				</template>	
			</el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small"> {{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
            	:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>
        </div>
		<el-dialog :title='$i18n.t("common.HomepageConfiguration")'  :visible.sync="dialogConfigFormVisible"  :before-close="closeDialog" :close-on-click-modal="false">
			<div v-if="dialogConfigFormVisible">
				<config-dialog :langList='languageList' @closeDialog='closeDialog' @handleSearchList="handleSearchList"/>
			</div>		    
		</el-dialog>
        <el-dialog :title='$i18n.t("common.edit")' :visible.sync="dialogFormVisible" :before-close="closeDialog2" :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<update-dialog :editData='editData' @closeDialog='closeDialog2'/>
			</div>          
        </el-dialog>

		<el-dialog :title='$i18n.t("common.WebsiteNavigationConfiguration")' width="600px" :visible.sync="dialogAddVisible" :before-close="closeDialogAdd" :close-on-click-modal="false">
			<div v-if="dialogAddVisible">
				<add-dialog  :dictionary='dictionary'  @closeDialog='closeDialogAdd'/>
			</div>          
        </el-dialog>

    </div>
</template>
<script>
import configDialog from './components/configDialog.vue';
import UpdateDialog from './components/UpdateDialog.vue';
import AddDialog from './components/AddDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import dictionaryUtil from '@/utils/dictionaryUtil';
const defaultlanguageList=dictionaryUtil.getDict('lang')
const defaultDictionary={
		// 国家
		country:dictionaryUtil.getDict('countrys'),
		brandCode:dictionaryUtil.getDict('brand')
	};  
export default {
    components: { configDialog,UpdateDialog,FilterContainer,AddDialog },
    name: 'varoantAdmin',
    data() {
        return {
			languageList:JSON.parse(JSON.stringify(defaultlanguageList)),
            //查询项
			dictionary:Object.assign({}, defaultDictionary),
			editData:{},
			//组件标签是否显示
			arg:{
				// brand:true,
				country:true,
			},

			// placeholder:{
			// 	country:this.$i18n.t("common.brand")
			// },

			placeholder:{
				country:this.$i18n.t("common.country")
			},
			pageData:{},
			listQuery:{
			  "page": 1,
			  "limit": 10
			},
			defaultListQuery:{
			  "page": 1,
			  "limit": 10
			},
            //表单数据
            formItem: {},
            platformList: [],
            table: {},
            dialogFormVisible: false,
			dialogConfigFormVisible:false,
			dialogAddVisible: false,
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
			listloading: false
        };
    },

    mounted() {
        this.handleSearchList();
    },
    methods: {
		//获取表数据
        //搜索列表
        handleSearchList(listQuery){
			this.listloading = true;
        	if(listQuery){
				this.listQuery.page=1
        		this.listQuery={
        			// "brandCode": listQuery.brand,
        			"countryCodes":listQuery.country,
        			"page": this.listQuery.page,
        			"limit": this.listQuery.limit
        		}				
        	}				
        	this.$store
        		.dispatch('getNaviSearch', this.listQuery)
        		.then(res => {					
        			this.pageData=res  			
        		}).finally(() => {
				this.listloading = false;
			});
        },
		handleClick(val){
			this.countryCode=val.name		
		},
		handleResetSearch(){
			this.listQuery = Object.assign({}, this.defaultListQuery);
			this.handleSearchList()
		},
		add(){
			this.dialogConfigFormVisible=true
		},
		//关闭窗口
		closeDialog(){
			this.dialogConfigFormVisible=false
		},
		closeDialog2(){
			this.dialogFormVisible=false
		},	
        edit(row) {
			this.editData=Object.assign({}, row)
            this.dialogFormVisible = true;		
        },
        preview(row) {
            var url = `${this.webSiteUrl}promotionTheme?id=${row.activityId}`;
            window.open(url, '_blank');
        },
        del(row) {
			console.log("id:"+row.siteNavigationId)
            this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {					
                    this.$store.dispatch('deleteNaviClass', {id:row.siteNavigationId}).then(response => {
						let totalPage = Math.ceil((this.pageData.total - 1) / this.listQuery.limit)	
						let currentPage = this.listQuery.page > totalPage ? totalPage : this.listQuery.page
						this.listQuery.page = currentPage < 1 ? 1 : currentPage
						this.handleSearchList();
					})
            }).catch(() => {});
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.handleSearchList();
        },
        formInit() {
            var _this = this;
            if (this.$refs.formItem !== undefined) {
                this.$refs.formItem.initFormData(this.formItem);
                this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
            } else {
                setTimeout(() => {
                    this.$refs.formItem.initFormData(this.formItem);
                    this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
                }, 500);
            }
        },
        closeWindow(status) {
            this.dialogFormVisible = false;
            if (status) {
                this.handleSearchList();
            }
        },
		// 添加
		addSite(){
			this.dialogAddVisible = true
		},
		closeDialogAdd(){
			this.dialogAddVisible=false
			this.handleSearchList();
		},	
    }
};
</script>
