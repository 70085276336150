<template>
    <div style="height: 100%;min-height:350px;">
        <filter-container
            ref="filterContainer"
            :arg="arg"
            :placeholder="placeholder"
            :isShowObject="isShowObject"
            :multiple="multiple"
            :loading="loadingDownloadFileByDkAndNo"
            :dictionary="dictionary"
            importAction="'postGoodsImport'"
            @handleSearchList="handleSearchListF(1)"
            @handleCommand="handleCommand"
            @countryClick="countryClick"
            @putClick="putClick"
            @offClick="offClick"
            @searchClick="searchClick"
            @importClick="importClick"
            @classClick="classClick"
            @SKUClick="SKUClick"
            @subBarCodeClick="subBarCodeClick"
            @EBPClick="EBPClick"
            @EBPPriceClick="EBPPriceClick"
            @packageQtyClick="packageQtyClick"
            @outStockClick="outStockClick"
            @downloadTemplateGoods="downloadTemplateGoods"
            @markClick="markClick"
            @baseline="baseline"
            @recordClick="recordClick"
            @downloadFileByDkAndNo="downloadFileByDkAndNo"
            @handleChangeCategory="handleChangeCategory"
            @handleResetSearch="handleResetSearch"
            @handleExport="handleExport"
            @handleShowColumn="handleShowColumn"
        ></filter-container>
        <el-table
            ref="tableData"
            id="goodsTable"
            :data="pageData.list"
            :tree-props="{ children: 'packageQtyList', hasChildren: 'hasChildren' }"
            default-expand-all
            style="width: 100%"
            class="table-fixed"
            height="calc(100vh - 430px)"
            border
            v-loading="loading"
            @select="(selection) => select(selection, this.$refs.tableData, this.pageData.list)"
            @select-all="(selection) => selectAll(selection, this.$refs.tableData, this.pageData.list)"
            @selection-change="handleSelectionChange"
            row-key="goodsId"
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
        >
            <el-table-column type="selection" :reserve-selection="true" align="center" width="55" />

            <!-- 用于显示子表格张开按钮 -->
            <el-table-column width="50px" align="center"> </el-table-column>

            <el-table-column
                v-if="tableColumSetting.includes('ProductID')"
                prop="goodsId"
                :label="$t('common.ProductID')"
                align="center"
                width="150"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column v-if="tableColumSetting.includes('SKU_EAN_Encoding')" prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" width="150" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span
                    ><br />
                    <span>EAN:{{ scope.row.barCode }}</span
                    ><br />
                    <span>EAN-SUB:{{ scope.row.subBarCode }}</span
                    ><br />
                    <span>{{ $t('common.coding') }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>

            <el-table-column v-if="tableColumSetting.includes('Productimage1')" prop="imageUrl" :label="$t('common.Productimage1')" width="150" align="center">
                <template slot-scope="scope">
<!--                    <el-image-->
<!--                        style="height: 100px; width: 100px"-->
<!--                        v-if="scope.row.imageUrl != '' && scope.row.imgType == 'img'"-->
<!--                        :src="ossHost + scope.row.imageUrl"-->
<!--                        fit="fill"-->
<!--                    ></el-image>-->
                    <listImage v-if="scope.row.imgType == 'img'" :imgUrl="scope.row.imageUrl"></listImage>
                    <video
                        v-else-if="scope.row.imageUrl != '' && scope.row.imgType == 'video'"
                        style="height: 100px; width: auto"
                        :src="ossHost + scope.row.imageUrl"
                        controls="controls"
                    ></video>
                </template>
            </el-table-column>
            <el-table-column
                v-if="tableColumSetting.includes('Category')"
                prop="treeList"
                :label="$t('common.Category')"
                align="center"
                height="55"
                style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; -o-text-overflow: ellipsis"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.treeList" :key="index">
                        <span v-for="(item2, index2) in item.parents" :key="index2">
                            <span>{{ item2.title }}></span>
                        </span>
                        <span>{{ item.title }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                v-if="tableColumSetting.includes('ProductName')"
                prop="title"
                :label="$t('common.ProductName')"
                align="center"
                width="170"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column v-if="tableColumSetting.includes('Countrieslisted')" prop="goodsSpecials" :label="$t('common.Countrieslisted')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.shelf">{{ $dict.getDictValue('countrys', s.countryCode) }}<br /></span>
                    </span>
                </template>
            </el-table-column>

            <!--
            <el-table-column prop="treeList" label="品牌" align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-if="scope.row.treeList && scope.row.treeList.length > 0">{{scope.row.treeList[0].brandCode}}</span>
                </template>
            </el-table-column>

            -->

            <el-table-column
                v-if="tableColumSetting.includes('Salesmultiples')"
                prop="goodsSpecials"
                :label="$t('common.Salesmultiples')"
                align="center"
                height="55"
                style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; -o-text-overflow: ellipsis"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.goodsSpecials" :key="index">
                        <template v-if="item.shelf">
                            <span>{{ item.countryCode }}: </span>
                            <span>{{ item.saleDoubleEbpNew }}</span>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                v-if="tableColumSetting.includes('PackingQuantity')"
                prop="packageQuantity"
                :label="$t('common.PackingQuantity')"
                align="center"
                show-overflow-tooltip
            ></el-table-column>

            <el-table-column v-if="tableColumSetting.includes('Fixedprice1')" prop="prices" :label="$t('common.Fixedprice1')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-show="s.fixedPrice && s.fixedPrice >= 0"
                            >{{ s.countryCode }}:{{ Number(s.fixedPrice).toFixed(2) }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                        ><br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column v-if="!scene.isNonSelf && tableColumSetting.includes('Lowestprice')" prop="prices" :label="$t('common.Lowestprice')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-if="s.lowestPrice"
                            >{{ s.countryCode }}:{{ Number(s.lowestPrice).toFixed(2) }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br
                        /></span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column v-if="!scene.isNonSelf && tableColumSetting.includes('fixedprice')" prop="prices" :label="$t('common.fixedprice')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-show="s.resultPrice && s.resultPrice >= 0"
                            >{{ s.countryCode }}:{{ Number(s.resultPrice).toFixed(2) }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                        ><br />
                    </span>
                </template>
            </el-table-column>

            <!--            <el-table-column prop="prices" :label="$t('common.Promotionprice_unitprice')" align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-show="s.promotionPrice && s.promotionPrice>=0">{{ s.countryCode }}:<span style="color: red;">{{ Number(s.promotionPrice).toFixed(2) }}</span>{{$dict.getDictCurrencyValue(s.countryCode)}}<br /> </span>
                    </span>
                </template>
            </el-table-column>-->
            <el-table-column
                v-if="!scene.isNonSelf && tableColumSetting.includes('Promotionprice_unitprice')"
                prop="prices"
                :label="$t('common.Promotionprice_unitprice')"
                width="120"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                        <span style="font-weight: bold; text-align: left; display: block">{{ $t('common.NewUserPromotion') }}<br /></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{
                                getPromotionGoodsPrice(promotion, scope.row.prices)
                            }}<br />
                        </span>
                    </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                        <span style="font-weight: bold; text-align: left; display: block"
                            >{{ $t('common.RegularUserPromotion') }}<br
                        /></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{
                                getPromotionGoodsPrice(promotion, scope.row.prices)
                            }}<br />
                        </span>
                    </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{
                        $i18n.t('common.ThereAreCurrentlyNoProductPromotions')
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="!scene.isNonSelf && tableColumSetting.includes('price1')" prop="prices" :label="$t('common.price1')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        <span v-show="s.salePriceEbp || s.salePriceEbp >= 0"
                            >{{ s.countryCode }}:{{ Number(s.salePriceEbp).toFixed(2)
                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                        >
                        <br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column v-if="tableColumSetting.includes('state')" prop="shelf" :label="$t('common.state')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $t('common.Listed') }}</span>
                    <span v-else>{{ $t('common.Notlisted') }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="tableColumSetting.includes('inventory')" prop="prices" :label="$t('common.inventory')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column v-if="tableColumSetting.includes('Inventorybottomline')" prop="goodsSpecials" :label="$t('common.Inventorybottomline')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index"> {{ s.countryCode }}:{{ s.stockLimit }}<br /> </span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="!scene.isNonSelf && tableColumSetting.includes('AutomaticallysynchronizeEBP')"
                prop="ebpSync"
                :label="$t('common.AutomaticallysynchronizeEBP')"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.ebpSync">
                        {{ scope.row.ebpSync.isAuto == true ? $t('common.yes') : $t('common.no') }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="!scene.isNonSelf && tableColumSetting.includes('ForcesynchronizationofEBP')"
                prop="ebpSync"
                :label="$t('common.ForcesynchronizationofEBP')"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.ebpSync">
                        {{ scope.row.ebpSync.isForce == true ? $t('common.yes') : $t('common.no') }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="!scene.isNonSelf && tableColumSetting.includes('Noinventorydisplay')"
                prop="noStockShow"
                :label="$t('common.Noinventorydisplay')"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        {{ s.countryCode }}:{{ s.noStockShow ? $t('common.Display') : $t('common.DoNotDisplay') }}<br />
                    </span>
                </template>
            </el-table-column>

            <el-table-column v-if="tableColumSetting.includes('VariantInformation')" prop="variantList" :label="$t('common.VariantInformation')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.variantList" :key="index">
                        {{ s.parameterName }}:{{ s.parameterValue }}<br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="tableColumSetting.includes('Creationtime')"
                prop="createTime"
                :label="$t('common.Creationtime')"
                :render-header="renderRuHeader"
                align="center"
                width="150"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.syTime | formatDate }}<br /> </span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="tableColumSetting.includes('Updatetime')"
                prop="updateTime"
                :label="$t('common.Updatetime')"
                :render-header="renderGeHeader"
                align="center"
                width="150"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.updateTime | formatDate }}<br /> </span>
                </template>
            </el-table-column>
            <el-table-column v-if="tableColumSetting.includes('label')" prop="prices" :label="$t('common.label')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.tags" :key="index">
                        <span
                            >{{ s.countryCode }}:<el-button type="text" size="small">{{ s.tagCode }}</el-button
                            ><br
                        /></span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column v-if="!scene.isNonSelf && tableColumSetting.includes('PreSaleEvent')" prop="goodsPreSaleCountries" :label="$t('common.PreSaleEvent')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsPreSaleCountries" :key="index">
                        <span >{{ $dict.getDictValue('countrys', s.countryCode) }}<br /></span>
                    </span>

                    <!-- {{ scope.row.preSale == true ? $t('common.yes') : $t('common.no') }} -->
                </template>
            </el-table-column>

            <el-table-column
                prop="updateByName"
                :label="$t('common.Operator')"
                align="center"
                show-overflow-tooltip
                v-if="!scene.isNonSelf && tableColumSetting.includes('Operator')"
            >
            </el-table-column>
            <el-table-column
                v-if="!scene.isNonSelf && tableColumSetting.includes('Evaluation_Shown_total')"
                prop="barCode"
                :label="$t('common.Evaluation_Shown_total')"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.comment" style="cursor: pointer" @click="toUrl(scope.row.barCode)">
                        {{ scope.row.comment.adoptNum }}/{{ scope.row.comment.commentNum }}<br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update', module_code)">{{
                        $t('common.edit')
                    }}</el-button>
                    <el-button @click="goodsDetails(scope.row)" type="text" size="small">{{ $t('common.ProductDetails') }}</el-button>
                    <el-button @click="editPrices(scope.row)" type="text" size="small">{{ $t('common.Fixedprice1') }}</el-button>

                    <!-- 
                    <el-button
                        v-if="!scope.row.preSale && !scope.row.shelf && preSaleShow && !scene.isNonSelf"
                        @click="addPreSale(scope.row)"
                        type="text"
                        size="small"
                        >{{ $t('common.AddToPresale') }}</el-button
                    >
                    <el-button v-if="scope.row.preSale && preSaleShow" @click="cancelPreSale(scope.row)" type="text" size="small">{{
                        $t('common.CancelPresale')
                    }}</el-button>
                    -->

                    <el-button v-if="preSaleShow" @click="editPreSaleCountry(scope.row)" type="text" size="small">{{
                        $t('common.PreSaleSwitch')
                    }}</el-button>

                    <el-button v-if="preSaleShow" @click="editPreSale(scope.row)" type="text" size="small">{{
                        $t('common.PresaleConfiguration')
                    }}</el-button>

                    <el-button
                        v-if="scope.row.shelf && $common.hasPermission('PutAway', module_code)"
                        @click="offorPut(scope.row, 'down')"
                        type="text"
                        size="small"
                        style="color: red"
                        >{{ $t('common.OffTheShelf') }}</el-button
                    >
                    <el-button
                        v-else-if="!scope.row.shelf && $common.hasPermission('SoldOut', module_code)"
                        @click="offorPut(scope.row, 'up')"
                        type="text"
                        size="small"
                        >{{ $t('common.OnTheShelf') }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div style="display:inline-block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.page"
                :page-size="listQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
                style="display:inline-block;height: 25px;line-height: 25px;"
            >
            </el-pagination>
            <div style="margin-left:20px;display:inline-block;height: 37px;line-height: 37px;">
                <span style="font-size: 12px;font-weight: 500;">{{$t('common.PerPage')}}：</span>    
                <el-dropdown @command="handleGoodsCommand">
                <span class="el-dropdown-link" >
                    {{goodsPageSize}}<i class="el-icon-arrow-down el-icon--right"></i>
                    <el-icon class="el-icon--right">
                    <arrow-down />
                    </el-icon>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                    <el-dropdown-item v-for="item in goodsPageSizeList" :key="item" :command="item">{{item}}</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
                </el-dropdown>
          </div>
        </div>
        <el-dialog
            :title="$t('common.Pleasecompletetheproductinformationbeforelistingit')"
            :visible.sync="dialogtipsVisible"
            :close-on-click-modal="false"
        >
            <div v-if="dialogtipsVisible">
                <div v-for="(item, index) in tipsList" :key="index">{{ index + 1 }}:{{ item }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button style="width: 20%" @click="dialogtipsVisible = false">{{ $t('common.cancel') }}</el-button>
                    <el-button style="width: 20%" type="primary" @click="dialogtipsVisible = false">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('common.PleaseCompleteTheProductInformationBeforeSettingUpThePresale')"
            :visible.sync="dialogtipsPreSaleVisible"
            :close-on-click-modal="false"
        >
            <div v-if="dialogtipsPreSaleVisible">
                <div v-for="(item, index) in tipsList" :key="index">{{ index + 1 }}:{{ item }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button style="width: 20%" @click="dialogtipsPreSaleVisible = false">{{ $t('common.cancel') }}</el-button>
                    <el-button style="width: 20%" type="primary" @click="dialogtipsPreSaleVisible = false">{{
                        $t('common.confirm1')
                    }}</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog :title="tabsTitle" :visible.sync="dialogTabsVisible" :close-on-click-modal="false" :before-close="closeDialog">
            <div v-if="dialogTabsVisible">
                <tabs-dialog
                    :formData="formData"
                    :defaultFormData="defaultFormData"
                    :dictionary="dictionary"
                    :defaultDictionary="defaultDictionary"
                    :formListQuery="formListQuery"
                    :special="special"
                    :treeData="treeData"
                    @closeDialog="closeDialog"
                    @handleSearchList="handleSearchListF"
                />
            </div>
        </el-dialog>
        <el-dialog :title="goodsTitle" :visible.sync="dialogDetailVisible" :close-on-click-modal="false" :before-close="closeDetailsDialog">
            <div v-if="dialogDetailVisible">
                <goods-details-dialog
                    :formData="formData"
                    :languageList="languageList"
                    :countryList="countryList"
                    @closeDetailsDialog="closeDetailsDialog"
                    @handleSearchList="handleSearchListF"
                />
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('common.Batchsearch')"
            :close-on-click-modal="false"
            :visible.sync="dialogSearchVisible"
            width="30%"
 
        >
            <div v-if="dialogSearchVisible">
                <span>{{ $t('common.Enterproductbarcodeandsubmitforaddition') }}：</span>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    :placeholder="$t('common.Enterproductbarcode')"
                    v-model="describe"
                ></el-input>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeSearchDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="handleBatchSerach" :loading="loadingSearch">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('common.Editlistingcountry')"
            :close-on-click-modal="false"
            :visible.sync="dialogCountryVisible"
            width="30%"
            :before-close="closeCountryDialog"
        >
            <div v-if="dialogCountryVisible">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
                    >{{ $t('common.SelectAll') }}
                </el-checkbox>
                <div style="margin: 15px 0"></div>
                <el-checkbox-group v-model="checkCountryList" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="(item, index) in dictionary.country" :key="item.code" :label="item.code">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeCountryDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="handleCountryClick">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('common.EditCategory')"
            :close-on-click-modal="false"
            :visible.sync="dialogClassVisible"
            width="30%"
            :before-close="closeClassDialog"
        >
            <div v-if="dialogClassVisible">
                <el-tree
                    ref="treeClass"
                    :props="defaultProps"
                    :data="dictionary.classifyTree"
                    node-key="goodsClassifyId"
                    :default-checked-keys="checkedKeys"
                    show-checkbox
                >
                </el-tree>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeClassDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="handleGoodsClassify">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('common.SynchronizeSKU')"
            :close-on-click-modal="false"
            :visible.sync="dialogSKUVisible"
            width="30%"
            :before-close="closeSKUDialog"
        >
            <div v-if="dialogSKUVisible">
                <div>{{ $t('common.ConfirmsynchronizationofSKU') }}?</div>
                <div style="margin-top: 10px">{{ $t('common.SynchronizationrequiresdockingwithEBP') }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeSKUDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="putGoodsSku">
                        <span v-if="restTime">{{ $t('common.PleaseWait') }}({{ restTime }})</span>
                        <span v-else-if="!restTime">{{ $t('common.confirm1') }}</span>
                    </el-button>
                </span>
            </div>
        </el-dialog>
        <!--  同步子条码      -->
        <el-dialog
            :title="$t('common.SynchronizeSubBarcodes')"
            :close-on-click-modal="false"
            :visible.sync="dialogSubBarCodeVisible"
            width="30%"
            :before-close="closeSubBarCodeDialog"
        >
            <div v-if="dialogSubBarCodeVisible">
                <div>{{ $t('common.ConfirmSynchronizationSubBarcodes') }}</div>
                <div style="margin-top: 10px">{{ $t('common.SynchronizationEbpEanSUB') }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeSubBarCodeDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="syncSubBarCode" :disabled = 'syncSubBarCodeRestTime'>
                        <span v-if="syncSubBarCodeRestTime">{{ $t('common.PleaseWait') }}({{ syncSubBarCodeRestTime }})</span>
                        <span v-else-if="!syncSubBarCodeRestTime">{{ $t('common.confirm1') }}</span>
                    </el-button>
                </span>
            </div>
        </el-dialog>
        <!--  同步EBP价格      -->
        <el-dialog
            :title="$t('common.SynchronizeEbpPrice')"
            :close-on-click-modal="false"
            :visible.sync="dialogEBPPriceVisible"
            width="30%"
            :before-close="closeEbpPriceDialog"
        >
            <div v-if="dialogEBPPriceVisible">
                <div>{{ $t('common.ConfirmToSynchronizeEbp') }}</div>
                <div style="margin-top: 10px">{{ $t('common.AsynchronousEbp') }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeEbpPriceDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="syncEbpPrice" :disabled = 'syncEbpPriceRestTime'>
                        <span v-if="syncEbpPriceRestTime">{{ $t('common.PleaseWait') }}({{ syncEbpPriceRestTime }})</span>
                        <span v-else-if="!syncEbpPriceRestTime">{{ $t('common.confirm1') }}</span>
                    </el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('common.Setlabel')"
            :close-on-click-modal="false"
            :visible.sync="dialogMarkisible"
            width="30%"
            :before-close="closeMarkDialog"
        >
            <div v-if="dialogMarkisible">
                <el-form :model="markQuery" ref="mark" :rules="tagRules" size="small" label-width="140px">
                    <el-form-item :label="$t('common.country')" prop="country">
                        <el-select v-model="markQuery.country" multiple class="input-width" :placeholder="$t('common.country')">
                            <el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('common.label')" prop="tagCode">
                        <el-select v-model="markQuery.tagCode" class="input-width" :placeholder="$t('common.PleaseSelectATag')">
                            <el-option v-for="item in goods_tag_code" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="有效时间:" v-show="markQuery.tagCode!=='无'">
						<el-date-picker v-model="markQuery.date" type="daterange" range-separator="至"
							start-placeholder="有效开始日期" end-placeholder="有效结束日期"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"/>
					</el-form-item> -->
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeMarkDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="postGoodsTag('mark')">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog :title="$t('common.UpdateRecords')" :visible.sync="dialogRecordVisible" @close="closeRecordDialog" append-to-body>
            <div v-if="dialogRecordVisible">
                <FormTableDialog
                    ref="FormTableDialog"
                    :argFrom="argFrom2"
                    :dictionary="dictionary"
                    :placeholderFrom="placeholderFrom"
                    :closeFooter="true"
                    @handleSearchList="getGoodsImport"
                    @handleResetSearch="getGoodsImport"
                >
                    <template v-slot:tableSlot>
                        <el-table
                            @selection-change="handleSelectionChange"
                            row-key="goodsId"
                            ref="tableData2"
                            :data="importData.list"
                            style="width: 100%"
                            border
                            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                        >
                            <el-table-column
                                prop="goodsImportId"
                                :label="$t('common.number')"
                                align="center"
                                show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                prop="fileName"
                                :label="$t('common.file')"
                                align="center"
                                show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column prop="createTime" :label="$t('common.Importtime')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.createTime | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="status" :label="$t('common.UpdateResults')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.status == 0">{{ $t('common.Updating') }}</span>
                                    <span v-else-if="scope.row.status == 1">{{ $t('common.success') }}</span>
                                    <span v-else-if="scope.row.status == 2">{{ $t('common.Abortive') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="operator"
                                :label="$t('common.Operator')"
                                align="center"
                                show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column :label="$t('common.operation')" align="center" fixed="right">
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.status == 1" type="primary" @click="downFileUrl(scope.row.fileUrl)">{{
                                        $t('common.Downloadfiles')
                                    }}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="block">
                            <el-pagination
                                @current-change="handleCurrentChangeRecord"
                                :current-page="importQuery.page"
                                :page-size="importQuery.size"
                                layout="total, prev, pager, next, jumper"
                                :total="importData.total"
                            >
                            </el-pagination>
                        </div>
                    </template>
                </FormTableDialog>
            </div>
        </el-dialog>

        <el-dialog
            :title="tagType"
            :close-on-click-modal="false"
            :visible.sync="dialogNEWVisible"
            width="30%"
            :before-close="closeDialogNEW"
        >
            <div v-if="dialogNEWVisible">
                <div style="display: flex; justify-content: center; align-items: center">
                    <div v-if="tagBeginTime || tagEndTime">
                        <span>{{ tagBeginTime | formatDate }}</span>
                        <span>{{ tagEndTime | formatDate }}</span>
                    </div>
                    <div v-else>
                        {{ $t('common.DefaultRule') }}
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogNEWVisible = false">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('common.BottomInventory')"
            :close-on-click-modal="false"
            :visible.sync="dialogBaselineisible"
            width="30%"
            :before-close="closeBaselineDialog"
        >
            <div v-if="dialogBaselineisible">
                <el-button type="primary" size="mini" @click="isStockLimit = !isStockLimit">{{ $t('common.edit') }}</el-button>
                <el-table ref="pagedata" :data="goodsSpecials" border style="width: 100%; margin-top: 20px">
                    <el-table-column prop="countryCode" :label="$t('common.country')" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stockLimit" :label="$t('common.Inventorybottomline')" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input
                                v-show="scope.row.stockLimit >= 0 && isStockLimit"
                                v-model="scope.row.stockLimit"
                                oninput="value=value.replace(/\D/g,'')"
                                :placeholder="$t('common.Pleaseentercontent')"
                            ></el-input>
                            <span v-show="scope.row.stockLimit >= 0 && !isStockLimit"> {{ scope.row.stockLimit }} </span>
                        </template>
                    </el-table-column>
                </el-table>

                <div style="margin-top: 20px" v-show="isStockLimit">
                    {{ $t('common.Synchronizetoothertenants') }}:
                    <el-checkbox-group v-model="curTenantList">
                        <el-checkbox v-for="item in filterNoTenantList" :key="item.tenant_id" :label="item.tenant_id"
                            >{{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeBaselineDialog">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="postGoodsBaseline">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 同步EBP产品信息 -->
        <el-dialog
            :title="$t('common.SynchronizeEBPproductinformation')"
            :close-on-click-modal="false"
            :visible.sync="dialogEBPVisible"
            width="30%"
            :before-close="closeDialogEBP"
        >
            <div v-if="dialogEBPVisible">
                <h3>{{ $t('common.Afterturningonsynchronization') }}：</h3>
                <div style="margin: 10px 0px">1.{{ $t('common.Synchronizeproducttitlesbylanguage') }}；</div>
                    <el-checkbox :indeterminate="isIndeterminateTitleLang" v-model="checkAllTitleLang" @change="handleCheckAllTitleLangChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="formEBPQuery.titleLang" @change="handleCheckedTitleLangChange">
                        <el-checkbox v-for="(item, index) in languageList" :key="item.code" :label="item.code">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                <div style="margin: 10px 0px">2.{{ $t('common.SynchronisedAlertsForLargeProductImages') }}；</div>
                    <el-checkbox :indeterminate="isIndeterminateImageLang" v-model="checkAllImageLang" @change="handleCheckAllImageLangChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="formEBPQuery.imageLang" @change="handleCheckedImageLangChange">
                        <el-checkbox v-for="(item, index) in languageList" :key="item.code" :label="item.code">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                <div style="margin: 10px 0px">3.{{ $t('common.SynchronisedAlertsForGraphicDetails') }}；</div>
                    <el-checkbox :indeterminate="isIndeterminateDetailLang" v-model="checkAllDetailLang" @change="handleCheckAllDetailLangChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="formEBPQuery.detailLang" @change="handleCheckedDetailLangChange">
                        <el-checkbox v-for="(item, index) in languageList" :key="item.code" :label="item.code">
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                <el-form :model="formEBPQuery">
                    <el-form-item :label="$t('common.Automaticallysynchronizeornot')" prop="isAuto">
                        <el-radio-group v-model="formEBPQuery.isAuto">
                            <el-radio v-for="item in isAuto" :key="item.id" :label="item.value">{{ item.name }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('common.Doyouwanttoforcesynchronization')" prop="isForce">
                        <el-radio-group v-model="formEBPQuery.isForce">
                            <el-radio v-for="item in isForce" :key="item.id" :label="item.value">{{ item.name }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('common.tenant')" prop="tenantIdList">
                        <el-checkbox-group v-model="formEBPQuery.tenantIdList">
                            <el-checkbox v-for="(item, index) in filterNoTenantList" :key="index" :label="item.tenant_id">{{
                                item.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialogEBP">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" :loading="EBPloading" @click="postEBP">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <!-- 无库存显示 -->
        <el-dialog
            :title="$t('common.SynchronizeThePackageQuantity')"
            :close-on-click-modal="false"
            :visible.sync="dialogPackageQtyVisible"
            width="30%"
            :before-close="closeDialogPackageQty"
        >
            <div v-if="dialogPackageQtyVisible">
                <el-form ref="packageQtyForm" :model="formPackageQtyQuery" :rules="formPackageQtyRules">
                    <el-form-item :label="$t('common.PackageQuantity')" prop="packageQty" label-width="120px">
                        <el-input :placeholder="$t('common.PleaseEnterSeparatedBy')" v-model="formPackageQtyQuery.packageQty"></el-input>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.Synchronizetoothertenants')" prop="tenantIdList" label-width="120px">
                        <el-checkbox-group v-model="formPackageQtyQuery.tenantIdList">
                            <el-checkbox v-for="(item, index) in filterNoTenantList" :key="index" :label="item.tenant_id">{{
                                item.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialogPackageQty">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" :loading="packageQtyLoading" @click="postPackageQty">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <!-- 无库存显示 -->
        <el-dialog
            :title="$t('common.Noinventorydisplay')"
            :close-on-click-modal="false"
            :visible.sync="dialogOutStockVisible"
            width="30%"
            :before-close="closeDialogOutStock"
        >
            <div v-if="dialogOutStockVisible">
                <el-form :model="formOutStockQuery">
                    <div v-for="(item, index) in formOutStockQuery.noStockShowDtoList" :key="index">
                        <el-form-item :label="item.countryLable">
                            <el-radio-group v-model="item.noStockShow">
                                <el-radio v-for="item in outStock" :key="item.id" :label="item.value">{{ item.name }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <el-form-item :label="$t('common.Synchronizetoothertenants')" prop="tenantSyn">
                        <el-checkbox-group v-model="formOutStockQuery.tenantList">
                            <el-checkbox v-for="(item, index) in filterNoTenantList" :key="index" :label="item.tenant_id">{{
                                item.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialogOutStock">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" :loading="outStockloading" @click="postOutStock">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <!-- 预售配置页面  -->
        <!-- <el-dialog :title="preSaleTitle" :visible.sync="dialogPreSaleVisible" :close-on-click-modal="false" :before-close="closePreSaleDialog">
            <div v-if="dialogPreSaleVisible">
                <goods-presale-dialog :rowData="rowData" @closePreSaleDialog="closePreSaleDialog" />
            </div>
        </el-dialog> -->
        <el-dialog :title='$i18n.t("common.AddPreSalePromotion")' :visible.sync="dialogPreSaleVisible" :close-on-click-modal="false" v-if="dialogPreSaleVisible" >
             
                <AddGoodsPreSaleConfig  :defaultDictionary="dictionary" :formItem="formItemPreSaleConfig" :defaultFormItem="defaultFormItemPreSaleConfig" 
                 @closePreSaleDialog="closePreSaleDialog" />
            
        </el-dialog>

 
        <el-dialog :title='$i18n.t("common.PreSaleSwitch")' :visible.sync="dialogPreSaleCountryVisible" :close-on-click-modal="false" v-if="dialogPreSaleCountryVisible" >
             
             <GoodsPresaleCountryDialog  :dictionary="dictionary" :rowData="rowData" 
              @closePreSaleCountryDialog="closePreSaleCountryDialog" />
         
        </el-dialog>

		 

        <!-- 固定价格页面  -->
        <el-dialog
            :title="$t('common.SetAFixedPrice')"
            :visible.sync="dialogPricesVisible"
            :close-on-click-modal="false"
            :before-close="closePricesDialog"
        >
            <div v-if="dialogPricesVisible">
                <goods-prices-dialog
                    :formData="formData"
                    :defaultFormData="defaultFormData"
                    @handleSearchList="handleSearchListF"
                    @closePricesDialog="closePricesDialog"
                />
            </div>
        </el-dialog>

        <!-- 列表展示页面 -->
        <el-dialog
            width="500px"
            :title="$t('common.ListDisplay')"
            :visible.sync="showColumnVisible"
            :close-on-click-modal="false"
            :before-close="closeColumnDialog"
        >
            <div v-if="showColumnVisible">
                <el-table
                    ref="columnTable"
                    :data="tableColumn"
                    style="width: 100%"
                    class="table-fixed"
                    border
                    row-key="goodsTableColumnSettingId"
                    @selection-change="handleColumnSelectionChange"
                >
                    <el-table-column type="selection" :label="$t('common.Displayornot')"></el-table-column>
                    <el-table-column :label="$t('common.FieldName')" prop="columnValue"></el-table-column>
                </el-table>
                <div style="margin-top:30px">
                    <el-button @click="closeColumnDialog" style="margin-left: 100px">{{ $t('common.cancel') }}</el-button>
                    <el-button type="primary" @click="submitColumnChange" style="margin-left: 100px">{{ $t('common.confirm1') }}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import TabsDialog from '@/components/common/TabsDialog.vue';
import GoodsDetailsDialog from './components/GoodsDetailsDialog.vue';
import GoodsPresaleDialog from './components/GoodsPresaleDialog.vue';
import cache from '@/utils/cache';
import { select, selectAll, showChildrenCheckbox } from '@/utils/treeTable';
import config from '@/utils/config';
import scene from '@/utils/scene';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';

import i18n from '@/i18n/i18n';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import GoodsPricesDialog from './components/GoodsPricesDialog.vue';
import GoodsPresaleCountryDialog from './components/GoodsPresaleCountryDialog.vue';
import AddGoodsPreSaleConfig from '@/views/goodsPreSaleConfig/components/AddUpdateDialog.vue';
import listImage from '@/components/common/ListImage.vue';
import dictionaryUtil from '@/utils/dictionaryUtil';
import Config from '@/utils/config';
import Scene from '@/utils/scene';

const defaultFormListQuery = {
    goodsId: '',
    peso: null,
    countryCodes: []
};
const defaultImportQuery = {
    page: 1,
    limit: 10,
    createBy: '',
    beginTime: '',
    endTime: ''
};
const defaultformEBPQuery = {
    goodsEbpGoodsItems: [],
    isAuto: false,
    isForce: false,
    tenantIdList: [],
    titleLang:[],
    imageLang:[],
    detailLang:[]
};
const defaultPackageQtyQuery = {
    relationGoodsId: null,
    packageQty: null,
    tenantIdList: []
};
const defaultformOutStockQuery = {
    noStockShowDtoList: dictionaryUtil.getDict('countrys').map((item) => {
        return {
            noStockShow: false,
            countryCode: item.code,
            countryLable: item.name
        };
    }),
    goodsIdList: [],
    tenantList: cache.get(Config.tenantList).map((item) => {
        return item.tenant_id;
    })
};
const defaultMarkQuery = {
    country: [dictionaryUtil.getDict('countrys') ? dictionaryUtil.getDict('countrys')[0].code : ''],
    tagCode: dictionaryUtil.getDict('goods_tag_code') ? dictionaryUtil.getDict('goods_tag_code')[0].code : '',
    date: [],
    beginTime: '',
    endTime: ''
};
const defaultListQuery = {
    shelfCountryCode: '',
    classifyId: '',
    idFuzzy: '',
    brandCode: '',
    updateUserId: '', //操作人
    inPromotion: '', //是否促销
    preSale: '', // 是否预售
    fixedPrice: '', //是否是固定价格
    isOnShelf: '', //是否上架
    beginCreateTime: '',
    endCreateTime: '',
    beginUpdateTime: '',
    endUpdateTime: '',
    stockCountryCode: '',
    isStock: '',
    page: 1,
    size: 10
};
const defaultDictionary = {
    // 国家
    country: dictionaryUtil.getDict('countrys'),
    //品牌
    brandCode: dictionaryUtil.getDict('brand'),
    //操作人
    operator: dictionaryUtil.getDict('operator'),
    //标签
    markList: dictionaryUtil.getDict('goods_tag_code'),
    //促销
    promotion: [
        {
            id: '1',
            name: i18n.t('common.Inpromotion'),
            value: true
        },
        {
            id: '2',
            name: i18n.t('common.whole'),
            value: false
        }
    ],
    state: [
        {
            id: '1',
            name: i18n.t('common.Listed'),
            value: true
        },
        {
            id: '2',
            name: i18n.t('common.Notlisted'),
            value: false
        }
    ],
    fixedPrice: [
        {
            id: '1',
            name: i18n.t('common.yes'),
            value: true
        },
        {
            id: '2',
            name: i18n.t('common.no'),
            value: false
        }
    ],
    //是否预售
    preSale: [
        {
            id: '1',
            name: i18n.t('common.yes'),
            value: true
        },
        {
            id: '2',
            name: i18n.t('common.no'),
            value: false
        }
    ],
    classifyTree: [],
    stockStatus: [
        {
            id: '1',
            name: i18n.t('common.InStock'),
            value: true
        },
        {
            id: '2',
            name: i18n.t('common.OutOfStock'),
            value: false
        }
    ]
};
const defaultlanguageList = dictionaryUtil.getDict('lang');
const defaultCountryList = config.countryList;

export default {
    components: {
        GoodsPresaleCountryDialog,
        GoodsPricesDialog,
        AddGoodsPreSaleConfig,
        TabsDialog,
        GoodsDetailsDialog,
        GoodsPresaleDialog,
        FilterContainer,
        FormTableDialog,
        listImage
    },
    name: 'goods',
    data() {
        return {
            currentTenant: Scene.getTenantId(),
            preSaleShow: Scene.isBrandSelf,
            tenant_idMall: config.tenant_idMall, //商城租户id
            outStockloading: false,
            EBPloading: false,
            packageQtyLoading: false,
            // 同步EBP数据
            formEBPQuery: Object.assign({}, defaultformEBPQuery),
            // 数量装数据
            formPackageQtyQuery: Object.assign({}, defaultPackageQtyQuery),
            formPackageQtyRules: {
                packageQty: [
                    { required: true, message: i18n.t('common.PleaseEnterThePackageQuantity'), trigger: 'blur' },
                    { max: 100, message: i18n.t('common.Thelengthcannotexceed100characters'), trigger: 'change' },
                    { pattern: /^(\d+#)*\d+$/, message: i18n.t('common.PleaseEnterTheCorrectQuantityFormat'), trigger: 'change' }
                ]
            },
            // 无库存显示数据
            formOutStockQuery: Object.assign({}, defaultformOutStockQuery),
            // 无库存显示
            outStock: [
                {
                    id: '1',
                    name: i18n.t('common.DoNotDisplay'),
                    value: false
                },
                {
                    id: '2',
                    name: i18n.t('common.Display'),
                    value: true
                }
            ],
            // 是否自动同步
            isAuto: [
                {
                    id: '1',
                    name: i18n.t('common.no'),
                    value: false
                },
                {
                    id: '2',
                    name: i18n.t('common.yes'),
                    value: true
                }
            ],
            // 是否强制同步            
            isForce: [
                {
                    id: '1',
                    name: i18n.t('common.no'),
                    value: false
                },
                {
                    id: '2',
                    name: i18n.t('common.yes'),
                    value: true
                }
            ],
            goodsPageSize : 10,
            goodsPageSizeList:[5,10,20,50],
            module_code: config.module_code.goods,
            tenantList: cache.get(Config.tenantList), //租户列表
            curTenantList: [], //底线弹窗选中的的租户
            filterNoTenantList: [], //无库存显示租户列表
            goodsSpecials: [], //底线库存列表
            isStockLimit: false, //底线库存编辑开关
            loadingSearch: false,
            loadingDownloadFileByDkAndNo: false,  // 挪威丹麦导出文件
            loading: false,
            searchType: 0,
            tagType: '',
            //标签时间
            tagBeginTime: '',
            tagEndTime: '',
            markQuery: Object.assign({}, defaultMarkQuery),
            restTime: null,
            syncSubBarCodeRestTime: null,
            syncEbpPriceRestTime: null,
            cutDown: false,
            //更新记录
            importQuery: Object.assign({}, defaultImportQuery),
            //选中的类目
            checkedKeys: [],
            //半选状态
            isIndeterminate: false,
            isIndeterminateTitleLang:false,
            isIndeterminateImageLang:false,
            isIndeterminateDetailLang:false,
            //选中的国家列表
            checkCountryList: [],
            //是否全选
            checkAll: true,
            checkAllTitleLang:true,
            checkAllImageLang:true,
            checkAllDetailLang:true,
            //多条条码搜索
            describe: '',
            oldDescribe: '',
            placeholderFrom: {
                date: {
                    start: i18n.t('common.Importtime'),
                    end: i18n.t('common.Importtime')
                }
            },
            argFrom2: {
                date: true,
                operator: true
            },
            listQuery: Object.assign({}, defaultListQuery),
            //商品类目树
            goodsClassifyTree: [],
            //获取编辑列表
            special: [],
            //组件标签是否显示
            arg: {
                code: true,
                date: true,
                date2: true,
                operator: true,
                dropdown: true,
                category: true,
                country: true,
                state: true,
                promotion: true,
                labelMark: true,
                fixedPrice: true,
                stockCountry: true,
                showColumn: true,
                // preSale:
                preSaleCountryCode: Scene.isBrandSelf
            },
            placeholder: {
                promotion: i18n.t('common.Whethertopromoteornot'),
                date: {
                    start: i18n.t('common.Creationtime'),
                    end: i18n.t('common.Creationtime')
                },
                date2: {
                    start: i18n.t('common.Updatetime'),
                    end: i18n.t('common.Updatetime')
                },
                state: i18n.t('common.state'),
                country: i18n.t('common.Countrieslisted'),
                stockCountry: i18n.t('common.Country'),
                preSale: i18n.t('common.Presaleornot'),
                preSaleCountryCode: i18n.t('common.PreSaleEvent'),
                exportText: i18n.t('common.ExportForFiltering')
            },
            //按钮是否显示
            isShowObject: {
                export: true
            },
            sortState: 0,
            sortState1: 0,
            goods_tag_code: dictionaryUtil.getDict('goods_tag_code'),
            dictionary: Object.assign({}, defaultDictionary),
            defaultDictionary: Object.assign({}, defaultDictionary),
            formListQuery: Object.assign({}, defaultFormListQuery),
            languageList: JSON.parse(JSON.stringify(defaultlanguageList)),
            countryList: JSON.parse(JSON.stringify(defaultCountryList)),
            translate: [],
            formData: {},
            //默认数据用来初始化数据
            defaultFormData: {},
            tableColumn: [],
            tableColumSetting:[],
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: false,
            //tabs
            tabPaneSub: [],
            //所属类目
            treeData: [],
            //产品类目的数据
            goodsClassData: [],
            goodsClassSetProps: {
                label: 'title',
                value: 'classSetId',
                checkStrictly: true
            },
            curRow: {},
            //表单数据
            formItem: {},
            platformList: [],
            importData: {},
            pageData: [],
            tableList: {
                tableData: []
            },
            dialogRecordVisible: false,
            dialogMarkisible: false,
            dialogSKUVisible: false,
            dialogSubBarCodeVisible: false,
            dialogEBPVisible: false,
            dialogEBPPriceVisible: false,
            dialogClassVisible: false,
            dialogCountryVisible: false,
            dialogSearchVisible: false,
            dialogTabsVisible: false,
            dialogDetailVisible: false,
            dialogtipsVisible: false,
            dialogtipsPreSaleVisible: false,
            dialogNEWVisible: false,
            dialogBaselineisible: false,
            dialogPackageQtyVisible: false,
            dialogOutStockVisible: false,
            showColumnVisible: false,
            // 预售
            dialogPreSaleVisible: false,
            preSaleTitle: i18n.t('common.PresaleConfiguration'),
            // countryShelfList: [],
            rowData: {},
            dialogPreSaleCountryVisible: false,
            // 固定价格
            dialogPricesVisible: false,
            tabsTitle: i18n.t('common.EditProduct'),
            goodsTitle: i18n.t('common.ProductDetails'),

            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            category: '',
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            selectedIds: [],
            selectionList: [],
            columnSecelctionList: [],
            tipsList: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            tagRules: {
                country: [
                    {
                        required: true,
                        message: i18n.t('common.Pleaseselectacountry'),
                        trigger: 'change'
                    }
                ],
                tagCode: [
                    {
                        required: true,
                        message: i18n.t('common.PleaseSelectATag'),
                        trigger: 'change'
                    }
                ]
            },
            defaultProps: {
                label: 'title',
                id: 'goodsClassifyId',
                children: 'children',
                disabled: function (data, node) {
                    if (data.parentId == 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            sortRule: {
                sortType: '',
                sortOrder: ''
            },
            defaultSortRule: {
                sortType: '',
                sortOrder:''
            },
            formItemPreSaleConfig: {
                "goodsPreSaleConfigId": "",
                "goodsId": "",
                "countryCode": "",
                "preSaleStartTime": "",
                "preSaleEndTime": "",
                "preSaleDiscountType": "Discount",
                "preSaleValue": null,
                "preSaleQuantity": null,
                "salesCount": null,
                "depositType": "DEPOSIT_AND_FINAL_PAYMENT",
                "depositValue": null,
                "agbsInTransitOrderNo": "",
                "expectedShipmentTime": null,
                "finalPaymentDeadlineTime": null,
                "finalPaymentDeadlineTimeDisabled": false,         
                "fixedPrice":  null,
                "resultPrice":  null,
                "salePriceEbp":  null,
                "time":[],
                "preSalePrice": null,
                "freightFeeType": "Fixed",
                "freightFee": null,
                "freeFreightFeePrice": null
            },
            defaultFormItemPreSaleConfig: {
                "goodsPreSaleConfigId": "",
                "goodsId": "",
                "countryCode": "",
                "preSaleStartTime": "",
                "preSaleEndTime": "",
                "preSaleDiscountType": "Discount",
                "preSaleValue": null,
                "preSaleQuantity": null,
                "salesCount": null,
                "depositType": "DEPOSIT_AND_FINAL_PAYMENT",
                "depositValue": null,
                "agbsInTransitOrderNo": "",
                "expectedShipmentTime": null,
                "finalPaymentDeadlineTime": null,
                "finalPaymentDeadlineTimeDisabled": false,         
                "fixedPrice":  null,
                "resultPrice":  null,
                "salePriceEbp":  null,
                "time":[],
                "preSalePrice": null,
                "freightFeeType": "Fixed",
                "freightFee": null,
                "freeFreightFeePrice": null
            },
        };
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/goods/index') {
                let code = this.$route.params.code;
                if(code){
                    this.handleRouteSearch();
                }
                
            }
        },
        describe(newValue, oldValue) {
            if (this.getAddRow() > 500) {
                this.describe = oldValue;
                this.$message({
                    message: this.$i18n.t('common.EnterUpTo500Entries'),
                    type: 'warning'
                });
            }
        }
    },
    computed: {
        scene() {
            return scene;
        }
    },
    mounted() {
        this.goodsPageSize = cache.get(config.goodsPageSize)?cache.get(config.goodsPageSize):10
        this.listQuery.size = this.goodsPageSize;
        this.checkCountryList = this.dictionary.country.map((item) => {
            return item.code;
        });
        this.formOutStockQuery.tenantList.forEach((item, index) => {
            if (this.currentTenant == item) {
                this.formOutStockQuery.tenantList.splice(index, 1);
            }
        });
        this.formEBPQuery.tenantIdList.forEach((item, index) => {
            if (this.currentTenant == item) {
                this.formEBPQuery.tenantIdList.splice(index, 1);
            }
        });
        this.getbyUser();
        if (this.languageList.length > 0) {
            this.languageList.forEach((item, index) => {
                let id = index;
                this.languageList[index].id = id.toString();
            });
        }
        let code = this.$route.params.code;
        if (code) {
            this.handleRouteSearch();
        } else {
            this.handleSearchList();
        }

        this.getGoodsClassifyTree();
    },
    methods: {
        select,
        selectAll,
        filterTenantList() {
            let tenantList = [];
            this.tenantList.forEach((item) => {
                if (this.currentTenant != item.tenant_id) {
                    tenantList.push(item);
                }
            });
            return tenantList;
        },
        getbyUser() {
            this.curTenantList = [];
            this.tenantList.forEach((element) => {
                if (element.app_id == Config.appId && element.permission && element.tenant_id != this.currentTenant) {
                    this.curTenantList.push(element.tenant_id);
                }
            });
            this.filterNoTenantList = this.filterTenantList();
        },
        downFileUrl(url) {
            window.location.href = url;
        },
        getAddRow() {
            let row = 0;
            this.describe.split('\n').forEach((item) => {
                if (item.length === 0) {
                    row += 1;
                } else {
                    row += Math.ceil(item.replace(/[\u0391-\uFFE5]/g, 'aa').length / 20);
                }
            });
            return row;
        },
        //关闭标签
        closeDialogNEW() {
            this.dialogNEWVisible = false;
        },
        //关闭EBP标签
        closeDialogEBP() {
            this.dialogEBPVisible = false;
            this.formEBPQuery = Object.assign({}, defaultformEBPQuery);
            this.formEBPQuery.tenantIdList.forEach((item, index) => {
                if (this.currentTenant == item) {
                    this.formEBPQuery.tenantIdList.splice(index, 1);
                }
            });
        },
        //关闭数量装
        closeDialogPackageQty() {
            this.dialogPackageQtyVisible = false;
            this.formPackageQtyQuery = Object.assign({}, defaultPackageQtyQuery);
            this.formPackageQtyQuery.tenantIdList.forEach((item, index) => {
                if (this.currentTenant == item) {
                    this.formPackageQtyQuery.tenantIdList.splice(index, 1);
                }
            });
        },
        //关闭无库存显示
        closeDialogOutStock() {
            this.dialogOutStockVisible = false;
            this.formOutStockQuery = Object.assign({}, defaultformOutStockQuery);
            this.formOutStockQuery.tenantList.forEach((item, index) => {
                if (this.currentTenant == item) {
                    this.formOutStockQuery.tenantList.splice(index, 1);
                }
            });
        },
        //打开标签
        clickTags(value) {
            this.tagType = value.tagCode ? value.tagCode : '';
            this.tagBeginTime = value.beginTime ? value.beginTime : '';
            this.tagEndTime = value.endTime ? value.endTime : '';
            this.dialogNEWVisible = true;
        },
        //新增标签
        postGoodsTag(formName) {
            // if(!this.markQuery.tagCode){
            //     this.$message({
            //         type: 'warning',
            //         message: '请选择标签'
            //     });
            //     return
            // }
            // if(this.markQuery.date && this.markQuery.date.length > 0) {
            //     this.markQuery.beginTime=this.markQuery.date[0]
            //     this.markQuery.endTime=this.markQuery.date[1]
            // }else{
            //     this.$message({
            //         type: 'warning',
            //         message: '请选择有效时间'
            //     });
            //     return
            // }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let tagCode = [];
                    let arr = [];
                    this.selectionList.forEach((item, index) => {
                        if (item.goodsId) {
                            arr.push(item.goodsId);
                        }
                    });
                    tagCode = [this.markQuery.tagCode];
                    const data = {
                        goodsIds: arr,
                        tagCode: tagCode,
                        beginTime: this.markQuery.beginTime,
                        endTime: this.markQuery.endTime,
                        countryCodes: this.markQuery.country
                    };
                    this.$store.dispatch('postGoodsTag', data).then((res) => {
                        console.log('res', res);
                        if (res) {
                            this.$message.success(this.$i18n.t('common.SetSuccessfully'));
                            this.dialogMarkisible = false;
                            this.markQuery = Object.assign({}, defaultMarkQuery);
                            this.handleSearchListF();
                        }
                    });
                }
            });
        },
        //商品批量修改类目
        handleGoodsClassify() {
            if (this.$refs.treeClass.getCheckedKeys().length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectacategory')
                });
                return;
            }
            let arr = [];
            this.selectionList.forEach((item, index) => {
                if (item.goodsId) {
                    arr.push(item.goodsId);
                }
            });
            const data = {
                goodsIds: arr,
                classifyIds: this.$refs.treeClass.getCheckedKeys()
            };
            this.$store.dispatch('putGoodsMultipleClassify', data).then((res) => {
                if (res) {
                    this.$message.success(i18n.t('common.Successfullyedited'));
                    this.dialogClassVisible = false;
                    this.handleSearchListF();
                }
            });
        },
        //同步SKU
        putGoodsSku() {
            this.$store.dispatch('putGoodsSku', {}).then((res) => {
                if (res) {
                    if (res.status == 1) {
                        this.$message.success(i18n.t('common.Synccompleted'));
                        this.dialogSKUVisible = false;
                    } else if (res.status == 0) {
                        this.restTime = res.restTime;
                    }
                }
            });
        },
        //同步子条码
        syncSubBarCode() {
            const params = {}
            let barcodeList = []
            this.selectionList.forEach((item, index) => {
                if (item.barCode) {
                    barcodeList.push(item.barCode);
                }
            });
            params.barcodeList = barcodeList
            params.sync = true // true 同步子条码 false 查询状态

            this.$store.dispatch('syncSubBarCode', params).then((res) => {
                if (res) {
                    if (res.status == 1) {
                        this.$message.success(i18n.t('common.Synccompleted'));
                        this.dialogSubBarCodeVisible = false;
                    } else if (res.status == 0) {
                        this.syncSubBarCodeRestTime = res.restTime;
                    }
                }
            });
        },

        //记录更新
        recordClick() {
            this.getGoodsImport();
            this.dialogRecordVisible = true;
        },
        //同步EBP
        postEBP() {
            // if(this.formEBPQuery.tenantIdList.length<=0){
            //     this.$message({
            //         message: '请先选择租户',
            //         type: 'warning',
            //         duration: 2000
            //     });
            //     return
            // }
            this.EBPloading = true;
            this.$store
                .dispatch('postSyncEbp', this.formEBPQuery)
                .then((res) => {
                    this.$message({
                        message: this.$i18n.t('common.SynchronizedSuccessfully'),
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialogEBP();
                    this.EBPloading = false;
                    this.handleSearchListF();
                })
                .catch((err) => {
                    this.EBPloading = false;
                });
        },
        //提交数量装
        postPackageQty() {
            this.$refs['packageQtyForm'].validate().then(() => {
                this.packageQtyLoading = true;
                this.$store
                    .dispatch('postPackageQty', this.formPackageQtyQuery)
                    .then((res) => {
                        if (!res || (!res.noExistQty && !res.noConformQty)) {
                            this.$message({
                                message: this.$i18n.t('common.Successfullysubmitted'),
                                type: 'success',
                                duration: 1000
                            });
                        } else {
                            let message = '';
                            if (res.noExistQty && res.noConformQty) {
                                message =
                                    this.$i18n.t('common.SKUWithAQuantityOfNumDoesNotExist', { num: res.noExistQty }) +
                                    this.$i18n.t('common.SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple', { num: res.noExistQty });
                            } else if (res.noExistQty && !res.noConformQty) {
                                message = this.$i18n.t('common.SKUWithAQuantityOfNumDoesNotExist', { num: res.noExistQty });
                            } else if (!res.noExistQty && res.noConformQty) {
                                message = this.$i18n.t('common.SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple', { num: res.noExistQty });
                            }
                            this.$message({
                                message: message,
                                type: 'warning',
                                duration: 2000
                            });
                        }
                        this.packageQtyLoading = false;
                        this.closeDialogPackageQty();
                        setTimeout(() => {
                            this.handleSearchListF();
                        }, 1000);
                    })
                    .catch((err) => {
                        this.packageQtyLoading = false;
                    });
            });
        },
        // 提交无库存显示
        postOutStock() {
            this.outStockloading = true;
            this.formOutStockQuery.goodsIdList = this.selectionList.map((item) => {
                return item.goodsId;
            });
            this.$store
                .dispatch('putOutStock', this.formOutStockQuery)
                .then((res) => {
                    this.$message({
                        message: i18n.t('common.Successfullysubmitted'),
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialogOutStock();
                    this.outStockloading = false;
                    this.handleSearchListF();
                })
                .catch((err) => {
                    this.outStockloading = false;
                });
        },
        //编辑底线
        postGoodsBaseline() {
            let stockLimitConfigList = [];
            let goodsIdList = [];
            stockLimitConfigList = this.goodsSpecials.map((item) => {
                return {
                    stockLimit: item.stockLimit ? item.stockLimit : 0,
                    countryCode: item.countryCode
                };
            });
            this.selectionList.forEach((item) => {
                goodsIdList.push(item.goodsId);
            });
            this.$store
                .dispatch('putBatchUpdate', {
                    stockLimitConfigList,
                    tenantList: this.curTenantList,
                    goodsIdList
                })
                .then((res) => {
                    this.$message({
                        message: i18n.t('common.Successfullysubmitted'),
                        type: 'success',
                        duration: 1000
                    });
                    this.dialogBaselineisible = false;
                    this.getbyUser();
                    this.handleSearchListF();
                });
        },
        //设置标签
        markClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.dialogMarkisible = true;
        },
        //打开底线弹窗
        baseline() {
            let countrys = [];
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            countrys = JSON.parse(JSON.stringify(dictionaryUtil.getDict('countrys')));
            this.goodsSpecials = countrys.map((item) => {
                return {
                    stockLimit: 50,
                    countryCode: item.code
                };
            });
            this.dialogBaselineisible = true;
        },
        //同步SKU
        SKUClick() {
            this.dialogSKUVisible = true;
        },
        //同步子条码
        subBarCodeClick() {
            this.dialogSubBarCodeVisible = true;

            let params = {"sync" : false}
            this.$store.dispatch('syncSubBarCode', params).then((res) => {
                if (res) {
                    if(res.status == 1){
                        this.syncSubBarCodeRestTime = null;
                    }else if(res.status == 0){
                        this.syncSubBarCodeRestTime = res.restTime;
                    }
                }
            });
        },
        //同步EBP
        EBPClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.formEBPQuery.goodsEbpGoodsItems = this.selectionList.map((item) => {
                return {
                    goodsId: item.goodsId,
                    barCode: item.barCode,
                    sku: item.sku
                };
            });

            let arr = this.languageList.map((item) => {
                return item.code;
            })
            this.formEBPQuery.titleLang = arr;
            this.formEBPQuery.detailLang = arr;
            //【商品大图】默认仅勾选西班牙语和英语
            this.formEBPQuery.imageLang = [];
            this.formEBPQuery.imageLang.push('es');
            this.formEBPQuery.imageLang.push('en');
            
            this.dialogEBPVisible = true;
        },
        //同步EBP
        EBPPriceClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }

            let skuList = []
            this.selectionList.forEach((item, index) => {
                if (item.sku) {
                    skuList.push(item.sku);
                }
            });
            if(skuList.length === 0){
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.FirstSynchronizeSKU')
                });
                return;
            }

            this.dialogEBPPriceVisible = true;
            let params = {"sync" : false}
            this.$store.dispatch('syncEbpPrice', params).then((res) => {
                if (res) {
                    if(res.status == 1){
                        this.syncEbpPriceRestTime = null;
                    }else if(res.status == 0){
                        this.syncEbpPriceRestTime = res.restTime;
                    }
                }
            });
        },
        //同步EBP价格
        syncEbpPrice() {
            const params = {}
            let skuList = []
            this.selectionList.forEach((item, index) => {
                if (item.sku) {
                    skuList.push(item.sku);
                }
            });
            params.skuList = skuList
            params.sync = true // true 同步ebp价格 false 查询状态

            this.$store.dispatch('syncEbpPrice', params).then((res) => {
                if (res) {
                    if (res.status == 1) {
                        this.$message.success(i18n.t('common.Synccompleted'));
                        this.dialogEBPPriceVisible = false;
                    } else if (res.status == 0) {
                        this.syncEbpPriceRestTime = res.restTime;
                    }
                }
            });
        },
        //同步数量装
        packageQtyClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t('common.Pleaseselectaproduct')
                });
                return;
            } else if (this.selectionList.length > 1) {
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t('common.OnlyOneProductCanBeSynchronized')
                });
                return;
            }
            //使用商品关联ID同步数量装
            this.formPackageQtyQuery.relationGoodsId = this.selectionList[0].relationGoodsId;
            this.dialogPackageQtyVisible = true;
        },
        //无库存显示
        outStockClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.dialogOutStockVisible = true;
        },
        downloadTemplateGoods(){
            let fileName=  this.$i18n.t("common.ProductImporTemplate") + '.xlsx';  
            this.$store.dispatch('downloadTemplateGoods', { fileName: fileName }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Downloadsuccessful")
                });
            });
        },
        //处理批量编辑上架国家
        handleCheckCountry() {
            if (this.checkCountryList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectacountry')
                });
                return;
            }
        },
        //批量编辑上架国家
        countryClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.checkAll = true;
            this.dialogCountryVisible = true;
        },
        //处理批量编辑上架国家
        handleCountryClick() {
            if (this.checkCountryList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectacountry')
                });
                return;
            }
            //校验上架国家
            let countryCodes = this.checkCountryList;
            if (!this.validShelfCountry(this.selectionList, countryCodes)) {
                this.$message({
                    message: this.$i18n.t('common.SalesMultipleDoesNotMatchCannotBeListed'),
                    type: 'warning'
                });
                return;
            }

            let arr = [];
            this.selectionList.forEach((item, index) => {
                if (item.goodsId) {
                    arr.push(item.goodsId);
                }
            });
            const data = {
                goodsIds: arr,
                countryCodes: this.checkCountryList
            };
            this.$store.dispatch('putGoodsShelves', data).then((res) => {
                if (res) {
                    this.$message({
                        type: 'success',
                        message: this.$i18n.t('common.SuccessfullyListed')
                    });
                    this.dialogCountryVisible = false;
                    this.handleSearchListF();
                    // this.$refs.tableData.toggleRowSelection(row);
                }
            });
        },
        //编辑类目
        classClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.dialogClassVisible = true;
        },
        //全选
        handleCheckAllChange(val) {
            let arr = this.dictionary.country.map((item) => {
                return item.code;
            });
            this.checkCountryList = val ? arr : [];
            this.isIndeterminate = false;
        },
        //全选
        handleCheckAllTitleLangChange(val){
            let arr = this.languageList.map((item) => {
                return item.code;
            })
            this.formEBPQuery.titleLang = val ? arr : [];
            this.isIndeterminateTitleLang = false;
        },
        //全选
        handleCheckAllImageLangChange(val){
            let arr = this.languageList.map((item) => {
                return item.code;
            })
            this.formEBPQuery.imageLang = val ? arr : [];
            this.isIndeterminateImageLang = false;
        },
        //全选
        handleCheckAllDetailLangChange(val){
            let arr = this.languageList.map((item) => {
                return item.code;
            })
            this.formEBPQuery.detailLang = val ? arr : [];
            this.isIndeterminateDetailLang = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkCountryList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
        },
        handleCheckedTitleLangChange(value){
            let checkedCount = value.length;
            this.checkAllTitleLang = checkedCount === this.languageList.length;
            this.isIndeterminateTitleLang = checkedCount > 0 && checkedCount < this.languageList.length;
        },
        handleCheckedImageLangChange(value){
            let checkedCount = value.length;
            this.checkAllImageLang = checkedCount === this.languageList.length;
            this.isIndeterminateImageLang = checkedCount > 0 && checkedCount < this.languageList.length;
        },
        handleCheckedDetailLangChange(value){
            let checkedCount = value.length;
            this.checkAllDetailLang = checkedCount === this.languageList.length;
            this.isIndeterminateDetailLang = checkedCount > 0 && checkedCount < this.languageList.length;
        },
        //批量搜索
        handleSearch() {
            if (!this.describe) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseenterbarcode')
                });
                return;
            }
            this.loading = true;
            this.loadingSearch = true;
            let listQuery = {
                page: this.listQuery.page,
                size: this.goodsPageSize,
                barCodes: this.describe ? this.describe.replace(/\n/g, ',') : ''
            };
            // getGoodsList
            this.$store.dispatch('postGoodsSearch', listQuery).then((res) => {
                if (res) {
                    this.$message({
                        type: 'success',
                        message: this.$i18n.t('common.SearchSuccessful')
                    });
                    if (res.list.length > 0) {
                        this.handleImgType(res.list);
                        //过滤租户国家
                        res.list = this.filterTenantCountry(res.list);
                    }
                    if (res.barCodes) {
                        this.oldDescribe = res.barCodes.replace(/,/g, '\n');
                    }
                    this.pageData = res;
                    this.searchType = 1;
                    this.$refs.tableData.clearSelection();
                    this.$refs.tableData.toggleAllSelection();
                    this.importQuery = Object.assign({}, defaultImportQuery);
                    this.$refs.filterContainer.listQueryData = Object.assign({}, this.defaultListQuery);
                    this.category = '';
                    this.dialogSearchVisible = false;
                    this.$forceUpdate();
                    this.$nextTick(() => {
                        this.$refs.tableData.doLayout();
                    });
                    setTimeout(() => {
                        this.loading = false;
                        this.loadingSearch = false;
                    }, 500);
                }
            });
        },
        handleSearchListF(type = 0) {
            this.listQuery.page = 1;
            if (type == 1) {
                this.$refs.tableData.clearSelection();
            }
            let listQuery = this.$refs.filterContainer.listQueryData;
            console.log(listQuery);
            if (this.pageData.barCodes) {
                listQuery.barCodes = this.pageData.barCodes;
            }
            this.handleSearchList(listQuery);
        },
        handleRouteSearch() {
            // 页面跳转路由
            let code = this.$route.params.code;
            if (code) {
                // 重置参数 参考handleResetSearch
                this.category = '';
                this.describe = '';
                this.oldDescribe = '';
                this.listQuery = Object.assign({}, defaultListQuery);
                this.$refs.tableData.clearSelection();

                // 设置 条码查询
                let listQuery = {
                    code: code
                };
                this.$refs.filterContainer.handleListQueryData(listQuery);
                this.handleSearchList(listQuery);
            } 
            // else {
            //     this.handleResetSearch2();
            // }
        },
        //搜索列表
        handleSearchList(listQuery) {
            console.log(listQuery);
            console.log(this.listQuery.page);
            this.loading = true;
            let curSates = {
                page: this.listQuery.page,
                size: this.goodsPageSize
            };
            if (listQuery) {
                let inPromotion = '';
                let isOnShelf = '';
                let fixedPrice = '';
                let tag = '';
                if (listQuery.promotion === true) {
                    inPromotion = 'true';
                } else if (listQuery.promotion === false) {
                    inPromotion = 'false';
                }
                if (listQuery.fixedPrice === true) {
                    fixedPrice = 'true';
                } else if (listQuery.fixedPrice === false) {
                    fixedPrice = 'false';
                }
                if (listQuery.state === true) {
                    isOnShelf = 'true';
                } else if (listQuery.state === false) {
                    isOnShelf = 'false';
                }
                if (listQuery.mark == 'null') {
                    tag = null;
                } else {
                    tag = listQuery.mark;
                }
                this.listQuery = {
                    shelfCountryCode: listQuery.country,
                    classifyId: this.category,
                    idFuzzy: listQuery.code,
                    brandCode: listQuery.brand ? listQuery.brand : '',
                    updateUserId: listQuery.operator, //操作人
                    inPromotion: inPromotion, //是否促销
                    preSaleCountryCode: listQuery.preSaleCountryCode, //是否预售
                    useFixedPrice: fixedPrice, //是否固定价格
                    effectShelf: isOnShelf, //是否上架
                    beginCreateTime: listQuery.date ? new Date(listQuery.date[0]).getTime() : '',
                    endCreateTime: listQuery.date ? new Date(listQuery.date[1]).getTime() : '',
                    beginUpdateTime: listQuery.date2 ? new Date(listQuery.date2[0]).getTime() : '',
                    endUpdateTime: listQuery.date2 ? new Date(listQuery.date2[1]).getTime() : '',
                    page: this.listQuery.page,
                    size: this.goodsPageSize,
                    barCodes: this.describe ? this.describe.replace(/\n/g, ',') : '',
                    stockCountryCode: listQuery.stockCountryCode,
                    isStock: listQuery.isStock,
                    // sortRule:{
                    //     sortType:this.listQuery['sortRule.sortType'],
                    //     sortOrder:this.listQuery['sortRule.sortOrder']
                    // },
                    tag
                };
                if (this.sortRule.sortType !== null && this.sortRule.sortType !== '') {
                    this.listQuery.sortRule = {
                        sortType: this.sortRule.sortType,
                        sortOrder: this.sortRule.sortOrder
                    };
                }

                let temp = JSON.stringify(this.listQuery, (key, value) => {
                    if (value !== null && value !== '' && JSON.stringify(value) !== '{}') {
                        return value;
                    }
                });
                curSates = JSON.parse(temp);
                // console.log('this.listQuery',curSates)
            }
            //库存选项校验，两个必须都选
            if (curSates.stockCountryCode != null && curSates.isStock == null) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.PleaseSelectInventory')
                });
                this.loading = false;
                return;
            }
            if (curSates.isStock != null && curSates.stockCountryCode == null) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectacountry')
                });
                this.loading = false;
                return;
            }
            //表头过滤
            this.$store.dispatch('getTableColumn').then((res) => {
                this.tableColumSetting = res.filter(x=>x.status).map(x =>{
                    return x.columnCode;
                });
                console.log(this.tableColumSetting);
            });
            this.$store.dispatch('postGoodsSearch', curSates).then((res) => {
                this.searchType = 0;
                // this.describe = '';
                this.oldDescribe = '';
                if (res && res.list.length > 0) {
                    this.handleImgType(res.list);
                }
                this.pageData = res;
                //过滤租户国家
                this.pageData.list = this.filterTenantCountry(this.pageData.list);

                //子表格回显
                showChildrenCheckbox(this.$refs.tableData, this.pageData.list);

                setTimeout(()=>{
                    this.$refs.tableData.doLayout()
                },500)

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            });
        },
        //过滤租户国家
        filterTenantCountry(list) {
            let tenantCountrys = dictionaryUtil.getDict('countrys').map((country) => country.code);
            list.map((item) => {
                let goodsSpecials = item.goodsSpecials;
                let prices = item.prices;
                item.goodsSpecials = goodsSpecials.filter((goodsSpecials) => tenantCountrys.includes(goodsSpecials.countryCode));
                item.prices = prices.filter((prices) => tenantCountrys.includes(prices.countryCode));
            });
            return list;
        },
        //记录更新列表
        getGoodsImport(query) {
            if (query) {
                this.importQuery.createBy = query.operator ? query.operator : '';
                if (query.date && query.date.length > 0) {
                    this.importQuery.beginTime = query.date[0];
                    this.importQuery.endTime = query.date[1];
                } else {
                    this.importQuery.beginTime = '';
                    this.importQuery.endTime = '';
                }
            } else {
                this.importQuery = Object.assign({}, defaultImportQuery);
            }
            this.$store.dispatch('getGoodsImport', this.importQuery).then((res) => {
                this.importData = res;
            });
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            if (this.searchType == 0) {
                this.handleSearchList(this.$refs.filterContainer.listQueryData);
            } else if (this.searchType == 1) {
                this.handleSearch(this.$refs.filterContainer.listQueryData);
            }
        },
        handleCurrentChangeRecord(val) {
            this.importQuery.page = val;
            this.getGoodsImport(this.$refs.FormTableDialog.query);
        },
        //批量搜索
        searchClick() {
            this.dialogSearchVisible = true;
        },
        handleResetSearch() {
            this.category = '';
            this.describe = '';
            this.oldDescribe = '';
            this.listQuery = Object.assign({}, defaultListQuery);
            this.listQuery.size = this.goodsPageSize;
            this.$refs.tableData.clearSelection();
            this.handleSearchList();
        },
        handleResetSearch2() {
            this.$refs.tableData.clearSelection();
            this.$refs.filterContainer.handleResetSearch();
        },
        //获取商品树类目
        getGoodsClassifyTree() {
            this.$store.dispatch('getGoodsClassifyTree', {}).then((res) => {
                this.$set(this.dictionary, 'classifyTree', res);
            });
        },
        closeMarkDialog() {
            this.dialogMarkisible = false;
            this.markQuery = Object.assign({}, defaultMarkQuery);
        },
        //底线弹窗关闭
        closeBaselineDialog() {
            this.getbyUser();
            this.dialogBaselineisible = false;
        },
        closeRecordDialog() {
            this.dialogRecordVisible = false;
        },
        closeSKUDialog() {
            this.dialogSKUVisible = false;
        },
        closeSubBarCodeDialog() {
            this.dialogSubBarCodeVisible = false;
        },
        closeEbpPriceDialog() {
            this.dialogEBPPriceVisible = false;
        },
        closeSearchDialog() {
            // if (this.oldDescribe) {
            //     this.describe = this.oldDescribe;
            // } else {
            //     this.describe = '';
            // }
            this.oldDescribe = '';
            this.describe = '';
            this.dialogSearchVisible = false;
            this.handleSearchListF(1);
        },
        closeCountryDialog() {
            let arr = this.dictionary.country.map((item) => {
                return item.code;
            });
            this.checkCountryList = arr;
            this.isIndeterminate = false;
            this.dialogCountryVisible = false;
        },
        closeClassDialog() {
            this.dialogClassVisible = false;
        },
        //关闭窗口
        closeDialog() {
            this.dialogTabsVisible = false;
            this.formListQuery = Object.assign({}, defaultFormListQuery);
        },
        //关闭窗口
        closeDetailsDialog() {
            this.dialogDetailVisible = false;
            this.languageList = JSON.parse(JSON.stringify(defaultlanguageList));
        },
        //获取组件穿过来的类目
        handleChangeCategory(val) {
            if (val) {
                this.category = val[val.length - 1];
            } else {
                this.category = '';
            }
        },
        getRowKeys(row) {
            return row.id;
        },
        //自定义label
        renderRuHeader() {
            const creationtime = i18n.t('common.Creationtime');
            return (
                <div>
                    <el-button type="text" size="small" on-click={() => this.showRufilters()}>
                        <span class="ruku">
                            {creationtime} <span class="el-icon-sort"></span>
                        </span>
                    </el-button>
                </div>
            );
        },
        renderGeHeader() {
            const updatetime = i18n.t('common.Updatetime');
            return (
                <div>
                    <el-button type="text" size="small" on-click={() => this.showGefilters()}>
                        <span class="ruku">
                            {updatetime} <span class="el-icon-sort"></span>
                        </span>
                    </el-button>
                </div>
            );
        },
        //入库 更新排序
        showRufilters() {
            if (this.sortState === 0) {
                // MARKET_TIME
                // UPDATE_TIME
                this.sortRule.sortType = 'MARKET_TIME';
                this.sortRule.sortOrder = 'ASC';
                this.handleSearchListF(2);
                this.sortState = 1;
            } else {
                this.sortRule.sortType = 'MARKET_TIME';
                this.sortRule.sortOrder = 'DESC';
                this.handleSearchListF(2);
                this.sortState = 0;
            }
        },
        showGefilters() {
            if (this.sortState1 === 0) {
                this.sortRule.sortType = 'UPDATE_TIME';
                this.sortRule.sortOrder = 'ASC';
                this.handleSearchListF(2);
                this.sortState1 = 1;
            } else {
                this.sortRule.sortType = 'UPDATE_TIME';
                this.sortRule.sortOrder = 'DESC';
                this.handleSearchListF(2);
                this.sortState1 = 0;
            }
        },
        // 选择行 勾选复选框
        toggleSelection(row) {
            this.$refs.tableData.toggleRowSelection(row);
        },
        // 表格多选框 选中数据
        handleSelectionChange(selection) {
            this.selectionList = selection;
        },
        //表头多选框 选中事件
        handleColumnSelectionChange(selection) {
            this.columnSecelctionList = selection;
        },
        close() {
            // 取消表格的勾选
            this.tableList.tableData.forEach((row) => {
                this.$refs.tableData.toggleRowSelection(row, false);
            });
        },

        //单个上架下架
        offorPut(rows, type) {
            this.tipsList = [];
            this.curRow = rows ? rows : this.curRow;
            let row = this.curRow;
            console.log('row', row, type);

            if (type == 'up') {
                let preSaleCheck = false;
                this.$store.dispatch('getGoodsClassifycheck', [row.goodsId]).then((res) => {
                    if (res && res.length > 0) {
                        res.forEach((item, index) => {
                            if (item.checkType == 'Image') {
                                this.tipsList.push(i18n.t('common.ProductLargePicture'));
                            } else if (item.checkType == 'Title') {
                                this.tipsList.push(i18n.t('common.ProductTitle'));
                            } else if (item.checkType == 'PreSale') {
                                preSaleCheck = true;
                            }
                            // else if (item.checkType == 'Detail') {
                            //     this.tipsList.push('图文详情');
                            // }
                        });
                    }
                    setTimeout(() => {
                        if (preSaleCheck) {
                            this.$confirm(this.$i18n.t('common.PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }

                        if (!row.peso) {
                            this.tipsList.push(this.$i18n.t('common.ProductWeight'));
                        }
                        if (!row.sku && !scene.isNonSelf) {
                            this.tipsList.push('SKU');
                        }
                        let shelfCountrys = [];
                        if (row.goodsSpecials && row.goodsSpecials.length > 0) {
                            let arr = [];
                            row.goodsSpecials.forEach((item, index) => {
                                if (item.shelf) {
                                    shelfCountrys.push(item.countryCode);
                                }
                                arr.push(item.shelf);
                            });
                            if (arr.indexOf(true) == -1) {
                                this.tipsList.push(i18n.t('common.Countrieslisted'));
                            }
                        } else {
                            this.tipsList.push(i18n.t('common.Countrieslisted'));
                        }

                        if (row.prices) {
                            row.prices.forEach((item) => {
                                if (!scene.isNonSelf && !item.resultPrice) {
                                    this.tipsList.push(i18n.t('common.commodityprice'));
                                }
                                if (scene.isNonSelf && shelfCountrys.includes(item.countryCode) && !item.fixedPrice) {
                                    this.tipsList.push('请配置固定价格');
                                }
                            });
                        } else {
                            this.tipsList.push(i18n.t('common.commodityprice'));
                        }
                        if (this.tipsList.length > 0) {
                            this.tipsList = Array.from(new Set(this.tipsList));
                            this.dialogtipsVisible = true;
                            this.$forceUpdate();
                            return;
                        } else {
                            this.$confirm(this.$i18n.t('common.ConfirmExecution'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {
                                const data = {
                                    goodsIds: [].concat(row.goodsId),
                                    shelf: !row.shelf
                                };
                                this.$store.dispatch('putGoodsbShelf', data).then((res) => {
                                    this.handleSearchListF();
                                });
                            });
                        }
                    });
                });
            } else if (type == 'down') {
                this.$confirm(this.$i18n.t('common.ConfirmExecution'), {
                    confirmButtonText: i18n.t('common.confirm1'),
                    cancelButtonText: i18n.t('common.cancel'),
                    type: 'warning'
                }).then(() => {
                    const data = {
                        goodsIds: [].concat(row.goodsId),
                        shelf: !row.shelf
                    };
                    this.$store.dispatch('putGoodsbShelf', data).then((res) => {
                        this.handleSearchListF();
                    });
                });
            }
        },
        //上架二次确定
        shelfPut() {
            this.$confirm(this.$i18n.t('common.ConfirmExecution'), {
                confirmButtonText: i18n.t('common.confirm1'),
                cancelButtonText: i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {
                const data = {
                    goodsIds: [].concat(this.curRow.goodsId),
                    shelf: !this.curRow.shelf
                };
                this.$store.dispatch('putGoodsbShelf', data).then((res) => {
                    this.dialogtipsVisible = false;
                    this.handleSearchListF();
                });
            });
        },
        //批量上架
        putClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.tipsList = [];
            let arr = [];
            this.selectionList.forEach((item, index) => {
                if (item.goodsId) {
                    arr.push(item.goodsId);
                }
            });

            let preSaleCheck = false;
            this.$store.dispatch('getGoodsClassifycheck', arr).then((res) => {
                if (res && res.length > 0) {
                    res.forEach((item, index) => {
                        if (item.checkType == 'Image') {
                            this.tipsList.push(i18n.t('common.ProductLargePicture'));
                        } else if (item.checkType == 'Title') {
                            this.tipsList.push(i18n.t('common.ProductTitle'));
                        } else if (item.checkType == 'PreSale') {
                            preSaleCheck = true;
                        }

                        // else if (item.checkType == 'Detail') {
                        //     this.tipsList.push('图文详情');
                        // }
                    });
                }
                setTimeout(() => {
                    if (preSaleCheck) {
                        this.$confirm(this.$i18n.t('common.PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem'), {
                            confirmButtonText: i18n.t('common.confirm1'),
                            cancelButtonText: i18n.t('common.cancel'),
                            type: 'warning'
                        }).then(() => {});
                        return;
                    }

                    this.selectionList.forEach((item, index) => {
                        if (!item.sku && !scene.isNonSelf) {
                            this.tipsList.push('SKU');
                        }
                        if (!item.peso) {
                            this.tipsList.push(this.$i18n.t('common.ProductWeight'));
                        }
                        let shelfCountrys = [];
                        if (item.goodsSpecials) {
                            item.goodsSpecials.forEach((item, index) => {
                                if (item.shelf) {
                                    shelfCountrys.push(item.countryCode);
                                }
                                if (!item.countryCode) {
                                    this.tipsList.push(i18n.t('common.Countrieslisted'));
                                }
                            });
                        } else {
                            this.tipsList.push(i18n.t('common.Countrieslisted'));
                        }

                        if (item.prices) {
                            item.prices.forEach((item, index) => {
                                if (!scene.isNonSelf && !item.resultPrice) {
                                    this.tipsList.push(i18n.t('common.commodityprice'));
                                }
                                if (scene.isNonSelf && shelfCountrys.includes(item.countryCode) && !item.fixedPrice) {
                                    this.tipsList.push('请配置固定价格');
                                }
                            });
                        } else {
                            this.tipsList.push(i18n.t('common.commodityprice'));
                        }
                    });
                    if (this.tipsList.length > 0) {
                        this.tipsList = Array.from(new Set(this.tipsList));

                        this.dialogtipsVisible = true;
                        this.$forceUpdate();
                        return;
                    } else {
                        this.$confirm(this.$i18n.t('common.ConfirmBatchListing'), i18n.t('common.prompt'), {
                            confirmButtonText: i18n.t('common.confirm1'),
                            cancelButtonText: i18n.t('common.cancel'),
                            type: 'warning'
                        })
                            .then(() => {
                                const data = {
                                    goodsIds: arr,
                                    shelf: true
                                };
                                this.$store.dispatch('putGoodsbShelf', data).then((res) => {
                                    this.handleSearchListF();
                                });
                            })
                            .catch(() => {});
                    }
                });
            });
        },
        toUrl(barCode) {
            this.$router.push({
                name: 'comment_list',
                params: {
                    barCode: barCode
                }
            });
        },
        //批量下架
        offClick() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseselectaproduct')
                });
                return;
            }
            this.$confirm(`${i18n.t('common.Confirmtheexecutionofbatchdelisting')}?`, i18n.t('common.prompt'), {
                confirmButtonText: i18n.t('common.confirm1'),
                cancelButtonText: i18n.t('common.cancel'),
                type: 'warning'
            })
                .then(() => {
                    let arr = [];
                    this.selectionList.forEach((item, index) => {
                        if (item.goodsId) {
                            arr.push(item.goodsId);
                        }
                    });
                    const data = {
                        goodsIds: arr,
                        shelf: false
                    };
                    this.$store.dispatch('putGoodsbShelf', data).then((res) => {
                        this.handleSearchListF();
                    });
                })
                .catch(() => {});
        },
        importClick() {
            this.searchData.fileName = this.$i18n.t('common.Template') + '.xlsx';
            this.$store.dispatch('exportWorker', this.searchData);
        },
        loadGoodsClass() {
            var _this = this;
            this.$store.dispatch('getGoodsClassTotal', {}).then((res) => {
                res = this.$common.getTreeData(res);
                _this.goodsClassData = res;
            });
        },
        loadlist() {
            this.$store.dispatch('getGoodsPage', this.searchData).then((res) => {
                this.tableList.tableData = res;
            });
        },
        handleCommand(command, row) {
            console.log('command,row', command, row);
            var historyGoodsId = this.selectedIds;
            if (historyGoodsId.length <= 0) {
                this.$message.error(this.$i18n.t('common.PleaseSelectTheProductsToBeSynchronized'));
                return;
            }

            this.$confirm(
                this.$i18n.t('common.ConfirmSynchronizationExecution') +
                    '?(' +
                    this.$i18n.t('common.ForcedSynchronisationDescription') +
                    ')',
                i18n.t('common.prompt'),
                {
                    confirmButtonText: i18n.t('common.confirm1'),
                    cancelButtonText: i18n.t('common.cancel'),
                    type: 'warning'
                }
            )
                .then(() => {
                    var data = {
                        tag: tag,
                        historyGoodsId: historyGoodsId,
                        forced: forced
                    };
                    this.$store.dispatch('goodsSync', data).then((res) => {
                        if (res) {
                            this.$message.success(this.$i18n.t('common.SynchronisationHasBeenSentPleaseWait'));
                        }
                    });
                })
                .catch(() => {});
        },

        datasync(tag, forced) {
            console.log(tag, forced);
            var historyGoodsId = this.selectedIds;
            if (historyGoodsId.length <= 0) {
                this.$message.error(this.$i18n.t('common.PleaseSelectTheProductsToBeSynchronized'));
                return;
            }

            this.$confirm(
                this.$i18n.t('common.ConfirmSynchronizationExecution') +
                    '?(' +
                    this.$i18n.t('common.ForcedSynchronisationDescription') +
                    ')',
                i18n.t('common.prompt'),
                {
                    confirmButtonText: i18n.t('common.confirm1'),
                    cancelButtonText: i18n.t('common.cancel'),
                    type: 'warning'
                }
            )
                .then(() => {
                    var data = {
                        tag: tag,
                        historyGoodsId: historyGoodsId,
                        forced: forced
                    };
                    this.$store.dispatch('goodsSync', data).then((res) => {
                        if (res) {
                            this.$message.success(this.$i18n.t('common.SynchronisationHasBeenSentPleaseWait'));
                        }
                    });
                })
                .catch(() => {});
        },
        edit(items) {
            let row = Object.assign({}, items);
            let formListQuery = Object.assign({}, this.formListQuery);
            this.title = i18n.t('common.edit');
            if (row.goodsSpecials && row.goodsSpecials.length > 0) {
                row.SKUES = row.goodsSpecials.filter((item) => {
                    if (item.countryCode == 'ES') {
                        return item.saleDoubleEbp;
                    } else {
                        return 0;
                    }
                });
            }
            formListQuery.countryCodes = [];
            formListQuery.goodsId = row.goodsId;
            row.goodsSpecials.forEach((item) => {
                if (item.shelf == true) {
                    formListQuery.countryCodes.push(item.countryCode);
                }
            });
            if (row.peso) {
                formListQuery.peso = row.peso;
            }
            this.formData = JSON.parse(JSON.stringify(row));
            //用来初始化数据
            this.defaultFormData = JSON.parse(JSON.stringify(row));
            this.formListQuery = Object.assign({}, formListQuery);
            this.getGoodsClassifyTree();
            this.dialogTabsVisible = true;
        },
        goodsDetails(row) {
            this.formData = JSON.parse(JSON.stringify(row));
            //用来初始化数据
            this.defaultFormData = JSON.parse(JSON.stringify(row));
            this.getGoodsSeo(row);
            this.getGoodsTranslate(row);
            this.dialogDetailVisible = true;
        },
        preview(row) {
            var _this = this;
            // var url = `${this.webSiteUrl}preview/commodityDetail?goodsId=${row.goodsId}&countryCode=ES&currencyId=&priceType=E&tenantId=${row.tenantId}`;
            var url = `${this.webSiteUrl}commodityDetail?goodsId=${row.goodsId}`;
            window.open(url, '_blank');
        },
        getGoodsTranslate(row) {
            console.log('rowxxx', row);
            this.$store
                .dispatch('getGoodsTranslate', {
                    goodsId: row.goodsId
                })
                .then((res) => {
                    this.languageList.forEach((item, index) => {
                        res.forEach((item1, index1) => {
                            if (item1.lang == item.code) {
                                this.languageList[index].title = item1.title;
                            }
                        });
                    });
                });
        },
        addPreSale(row) {
            // 添加预售
            this.tipsList = [];
            let pallet = false;
            let promotion = false;
            let distribution = false;
            let fullDiscount = false;
            let goodsIdData = {
                goodsId: row.goodsId
            };
            this.$store.dispatch('getCheckPreSale', goodsIdData).then((res) => {
                if (res && res.length > 0) {
                    res.forEach((item, index) => {
                        if (item.checkType == 'Image') {
                            this.tipsList.push(i18n.t('common.ProductLargePicture'));
                        } else if (item.checkType == 'Title') {
                            this.tipsList.push(i18n.t('common.ProductTitle'));
                        } else if (item.checkType == 'Detail') {
                            this.tipsList.push(this.$i18n.t('common.GraphicDetails'));
                        } else if (item.checkType == 'Pallet') {
                            pallet = true;
                        } else if (item.checkType == 'Promotion') {
                            promotion = true;
                        } else if (item.checkType == 'Distribution') {
                            distribution = true;
                        } else if (item.checkType == 'FullDiscount') {
                            fullDiscount = true;
                        }
                    });
                }
                setTimeout(() => {
                    if (!row.peso) {
                        this.tipsList.push(this.$i18n.t('common.ProductWeight'));
                    }
                    if (!row.sku && !scene.isNonSelf) {
                        this.tipsList.push('SKU');
                    }
                    let shelfCountrys = [];
                    if (row.goodsSpecials && row.goodsSpecials.length > 0) {
                        let arr = [];
                        row.goodsSpecials.forEach((item, index) => {
                            if (item.shelf) {
                                shelfCountrys.push(item.countryCode);
                            }
                            arr.push(item.shelf);
                        });
                        if (arr.indexOf(true) == -1) {
                            this.tipsList.push(i18n.t('common.Countrieslisted'));
                        }
                    } else {
                        this.tipsList.push(i18n.t('common.Countrieslisted'));
                    }

                    if (row.prices) {
                        row.prices.forEach((item, index) => {
                            if (!scene.isNonSelf && !item.resultPrice) {
                                this.tipsList.push(i18n.t('common.commodityprice'));
                            }
                            if (scene.isNonSelf && shelfCountrys.includes(item.countryCode) && !item.fixedPrice) {
                                this.tipsList.push('请配置固定价格');
                            }
                        });
                    } else {
                        this.tipsList.push(i18n.t('common.commodityprice'));
                    }
                    console.log('this.tipsList', this.tipsList);

                    if (this.tipsList.length > 0) {
                        this.tipsList = Array.from(new Set(this.tipsList));
                        this.dialogtipsPreSaleVisible = true;
                        this.$forceUpdate();
                        return;
                    } else {
                        if (pallet) {
                            this.$confirm(this.$i18n.t('common.CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }
                        if (promotion) {
                            this.$confirm(this.$i18n.t('common.PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }
                        if (distribution) {
                            this.$confirm(
                                this.$i18n.t('common.DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst'),
                                {
                                    confirmButtonText: i18n.t('common.confirm1'),
                                    cancelButtonText: i18n.t('common.cancel'),
                                    type: 'warning'
                                }
                            ).then(() => {});
                            return;
                        }
                        if (fullDiscount) {
                            this.$confirm(this.$i18n.t('common.ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }

                        let priceMap = new Map();
                        row.prices.forEach((item) => {
                            priceMap.set(item.countryCode, item);
                        });
                        let countryPrice = '';
                        row.goodsSpecials.forEach((item) => {
                            if (item.shelf) {
                                let price = priceMap.get(item.countryCode);
                                if (price == null || !price.resultPrice) {
                                    // 没有价格
                                    countryPrice = countryPrice + item.countryCode + ',';
                                }
                            }
                        });

                        if (countryPrice) {
                            countryPrice = countryPrice.substring(0, countryPrice.length - 1);
                            this.$confirm(this.$i18n.t('common.ESNoPriceNotAvailableForPresale', { Any: countryPrice }), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }

                        this.$confirm(this.$i18n.t('common.ConfirmSettingThisProductAsAPresaleItem'), {
                            confirmButtonText: i18n.t('common.confirm1'),
                            cancelButtonText: i18n.t('common.cancel'),
                            type: 'warning'
                        }).then(() => {
                            const data = {
                                goodsId: row.goodsId,
                                preSale: true
                            };
                            this.$store.dispatch('putPreSale', data).then((res) => {
                                this.$message({
                                    message: this.$i18n.t('common.PresaleAddedSuccessfully'),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.handleSearchListF();
                            });
                        });
                    }
                });
            });
        },
        cancelPreSale(row) {
            //取消预售
            this.$confirm(this.$i18n.t('common.ConfirmSettingThisProductAsANonpresaleItem'), {
                confirmButtonText: i18n.t('common.confirm1'),
                cancelButtonText: i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {
                const data = {
                    goodsId: row.goodsId,
                    preSale: false
                };
                this.$store
                    .dispatch('putPreSale', data)
                    .then((res) => {
                        //
                        this.$message({
                            message: this.$i18n.t('common.CancelPresaleSuccessful'),
                            type: 'success',
                            duration: 1000
                        });
                        this.handleSearchListF();
                    })
                    .catch((err) => {});
            });
        },
        editPreSale(row) {
            // 预售配置
            // this.rowData=row;
            this.dialogPreSaleVisible = true;
            this.formItemPreSaleConfig = JSON.parse(JSON.stringify(this.defaultFormItemPreSaleConfig));
            this.formItemPreSaleConfig.goodsId = row.goodsId

        },
         //关闭预售配置窗口
         closePreSaleDialog(status,isEdit) {
            // this.rowData={};
            this.dialogPreSaleVisible = false;    
            if(status){
                // 商品id , 订单类型
                this.$router.push({
                    name: 'goodspresale',
                    params: {
                        queryType: status,
                    }
                });
            }
        },

        editPreSaleCountry(row) {
            this.dialogPreSaleCountryVisible = true;
            this.rowData = row
        },
        //关闭预售开关窗口
        closePreSaleCountryDialog() {
            this.dialogPreSaleCountryVisible = false;    
        },

        getGoodsSeo(row) {
            this.$store
                .dispatch('getGoodsSeo', {
                    goodsId: row.goodsId
                })
                .then((res) => {});
        },
        // 编辑固定价格
        editPrices(row) {
            this.formData = JSON.parse(JSON.stringify(row));
            // 用来初始化数据
            this.defaultFormData = JSON.parse(JSON.stringify(row));
            this.dialogPricesVisible = true;
        },
        // 关闭固定价格
        closePricesDialog() {
            this.dialogPricesVisible = false;
            this.formData = JSON.parse(JSON.stringify(this.defaultFormData));
        },
        fillImgTypeField(imageUrl) {
           
            if( imageUrl == null){
                return null;
            }
            
            let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
            let videolist = ['mp4', 'm2v', 'mkv'];
            let suffixType = imageUrl.substring(imageUrl.lastIndexOf('.') + 1, imageUrl.length).toLowerCase();
            // console.log('fillImgTypeField',suffixType)
            if (imageUrl && imglist.indexOf(suffixType) !== -1) {
                return 'img';
            } else if (imageUrl && videolist.indexOf(suffixType) !== -1) {
                return 'video';
            }
            return null;
        },
        //自定义选中框
        selectable: function (row, index) {
            return row.checkboxStatus;
        },
        //下载最新商品信息表
        downloadFileByDkAndNo(){
            this.loadingDownloadFileByDkAndNo = true;
            this.$store.dispatch('downloadFileByDkAndNo', {fileName: 'Latest Product Information Sheet.csv'}).then(res => {   
                this.$message({
                    type: 'success',
                    message: this.$i18n.t('common.Exportsuccessful')
                });
            }).finally(() => {
                this.loadingDownloadFileByDkAndNo = false;
            });

        },
        //处理图片类型
        handleImgType(list) {
            list.forEach((item) => {
                let imgType = this.fillImgTypeField(item.imageUrl);
                item.imgType = imgType;
                if (item.packageQtyList && item.packageQtyList.length > 0) {
                    item.packageQtyList.forEach((goods) => {
                        let imgType = this.fillImgTypeField(item.imageUrl);
                        goods.imgType = imgType;
                    });
                }
            });
        },
        //校验上架国家
        validShelfCountry(goodsPrivatelyList, countryCodes) {
            let bo = true;
            // countryCodes 有值
            goodsPrivatelyList.forEach((goodsPrivately) => {
                // 获取 选中的上架国家 并且 销售倍数不为空 为null代表 对应国家没有相关价格数据
                let shelfGoodsSpecials = goodsPrivately.goodsSpecials.filter((item)=> item.saleDoubleEbpNew).filter((item) => countryCodes.includes(item.countryCode));
                if (!shelfGoodsSpecials || !goodsPrivately.packageQuantity || shelfGoodsSpecials.length != countryCodes.length) {
                    bo = false;
                    return;
                }

                // 各国统一参考ES的销售倍数作为判断标准 只要包装数符合西班牙的销售倍数的整数倍就可以上架
                let shelfGoodsSpecialsByEs = goodsPrivately.goodsSpecials.filter((item) => item.countryCode == 'ES');
                if(!shelfGoodsSpecialsByEs ){
                    // 没有西班牙数据
                    bo = false;
                    return;
                }
                //数量装是否符合销售倍数
                // shelfGoodsSpecials.forEach((goodsSpecial) => {
                shelfGoodsSpecialsByEs.forEach((goodsSpecial) => {
                    if (!goodsSpecial.saleDoubleEbpNew || goodsPrivately.packageQuantity % goodsSpecial.saleDoubleEbpNew !== 0) {
                        bo = false;
                        return;
                    }
                });
            });
            return bo;
        },
        /**
         * 导出excel
         */
        handleExport() {

            

            let listQuery = this.$refs.filterContainer.listQueryData;
            if( this.pageData.barCodes ){
                // 批量搜索导出
                listQuery.barCodes = this.pageData.barCodes;
            }
            let curSates = {
                    page: this.listQuery.page,
                    size: this.listQuery.size
            };
                if (listQuery) {
                    let inPromotion = '';
                    let isOnShelf = '';
                    let fixedPrice = '';
                    let tag = '';
                    if (listQuery.promotion === true) {
                        inPromotion = 'true';
                    } else if (listQuery.promotion === false) {
                        inPromotion = 'false';
                    }
                    if (listQuery.fixedPrice === true) {
                        fixedPrice = 'true';
                    } else if (listQuery.fixedPrice === false) {
                        fixedPrice = 'false';
                    }
                    if (listQuery.state === true) {
                        isOnShelf = 'true';
                    } else if (listQuery.state === false) {
                        isOnShelf = 'false';
                    }
                    if (listQuery.mark == 'null') {
                        tag = null;
                    } else {
                        tag = listQuery.mark;
                    }
                    this.listQuery = {
                        shelfCountryCode: listQuery.country,
                        classifyId: this.category,
                        idFuzzy: listQuery.code,
                        brandCode: listQuery.brand ? listQuery.brand : '',
                        updateUserId: listQuery.operator, //操作人
                        inPromotion: inPromotion, //是否促销
                        preSale: listQuery.preSale, //是否预售
                        useFixedPrice: fixedPrice, //是否固定价格
                        effectShelf: isOnShelf, //是否上架
                        beginCreateTime: listQuery.date ? new Date(listQuery.date[0]).getTime() : '',
                        endCreateTime: listQuery.date ? new Date(listQuery.date[1]).getTime() : '',
                        beginUpdateTime: listQuery.date2 ? new Date(listQuery.date2[0]).getTime() : '',
                        endUpdateTime: listQuery.date2 ? new Date(listQuery.date2[1]).getTime() : '',
                        page: this.listQuery.page,
                        size: this.listQuery.size,
                        barCodes: this.describe ? this.describe.replace(/\n/g, ',') : '',
                        stockCountryCode: listQuery.stockCountryCode,
                        isStock: listQuery.isStock,
                        // sortRule:{
                        //     sortType:this.listQuery['sortRule.sortType'],
                        //     sortOrder:this.listQuery['sortRule.sortOrder']
                        // },
                        tag
                };
                if (this.sortRule.sortType !== null && this.sortRule.sortType !== '') {
                    this.listQuery.sortRule = {
                        sortType: this.sortRule.sortType,
                        sortOrder: this.sortRule.sortOrder
                    };
                }

                let temp = JSON.stringify(this.listQuery, (key, value) => {
                    if (value !== null && value !== '' && JSON.stringify(value) !== '{}') {
                        return value;
                    }
                });
                curSates = JSON.parse(temp);
                // console.log('this.listQuery',curSates)
                }

                curSates.fileName = 'Product Information' + '.xlsx';

                this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                    confirmButtonText: this.$i18n.t('common.confirm1'),
                    cancelButtonText: this.$i18n.t('common.cancel'),
                    type: 'warning'
                })
                .then(() => {
                    this.$refs.filterContainer.fullscreenLoading = true;
                    this.$store.dispatch('exportGoodsInfoExcel', curSates).then((res) => {
                        this.$refs.filterContainer.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Exportsuccessful')
                        });
                    });
                })
                .catch(() => {
                    this.$refs.filterContainer.fullscreenLoading = false;
                });
            
        },     
        handleShowColumn() {
            this.showColumnVisible = true;
            this.$store.dispatch('getTableColumn').then((res) => {
                this.tableColumn = res;
                this.tableColumn.forEach((x) => {
                    this.$nextTick(() =>{
                        if (x.status === true) {
                        this.$refs.columnTable.toggleRowSelection(x,true);
                    }
                    })
                });
            });
            
        },

        // 批量搜索导出
        handleExportByBarCodes(){

            let curSates = {
                page: 1,
                size: 500,
                barCodes: this.describe ? this.describe.replace(/\n/g, ',') : ''
            };
            curSates.fileName = 'Product Information' + '.xlsx';

            this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
                cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {
                this.$refs.filterContainer.fullscreenLoading = true;
                this.$store.dispatch('exportGoodsInfoExcel', curSates).then((res) => {
                    this.$refs.filterContainer.fullscreenLoading = false;
                    this.$message({
                        type: 'success',
                        message: this.$i18n.t('common.Exportsuccessful')
                    });
                });
            }).catch(() => {
                this.$refs.filterContainer.fullscreenLoading = false;
            });  
        },

        submitColumnChange(){
            console.log(this.columnSecelctionList);
            this.$store.dispatch('updateTableColumn',this.columnSecelctionList ).then((res) => {
                    this.closeColumnDialog();
                    let listQuery = this.$refs.filterContainer.listQueryData;
                    this.handleSearchList(listQuery);
                    
            });
        },
        closeColumnDialog() {
            this.showColumnVisible = false;
        },
        //切换分页
        handleGoodsCommand(command) {
            cache.set(config.goodsPageSize,command);     
            this.goodsPageSize = command;
            this.listQuery.page = 1;
            this.listQuery.size = this.goodsPageSize;
            if (this.searchType == 0) {
                this.handleSearchList(this.$refs.filterContainer.listQueryData);
            } else if (this.searchType == 1) {
                this.handleSearch(this.$refs.filterContainer.listQueryData);
            }
        },
        handleBatchSerach(){
            this.listQuery.page = 1;
            this.listQuery.size = this.goodsPageSize;
            this.handleSearch(this.$refs.filterContainer.listQueryData);
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice
        // handleSelectionChange(val) {
        // 	var historyGoodsId = val.map((x) => x.historyGoodsId);
        // 	this.selectedIds = historyGoodsId;
        // }
    }
};
</script>
<style scoped>
::v-deep .table-fixed .el-table__fixed-right {
    height: 100% !important;
}
::v-deep .table-fixed .el-table__fixed {
    height: 100% !important;
}
.input-width {
    width: 203px;
}

.down-template {
    text-decoration: underline;
    color: rgb(38, 166, 222);
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    margin: 20px 0px 0px 10px;
    cursor: pointer;
}
.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ruku {
    width: 150px;
    color: #000000;
}

</style>
