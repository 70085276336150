<template>
   
    <div  >
    
        <div class="filter-container" shadow="never">
            <el-form :inline="true">        
                <el-form-item>
                    <el-input v-model="listQuery.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")' clearable ></el-input>
                </el-form-item>
   
                <el-form-item>
                    <el-select v-model="listQuery.effectShelf" :placeholder='$i18n.t("common.state")'   clearable  >
                        <el-option :label='$i18n.t("common.Listed")' value="true"></el-option>
                        <el-option :label='$i18n.t("common.Notlisted")' value="false"></el-option>
                    </el-select>
                </el-form-item>
                 
                <el-form-item >

                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="batchsearchClick" style="margin-left: 10px"> 
                        {{ $i18n.t("common.Batchsearch") }}
                    </el-button>

                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleSearchList" style="margin-left: 10px"> 
                        {{ $i18n.t("common.query") }}
                    </el-button>

                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleResetSearch" style="margin-left: 10px" size="small">    
                         {{ $i18n.t("common.Reset") }}
                    </el-button>

                </el-form-item>
                
                
                
            </el-form>
        </div>     

            
 
        <el-table @selection-change="handleSelectionChange" row-key="goodsId" ref="tableData" default-expand-all
            :data="pageData.list" style="width: 100%" border
            :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
            id="goodsTable"
            @select="selectOfSelectable"
            @select-all="selectAllOfSelectable"
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">

            <el-table-column type="selection" width="55" :reserve-selection="true" align="center"


                show-overflow-tooltip :selectable="row => row.checkboxStatus"></el-table-column>
            <!-- 用于显示子表格张开按钮 -->
            <el-table-column prop="quantityType" label="" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center"

                show-overflow-tooltip></el-table-column>

            <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span><br />
                    <span>EAN:{{ scope.row.barCode }}</span><br />
                    <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="height: 100px" v-if="scope.row.imageUrl"
                        :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center"

                show-overflow-tooltip></el-table-column>

            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                    </span>
                </template>
            </el-table-column>


            <el-table-column prop="prices" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                        <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.NewUserPromotion") }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                        </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.RegularUserPromotion") }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                            </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.countryCode }}:{{
                        s.resultPrice.toFixed(2) }}<br /> </span>
                </template>
            </el-table-column>

            <el-table-column prop="shelf" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{$i18n.t("common.Listed") }}</span>
                    <span v-else>{{$i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>

        </el-table>

        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                :page-size="listQuery.size" layout="total, prev, pager, next, jumper"
                :total="pageData.total">
            </el-pagination>
        </div>


      
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: flex-end;align-items: center;" >           
            <span>{{ $i18n.t("common.Selected") }}:<span style="color: #008000;font-size: 16px;font-weight: 800;">{{pageDataSelectList.length}}</span></span>       
            <el-button style="width: 20%;margin-left: 50px;" type="primary" @click="handleSelected"> {{ $i18n.t("common.confirm") }}</el-button>
        </span>

      
 
        <el-dialog :title="$t('common.Batchsearch')" :close-on-click-modal="false"  :visible.sync="dialogBatchSearchVisible" append-to-body width="30%"  >
                
                <div v-if="dialogBatchSearchVisible">
                    <span>{{ $t('common.Enterproductbarcodeandsubmitforaddition') }}：</span>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4 }"
                        :placeholder="$t('common.Enterproductbarcode')"
                        v-model="describe"
                    ></el-input>
                    <span slot="footer" class="dialog-footer" style="margin-top: 20px;display: flex;justify-content: center;align-items: center;">
                        <el-button @click="closeBatchSearchDialog">{{ $t('common.cancel') }}</el-button>
                        <el-button type="primary" @click="handleBatchSerach" :loading="loadingBatchSearch">{{ $t('common.confirm1') }}</el-button>
                    </span>
                </div>
        </el-dialog>



    </div>
</template>
<script>
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
 
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import i18n from '@/i18n/i18n.js';

 
export default {
    components: {
 
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        goodsSelectList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {   
            listQuery: {
				"idFuzzy": "",
				"effectShelf": "",//是否上架
				"page": 1,
				"size": 10
			},
			defaultListQuery: {
				"idFuzzy": "",
				"effectShelf": "",//是否上架
				"page": 1,
				"size": 10
			},
            pageData: {},
            pageDataSelectList: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            flag: false,
            dialogBatchSearchVisible: false,
            loadingBatchSearch: false,
            //多条条码搜索
            describe: '',
            oldDescribe: '',
        };
    },
    watch: {
 
        describe(newValue, oldValue) {
            if (this.getAddRow() > 500) {
                this.describe = oldValue;
                this.$message({
                    message: this.$i18n.t('common.EnterUpTo500Entries'),
                    type: 'warning'
                });
            }
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        // 选择商品
        handleSelectionChange(val) {
            if (this.flag) return;
            this.pageDataSelectList = val;
            
        },

        // 页面加载
        initData() {
            this.pageDataSelectList=[];
            this.listQuery = Object.assign({}, this.defaultListQuery);
			this.loadlist();
            this.flag = true;
            if (this.goodsSelectList.length > 0) {
                this.goodsSelectList.forEach(item => {
                    this.$refs.tableData.toggleRowSelection(item)
                });              
                this.pageDataSelectList = this.goodsSelectList
            }
            this.flag = false;
		},
        loadlist(){

            this.listQuery.barCodes = this.describe ? this.describe.replace(/\n/g, ',') : '';

			let temp = JSON.stringify(this.listQuery, (key, value) => {
				if (value !== null && value !== '') {
					return value;
				}
			});
			let curSates = JSON.parse(temp);
			this.$store.dispatch('postGoodsSearch', curSates).then(res => {
                this.pageData = res
                this.dialogBatchSearchVisible = false;
                this.oldDescribe = res.barCodes
                showSelectOfSelectable(this.$refs.tableData, this.pageData.list)
            });
        },
        // 查询条件搜索
        handleSearchList() {
            this.listQuery.page = this.defaultListQuery.page
            this.loadlist();
		}, 
        // 点击重置
        handleResetSearch(){
            this.oldDescribe = '';
            this.describe = '';
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.loadlist();
        },
        // 点击分页
        handleCurrentChange(val){
			this.listQuery.page = val;
			this.loadlist();
        },


        // 点击确定
		handleSelected() {
            if (!this.pageDataSelectList || this.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst") ,
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            this.$emit('handleSelected',JSON.parse(JSON.stringify(this.pageDataSelectList)));
		},


        //批量搜索
        batchsearchClick() {
         
            if (this.oldDescribe) {
                this.describe = this.oldDescribe;
            } else {
                this.describe = '';
            }
            this.dialogBatchSearchVisible = true;
        },

        closeBatchSearchDialog() {
            this.oldDescribe = '';
            this.describe = '';
            this.dialogBatchSearchVisible = false;
            this.handleSearchList();
        },
        handleBatchSerach(){
            if (!this.describe) {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.Pleaseenterbarcode')
                });
                return;
            }           
            this.loadlist();
        },
        getAddRow() {
            let row = 0;
            this.describe.split('\n').forEach((item) => {
                if (item.length === 0) {
                    row += 1;
                } else {
                    row += Math.ceil(item.replace(/[\u0391-\uFFE5]/g, 'aa').length / 20);
                }
            });
            return row;
        },

        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
 
 

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
}
  
  
 
 
</style>
