<template>
    <div>
        <div class="filter-container">
            <el-form :inline="true">
                <el-form-item>
                    <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.position"
                            clearable
                            :codeType="'position'"
                            :placeholder='$i18n.t("common.Recommendedlocation")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.recommendModule"
                            clearable
                            :codeType="'recommend_module'"
                            :placeholder='$i18n.t("common.Recommendationmodule")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                            :multiple="true"
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.countryCode"
                            clearable
                            :codeType="'countrys'"
                           :placeholder='$i18n.t("common.country")'
                    ></dataSelect>
                </el-form-item>

                <!-- <el-form-item>
                    <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.brandCode"
                            clearable
                            :codeType="'brand'"
                            :placeholder='$i18n.t("common.brand")'
                    ></dataSelect>
                </el-form-item> -->

                <el-form-item>
                    <el-select
                            v-model="searchData.status"
                            :placeholder='$i18n.t("common.Enableornot")'
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.yes")' value="true"></el-option>
                        <el-option :label='$i18n.t("common.no")' value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>

                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick"
                               style="margin-left: 10px">
                               {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery"
                               style="margin-left: 10px" size="small">
                               {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin: 20px 0px 20px 20px">
            <el-button @click="add(null)" type="success" size="small">  {{ $i18n.t("common.Add1") }}</el-button>
        </div>
        <el-table v-loading="listloading" ref="pagedata" :data="pageData.list" border style="width: 100%">
            <el-table-column prop="homeModuleId" label="ID" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="position" :label='$i18n.t("common.Recommendedlocation")'align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.position" :codeType="'position'"></dataText>
                </template
                >
            </el-table-column>
            <el-table-column prop="recommendModule" :label='$i18n.t("common.Recommendationmodule")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.recommendModule" :codeType="'recommend_module'"></dataText>
                </template
                >
            </el-table-column>
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.countryCode" :codeType="'countrys'"></dataText>
                </template
                >
            </el-table-column>
            <el-table-column prop="recommendCount" :label='$i18n.t("common.Numberofproducts")' align="center"
                             show-overflow-tooltip></el-table-column>

<!--            <el-table-column prop="brandCode"  :label='$i18n.t("common.brand")' align="center" show-overflow-tooltip></el-table-column>-->

            <el-table-column
                    prop="createTime"
                    :label='$i18n.t("common.Addtime")'
                    align="center"
                    show-overflow-tooltip
                    :formatter="formatDate"
            ></el-table-column>
            <el-table-column
                    prop="updateTime"
                    :label='$i18n.t("common.Updatetime")'
                    align="center"
                    :formatter="formatDate"
                    show-overflow-tooltip
            ></el-table-column>

            <el-table-column prop="updateName" :label='$i18n.t("common.Operator")'    align="center" show-overflow-tooltip></el-table-column>

            <el-table-column :label='$i18n.t("common.Enableornot")' prop="status" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-switch :disabled="true" v-model="row.status" active-color="#13ce66"></el-switch>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" width='200' fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row, 'dialogFormVisible')" type="text" size="small">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button @click="chooseGood(scope.row, 'dialogFormTableVisible')" type="text" size="small">{{ $i18n.t("common.commodity") }}
                    </el-button>
                    <el-button @click="addGoods(scope.row)" type="text" size="small">{{ $i18n.t("common.Addproduct") }}</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="searchData.page"
                    :page-size="searchData.size"
                    layout="total, prev, pager, next, jumper"
                    :total="pageData.total"
            ></el-pagination>
        </div>
        <el-dialog :title="title" :visible.sync="dialogFormVisible" v-if="dialogFormVisible"
                   :close-on-click-modal="false">
            <varoant-dialog @closeDialog="closeWindow" :formItem="formItem" :isEdit="isEdit"/>
        </el-dialog>
        <goods ref="goods" :close-on-click-modal="false" @closed="loadlist"
               @close="closeWindow(true)"/>
        <goods-dialog ref="goodsDialog" v-if="goodsDialogVisible" :typeName="typeName" :show-close="true"
                      :close-on-click-modal="false"/>
        <!--  <el-dialog :title="title" :visible.sync="dialogFormTableVisible" :close-on-click-modal="false">
         <FormTableDialog :argFrom="argFrom">
                <template v-slot:tableSlot>
                    <el-table
                        ref="pagedata"
                        :data="tableData"
                        @selection-change="selectionChange"
                        border
                        class="tableGoods"
                        style="width: 100%"
                    >
                        <el-table-column type="selection" width="55" />
                        <el-table-column prop="id" label="ID" align="center" show-overflow-tooltip> </el-table-column>
                        <el-table-column prop="code" label="SKU/EAN/编码" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="imgs" label="图片" align="center" show-overflow-tooltip> </el-table-column>
                        <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="category" label="所属类目" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="country" label="销售国家" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="brand" label="品牌" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip></el-table-column>
                    </el-table>
                </template>
            </FormTableDialog>
        </el-dialog> -->
    </div>
</template>
<script>
import VaroantDialog from './components/VaroantDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import GoodsDialog from '@/components/common/GoodsDialog.vue';
import goods from './components/goods.vue';
import moment from 'moment';

export default {
    components: {VaroantDialog, FormTableDialog, dataSelect, moment, dataText, GoodsDialog, goods},
    name: 'recommend_module',
    data() {
        return {
            typeName: '首页推荐',
            //查询项
            searchData: {
                position: '',
                countryCode: [],
                brandCode: '',
                status: '',
                recommendModule: '',
                page: 1,
                limit: 10
            },
            //组件标签是否显示
            arg: {
                name: true,
                //brand: true,
                category: true
            },
            //组件标签是否显示
            argFrom: {
                code: true,
                put: true
            },
            isEdit: false,
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            dialogFormTableVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            goodsDialogVisible: false,
            listloading: false
        };
    },

    created() {
        this.getDictItems();
        this.loadlist();
    },
    methods: {
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist() {
            this.listloading = true;
            var _this = this;
            this.$store.dispatch('getRecommendPage', this.searchData).then(res => {
                _this.pageData = res;
            }).finally(() => {
                this.listloading = false;
            });
        },
        add() {
            this.isEdit = false;
            this.title = this.$i18n.t("common.Add1");
            this.formItem = {
                goodClassId: '',
                countryCode: '',
                status: true,
                position: 'HomePage',
                brandCode: this.$tenantBrand.getBrand(),
                recommendModule: 'NewProducts'
            };
            this.dialogFormVisible = true;
        },
        edit(row, form) {
            this.title =  this.$i18n.t("common.edit");
            this.isEdit = true;
            this.formItem = Object.assign({}, row);
            if (form != null) {
                this[form] = true;
            }
        },
        async chooseGood(row, form) {
            if (this.$refs.goods) {
                const x = await this.$refs.goods.open(row.homeModuleId, row.countryCode, row.brandCode);
                console.log(x);
            }
        },
        del(row) {
            var _this = this;
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                    .then(() => {
                        this.$store.dispatch('delRecommendModule', {id: row.homeModuleId}).then(res => {
                            let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
                            let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                            this.searchData.page = currentPage < 1 ? 1 : currentPage
                            this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
                            this.loadlist();
                        });
                    })
                    .catch(() => {
                    });
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        formInit() {
            var _this = this;
            if (this.$refs.formItem !== undefined) {
                this.$refs.formItem.initFormData(this.formItem);
                this.$refs.formItem.initDictData({countrys: _this.countrysDicts, platforms: _this.platformDicts});
            } else {
                setTimeout(() => {
                    this.$refs.formItem.initFormData(this.formItem);
                    this.$refs.formItem.initDictData({countrys: _this.countrysDicts, platforms: _this.platformDicts});
                }, 500);
            }
        },
        closeWindow(status) {
            this.dialogFormVisible = false;
            if (status) {
                this.loadlist();
            }
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', {tag: 'platform'}).then(res => {
                if (res != null) _this.platformDicts = res;
            });
            this.$store.dispatch('getDicItems', {tag: 'countrys'}).then(res => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            this.searchData.position = '';
            this.searchData.countryCode = [];
            this.searchData.brandCode = '';
            this.searchData.status = null;
            this.searchData.recommendModule = '';
            this.searchClick();
        },
        async addGoods(row) {
            this.goodsDialogVisible = true;
            this.$nextTick(async () => {
                if (this.$refs.goodsDialog) {
                    const goodsList = await this.$refs.goodsDialog.open(row.homeModuleId, true, null, row.countryCode, row.brandCode).catch(err => {
                        this.goodsDialogVisible = false;
                    });
                    if (goodsList && goodsList.length > 0) {
                        this.$store.dispatch('addRecommendGoods', {
                            homeModuleId: row.homeModuleId,
                            goodsIds: goodsList.map(x => x.goodsId)
                        }).then(res => {
                            this.loadlist();
                        });
                    }
                }
            });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.my-content {
    background: #fde2e2;
}
</style>
