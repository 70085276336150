<template>
  <div>
    <div class="filter-container" shadow="never">
      <el-form :inline="true">
      
        <el-form-item>
					<el-input v-model="searchData.advertisingSpaceName" :placeholder='$i18n.t("common.name")' style="width: 180px;" class="filter-item" clearable ></el-input>
        </el-form-item>

        <el-form-item>
					<el-select v-model="searchData.countryCode" clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.country")'>
						<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code"></el-option>      
					</el-select>
        </el-form-item>
        
        <el-form-item>
					<el-select v-model="searchData.advertisingSpaceStatus"   clearable class="filter-item" style="width: 180px; margin-left: 10px" :placeholder='$i18n.t("common.state")'>
						<el-option v-for="item in dictionary.advertisingSpaceStatus" :key="item.id" :label="item.name" :value="item.code"></el-option>      
					</el-select>
        </el-form-item>

        <el-form-item>
					<el-select v-model="searchData.status"   clearable class="filter-item" style="width: 180px; margin-left: 10px"   :placeholder='$i18n.t("common.Enableornot")' >
						<el-option v-for="item in dictionary.status" :key="item.id" :label="item.name" :value="item.value"></el-option>      
					</el-select>
        </el-form-item>
        
        
        <el-form-item>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleSearchList" style="margin-left: 10px">
              {{ $i18n.t("common.query") }}
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleResetSearch" style="margin-left: 10px" size="small">
              {{ $i18n.t("common.Reset") }}
            </el-button>
        </el-form-item>
      </el-form>
    </div>     
        
    <div style="margin: 20px 0px 20px 20px;">

			<el-button @click="add()" type="success" size="small">{{ $i18n.t("common.Add1") }}</el-button>

		</div>

    <el-table ref="pagedata" :data="pageData.list" border v-loading="loading"  style="width: 100%">

      <el-table-column prop="advertisingSpaceId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="advertisingSpaceName"  :label='$i18n.t("common.name")' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="countryCode"  :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
        <template slot-scope="scope">
            {{ $dict.getDictValue('countrys',  scope.row.countryCode)  }}
        </template>
      </el-table-column>
      <el-table-column prop="imgUrl"  :label='$i18n.t("common.picture")' align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <listImage v-if="scope.row.imgUrl" :imgUrl="scope.row.imgUrl"></listImage>
        </template>
      </el-table-column>

      <el-table-column prop="startTime" :label='$i18n.t("common.starttime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
			<el-table-column prop="endTime" :label='$i18n.t("common.Endtime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column> 


      <el-table-column prop="advertisingSpaceStatus" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
              <span v-if="row.advertisingSpaceStatus == 'NotStarted'" style="color: black">{{ $i18n.t("common.begininaminute")}}</span>
              <span v-if="row.advertisingSpaceStatus == 'UnderWay'" style="color: green">{{ $i18n.t("common.Inprogress") }}</span>
              <span v-if="row.advertisingSpaceStatus == 'Complete'" style="color: gray">{{ $i18n.t("common.Ended") }}</span>
          </template>       
      </el-table-column>

      <el-table-column prop="updateTime" :label=' $i18n.t("common.Updatetime")' align="center"   :formatter="formatDate" show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateName" :label=' $i18n.t("common.Operator")' align="center" show-overflow-tooltip></el-table-column>

      <el-table-column prop="status" :label='$i18n.t("common.Enableornot")'  align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" active-color="#13ce66" :active-value="1" :inactive-value="0" disabled></el-switch>
        </template>
      </el-table-column>
      
      <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
          <template slot-scope="scope">
              <el-button @click="edit(scope.row)" type="text" size="small">{{ $i18n.t("common.edit") }}</el-button>
              <el-button @click="del(scope.row)" type="text" size="small">{{ $i18n.t("common.delete") }}</el-button>
          </template>
      </el-table-column>


    </el-table>



    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"  
        :page-sizes="pageSizes"   @size-change="handleSizeChange"
        :page-size="searchData.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageData.total">
      </el-pagination>
    </div>




    <el-dialog :title='isEdit ? $i18n.t("common.edit")  : $i18n.t("common.Add1") ' :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false"   >
      <add-update-dialog :isEdit="isEdit" :dictionary="dictionary" :formdata="formdata" @closeDialog="closeDialog"/>
    </el-dialog>


    
  </div>
</template>
<script>

import AddUpdateDialog from './components/AddUpdateDialog.vue';
import i18n from '@/i18n/i18n.js';
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';
import listImage from '@/components/common/ListImage.vue';

const defaultDictionary={
    // 国家
    country: dictionaryUtil.getDict('countrys'),
    // 商品上架状态
    state:[{
      id:'1',
      name:i18n.t("common.Listed"),
      value:true
    },{
      id:'2',
      name:i18n.t("common.Notlisted"),
      value:false
    }],
    // 是否启用
    status: [
      {
        id: '1',
        name: i18n.t("common.yes"),
        value: 1
      },
      {
        id: '2',
        name: i18n.t("common.no"),
        value: 0
      }
    ]
};


export default {
  components: {  moment,listImage,AddUpdateDialog },
  name: 'advertising_space_module',
  data() {
    return {
      dictionary:Object.assign({}, defaultDictionary),
      //查询项
      searchData: {
        advertisingSpaceName: null,
        countryCode: null,
        advertisingSpaceStatus: null,
        status: null,
        page: 1,
        limit: 10
      },
      defaultSearchData: {
        advertisingSpaceName: null,
        countryCode: null,
        advertisingSpaceStatus: null,
        status: null,
        page: 1,
        limit: 10
      },
      loading: false,
      pageSizes: [10, 20, 30, 50],
      pageData: {}, 
      dialogFormVisible: false,
      isEdit: false,
      formdata: {
      },
      defaultFormdata: {
        advertisingSpaceName: null,
        countryCode: null,
        time: [],
        startTime: null,
        endTime: null,
        imgUrl: null,
        advertisingSpaceLinkList: [
          {linkUrl: null}
        ],
        status: 1,
        goodsClassifyIdList: [],
        goodsIdList: [],
      }
      
    };
  },
  created() {
    this.getDictItems();
  },
  mounted() {
    this.handleSearchList();
  },
  methods: {

    // 业务字典
    getDictItems() {
      this.$store.dispatch('getDicItems', { tag: 'advertising_space_status' }).then((res) => {    
          this.$set(this.dictionary,"advertisingSpaceStatus",res)
      });   
    },

    //搜索列表
    handleSearchList() {
      this.searchData.page = this.defaultSearchData.page
      this.loadlist();
    },
    //重置
    handleResetSearch() {
      this.searchData = Object.assign({}, this.defaultSearchData);
      this.loadlist();
    },
    // 点击页数查询
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.loadlist();
    },
    // 点击条数查询
		handleSizeChange(val) {
      this.searchData.limit = val;
      this.loadlist();
    },
    loadlist(){
      this.loading=true
      
      let temp = JSON.stringify(this.searchData, (key, value) => {
        if (value !== null && value !== '') {
          return value;
        }
      });
      let curSates = JSON.parse(temp);

      // status 前端 = 默认等于'' 所有需要转字符串  params[key] != '' 
      if(curSates.status===1 ){
        curSates.status = "1";
      }else if(curSates.status===0){
        curSates.status = "0";
      }		

      // console.log('loadlist',this.searchData,curSates)
      this.$store.dispatch('getAdvertisingSpacePage', curSates).then(res => {
        this.pageData = res  
      }).finally(() => {
				this.loading = false;
			});
    },
    // 广告位 新增
    add(){    
      this.isEdit = false;
      this.formdata = JSON.parse(JSON.stringify(this.defaultFormdata));
      // 默认选中第一个国家
      this.formdata.countryCode = this.dictionary.country[0].code;
      this.dialogFormVisible = true;
    },
    // 广告位 修改
    edit(row){
      this.isEdit = true;
      this.formdata = JSON.parse(JSON.stringify(row));   
      this.dialogFormVisible = true;
    },
    // 广告位 删除
    del(row){
      var _this = this;
      this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
          type: 'warning'
      }).then(() => {
          this.$store.dispatch('deleteAdvertisingSpace', {advertisingSpaceId: row.advertisingSpaceId}).then(res => { 
              let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
              let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
              this.searchData.page = currentPage < 1 ? 1 : currentPage
              this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
              this.loadlist();
          });
      }).catch(() => {
      });
    },
    // 广告位新增编辑 弹框关闭
    closeDialog(status,isEdit){
      this.dialogFormVisible = false; 
      if(status){
          if(isEdit){
              this.loadlist();
          } else {
              this.handleSearchList();
          }   
      }
    },
    /**
    * 时间戳转码
    */
    formatDate(row, column) {
        // 获取单元格数据
        let data = row[column.property];
        if (!data) {
            return '';
        } else {
            return moment(data).format('YYYY-MM-DD HH:mm:ss');
        }
    }
        
  }
 
};

</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}


</style>