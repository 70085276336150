var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          defaultListQuery: _vm.defaultListQuery,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "10px 0px 20px 20px" } },
        [
          _vm.$common.hasPermission("Create", _vm.module_code)
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.add }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Add")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listloading,
              expression: "listloading"
            }
          ],
          ref: "tableData",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            height: "calc(100% - 190px)",
            border: "",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            "row-key": "goodsClassifyId",
            "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "level",
              label: _vm.$i18n.t("common.Directoryhierarchy"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.Categoryname1"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sort",
              label: _vm.$i18n.t("common.sort"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imageUrl",
              label: _vm.$i18n.t("common.picture"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("listImage", {
                      attrs: { imgUrl: scope.row.backgroundImage }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949"
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateStatus(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm.scene.isAigoSmartApp
            ? _c("el-table-column", {
                attrs: {
                  prop: "appStatus",
                  label: _vm.$i18n.t("common.Isthehomepagedisplayed"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateHomeShow(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.appStatus,
                              callback: function($$v) {
                                _vm.$set(scope.row, "appStatus", $$v)
                              },
                              expression: "scope.row.appStatus"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1233947385
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateByName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.updateByName
                      ? _c("span", [_vm._v(_vm._s(row.updateByName))])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.SystemAutoUpdate")))
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right",
              width: "400"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$common.hasPermission("Update", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.openLan(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t("common.Multilingualconfiguration")
                          )
                        )
                      ]
                    ),
                    !_vm.scene.isNonSelf
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.openRelaMaj(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t("common.Relatedimportantparameters")
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.scene.isNonSelf
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.openRelaSearch(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t("common.Associatesearchparameters")
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.parameterGroupTitle == true
                ? _vm.$i18n.t("common.Relatedimportantparameters")
                : _vm.$i18n.t("common.Associatesearchparameters"),
            visible: _vm.dialogRelaMajVisible,
            "before-close": _vm.closeRelaMajDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRelaMajVisible = $event
            }
          }
        },
        [
          _vm.dialogRelaMajVisible
            ? _c(
                "div",
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      props: _vm.defaultProps,
                      data: _vm.options,
                      "node-key": "parameterItemId",
                      "default-checked-keys": _vm.checkedKeys,
                      "show-checkbox": ""
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          on: { click: _vm.closeRelaMajDialog }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmitLang }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit
              ? _vm.$i18n.t("common.edit")
              : _vm.$i18n.t("common.Add"),
            visible: _vm.dialogAddVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAddVisible = $event
            }
          }
        },
        [
          _vm.dialogAddVisible
            ? _c(
                "div",
                [
                  _c("add-dialog", {
                    attrs: {
                      isEdit: _vm.isEdit,
                      formListQuery: _vm.formListQuery,
                      dictionary: _vm.dictionary
                    },
                    on: {
                      closeDialog: _vm.closeDialog,
                      handleSearchList: _vm.handleSearchList
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Multilingualconfiguration"),
            visible: _vm.dialogLanVisible,
            "before-close": _vm.closeLangDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLanVisible = $event
            }
          }
        },
        [
          _vm.dialogLanVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    { ref: "titleFrom", attrs: { "label-width": "150px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Categoryname1") }
                        },
                        [_c("div", [_vm._v(_vm._s(_vm.langTitle))])]
                      ),
                      _vm._l(_vm.languageList, function(item) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.id,
                            attrs: { label: item.name, prop: "name" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                "show-word-limit": "",
                                maxlength: "200",
                                placeholder: _vm.$i18n.t(
                                  "common.Pleaseenteraname"
                                ),
                                autocomplete: "off"
                              },
                              on: { input: _vm.forceupdate },
                              model: {
                                value: item.value,
                                callback: function($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value"
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          on: { click: _vm.closeLangDialog }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.updateTranslate }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }