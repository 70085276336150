<template>
    <div>
        <el-form :model="formdata" :rules="rules" ref="formdata">

            <el-form-item  :label='$i18n.t("common.name")'  prop="advertisingSpaceName" :label-width="formLabelWidth"  >
                <el-input v-model="formdata.advertisingSpaceName" style="width: 600px;" :placeholder='$i18n.t("common.Pleaseenteraname")'  show-word-limit  maxlength="100"></el-input>
            </el-form-item>
 

            <el-form-item :label='$i18n.t("common.country")' prop="countryCode" :label-width="formLabelWidth"  >
                <el-select v-model="formdata.countryCode" clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.country")'>
						<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code">
						</el-option>      
					</el-select>
            </el-form-item>

            <el-form-item  :label='$i18n.t("common.time")' prop="time" :label-width="formLabelWidth"  >
                <el-date-picker v-model="formdata.time" clearable  type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-" :start-placeholder='$i18n.t("common.starttime")' :end-placeholder='$i18n.t("common.Endtime")'
                                :default-time="['00:00:00','23:59:59']">
                </el-date-picker>
            </el-form-item>
             
            <el-form-item :label='$i18n.t("common.picture")'  :label-width="formLabelWidth" :required="true">         
                <imgUpload ref="imageUpload" :img="formdata.imgUrl"  @event="changeUrl"></imgUpload>
            </el-form-item>


            <el-form-item :label='$i18n.t("common.InputLink")' :label-width="formLabelWidth" :required="true">
                <div v-for="(item, index) in formdata.advertisingSpaceLinkList" :key="index" style="display: flex; margin-bottom: 10px; ">
                    <el-input v-model="item.linkUrl"   style="width: 600px;" :placeholder='$i18n.t("common.Enterjumplink")' show-word-limit  maxlength="255"   ></el-input>
                    <span  v-if="formdata.advertisingSpaceLinkList.length<10" ><i style="font-size:22px;" class="el-icon-circle-plus-outline" @click="addLinks(item)" ></i></span> 
                    <span  v-if="formdata.advertisingSpaceLinkList.length>1" ><i style="font-size:20px;" class="el-icon-remove-outline"  @click="delLinks(item,index)"></i></span>
                </div>
            </el-form-item>


            <el-form-item :label='$i18n.t("common.Enableornot")'  prop="status" :label-width="formLabelWidth" >
                <el-switch v-model="formdata.status"  active-color="#13ce66" :active-value="1" :inactive-value="0" ></el-switch>
            </el-form-item>


            <el-form-item :label='$i18n.t("common.Applicableclassification")'  :label-width="formLabelWidth">
				<el-tree ref="tree" :props="classifyTreeProps" :data="classifyTreeDict" node-key="goodsClassifyId" :default-checked-keys="checkedKeys" show-checkbox>
				</el-tree>
			</el-form-item>


           
            <el-form-item :label='$i18n.t("common.Applicableproducts")'  :label-width="formLabelWidth">
				<el-button type="success" @click="changeGoods">{{ $i18n.t("common.Addproduct") }}</el-button>
			</el-form-item>

            
            <el-form-item  :label-width="formLabelWidth">
				<el-table ref="goodsSelectList" :data="goodsSelectList" border style="width: 100%">
					<el-table-column prop="goodsId"   :label='$i18n.t("common.ProductID1")' align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsCode"  :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span>SKU:{{ scope.row.sku }}</span><br />
							<span>EAN:{{ scope.row.barCode }}</span><br />
							<span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-image style="height: 100px" v-if="scope.row.imageUrl" :src="ossHost + scope.row.imageUrl"
								fit="fill"></el-image>
						</template>
					</el-table-column>
					<el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
 
                            <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                            </span>
						</template>
					</el-table-column>

 
					<el-table-column prop="prices" :label='$i18n.t("common.Promotionprice1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.NewUserPromotion") }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
							<span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $i18n.t("common.RegularUserPromotion") }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
							<span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand"  :label='$i18n.t("common.fixedprice")'  align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.resultPrice.toFixed(2) }}<br />
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
							<span v-else>{{ $i18n.t("common.Notlisted") }}</span>
						</template>
					</el-table-column>

                    <el-table-column :label='$i18n.t("common.operation")' fixed="right" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">  
                            <el-button type="primary" size="mini" @click="deleteGoods(scope.$index, scope.row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                        </template>
                    </el-table-column>
				</el-table>
			</el-form-item>  

        </el-form>   


        <div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" :loading="loading"  @click="onSubmit('formdata')"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>



        <el-dialog :visible.sync="goodsDialogVisible" v-if="goodsDialogVisible"  append-to-body  >
            <goods-dialog  ref="goodsDialog" :dictionary="dictionary" :goodsSelectList="goodsSelectList"  @handleSelected="handleSelectionGooods"  />
        </el-dialog>

    </div>
</template>
<script>
 
 
import goodsDialog from './GoodsDialog.vue';
import imgUpload from './imgUpload';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";


 

export default {
    components: {
        imgUpload,goodsDialog
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        formdata: {
            type: Object,
            default: () => {
                return {};
            }
        }    
    },
    data() {
        return {
            // 默认输入链接
            defaultAdvertisingSpaceLink:{
                linkUrl: null,
            },
            // 类目tree 业务字典
            classifyTreeDict: [],
            // 类目tree 默认勾选的节点的 key 的数组
            checkedKeys: [],
            formLabelWidth: '150px',
            ossHost: process.env.VUE_APP_OSS_Head,
            classifyTreeProps: {
				label: function (data, node) {
					return data.title
				},
				id: function (data, node) {
					return data.parameterGroupId
				},
			},
            loading: false,
 
            
            rules: {
                advertisingSpaceName: [ 
                    { required: true, message: this.$i18n.t("common.Pleaseenteraname"), trigger: 'blur' },              
                ],
                countryCode: [
                    { required: true, message:  this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }
                ],
                time: [
                    { 
                        type: 'array', required: true, message:  this.$i18n.t("common.Pleaseselectadaterange"), trigger: 'change',
                        fields: {
                            0: { type: 'string', required: true, message:  this.$i18n.t("common.Pleaseselectastartdate") },
                            1: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselecttheenddate") },
                        }
                    }
                ],
 
                status: [
                    { required: true, message: this.$i18n.t("common.Enableornot"), trigger: 'change' }     
                ]      
            },
            
            // 商品列表 
            goodsDialogVisible: false,
            // 选中商品列表 
            goodsSelectList: [],
 
            
        };
    },
    created() {
        this.getGoodsClassifyTree();
        this.initData();
    },
    mounted() {
       
        
    },
    methods: {
        // 类目
        getGoodsClassifyTree(){
            this.$store.dispatch('getGoodsClassifyTree', {}).then(res => {
                this.classifyTreeDict = res;		
            });
        },

        initData(){
 
            if(this.isEdit){
                this.formdata.time = [];
                let type='yyyy-MM-dd hh:mm:ss';
                let beginTime=this.$options.filters['formatDate'](this.formdata.startTime,type);
                let endTime=this.$options.filters['formatDate'](this.formdata.endTime,type);
                this.formdata.time.push(beginTime);
                this.formdata.time.push(endTime);

                if(this.formdata.goodsClassifyIdList && this.formdata.goodsClassifyIdList.length > 0){
                    this.checkedKeys = this.formdata.goodsClassifyIdList; 
                }

                if(this.formdata.goodsIdList && this.formdata.goodsIdList.length > 0){
                    this.searchGoodsList(this.formdata.goodsIdList);
                }
    
            } else {

            }
        },
 
        // 输入链接 添加
        addLinks(item){
            if(this.formdata.advertisingSpaceLinkList.length>=10){
                return;
            }
            let defaultLink = Object.assign({},this.defaultAdvertisingSpaceLink);

            this.formdata.advertisingSpaceLinkList.push(defaultLink)

            if(this.formdata.advertisingSpaceLinkList.length>10){
                this.formdata.advertisingSpaceLinkList = this.formdata.advertisingSpaceLinkList.slice(0, 10);
            }
        },
        // 输入链接 删除
        delLinks(item,index){      
            this.formdata.advertisingSpaceLinkList.splice(index, 1);    
        },

        // 设置 图片地址
        changeUrl(data) {
            this.formdata.imgUrl = data
 
        },

        // 关闭当前页面
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        }, 
       
       
    
        //提交
        onSubmit(formName) {
            
            this.$refs[formName].validate(valid => {
                if (valid) {

                    this.formdata.startTime = new Date(this.formdata.time[0]).getTime(); 
                    this.formdata.endTime = new Date(this.formdata.time[1]).getTime(); 
                                        
                    let checkedKeyList = this.$refs.tree.getCheckedKeys();
                    this.formdata.goodsClassifyIdList = checkedKeyList;
                    this.formdata.goodsIdList =[];
                    this.goodsSelectList.forEach(x=>{
                        this.formdata.goodsIdList.push(x.goodsId);
                    });
 
                    console.log('onSubmit',this.formdata)
                   
                    let imgUrl = this.formdata.imgUrl;

                    if(imgUrl === undefined || imgUrl == null || imgUrl == ''  ){
                        this.$message({
                            message: this. $i18n.t("common.Pleaseuploadpictures") ,
                            type: 'warning',
                            duration: 1000
                        });
                        return;
                    }

                    // 输入链接 必填
                    for(var i=0; i<this.formdata.advertisingSpaceLinkList.length; i++){
                        let item = this.formdata.advertisingSpaceLinkList[i];
                        let linkUrl = item.linkUrl;
                        if(linkUrl === undefined || linkUrl == null || linkUrl == ''  ){
                            // 输入链接地址：如果有一个地址未填写提示“”请输入链接地址“
                            this.$message({
                                message: this. $i18n.t("common.EnterAddress") ,
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                        let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                        if (!reg.test(linkUrl)) {
                            this.$message({
                                message: this.$i18n.t("common.PleaseEnterTheCorrectLink"),
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }

                    }
                    
                    this.formdata.advertisingSpaceLinkList.forEach((item,index)=>{
                        item.sort = index+1;
                    });
                 

                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),  this.$i18n.t("common.prompt"), {
                        type: 'warning'
                    }).then(() => {   
                        this.loading=true
                        if (this.isEdit) {
                            this.$store.dispatch('putAdvertisingSpace', this.formdata).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullymodified"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addAdvertisingSpace', this.formdata).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true);
                            }).finally(() => {
                                this.loading = false;
                            });
                        }
                    }).catch(() => {
                    });
                    
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },

        // 选择商品
        changeGoods() {
			this.goodsDialogVisible = true;
		},
        // 商品子组件点击确定回调
        handleSelectionGooods(val) {
 
			this.goodsSelectList = val;
 
            this.goodsDialogVisible = false; 
		},
        // 商品删除
        deleteGoods(index, row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                this.goodsSelectList.splice(index, 1);
                this.$message({
                    message:   this.$i18n.t("common.Successfullydeleted"),
                    type: 'success'
                });
            }).catch(() => {});
        },
        // 回显商品列表
        searchGoodsList(goodsIdList){
			this.$store.dispatch('postPromotionId', goodsIdList).then(res => {
                this.goodsSelectList = res
            });
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped> 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>
