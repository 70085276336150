<template> 
	<div>
		<el-form :model="formListQuery" :rules="rules" ref="variantFrom">
			<el-form-item :label="$i18n.t('common.name')" prop="title" :label-width="formLabelWidth">
				<el-input show-word-limit  maxlength="140" v-model="formListQuery.title" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item class="must" :label="$i18n.t('common.Selectvariant')" :label-width="formLabelWidth">
				<el-button type="success" @click="handleChange('para')">{{ $i18n.t('common.Selectparameters') }}</el-button>
			</el-form-item>
			<el-form-item :label-width="formLabelWidth">
				<div v-if="checkedKeys.length>0">
					<el-table ref="pagedata" :data="checkedKeys" border style="width: 100%">
						<el-table-column :label="$i18n.t('common.Serialnumber')" type="index" width="50" align="center"></el-table-column>
						<el-table-column prop="title" :label="$i18n.t('common.name')" align="center"></el-table-column>
						<!-- <el-table-column prop="countryCode" label="名称" show-overflow-tooltip>
							<template slot-scope="scope">
								{{ $dict.getDictValue('countrys', scope.row.countryCode) }}
							</template>
						</el-table-column> -->
						<el-table-column align="center" :label="$i18n.t('common.sort')" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-button type="text" @click="upMove(scope.$index,scope.row)" v-if="scope.$index!==0">{{ $i18n.t('common.MoveUp') }}
								</el-button>
								<el-button type="text" @click="upDown(scope.$index,scope.row)"
									v-if="scope.$index!==checkedKeys.length-1">{{ $i18n.t('common.MoveDown') }}</el-button>
							</template>
						</el-table-column>
						<el-table-column :label="$i18n.t('common.operation')" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="delCheckedKeys(scope.row)" type="text" size="small">{{ $i18n.t('common.delete') }}</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>			
			</el-form-item>
			<el-form-item class="must" :label="$i18n.t('common.commodity')" :label-width="formLabelWidth">
				<el-button type="success" @click="handleChange('goods')">{{ $i18n.t('common.Selectproduct') }}</el-button>
			</el-form-item>
			<el-form-item :label-width="formLabelWidth">
				<div v-if="pageDataSelectList.length>0">
					<el-table  ref="pageDataSelect" :data="pageDataSelectList" border style="width: 100%">
						<el-table-column :label="$i18n.t('common.Serialnumber')" type="index" width="50" align="center"></el-table-column>
						<el-table-column prop="goodsId" :label="$i18n.t('common.ProductID1')" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="barCode" label="EAN" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column v-if="flag" v-for="(item, index) in checkedKeys" :key="item.variantParameterId" :label="item.title" :prop="item.title" align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column :label="$i18n.t('common.operation')" width="150" fixed="right" align="center">
							<template slot-scope="scope">
								<el-button @click="delPageData(scope.row)" type="text" size="small">{{ $i18n.t('common.delete') }}</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>				
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog">{{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')">{{ $i18n.t("common.confirm") }}</el-button>
		</div>
		<el-dialog :title="$i18n.t('common.Selectvariantparameters')" :visible.sync="paraVisible" width="35%" append-to-body>
			<div class="flex-center" v-if="paraVisible">
				<el-tree ref="tree" @check="handleTreeChange" :props="defaultProps" :data="classifyTree" node-key="variantParameterId" :default-checked-keys="checkedKeys2" :default-expanded-keys="expandedClassifyKeys"  show-checkbox>
				</el-tree>
				<!-- <el-tree :props="defaultProps" :data="treeData" show-checkbox @check-change="handleCheckChange">
				</el-tree> -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 20%;" @click="handleCloseVar()">{{ $i18n.t("common.cancel") }}</el-button>
				<el-button style="width: 20%;" type="primary" @click="handleChangeVar()">{{ $i18n.t("common.confirm") }}</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="$i18n.t('common.Selectproduct')" :visible.sync="goodsVisible" append-to-body>
			<div v-if="goodsVisible">
			<FormTableDialog :argFrom='argFrom' :dictionary="dictionary" :pageDataSelectList="pageDataSelectChangeList" :placeholderFrom="placeholderFrom" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch' @handleSelected='handleSelected'>
				<template v-slot:tableSlot>
				   <el-table default-expand-all ref="tableData" :data="pageData2.list" :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}" style="width: 100%" border
				   	@selection-change="handleSelectionChange" id="goodsTable" @select="selectOfSelectable" @select-all="selectAllOfSelectable" :row-key="(row) => { return row.goodsId }"  :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
				   	<el-table-column type="selection" :reserve-selection="true" align="center" width="55" :selectable="row => row.checkboxStatus"/>
					<!-- 用于显示子表格张开按钮 -->
					<el-table-column prop="quantityType" label="" width="50px" align="center">
					</el-table-column>
				   	<el-table-column prop="goodsId" :label="$i18n.t('common.ProductID')" align="center" show-overflow-tooltip></el-table-column>
				   	<el-table-column prop="goodsCode" :label="`SKU/EAN/`+$i18n.t('common.coding')" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span>SKU:{{scope.row.sku}}</span><br/>
				   			<span>EAN:{{scope.row.barCode}}</span><br/>
							<span>{{ $i18n.t('common.coding') }}:{{scope.row.goodsCode}}</span>
				   		</template>				
					</el-table-column>
				   	<el-table-column prop="imageUrl" :label="$i18n.t('common.Productimage1')" align="center" show-overflow-tooltip>
				   		<template slot-scope="scope">
				   			<el-image style="height: 100px" v-if="scope.row.imageUrl"
				   				:src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
				   		</template>
				   	</el-table-column>
                   <el-table-column prop="packageQuantity" :label="$i18n.t('common.PackageQuantity')" align="center" show-overflow-tooltip></el-table-column>
				   	<el-table-column prop="treeList" :label="$i18n.t('common.Category')"  align="center" show-overflow-tooltip>
				   		<template slot-scope="scope" style="width: 200px !important;word-wrap: break-word;word-break: break-all;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">
				   			<div v-for="(item,index) in scope.row.treeList" :key="index">{{item.title}}<br/></div>
				   		</template>
				   	</el-table-column>
				   	<el-table-column prop="title" :label="$i18n.t('common.ProductName')" align="center" show-overflow-tooltip></el-table-column>
				   	<el-table-column prop="goodsSpecials" :label="$i18n.t('common.Countrieslisted')" align="center" show-overflow-tooltip>
				   		<template slot-scope="scope">
				   			<span v-for="(s,index) in scope.row.goodsSpecials" :key="index">
				   				<span v-if="s.shelf==true">{{ $dict.getDictValue('countrys', s.countryCode) }}<br/></span>
				   			</span>
				   		</template>
				   	</el-table-column>
				   	<el-table-column prop="shelf" :label="$i18n.t('common.state')" align="center" show-overflow-tooltip>
				   	    <template slot-scope="scope">
				   	        <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t('common.Listed') }}</span>
				   	        <span v-else>{{ $i18n.t('common.Notlisted') }}</span>
				   	    </template>
				   	</el-table-column>
				   </el-table>
				   <div class="block">
				   	<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
				   		:page-size="size" layout="total, prev, pager, next, jumper" :total="pageData2.total">
				   	</el-pagination>
				   </div>
				</template> 
			</FormTableDialog>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
	import FormTableDialog from '@/components/common/FormTableDialog.vue';
	export default {
		components: {
			FormTableDialog
		},
		props: {
			isEdit:{
				type: Boolean,
				default: false
			},
			formListQuery: {
				type: Object,
				default: () => {
					return {}
				}
			},
			dictionary: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				goodsList:[],
				ossHost: process.env.VUE_APP_OSS_Head,
				defaultProps:{
					label:function(data, node) {
						let title=''
						if(data.classifyName){
							title=data.classifyName
						}else{
							title=data.title
						}
					  return title
					},
					id:'variantParameterId',
					children:'variantParameters',
					/*disabled:function(data,node){
					if(data.parentId==0){
							return true
					    }else{
					        return false            
					    }        
					}*/
				},
				is5:false,
				flag:false,
				fatherNode:"",
				formLabelWidth: '120px',
				form: {},
				pageDataSelected:[],
				pageDataSelectList:[],
				pageDataSelectChangeList:[],
				checkedKeys2:[],
        expandedClassifyKeys:[],
				classifyTree:[],
				checkedKeys:[],
				goodsParameterList:[],
				pageRelaSelectList:[],
				//组件标签是否显示
				arg: {
					code: true,
					country: true,
					status: true
				},
				//组件标签是否显示
				argFrom: {
					code: true,
					country:true,
					state:true
				},
				placeholderFrom:{
					state:this.$i18n.t('common.state'),
					country:this.$i18n.t('common.Countrieslisted')
				},
				variantId:"",
				paraVisible: false,
				//商品
				goodsVisible: false,
				//选中数量
				selectedMount:0,
				rules: {
					title: [{
							required: true,
							message: this.$i18n.t('common.Pleaseenteraname'),
							trigger: 'blur'
						},
						{
							min: 1,
							max: 140,
							message: this.$i18n.t('common.LengthBetween1And140Characters'),
							trigger: 'blur'
						}
					],
					/*brandCode: [{
						required: true,
						message: '请选择品牌',
						trigger: 'change'
					}],*/
				},
				listQuery:{
					"shelfCountryCodes": [],
					"classifyId": '',
					"idFuzzy": "",
					"brandCode": "",
					"updateUserId":"",//操作人
					"inPromotion":"", //是否促销
					"isOnShelf":"",//是否上架
					"beginCreateTime": "",
					"endCreateTime": '',
					"beginUpdateTime": "",
					"endUpdateTime": '',
					"page": 1,
					"size": 10
				},
				defaultListQuery:{
					"shelfCountryCodes": [],
					"classifyId": '',
					"idFuzzy": "",
					"brandCode": "",
					"updateUserId":"",//操作人
					"inPromotion":"", //是否促销
					"isOnShelf":"",//是否上架
					"beginCreateTime": "",
					"endCreateTime": '',
					"beginUpdateTime": "",
					"endUpdateTime": '',
					"page": 1,
					"size": 10
				},
				size: 10,	
				pageData: {},
				pageData2:{},
				goodsData: {
					list: [],
				}
			};
		},
		methods: {
			selectAllOfSelectable(selection) {
				selectAllOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
			},
			selectOfSelectable(selection) {
				selectOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
			},
			initData(){
				if(this.isEdit==true){
					this.$nextTick(()=>{
						if(this.formListQuery.goodsVariantId){
							this.getGoodsVarClassifyItem(this.formListQuery.goodsVariantId)
							
						}						
					})					
				}
			},
			
			//选择品牌
			handleBrandCode(val){
				if(val){
					this.checkedKeys=[]
					this.pageDataSelectList=[]
					this.paraVisible=false
					this.goodsVisible=false
				}			
			},
			//获取商品树类目
			getGoodsVarClassifyItem(id){
				this.$store
					.dispatch('getGoodsVarClassifyItem', {goodsVariantId:id})
					.then(res => {
						res.forEach((item,index)=>{
							res[index].title=item.parameterName
						})
						this.checkedKeys=res
						this.getGoodsItem(id)
						this.getGoodsVariantList(this.formListQuery.goodsVariantId)
					});
			},	
			getGoodsItem(id){
				this.$store
					.dispatch('getGoodsItem',{goodsVariantId:id})
					.then(res => {
						this.pageRelaSelectList=res
						this.checkedKeys.forEach((item)=>{
							res.forEach((item2)=>{
								if(item.goodsVariantParameterId==item2.goodsVariantParameterId){
									item.parameterValue=item2.parameterValue									
								}				  							  
							})					  				  						  
						})
					});	
			},
			handleSearchList2(){
				this.$emit('handleSearchList')
			},
			closeDialog(){
				this.pageData.list=[]
				this.pageData2.list=[]
				this.$emit('closeDialog')
			},
			handleSearchList(listQuery,type){
        if(type!=1){
          this.listQuery.page=1
        }
        let parameterItemIds=[]
        this.checkedKeys.forEach((item,index)=>{
          if(item.parameterItemId){
            parameterItemIds.push(item.parameterItemId)
          }
        })

				if(listQuery){
					let isOnShelf=listQuery.state
					let country=[]
					if(listQuery.country && listQuery.country.length>0){
						country=listQuery.country
					}
					// this.variantId
					this.listQuery={
						"shelfCountryCodes": country,
						"classifyId": '',
						"goodsVariantId":this.formListQuery.goodsVariantId?this.formListQuery.goodsVariantId:'',
						"idFuzzy": listQuery.code,
						"brandCode": "",
						"updateUserId":"",//操作人
						"inPromotion":"", //是否促销
						"goodsShelf":isOnShelf,//是否上架
						"variantId":this.checkedKeys[0].variantId,
						"beginCreateTime": "",
						"endCreateTime": '',
						"beginUpdateTime": "",
						"endUpdateTime": '',
						"parameterItemIds": parameterItemIds,
						"page": this.listQuery.page,
						"limit": this.size,
					}
				}else{
					this.listQuery={
						"shelfCountryCodes":[],
						"classifyId": '',
						"goodsVariantId":this.formListQuery.goodsVariantId?this.formListQuery.goodsVariantId:'',
						"idFuzzy": "",
						"brandCode": "",
						"updateUserId":"",//操作人
						"inPromotion":"", //是否促销
						"goodsShelf":'',//是否上架
						"variantId":this.checkedKeys[0].variantId,
						"beginCreateTime": "",
						"endCreateTime": '',
						"beginUpdateTime": "",
						"endUpdateTime": '',
            "parameterItemIds": parameterItemIds,
						"page": this.listQuery.page,
						"limit": this.size,
					}
				}
				this.$store
					.dispatch('getTinyVariant', this.listQuery)
					.then(res => {					
						this.pageData2=res
						showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
					});
			},
			handleResetSearch(){
				this.listQuery = Object.assign({}, this.defaultListQuery);
				this.handleSearchList()
			},	
			handleCurrentChange(val) {
				this.listQuery.page = val;
				let data = {
					state:this.listQuery.goodsShelf,
          country:this.listQuery.shelfCountryCodes,
          code:this.listQuery.idFuzzy,
				}
				this.handleSearchList(data,1);
			},
			//上移
			upMove(index, row) {
				if (index <= 0) {
					this.$message.error(this.$i18n.t("common.CannotContinueToMoveUp"))
				} else {
					const upData = this.checkedKeys[index - 1]
					this.checkedKeys.splice(index - 1, 1)
					this.checkedKeys.splice(index, 0, upData)
				}
			},
			//下移
			upDown(index, scope) {
				if (index === (this.checkedKeys.length - 1)) {
					this.$message.error(this.$i18n.t("common.CannotContinueToMoveDown"))
				} else {
					const downData = this.checkedKeys[index + 1]
					this.checkedKeys.splice(index + 1, 1)
					this.checkedKeys.splice(index, 0, downData)
				}
			},
			//选择
			handleChange(type) {
				switch (type) {
					case 'para':
						// this.pageDataSelectList=[]
						//if(this.formListQuery.brandCode){
              this.expandedClassifyKeys=[]
							this.getGoodsVarClassifyTree()						
							this.paraVisible = true
							if(this.checkedKeys2.length<=0 && this.$refs.tree){
								if(this.$refs.tree.getCheckedKeys()){
									this.checkedKeys2=this.$refs.tree.getCheckedKeys()
								}
							}					
							// let arr=this.$refs.tree.getCheckedNodes()						
							// this.$refs.tree.setCheckedNodes(arr);
						/*}else{
							this.$message({
								message: '请先选品牌',
								type: 'error',
								duration: 1000
							});
						}*/
						break;
					case 'goods':
            let variantId = (this.checkedKeys && this.checkedKeys.length>0)?this.checkedKeys[0].variantId:''
						if(this.checkedKeys.length<=0 || !variantId){
							this.$message({
								message: this.$i18n.t("common.PleaseSelectTheVariantParametersFirst"),
								type: 'error',
								duration: 1000
							});
							return
						}
						//解决调用分页数据慢，而数据已经渲染问题
						setTimeout(()=>{
							this.pageDataSelectList.forEach((item,index)=>{
								this.$nextTick(()=>{
									this.$refs.tableData.toggleRowSelection(item,true)
									this.$forceUpdate()
								})
							})
              this.pageDataSelectChangeList = this.pageDataSelectList
						},100)
						this.handleSearchList()
						this.goodsVisible = true
						break;
				}
			},
			//删除选中的变体
			delCheckedKeys(row){
				this.$confirm(this.$i18n.t("common.TheParameterIsAssociatedWithTheVariantPleaseBeCareful"), this.$i18n.t("common.Prompt"), {
				    type: 'warning'
				})
				    .then(() => {
				        this.checkedKeys.forEach((item,index)=>{
				        	if(row.variantParameterId==item.variantParameterId){
				        		this.checkedKeys.splice(index, 1)
				        	}
				        })
				        this.pageDataSelectList=[]
				        this.goodsList=[]
                this.goodsParameterList=[]
				        this.$refs.tree.setCheckedNodes(this.checkedKeys)	
				    })
				    .catch(() => {});	
				
			},
			//删除选中的商品
			delPageData(row){
				this.pageDataSelectList.forEach((item,index)=>{
					if(row.goodsId==item.goodsId){
						this.pageDataSelected.push(item)
						this.pageDataSelectList.splice(index, 1)
					}
				})
				this.postGoodsParameter()
			},
			//获取变体
			getGoodsVarClassifyTree(){
				this.$store
					.dispatch('getGoodsVarClassifyTree', {
						//brandCode:this.formListQuery.brandCode
					})
					.then(res => {
						res.forEach((item,index)=>{
							if(item.variantParameters){
								res[index].variantParameterId=index
                if(this.checkedKeys && this.checkedKeys.length>0){
                  if(item.variantId == this.checkedKeys[0].variantId){
                    this.expandedClassifyKeys[0] = index
                  }
                }
							}
						})
						this.classifyTree=res
            this.showSelectedParameterTree()
					});
			},
			handleTreeChange(data, checked) {
				this.is5=false
				let arra=[]
				this.classifyTree.forEach((item,index)=>{
					arra.push(item.variantParameterId)
				})
				let arr=this.$refs.tree.getCheckedKeys()				
				let temp = [];
				arr.forEach(res=>{
					let index = arra.indexOf(res);
					if(index==-1){
					  temp.push(res);
					}
				});	
				// arr.forEach((item,index)=>{
				// 	this.classifyTree.forEach((item2,index2)=>{
				// 		if(item==item.variantParameterId){
				// 			arr.splice(index,1)
				// 		}
				// 	})
				// })
				// console.log('arr',arr,this.classifyTree)
				const variantId = checked.checkedNodes.map(value => value.variantId);
				const variantIdSet = Array.from(new Set(variantId));
				if (variantIdSet.length>1) {
					this.$message({
						message: this.$i18n.t("common.PleaseSelectDataOfTheSameCategoryPleaseReselect"),
						type: 'error',
					});					
					this.$nextTick(function () {
						this.$refs.tree.setCheckedKeys([]);
						this.fatherNode = '';
						this.$forceUpdate()
            this.setTreeOtherNodeCheckable()
					});
					return			
				}														
				// let fatherId=''
				// if(checked.halfCheckedNodes.length>0){
				// 	fatherId=checked.halfCheckedNodes[0].variantParameterId;
				// }else if(checked.checkedKeys.length>0){
				// 	fatherId=checked.checkedKeys[0];
				// }
				
				// if(!this.fatherNode){
				// 	this.fatherNode = fatherId;							
				// }
				// if(checked.halfCheckedKeys.length>=2){
				// 	if(checked.halfCheckedKeys[0]!==checked.halfCheckedKeys[1]){
				// 		this.$message({
				// 			message: '请选择同一类目的数据,请重选',
				// 			type: 'error',
				// 		});					
				// 		this.$nextTick(function () {
				// 			this.$refs.tree.setCheckedKeys([]);
				// 			this.fatherNode = '';
				// 		});
				// 		return
				// 	}
				// }
				// if(this.fatherNode != fatherId){
				// 	this.$message({
				// 		message: '请选择同一类目的数据,请重选',
				// 		type: 'error',
				// 	});					
				// 	this.$nextTick(()=>{
    //     				this.$refs.tree.setCheckedKeys([]);
				// 		this.fatherNode = '';
    //   				});
				// 	return							
				// }
				if(temp.length>6){
					this.$message({
						message: this.$i18n.t("common.UpTo5VariantsCanBeSelected"),
						type: 'error',
						duration: 1000
					});
					this.$nextTick(()=>{
						this.$refs.tree.setCheckedKeys([]);
						this.fatherNode = '';
						this.$forceUpdate()
            this.setTreeOtherNodeCheckable()
					});
					return	
				}
				if(temp.length==6){			
					this.$message({
						message: this.$i18n.t("common.UpTo5VariantsCanBeSelected"),
						type: 'error',
						duration: 1000
					});
					let array=[]
					let checkedNodes=JSON.parse(JSON.stringify(checked.checkedNodes)) 
					checkedNodes.forEach((item,index)=>{
						if(checkedNodes[index].variantParameters && checkedNodes[index].variantParameters.length>0){
							checkedNodes.splice(index,1)
						}
					})
					checkedNodes.forEach((item,index)=>{
						array.push(item.variantParameterId)
					})
					array.forEach((item,index)=>{
						if(item==data.variantParameterId){
							array.splice(index,1)
						}
					})
          if(array.length>5){
            this.$nextTick(()=>{
              this.$refs.tree.setCheckedKeys([]);
              this.fatherNode = '';
              this.$forceUpdate()
              this.setTreeOtherNodeCheckable()
            });
            return
          }
					this.is5=true				
					// this.checkedKeys=checkedNodes.slice(0,5)
					console.log('checkedNodes',this.checkedKeys)
					this.$nextTick(()=>{
						this.$refs.tree.setCheckedKeys(array);
						this.fatherNode = '';
						this.$forceUpdate()
            this.setTreeOtherNodeCheckable()
					});
          return
				}
        this.setTreeOtherNodeCheckable()
			},
			handleCloseVar(){
				this.$nextTick(()=>{
        			this.$refs.tree.setCheckedKeys([]);
      			});					
				this.fatherNode = ''
				this.paraVisible = false
			},
      //回显已选择的参数
      showSelectedParameterTree(){
        if(this.checkedKeys){
          //提取数组中variantParameterId值
          const variantParameterIds = this.checkedKeys.map(value =>value.variantParameterId);
          const variantParameterIdSet = Array.from(new Set(variantParameterIds));//去重
          this.checkedKeys2 = variantParameterIdSet
        }else{
          this.checkedKeys2 = []
        }
        //设置其他的一级类目及其子类目置灰不可勾选
        this.setTreeOtherNodeCheckable(this.checkedKeys)
      },
      //设置树其他节点选择状态
      setTreeOtherNodeCheckable(checkedNodes){
        if(!checkedNodes){
          checkedNodes = this.$refs.tree.getCheckedNodes()
        }

        let selectedVariantId = '';//已选的变体id
        if(checkedNodes && checkedNodes.length>0) {
          selectedVariantId = checkedNodes[0].variantId
        }
        this.classifyTree.forEach((item,index)=>{
          let disabled = false;
          if(!selectedVariantId){//未选择任何参数时,都可选
            disabled = false;
          }else{
            //有选择参数，其他类目的都不可选
            if(item.variantId != selectedVariantId){
              disabled = true;
            }
          }

          //旧代码在defaultProps中有此规则，先补上
          if(item.parentId==0){
            return true
          }

          this.$set(item,"disabled",disabled)
          if(item.variantParameters && item.variantParameters.length>0){
            item.variantParameters.forEach((parameter)=>{
              this.$set(parameter,"disabled",disabled)
            })
          }
        })

        console.log('this.classifyTree',this.classifyTree)
        this.$forceUpdate()

      },
			//确认选中变体
			handleChangeVar(){				
				let arr=this.$refs.tree.getCheckedNodes()
				if(arr.length<=0){
					this.$message({
						message: this.$i18n.t('common.Pleaseselectparameters'),
						type: 'error',
					});
					return
				}
				arr.forEach((item,index)=>{
					if(item.variantParameters && item.variantParameters.length>0){
						arr.splice(index,1)
					}
				})
        let oldCheckedKeys = this.checkedKeys
        let oldVariantId = (this.checkedKeys && this.checkedKeys.length>0)?this.checkedKeys[0].variantId:''
				if(this.is5){
					this.checkedKeys=arr.slice(0,5)
				}else{
					this.checkedKeys=arr
				}		
				this.paraVisible = false
				this.variantId=arr[0].variantId
				/*setTimeout(()=>{
					if(this.isEdit==true){
						this.postGoodsParameter()
					}
					this.handleSearchList()
				})*/
        //
        let isClearSelectedGoods = false
        //类目选择变更了要清空选择的商品
        if(oldVariantId || oldCheckedKeys.length>0){
          if(this.variantId!=oldVariantId){
            isClearSelectedGoods = true
          }else{
            //类目没有变更，原来选择的参数变少了，要清空
            let parameterChangeType = 0 //已选过的参数重新选择变更类型(-1：变少，0:未变更，1：变多)
            if(this.checkedKeys.length==oldCheckedKeys.length){
              let checkedKeyMap = this.checkedKeys.reduce((acc, obj) => {
                acc.set(obj.variantParameterId, obj);
                return acc;
              }, new Map());
              for (const key in oldCheckedKeys) {
                const value = checkedKeyMap.get(oldCheckedKeys[key].variantParameterId);
                if(!value){
                  parameterChangeType = -1;
                  break
                }
              }

              if(parameterChangeType==-1){
                isClearSelectedGoods = true
              }
            }else{
              isClearSelectedGoods = true
            }

          }


          //清空已选择的商品
          if(isClearSelectedGoods==true){
            this.pageDataSelectList=[]
            this.goodsList=[]
            this.goodsParameterList=[]
          }
        }

			},
			//获取选中的
			handleSelectionChange(selection) {
				this.selectedMount=selection.length
				selection.forEach((item,index)=>{
					selection[index].sort=0
					selection[index].goodsId
				})
				this.pageDataSelectChangeList=selection
			},
			
			append(data) {
			    const newChild = { id: id++, label: 'testtest', children: [] };
			    if (!data.children) {
			      this.$set(data, 'children', []);
			    }
			    data.children.push(newChild);    
			},
			remove(node, data) {
			  const parent = node.parent;
			  const children = parent.data.children || parent.data;
			  const index = children.findIndex(d => d.id === data.id);
			  children.splice(index, 1);
			},
			renderContent(h, { node, data, store }) {
			  return (
			    <span class="custom-tree-node">
			      <span>{node.label}</span>
			      <span style="margin-left: 100px;">
			        <el-button size="mini" type="text" icon="el-icon-plus" on-click={ () => this.append(data) }></el-button>
			        <el-button size="mini" type="text" icon="el-icon-minus" on-click={ () => this.remove(node, data) }></el-button>
			      </span>
			    </span>
				);
			},
			getGoodsVariantList(goodsVariantId){
				this.$store.dispatch('getGoodsVariantList', { goodsVariantId }).then((res) => {				       
				       this.pageDataSelectList=res
					   setTimeout(()=>{
						   this.postGoodsParameter()					   
					   })					   
				});
			},
			postGoodsParameter(){
        this.flag=false
				const data={
					parameterItemIds:[],
					goodsIds:[]
				}
				this.checkedKeys.forEach((item,index)=>{
					data.parameterItemIds.push(item.parameterItemId)
				})
				this.pageDataSelectList.forEach((item,index)=>{
					data.goodsIds.push(item.goodsId)
				})
				this.$store
				  .dispatch("postGoodsParameter",data).then(res => {
					  //pageDataSelectList商品列表
					  //checkedKeys参数
					  //res参数值
					if(res && res.length>0){
						res.forEach((item)=>{
						  let parameterItem=this.checkedKeys.find((item2)=>{
							  return item2.parameterItemId==item.parameterId
						  })
						  item.variantParameterId=parameterItem.variantParameterId
						  let good=this.pageDataSelectList.find((item2)=>{
							  return item2.goodsId==item.goodsId
						  })
						  if(good){
							  good[parameterItem.title]=item.parameterValue
						  }
					  	})
						this.goodsList=res
						this.flag=true
						this.goodsParameterList=res
					}else{
            this.goodsList=[]
            this.goodsParameterList=[]
          }
				});	
			},
			//选中商品
			handleSelected(){
        if(this.$refs.tableData){
          this.pageDataSelectList = this.$refs.tableData.selection
        }
				this.goodsVisible=false
				this.postGoodsParameter()
			},
			//提交
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
							confirmButtonText: this.$i18n.t("common.confirm"),
                    		cancelButtonText: this.$i18n.t("common.cancel"),
							type: 'warning'
						}).then(() => {							
							if (this.isEdit) {
                if(this.checkedKeys.length<=0){
									this.$message({
										message: this.$i18n.t("common.NoVariant"),
										type: 'error',
										duration: 1000
									});
                  return
								}
								
								let goodArr=[]
								let parameterArr=[]
								goodArr=this.goodsParameterList.map(item=>{
									return {
										goodsVariantRelationId:item.goodsVariantRelationId,
										goodsId:item.goodsId,
										goodsParameterId:item.goodsParameterId,
										variantParameterId:item.variantParameterId,
										sort:item.sort?item.sort:0
									}
								})
								
								parameterArr=this.checkedKeys.map(item=>{
									return {
										goodsVariantParameterId:"0",
										variantParameterId:item.variantParameterId,
										goodsParameterId:item.goodsParameterId,
										sort:item.sort?item.sort:0
									}
								})
								parameterArr.forEach((item,index)=>{
									parameterArr[index].sort=index
								})
								this.formListQuery.parameterList=parameterArr
								this.formListQuery.goodsList=goodArr
								if(this.formListQuery.parameterList.length<=0){
									this.$message({
										message: this.$i18n.t("common.VerificationFailedPleaseSelectTheVariant"),
										type: 'error',
										duration: 1000
									});
									return
								}
                if(this.formListQuery.goodsList.length<=0){
									this.$message({
										message: this.$i18n.t("common.VerificationFailedPleaseSelectTheProduct"),
										type: 'error',
										duration: 1000
									});
									return
								}
								this.$store
								  .dispatch("putGoodsAdVariant",this.formListQuery).then(res => {
									this.$message({
										message: this.$i18n.t('common.Successfullysubmitted'),
										type: 'success',
										duration: 1000
									});
									this.closeDialog()
									this.handleSearchList2()
								});
							} else {
								let goodArr=[]
								let parameterArr=[]
                if(this.checkedKeys.length<=0){
									this.$message({
										message: this.$i18n.t("common.NoVariant"),
										type: 'error',
										duration: 1000
									});
									return
								}							
								goodArr=this.goodsParameterList.map(item=>{
									return {
										goodsVariantRelationId:"0",
										goodsId:item.goodsId,
										goodsParameterId:item.goodsParameterId,
										sort:item.sort?item.sort:0
									}
								})
								parameterArr=this.checkedKeys.map((item)=>{
									return {
										goodsVariantParameterId:"0",
										variantParameterId:item.variantParameterId,
										goodsParameterId:item.goodsParameterId,
										sort:item.sort?item.sort:0
									}
								})								
								parameterArr.forEach((item,index)=>{
									parameterArr[index].sort=index
								})
								this.formListQuery.parameterList=parameterArr
								this.formListQuery.goodsList=this.goodsList
								if(this.formListQuery.parameterList.length<=0){
									this.$message({
										message: this.$i18n.t("common.VerificationFailedPleaseSelectTheVariant"),
										type: 'error',
										duration: 1000
									});
									return
								}
                if(this.goodsList.length<=0){
									this.$message({
										message: this.$i18n.t("common.VerificationFailedTheProductParameterValueIsEmpty"),
										type: 'error',
										duration: 1000
									});
									return
								}
								
								this.$store
								  .dispatch("postGoodsAdVariant",this.formListQuery).then(res => {
									this.$message({
										message: this.$i18n.t('common.Successfullysubmitted'),
										type: 'success',
										duration: 1000
									});
									this.closeDialog()
									this.handleSearchList2()
								});
							}
						});

					} else {
						this.$message({
							message: this.$i18n.t('common.Verificationfailed'),
							type: 'error',
							duration: 1000
						});
						return false;
					}
				});
			}
		},
		mounted(){
			this.initData()		
		}
	};
</script>
<style scoped>
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}	
</style>
<style>
	.must .el-form-item__label:before{
		content: '*';
		color: #F56C6C;
		margin-right: 4px;
	}
</style>

