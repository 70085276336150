<template>
    <div style="height: 100%; min-height: 350px">
        <div class="filter-container">
            <div style="margin-top: 0px">
                <el-form :inline="true" :model="searchData" size="small" label-width="140px" ref="searchFrom">
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.email"
                            clearable
                            :placeholder="$i18n.t('common.Emailaddress')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.orderNo"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            maxlength="25"
                            :placeholder="$i18n.t('common.Ordernumber')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.paymentOrderId"
                            clearable
                            :placeholder="$i18n.t('common.Paymentcode')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.userId"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            :placeholder="$i18n.t('common.UserID')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.barCode"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            :placeholder="$i18n.t('common.barcode')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.goodsId"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            :placeholder="$i18n.t('common.ProductID1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.countryCode"
                            clearable
                            :codeType="'countrys'"
                            :placeholder="$i18n.t('common.country')"
                        ></dataSelect>
                    </el-form-item>
                    <!--<el-form-item>
                         <dataSelect
                             style="width: 180px; margin-left: 10px"
                             class="filter-item"
                             v-model="searchData.brandCode"
                             clearable
                             :codeType="'brand'"
                             placeholder="品牌"
                         ></dataSelect>
                     </el-form-item>-->
                    <!--                    <el-form-item>-->

                    <!--                        <el-select style="margin-left: 10px" v-model="searchData.sourceId" clearable :placeholder='$i18n.t("common.PleaseSelectTheActivity")'>-->
                    <!--                            <el-option  v-for="item in distributionlist" :key="item.distributionId" :label="item.title" :value="item.title">-->

                    <!--                            </el-option>-->
                    <!--                        </el-select>-->
                    <!--                    </el-form-item>-->
                    <el-form-item prop="activity">
                        <el-input
                            :placeholder="$i18n.t('common.ActivityID')"
                            v-model="searchData.activityId"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                        >
                            <el-select v-model="searchData.activityType" slot="prepend" :placeholder="$i18n.t('common.activity')" clearable>
                                <el-option v-for="item in activityOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.orderStatus"
                            clearable
                            :codeType="'order_status'"
                            :placeholder="$i18n.t('common.OrderStatus')"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchData.payType" clearable :placeholder="$i18n.t('common.Pleaseselectpaymentmethod')">
                            <el-option
                                v-for="item in paymentList"
                                :key="item.pay_plat_id"
                                :label="handleLabel(item)"
                                :value="item.pay_plat_id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.deliveryStatus"
                            clearable
                            :codeType="'delivery_status'"
                            :placeholder="$i18n.t('common.Shipmentstatus')"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-model="searchData.transferStatus"
                            clearable
                            :codeType="'transfer_status'"
                            :placeholder="$i18n.t('common.Remittanceconfirmation')"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.isManualDeliver"
                            :placeholder="$i18n.t('common.Whethertomanuallyship')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                            <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.orderType"
                            :placeholder="$i18n.t('common.Originalordertype')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.Presaleorder')" value="PRE_SALE_ORDER"></el-option>
                            <el-option :label="$i18n.t('common.Officialorder')" value="SALE_ORDER"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.rebateFlag"
                            :placeholder="$i18n.t('common.RebateOrNot')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                            <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.useCoinsFlag"
                            :placeholder="$i18n.t('common.UseAigoCoinsOrNot')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                            <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.preSaleOrderStatus"
                            :placeholder="$i18n.t('common.Preorderstatus')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.Deposittobepaid')" value="DEPOSIT_UN_PAY"></el-option>
                            <el-option :label="$i18n.t('common.Depositpaid')" value="DEPOSIT_PAID"></el-option>
                            <el-option :label="$i18n.t('common.Finalpaymenttobemade')" value="FINAL_PAYMENT_UN_PAY"></el-option>
                            <el-option :label="$i18n.t('common.Finalpaymentpaid')" value="FINAL_PAYMENT_PAID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.preSalePayType"
                            :placeholder="$i18n.t('common.Presalepaymentmethod')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.Finaldeposit')" value="DEPOSIT_AND_FINAL_PAYMENT"></el-option>
                            <el-option :label="$i18n.t('common.Fullpayment')" value="FULL_PAYMENT"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.formal"
                            :placeholder="$i18n.t('common.Whetherthepresaleorderisconvertedtoformal')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                            <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="searchData.pallet"
                            :placeholder="$i18n.t('common.Combinedorders')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                        >
                            <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                            <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.Creationtime')">
                        <el-date-picker
                            v-model="searchData.selectCreateTime"
                            type="datetimerange"
                            :range-separator="$i18n.t('common.to')"
                            :start-placeholder="$i18n.t('common.Startdate')"
                            :end-placeholder="$i18n.t('common.Enddate')"
                            :default-time="['00:00:00','23:59:59']"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.Paymenttime')">
                        <el-date-picker
                            v-model="searchData.selectPayTime"
                            type="datetimerange"
                            :range-separator="$i18n.t('common.to')"
                            :start-placeholder="$i18n.t('common.Startdate')"
                            :end-placeholder="$i18n.t('common.Enddate')"
                            :default-time="['00:00:00','23:59:59']"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item>
                       <el-input
                           style="width: 180px; margin-left: 10px"
                           class="filter-item"
                           v-model="searchData.sourceUrlName"
                           clearable
                           :placeholder='$i18n.t("common.Pleaseenterthesource")'
                           v-if="!scene.isAigoSmartApp"
                       ></el-input>
                     </el-form-item>-->
                    <el-form-item>
                        <el-select
                            v-model="searchData.platform"
                            :placeholder="$i18n.t('common.platform')"
                            clearable
                            style="width: 180px; margin-left: 10px"
                            class="filter-item"
                            v-if="!scene.isAigoSmartApp"
                        >
                            <el-option label="PC" value="PC"></el-option>
                            <el-option label="H5" value="H5"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="orderActivityType">
                        <dataSelect
                            style="width: 200px"
                            class="filter-item"
                            v-model="searchData.orderActivityType"
                            clearable
                            :codeType="'activity_type'"
                            :placeholder="$i18n.t('common.ActivityType')"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="searchClick" style="margin-left: 10px">
                            {{ $i18n.t('common.query') }}
                        </el-button>
                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small"
                        >
                            {{ $i18n.t('common.Reset') }}
                        </el-button>
                        <el-button
                            class="filter-item"
                            icon="el-icon-download"
                            type="primary"
                            @click="handleExport"
                            style="margin-left: 10px"
                            size="small"
                            v-loading.fullscreen.lock="fullscreenLoading"
                        >
                            {{ $i18n.t('common.export') }}
                        </el-button>
                        <el-button
                            class="filter-item"
                            type="primary"
                            @click="handleEMonads"
                            style="margin-left: 10px"
                            size="small"
                            v-if="!scene.isNonSelf"
                        >
                            {{ $i18n.t('common.Positiveconversionsingle') }}
                        </el-button>
                        <el-button
                            class='filter-item'
                            type='primary'
                            @click='openOrderSettingDialog'
                            style='margin-left: 10px'
                            size='small'
                        >
                            {{ $i18n.t('common.OrderSettings') }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-table
            v-loading="loading"
            height="calc(100% - 310px)"
            @selection-change="handleSelectionChange"
            ref="pagedata"
            row-key="orderNo"
            :data="pageData.list"
            :tree-props="{ children: 'preSaleOrderMgrDtos', hasChildren: 'hasChildren' }"
            border
            style="width: 100%"
        >
            <el-table-column type="selection" :selectable="selectable" align="center" width="55" />
            <el-table-column prop="preSalePayType" label="" width="120px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.preSalePayType && row.orderNo && row.orderNo.indexOf('-') != -1">{{
                        $dict.getPreSalePayType(row.preSalePayType)
                    }}</span>
                </template>
            </el-table-column>

            <!--<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.brandCode" :codeType="'brand'"></dataText>
                </template>
            </el-table-column>-->
            <el-table-column prop="orderNo" :label="$i18n.t('common.Ordernumber')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="countryCode" :label="$i18n.t('common.country')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.countryCode" :codeType="'countrys'"></dataText>
                </template>
            </el-table-column>
            <el-table-column
                prop="deliveryCountry"
                :label="$i18n.t('common.CountryOfShipmentAddress')"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <dataText v-model="scope.row.deliveryCountry" :codeType="'countrys'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="userId" :label="$i18n.t('common.UserID')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="email" :label="$i18n.t('common.Useremail')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="source" :label="$i18n.t('common.activity')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="totalAmt" :label="$i18n.t('common.money')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.currencyCode" :codeType="'currency_code'">{{ scope.row.totalAmt }} </dataText>
                </template>
            </el-table-column>
            <el-table-column prop="orderStatus" :label="$i18n.t('common.OrderStatus')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.orderStatus" :codeType="'order_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                :label="$i18n.t('common.Creationtime')"
                align="center"
                :formatter="formatDate"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="eans" label="EAN" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="skus" label="SKU" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="nums" :label="$i18n.t('common.Numberofproductmodels')" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="payTypeDesc" :label="$i18n.t('common.Paymentmethod')" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="paymentOrderId"
                :label="$i18n.t('common.Paymentcode')"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="transferStatus" :label="$i18n.t('common.Remittanceconfirmation')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.transferStatus" :codeType="'transfer_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column
                prop="paymentTime"
                :label="$i18n.t('common.Paymenttime')"
                :formatter="formatDate"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="deliveryStatus" :label="$i18n.t('common.Shipmentstatus')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.deliveryStatus" :codeType="'delivery_status'"></dataText>
                </template>
            </el-table-column>
            <el-table-column
                prop="deliverTime"
                :label="$i18n.t('common.Deliverytime')"
                :formatter="formatDate"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="isManualDeliver" :label="$i18n.t('common.Whethertomanuallyship')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.isManualDeliver == true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.isManualDeliver != true && row.deliveryStatus == 'HaveDelivered'">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderType" :label="$i18n.t('common.Originalordertype')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.orderType">{{ $dict.getOrderType(row.orderType) }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="activityType" :label="$i18n.t('common.ActivityType')" width="200px" align="center" show-tooltip-when-overflow>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.activityTypeDtoList" :key="index">
                        <dataText class="filter-item" v-model="s.orderActivityType" :codeType="'activity_type'">:</dataText>
                        <span>{{ s.activityIds }}<br /></span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="rebateFlag" :label="$i18n.t('common.RebateOrNot')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.rebateFlag === true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.rebateFlag === false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="giftActivityFlag" :label="$i18n.t('common.IsThereAGiftEvent')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.giftActivityFlag === true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.giftActivityFlag === false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="useCoinsFlag" :label="$i18n.t('common.UseAigoCoinsOrNot')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.useCoinsFlag === true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.useCoinsFlag === false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="rebateAmt" :label="$i18n.t('common.RebateAmount')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.currencyCode" :codeType="'currency_code'">{{ scope.row.rebateAmt }} </dataText>
                </template>
            </el-table-column>
            <el-table-column prop="formal" :label="$i18n.t('common.Whethertotransfertoformal')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.orderType == 'PRE_SALE_ORDER' && row.formal == true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.orderType == 'PRE_SALE_ORDER' && row.formal == false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="preSalePayType" :label="$i18n.t('common.Presalepaymentmethod')" width="120px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.orderType == 'PRE_SALE_ORDER' && row.preSalePayType">{{
                        $dict.getPreSalePayType(row.preSalePayType)
                    }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="preSaleOrderStatus" label="预售单状态" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.preSaleOrderStatus">{{ $dict.getPreSaleOrderStatus(row.preSaleOrderStatus) }}</span>
                </template>
            </el-table-column>          -->
            <el-table-column prop="rightsItemCount" :label="$i18n.t('common.Returnsandexchanges')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-link type="primary" @click="postRouterHandle(scope.row.orderNo)">{{ scope.row.rightsItemCount }} </el-link>
                    <!-- <div v-else>{{scope.row.rightsItemCount}}</div> -->
                </template>
            </el-table-column>
            <el-table-column prop="unReplyConsultNum" :label="$i18n.t('common.Tobereplied_consulted')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-if="scope.row.orderNo.indexOf('-') == -1">
                        <el-tag type="info" v-if="scope.row.unReplyConsultNum < 10">
                            <el-link type="primary" v-if="scope.row.consultNum > 0" @click="consultRouterHandle(scope.row.orderNo)"
                                >{{ scope.row.unReplyConsultNum }} /
                                {{ scope.row.consultNum }}
                            </el-link>
                            <div v-else>{{ scope.row.unReplyConsultNum }} / {{ scope.row.consultNum }}</div>
                        </el-tag>
                        <el-tag type="danger" v-else>
                            <el-link type="primary" v-if="scope.row.consultNum > 0" @click="consultRouterHandle(scope.row.orderNo)"
                                >{{ scope.row.unReplyConsultNum }} /
                                {{ scope.row.consultNum }}
                            </el-link>
                            <div v-else>{{ scope.row.unReplyConsultNum }} / {{ scope.row.consultNum }}</div>
                        </el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="sourceUrlName"
                :label="$i18n.t('common.source')"
                align="center"
                show-overflow-tooltip
                v-if="!scene.isAigoSmartApp"
            >
                <template slot-scope="{ row }">
                    <span>{{ showSourceTranslate(row.sourceUrlName) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="sourceUrlName" :label="$i18n.t('common.OrderSource')" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span>{{ row.orderSourceUrlName }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                :label="$i18n.t('common.Updatetime')"
                align="center"
                :formatter="formatDate"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="updateByName" :label="$i18n.t('common.Operator')" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.updateByName">{{ row.updateByName }}</span>
                    <span v-else>{{ $i18n.t('common.SystemAutoUpdate') }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="platform"
                :label="$i18n.t('common.platform')"
                align="center"
                show-overflow-tooltip
                v-if="!scene.isAigoSmartApp"
            ></el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right" width="160">
                <template slot-scope="scope">
                    <!-- 预售单操作 -->
                    <div v-if="scope.row.orderNo && scope.row.orderNo.indexOf('-') == -1 && scope.row.orderType == 'PRE_SALE_ORDER'">
                        <!-- 未转正式单 -->
                        <div v-if="scope.row.formal == false">
                            <el-button @click="edit(scope.row, 'dialogFormVisible')" type="text" size="small">{{
                                $i18n.t('common.detailed')
                            }}</el-button>
                            <el-button v-if="scope.row.orderStatus == 'Paid'" @click="handleEMonad(scope.row)" type="text" size="small">{{
                                $i18n.t('common.Positiveconversionsingle')
                            }}</el-button>
                        </div>
                        <!-- 已转正式单 -->
                        <div v-if="scope.row.formal == true">
                            <el-button @click="edit(scope.row, 'dialogFormVisible')" type="text" size="small">{{
                                $i18n.t('common.detailed')
                            }}</el-button>
                            <el-button
                                v-if="isManualDeliverBtnShow(scope.row)"
                                @click="openManualDeliver(scope.row, 'manualDeliverForm')"
                                type="text"
                                size="small"
                                >{{ $i18n.t('common.Manualshipping') }}</el-button
                            >
                            <el-button
                                v-if="istransferConfirmBtnShow(scope.row)"
                                @click="openTransferConfirm(scope.row, 'transferConfirmForm')"
                                type="text"
                                size="small"
                                >{{ $i18n.t('common.Remittanceconfirmation') }}</el-button
                            >
                        </div>
                    </div>
                    <!-- 正式单操作 -->
                    <div v-else-if="scope.row.orderNo && scope.row.orderNo.indexOf('-') == -1 && scope.row.orderType != 'PRE_SALE_ORDER'">
                        <el-button @click="edit(scope.row, 'dialogFormVisible')" type="text" size="small">{{
                            $i18n.t('common.detailed')
                        }}</el-button>
                        <el-button
                            v-if="isManualDeliverBtnShow(scope.row)"
                            @click="openManualDeliver(scope.row, 'manualDeliverForm')"
                            type="text"
                            size="small"
                            >{{ $i18n.t('common.Manualshipping') }}</el-button
                        >
                        <el-button
                            v-if="istransferConfirmBtnShow(scope.row)"
                            @click="openTransferConfirm(scope.row, 'transferConfirmForm')"
                            type="text"
                            size="small"
                            >{{ $i18n.t('common.Remittanceconfirmation') }}</el-button
                        >
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="searchData.page"
                :page-size="searchData.size"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
        <el-dialog
            :title="$i18n.t('common.OrderDetails')"
            v-if="dialogFormVisible"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
        >
            <varoant-dialog @closeDialog="closeWindow" :formItem="formItem" />
        </el-dialog>

        <!-- 手动发货对话窗口  -->
        <el-dialog :title="$i18n.t('common.Manuallogisticsshipment')" :visible.sync="manualDeliverDialogVisible" width="600px" class='el-dialog-center' :close-on-click-modal='false'>
            <el-form
                ref="manualDeliverForm"
                :rules="manualDeliverFormRules"
                :label-position="labelPosition"
                label-width="100px"
                :model="manualDeliverFormData"
            >
                <el-form-item :label="$i18n.t('common.logisticscompany')" prop="logisticsCompanyId">
                    <el-select
                        v-model="manualDeliverFormData.logisticsCompanyId"
                        clearable
                        :placeholder="$i18n.t('common.Pleaseselectalogisticscompany')"
                        style="width: 400px"
                    >
                        <el-option
                            v-for="item in logisticsCompanyList"
                            :key="item.logisticsCompanyId"
                            :label="item.title"
                            :value="item.logisticsCompanyId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Logisticstrackingnumber')" prop="logisticsNo">
                    <el-input v-model="manualDeliverFormData.logisticsNo" style="width: 400px" />
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Deliverytime')" prop="deliverTime">
                    <el-date-picker
                        style="width: 400px"
                        v-model="manualDeliverFormData.deliverTime"
                        type="datetime"
                        :placeholder="$i18n.t('common.SelectDateTime')"
                    />
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Shippingremarks')" prop="deliverRemark">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        :placeholder="$i18n.t('common.Pleasenote')"
                        :autosize="{ minRows: 5 }"
                        v-model="manualDeliverFormData.deliverRemark"
                        maxlength="1000"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('manualDeliverForm')">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button type="primary" @click="manualDeliver('manualDeliverForm')">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>

        <!-- 汇款确认对话窗口  -->
        <el-dialog :title="$i18n.t('common.Remittanceconfirmation')" :visible.sync="transferConfirmDialogVisible" width="600px" close="" class='el-dialog-center' :close-on-click-modal='false'>
            <el-form
                ref="transferConfirmForm"
                :model="transferConfirmFormData"
                :rules="transferConfirmFormRules"
                :label-position="labelPosition"
                label-width="100px"
            >
                <el-form-item :label="$i18n.t('common.state')" prop="transferStatus">
                    <el-select v-model="transferConfirmFormData.transferStatus" :placeholder="$i18n.t('common.Pleaseselect')">
                        <el-option
                            style="width: 400px"
                            :placeholder="$i18n.t('common.Pleaseselectastatus')"
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Remarks')" prop="confirmRemark">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        :autosize="{ minRows: 5 }"
                        :placeholder="$i18n.t('common.Pleasenote')"
                        v-model="transferConfirmFormData.confirmRemark"
                        maxlength="1000"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('transferConfirmForm')">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button type="primary" @click="transferConfirm('transferConfirmForm')">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>

        <!-- 订单设置  -->
        <el-dialog :title="$i18n.t('common.OrderSettings')"  :visible.sync='orderSettingDialogVisible' width='40%' class='el-dialog-center' :close-on-click-modal='false'>
            <div>
                <el-form
                    center
                    ref='orderSettingForm'
                    :model='orderSettingFormData'
                    :rules='orderSettingFormRules'
                    label-width='130px'
                >
                    <el-form-item :label="$i18n.t('common.NormalOrdersExceed')" prop='orderAutoClose'>
                        <el-input
                            style='width: 250px'
                            v-model='orderSettingFormData.orderAutoClose'
                            oninput='if(value){value=value.replace(/[^\d]/g,1);
                                if(Number(value)<=0){value=1}} if(Number(value)>60*24){value=60*24}'

                        >
                            <template slot='append'>{{ $i18n.t('common.Minutes') }}</template>
                        </el-input>
                        <span style='margin-left: 10px'>{{ $i18n.t('common.OrderSautomaticallyClosed') }}</span>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.OrdeIsShipped')" prop='orderAutoFinish'>
                        <el-input
                            style='width: 250px'
                            v-model='orderSettingFormData.orderAutoFinish'
                            oninput='if(value){value=value.replace(/[^\d]/g,1);
                                if(Number(value)<=0){value=1}} if(Number(value)>90){value=90}'
                        >
                            <template slot='append'>{{ $i18n.t('common.Days') }}</template>
                        </el-input>
                        <span style='margin-left: 10px'>{{ $i18n.t('common.AutomaticallyComplete') }}</span>
                    </el-form-item>
                </el-form>
            </div>
            <div slot='footer' class='dialog-footer'>
                <el-button @click="closeOrderSetting"> {{ $i18n.t('common.Cancel') }} </el-button>
                <el-button type='primary' @click="orderSettingConfirm('orderSettingForm')">{{ $i18n.t('common.Submit')
                    }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import VaroantDialog from './components/VaroantDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import GoodsDialog from '@/components/common/GoodsDialog.vue';
import moment from 'moment';
import scene from '../../utils/scene';
import i18n from '@/i18n/i18n';

export default {
    computed: {
        scene() {
            return scene;
        }
    },
    components: { VaroantDialog, FormTableDialog, dataSelect, moment, dataText, GoodsDialog },
    name: 'recommend_module',
    data() {
        return {
            activityOptions: [
                {
                    value: 'PreSale',
                    label: i18n.t('common.PreSalePromotionID')
                },
                {
                    value: 'Promotion',
                    label: i18n.t('common.PromotionID')
                },
                {
                    value: 'Distribution',
                    label: i18n.t('common.DistributionPromotionID')
                },
                {
                    value: 'Coupon',
                    label: i18n.t('common.CouponID')
                },
                {
                    value: 'FullDiscount',
                    label: i18n.t('common.PromotionManagementID')
                },
                {
                    value: 'Pallet',
                    label: i18n.t('common.PortfolioManagementID')
                }
            ],
            selectionList: [],
            options: [
                {
                    value: 'ConfirmedTransferred',
                    label: this.$i18n.t('common.adopt')
                },
                {
                    value: 'ConfirmedNotTransfer',
                    label: this.$i18n.t('common.Failed')
                }
            ],
            fullscreenLoading: false,
            typeName: this.$i18n.t('common.Homepagerecommendation'),
            manualDeliverFormRules: {
                logisticsCompanyId: [{ required: true, message: this.$i18n.t('common.Pleaseselectalogisticscompany'), trigger: 'change' }],
                logisticsNo: [{ required: true, message: this.$i18n.t('common.Pleaseenterthelogisticstrackingnumber'), trigger: 'blur' }],
                deliverTime: [{ required: true, message: this.$i18n.t('common.Pleaseselecttheshippingtime'), trigger: 'change' }]
            },
            orderSettingFormRules: {
                orderAutoClose: [{ required: true, message: this.$i18n.t('common.TimeCannotBeEmpty'), trigger: 'blur' }],
                orderAutoFinish: [{ required: true, message: this.$i18n.t('common.TimeCannotBeEmpty'), trigger: 'blur' }]
            },
            transferConfirmFormRules: {
                transferStatus: [{ required: true, message: this.$i18n.t('common.Pleaseselectastatus'), trigger: 'change' }]
            },
            labelPosition: 'right',
            transferConfirmDialogVisible: false,
            orderSettingDialogVisible: false,
            //查询项
            defalultSearchData: {
                searchContent: '',
                countryCode: [],
                brandCode: '',
                sourceId: '',
                orderStatus: '',
                transferStatus: '',
                deliveryStatus: '',
                selectCreateTime: '',
                selectPayTime: '',
                payType: '',
                page: 1,
                limit: 10,
                orderNo: '',
                beginCreateTime: '',
                endCreateTime: '',
                beginPayTime: '',
                endPayTime: '',
                isManualDeliver: '',
                preSaleOrderStatus: '',
                formal: '',
                preSalePayType: '',
                orderType: '',
                goodsId: '',
                sourceUrlName: '',
                platform: '',
                activityId: '',
                activityType: '',
                orderActivityType: ''
            },
            // 汇款确认表单数据
            transferConfirmFormData: {
                transferStatus: '',
                orderId: '',
                confirmRemark: ''
            },
            // 手动发货表单数据
            manualDeliverFormData: {
                logisticsCompanyId: '',
                orderId: '',
                logisticsNo: '',
                deliverTime: '',
                deliverRemark: '',
                countryCode: ''
            },
            // 订单设置表单数据
            orderSettingFormData: {},
            searchData: {
                searchContent: '',
                countryCode: [],
                brandCode: '',
                sourceId: '',
                orderStatus: '',
                transferStatus: '',
                deliveryStatus: '',
                selectCreateTime: '',
                selectPayTime: '',
                payType: '',
                page: 1,
                limit: 10,
                orderNo: '',
                beginCreateTime: '',
                endCreateTime: '',
                beginPayTime: '',
                endPayTime: '',
                isManualDeliver: '',
                preSaleOrderStatus: '',
                formal: '',
                preSalePayType: '',
                orderType: '',
                goodsId: '',
                sourceUrlName: '',
                platform: '',
                rebateFlag: '',
                useCoinsFlag: '',
                giftActivityFlag: '',
                activityId: '',
                activityType: '',
                orderActivityType: ''
            },
            //组件标签是否显示
            arg: {
                name: true,
                brand: true,
                category: true
            },
            //组件标签是否显示
            argFrom: {
                code: true,
                put: true
            },
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            manualDeliverDialogVisible: false,
            dialogFormTableVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            loading: false,
            distributionlist: [],
            paymentList: [],
            logisticsCompanyList: []
        };
    },

    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if (to.path === '/b2c/order/index') {
                var orderId = this.$route.params.orderId;
                var userId = this.$route.params.userId;
                var goodsId = this.$route.params.goodsId;
                var orderType = this.$route.params.orderType;
                if (orderId || userId || goodsId || orderType) {
                    this.routerHandler();
                }
            }
        }
    },

    created() {
        this.getDictItems();
        this.getDistributionlist();
        this.routerHandler();
        this.getPayment();
    },
    mounted() {},
    methods: {
        //自定义选中框
        selectable: function (row, index) {
            if (row.orderStatus == 'Paid' && row.formal == false && row.orderNo.indexOf('-') == -1) {
                return true;
            } else {
                return false;
            }
        },
        istransferConfirmBtnShow(row) {
            if (row.transferStatus == 'Transferred') {
                return true;
            }
            return false;
        },
        isManualDeliverBtnShow(row) {
            if (
                row.paymentStatus == 'Paid' &&
                (row.orderLogistics == null ||
                    (row.orderLogistics != null && (row.orderLogistics.logisticsNo == null || row.orderLogistics.logisticsNo == '')))
            ) {
                return true;
            }
            return false;
        },
        handleLabel(item) {
            return item.plat_name + '  ' + item.country_code;
        },
        getPayment() {
            this.$store.dispatch('getPayment').then((res) => {
                this.paymentList = res;
                //const map = new Map();
                //this.paymentList = res.filter((res) => !map.has(res.plat_name) && map.set(res.plat_name, 1));
            });
        },
        getLogisticsCompany(countryCode) {
            this.$store.dispatch('getLogisticsCompany', countryCode).then((res) => {
                this.logisticsCompanyList = res;
                if (res.length > 0) {
                    this.manualDeliverFormData.logisticsCompanyId = this.logisticsCompanyList[0].logisticsCompanyId;
                }
            });
        },
        getDistributionlist() {
            this.$store.dispatch('getDistributionlist', null).then((res) => {
                this.distributionlist = res;
            });
        },

        /**
         * 处理路由跳转参数赋值
         */
        routerHandler() {
            var orderId = this.$route.params.orderId;
            this.searchData = Object.assign({}, this.defalultSearchData);
            if (orderId) {
                this.searchData.orderId = orderId;
            }
            var userId = this.$route.params.userId;
            if (userId) {
                this.searchData.userId = userId;
            }

            var goodsId = this.$route.params.goodsId;
            if (goodsId) {
                this.searchData.goodsId = goodsId;
            }
            var orderType = this.$route.params.orderType;
            if (orderType) {
                this.searchData.orderType = orderType;
            }

            console.log(this.searchData, 'order');
            this.loadlist();
            if (orderId) {
                this.edit({ orderId: orderId }, 'dialogFormVisible');
            }
        },
        /**
         * 跳转到咨询页面
         */
        consultRouterHandle(orderId) {
            this.$router.push({
                name: 'consultation_list',
                params: {
                    orderId: orderId
                }
            });
        },
        /**
         * 跳转到退换页面
         */
        postRouterHandle(orderNo) {
            this.$router.push({
                name: 'postSale_list',
                params: {
                    postOrderNo: orderNo
                }
            });
        },
        searchClick() {
            //活动和活动id，两个都需要有值
            if (this.searchData.activityId === '' && this.searchData.activityType !== '') {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.PleaseEnter') + i18n.t('common.ActivityID')
                });
                return;
            }
            if (this.searchData.activityId !== '' && this.searchData.activityType === '') {
                this.$message({
                    type: 'warning',
                    message: i18n.t('common.PleaseSelectTheActivity')
                });
                return;
            }

            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist() {
            this.loading = true;
            if (this.searchData.selectCreateTime) {
                this.searchData.beginCreateTime = moment(this.searchData.selectCreateTime[0]).unix() * 1000;
                this.searchData.endCreateTime = moment(this.searchData.selectCreateTime[1]).unix() * 1000;
            } else {
                this.searchData.beginCreateTime = null;
                this.searchData.endCreateTime = null;
            }
            if (this.searchData.selectPayTime) {
                this.searchData.beginPayTime = moment(this.searchData.selectPayTime[0]).unix() * 1000;
                this.searchData.endPayTime = moment(this.searchData.selectPayTime[1]).unix() * 1000;
            } else {
                this.searchData.beginPayTime = null;
                this.searchData.endPayTime = null;
            }
            this.formbody = Object.assign({}, this.searchData);
            delete this.formbody.selectCreateTime;
            delete this.formbody.selectPayTime;
            var _this = this;
            this.$store.dispatch('getOrderList', this.formbody).then((res) => {
                _this.pageData = res;
                setTimeout(()=>{
                    _this.$refs.pagedata.doLayout()
                },500)
                this.loading = false;
            });
            
        },
        edit(row, form) {
            this.title = this.$i18n.t('common.edit');
            let _this = this;
            this.$store.dispatch('getOrderDetailForBg', row).then((res) => {
                this.formItem = Object.assign({}, res);
                if (form != null) {
                    _this[form] = true;
                }
            });
        },
        /*
         *   手动发货
         */
        manualDeliver(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.manualDeliverFormData.deliverTime = new Date(this.manualDeliverFormData.deliverTime).getTime();
                    this.$store.dispatch('manualDeliver', this.manualDeliverFormData).then(() => {
                        this.$message({
                            message: this.$i18n.t('common.Successfullyshippedmanually'),
                            type: 'success'
                        });
                        this.manualDeliverDialogVisible = false;
                        this.searchClick();
                        this.$refs[formName].resetFields();
                    });
                }
            });
        },
        /*
         *   提交订单设置
         */
        orderSettingConfirm(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.$confirm(this.$i18n.t('common.Whethertosubmitdata'), this.$i18n.t('common.prompt'), {
                        confirmButtonText: this.$i18n.t('common.confirm1'),
                        cancelButtonText: this.$i18n.t('common.cancel'),
                        type: 'warning'
                    })
                        .then(() => {
                            this.$store.dispatch('saveOrderSetting', this.orderSettingFormData).then(() => {
                                this.$message({
                                    message: '成功',
                                    type: 'success'
                                });
                                this.orderSettingDialogVisible = false;
                                //this.$refs[formName].resetFields();
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: 'info',
                                message: this.$i18n.t('common.Canceled')
                            });
                        });


                }
            });


        },
        closeOrderSetting() {
            this.orderSettingDialogVisible = false;
        },
        /*
         *   汇款确认
         */
        transferConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('transferConfirm', this.transferConfirmFormData).then(() => {
                        this.$message({
                            message: this.$i18n.t('common.Remittanceconfirmationsuccessful'),
                            type: 'success'
                        });
                        this.transferConfirmDialogVisible = false;
                        this.searchClick();
                        this.$refs[formName].resetFields();
                    });
                }
            });
        },
        /*
         *   打开手动发货窗口
         */
        openManualDeliver(row, formName) {
            this.getLogisticsCompany(row.countryCode);

            this.manualDeliverFormData.orderId = row.orderId;
            this.manualDeliverDialogVisible = true;
        },
        /*
         *   打开汇款确认窗口
         */
        openTransferConfirm(row, formName) {
            this.transferConfirmFormData.orderId = row.orderId;
            this.transferConfirmDialogVisible = true;
        },
        /*
         *   重置字段
         */
        resetForm(formName) {
            this.manualDeliverDialogVisible = false;
            this.transferConfirmDialogVisible = false;
            this.$refs[formName].resetFields();
        },
        preview(row) {
            var url = `${this.webSiteUrl}promotionTheme?id=${row.activityId}`;
            window.open(url, '_blank');
        },
        del(row) {
            var _this = this;
            this.$confirm(this.$i18n.t('common.confirmdeletion') + '？', this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('delActivity', { id: row.activityId }).then((res) => {
                        if (res) {
                            this.$message.success(this.$i18n.t('common.Successfullydeleted'));
                            this.loadlist();
                        }
                    });
                })
                .catch(() => {});
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        formInit() {
            var _this = this;
            if (this.$refs.formItem !== undefined) {
                this.$refs.formItem.initFormData(this.formItem);
                this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
            } else {
                setTimeout(() => {
                    this.$refs.formItem.initFormData(this.formItem);
                    this.$refs.formItem.initDictData({ countrys: _this.countrysDicts, platforms: _this.platformDicts });
                }, 500);
            }
        },
        closeWindow(status) {
            this.dialogFormVisible = false;
            if (status) {
                this.loadlist();
            }
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'platform' }).then((res) => {
                if (res != null) _this.platformDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            this.searchData = Object.assign({}, this.defalultSearchData);
            this.searchClick();
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        handleSelectionChange(selection) {
            this.selectionList = selection;
        },
        //批量转正单
        handleEMonads() {
            if (this.selectionList && this.selectionList.length <= 0) {
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t('common.Pleaseselectapresaleorder')
                });
                return;
            }
            let arr = [];
            this.selectionList.forEach((item) => {
                arr.push(item.orderId);
            });
            arr = Array.from(new Set(arr));
            this.$confirm(this.$i18n.t('common.Confirmtoconverttheorderintoaformalorder'), this.$i18n.t('common.prompt'), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
                cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('putTurntoFormal', arr).then((res) => {
                        if (res) {
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t('common.success')
                            });
                            this.loadlist();
                        }
                    });
                })
                .catch(() => {});
        },
        handleEMonad(row) {
            let arr = [row.orderId];
            this.$confirm(this.$i18n.t('common.Confirmtoconverttheorderintoaformalorder'), this.$i18n.t('common.prompt'), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
                cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('putTurntoFormal', arr).then((res) => {
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.success')
                        });
                        this.loadlist();
                    });
                })
                .catch(() => {});
        },
        /*
         *   提交订单设置
         */
        openOrderSettingDialog() {
            this.$store.dispatch('getOrderSetting', {}).then((res) => {
                if (res) {
                    this.orderSettingFormData = res
                    this.orderSettingDialogVisible = true;
                }
            });
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.searchData.fileName = this.$i18n.t('common.OrderData') + '.xlsx';

            this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
                cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            })
                .then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportOrderInfoExcel', this.searchData).then((res) => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Exportsuccessful')
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
        /**
         * 显示来源翻译
         */
        showSourceTranslate(sourceNameUrl) {
            if (!sourceNameUrl) {
                return '';
            }
            if ('直接访问' == sourceNameUrl) {
                return this.$t('common.DirectVisit');
            } else if ('其他' == sourceNameUrl) {
                return this.$t('common.other');
            } else {
                return sourceNameUrl;
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.my-content {
    background: #fde2e2;
}
::v-deep .el-input-group__prepend {
    width: 60px;
    background-color: #fff;
}

.el-table__fixed-body-wrapper .el-table__body {
  padding-bottom: 6px; 
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

::v-deep .el-dialog-center .el-dialog .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
