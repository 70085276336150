var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "header-container" }, [
        _c("span", { staticStyle: { "margin-left": "5px" } }, [
          _vm._v(
            _vm._s(_vm.$i18n.t("common.Class")) +
              ":" +
              _vm._s(_vm.rowInfo.classifyName)
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("el-input", {
            staticClass: "input-width",
            attrs: { placeholder: _vm.$i18n.t("common.name") },
            model: {
              value: _vm.variantTitle,
              callback: function($$v) {
                _vm.variantTitle = $$v
              },
              expression: "variantTitle"
            }
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.filterVariantList }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.query")))]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.pageData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "parameterItemId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.name"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.updateTime
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.updateTime))
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$i18n.t("common.operation"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.update(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.CustomName")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.CustomName"),
            visible: _vm.customVisible,
            width: "35%",
            "before-close": _vm.customCloseDialog,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.customVisible = $event
            }
          }
        },
        [
          _vm.customVisible
            ? _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      { ref: "titleFrom", attrs: { "label-width": "100px" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$i18n.t("common.name"),
                              prop: "name"
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.rowInfo2.title) +
                                "\n\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._l(_vm.titleList, function(item, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: index,
                              attrs: { label: item.name, prop: "name" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "show-word-limit": "",
                                  maxlength: "255",
                                  placeholder: _vm.$i18n.t("common.CustomName"),
                                  autocomplete: "off"
                                },
                                model: {
                                  value: item.group,
                                  callback: function($$v) {
                                    _vm.$set(item, "group", $$v)
                                  },
                                  expression: "item.group"
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "20%" },
                        on: { click: _vm.customCloseDialog }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "20%" },
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }