<template> 
	<div>
		<el-form :model="formListQuery" :rules="rules" ref="variantFrom">
			<el-form-item :label="$i18n.t('common.Categoryname1')" prop="title" :label-width="formLabelWidth">
				<el-input show-word-limit maxlength="50" v-model="formListQuery.title" class="input-width" :placeholder="$i18n.t('common.Categoryname1')"></el-input>
			</el-form-item>
			<el-form-item :label="$i18n.t('common.Category')" prop="parentId" :label-width="formLabelWidth">
				<el-cascader @change="handleCascader($event)" :placeholder="$i18n.t('common.Pleaseselectacategory')" ref="myCategory" v-model="formListQuery.parentId" :options="treeData"
				  :props="{checkStrictly: true,value:'goodsClassifyId',label:'title',children:'children'}" :show-all-levels="false"></el-cascader>
				<!-- <el-select v-model="formListQuery.parentId" placeholder="$i18n.t('common.Category')">
					<el-option v-for="item in treeData" :key="item.goodsClassifyId" :label="item.title"
						:value="item.goodsClassifyId">
					</el-option>
				</el-select> -->
			</el-form-item>
			<el-form-item v-if="formListQuery.level" :label="$i18n.t('common.Directoryhierarchy')" :label-width="formLabelWidth">
				<span>{{formListQuery.level}}</span>
			</el-form-item>
			<!--<el-form-item label="品牌" prop="brandCode" :label-width="formLabelWidth">
				<el-select v-model="formListQuery.brandCode" placeholder="品牌">
					<el-option v-for="(item,index) in dictionary.brandCode" :key="index" :label="item.name"
						:value="item.code">
					</el-option>
				</el-select>
			</el-form-item>-->
			<el-form-item :label="$i18n.t('common.sort')" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.sort" oninput="value=value.replace(/[^\d]/g,'')" class="input-width" :placeholder="$i18n.t('common.sort')"></el-input>
			</el-form-item>
			<el-form-item :label="$i18n.t('common.country')"  :label-width="formLabelWidth">
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $i18n.t('common.SelectAll') }}
					</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="formListQuery.bannerCountriesList" @change="handleCheckedCitiesChange">
						<el-checkbox v-for="(item,index) in dictionary.country" :key="item.code" :label="item.code">{{item.name}}</el-checkbox>
					</el-checkbox-group>		
			</el-form-item>
			<el-form-item :label="$i18n.t('common.Enableornot')" prop="status" :label-width="formLabelWidth">
				<el-switch v-model="formListQuery.status" :active-value="1"
                :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
				</el-switch>
			</el-form-item>
			<el-form-item :label="$i18n.t('common.Isthehomepagedisplayed')" v-if="scene.isAigoSmartApp" prop="appStatus" :label-width="formLabelWidth">
				<el-switch v-model="formListQuery.appStatus" :active-value="1"
                :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
				</el-switch>
			</el-form-item>
		</el-form>
		<div>
			<div style="display: flex;justify-content: flex-start;">
				<div class="uploadTitle">
					{{ $i18n.t('common.picture') }}
				</div>
				<el-upload
				  class="avatar-uploader"
				  :action="ossInfo.ossSignature.host"
				  :show-file-list="false"
				  :auto-upload="false"
				  list-type="picture-card"
				  ref="uploadImg"
				  :on-change="handleChange"
				  :data="fileDataObj"
				  accept=".jpg, .jpeg, .png, .gif"
				>
					<el-image
							v-if="imageUrl"
					      class='avatar'
					      :src="imageUrl"
					      fit="contain"></el-image>
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div style="margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;">
					<span>{{ $i18n.t('common.Imagesize') }}：1*1024，＜1M</span> 
				</div>
			</div>
			<div style="display: flex;justify-content: flex-start;margin-top: 20px;">
				<div class="uploadTitle">
					{{ $i18n.t('common.icon') }}
				</div>
				<el-upload
				  class="avatar-uploader"
				  :action="ossInfo.ossSignature.host"
				  :show-file-list="false"
				  :auto-upload="false"
				  list-type="picture-card"
				  ref="uploadImg2"
				  :on-change="handleChange2"
				  :data="fileDataObj2"
				  accept=".jpg, .jpeg, .png, .gif"
				>
					<el-image
							v-if="imageUrl2"
					      class='avatar'
						  :src="imageUrl2"
					      fit="contain"></el-image>
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div style="margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;">
					<span>{{ $i18n.t('common.Imagesize') }}：1*1024，＜1M</span> 
				</div>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog">{{ $i18n.t('common.cancel') }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')">{{ $i18n.t('common.confirm1') }}</el-button>
		</div>
	</div>
</template>
<script>
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import X2JS from "x2js"; //解析xml
	import {enumeration,randomString, getSuffix, blobToFile} from '@/utils/common2';
	import cache from '@/utils/cache';
	import config from '@/utils/config';
  import scene from '../../../utils/scene';
	export default {
    computed: {
      scene() {
        return scene
      }
    },
		components: {
			FilterContainer
		},
		props: {
			isEdit: {
			  type: Boolean,
			  default: false
			},
			dictionary: {
				//字典
				type: Object,
				default: () => {
					return {}
				}
			},
			formListQuery: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				formLabelWidth: '120px',
				//组件标签是否显示
				arg: {
					code: true,
					country: true,
					status: true
				},
				treeData:[],
				countryKey:[],
				//选中数量
				rules: {
					title: [{
							required: true,
							message: this.$i18n.t('common.Pleaseenteraname'),
							trigger: 'blur'
						},
						{
							min: 1,
							max: 50,
							message: this.$i18n.t('common.Between1and50charactersinlength'),
							trigger: 'blur'
						}
					],
					parentId:[{
						required: true,
						message: this.$i18n.t('common.Pleaseselectacategory'),
						trigger: 'change'
					}],
					brandCode: [{
						required: true,
						message: this.$i18n.t('common.Pleaseselectabrand'),
						trigger: 'change'
					}],
					status:[
						{ required: true, message: this.$i18n.t('common.PleaseSelectWhetherToEnable'), trigger: 'change' }
					],	
				},
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				imageUrl:"",
				imageUrl2:"",				
				fileDataObj: {},
				fileDataObj2: {},
				ossInfo:{
					ossHost: process.env.VUE_APP_OSS_Head,
					ossSignature: { host: '' },
					activityTranslates: [],
					langs: [],
					seoIndex: '0',
					newFiles: []
				},
				checkAll: false,
				isIndeterminate: false
			};
		},
		methods: {
			//全选
			handleCheckAllChange(val) {
				let arr=this.dictionary.country.map((item)=>{
					return item.code
				})
				this.formListQuery.bannerCountriesList = val ? arr : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.formListQuery.bannerCountriesList.length;
				if(checkedCount<=0 || this.formListQuery.bannerCountriesList.length<=0){
					this.checkAll = false;
					this.isIndeterminate = false;
				}
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
        	},
			//获取OSS签名
			getOssSignature(){
			  this.$store
			    .dispatch("getSignature", {
			      dir: 'b2c'
			    })
			    .then((res) => {
			      this.ossInfo.ossSignature = res;
			      this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
			    });
			},
			//获取商品树类目
			getGoodsClassifyTree(){
				this.$store
					.dispatch('getGoodsClassifyTree', {})
					.then(res => {			
						res.unshift({
							parentId:"0",
							goodsClassifyId:"0",
							title:this.$i18n.t('common.nothing')
						})
						this.$nextTick(()=>{
							this.treeData=res
							if(this.isEdit==false){						
								this.$set(this.formListQuery,'goodsClassifyId',this.treeData[0].goodsClassifyId)											
							}
						})
					});
			},	
			/**
			 * 生成随机文件名
			 * @param len
			 * @returns {string}
			 */
			randomString(len) {
			  len = len || 32;
			  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
			  let maxPos = chars.length;
			  let pwd = "";
			  for (let i = 0; i < len; i++) {
			    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
			  }
			  return pwd;
			},
			
			getSuffix(filename) {
			  let pos = filename.lastIndexOf(".");
			  let suffix = "";
			  if (pos != -1) {
			    suffix = filename.substring(pos);
			  }
			  return suffix;
			},
			handleCascader(row) {
				const checkedNode =  this.$refs["myCategory"].getCheckedNodes();
				this.formListQuery.parentId=checkedNode[0].data.goodsClassifyId
			},
			handleChange(file, fileLis) {
				const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
				if (fileType.indexOf(file.raw.type) == -1) {
						this.$message.error(this.$i18n.t('common.Uploadingimagesonlysupports'));
						return;
				}	
				if (file.size>1024 * 1024) {
					this.$message.error(this.$i18n.t('common.Thesizeoftheuploadedimagecannotexceed')+`1MB!`);
					return
				}
				let dataObj = {
				  name: file.name,
				  key:
				    this.ossInfo.ossSignature.dir +
				    "/" +
				    new Date().getFullYear() +
				    "/" +
				    this.randomString() +
				    this.getSuffix(file.name),
				  policy: this.ossInfo.ossSignature["policy"],
				  OSSAccessKeyId: this.ossInfo.ossSignature["access_id"],
				  success_action_status: "201",
				  callback: "",
				  signature: this.ossInfo.ossSignature["signature"],
				  // file: file.file,
				};
				file.type = "add";
				this.fileDataObj = dataObj;	
				setTimeout(() => {
				  this.$refs.uploadImg.submit();
				  var x2js = new X2JS();
				  var jsonObj = x2js.xml2js(file.response);
				  console.log(jsonObj);
				  let ossUrl = jsonObj.PostResponse.Location;
				  this.imageUrl = ossUrl;
				}, 500); 					
			},
			handleChange2(file, fileLis) {
				const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
				if (fileType.indexOf(file.raw.type) == -1) {
						this.$message.error(this.$i18n.t('common.Uploadingimagesonlysupports'));
						return;
				}
				if (file.size>1024 * 1024) {
					this.$message.error(this.$i18n.t('common.Thesizeoftheuploadedimagecannotexceed')+`1MB!`);
					return
				}
				let dataObj = {
				  name: file.name,
				  key:
				    this.ossInfo.ossSignature.dir +
				    "/" +
				    new Date().getFullYear() +
				    "/" +
				    this.randomString() +
				    this.getSuffix(file.name),
				  policy: this.ossInfo.ossSignature["policy"],
				  OSSAccessKeyId: this.ossInfo.ossSignature["access_id"],
				  success_action_status: "201",
				  callback: "",
				  signature: this.ossInfo.ossSignature["signature"],
				  // file: file.file,
				};
				file.type = "add";
				this.fileDataObj2 = dataObj;	
				setTimeout(() => {
				  this.$refs.uploadImg2.submit();
				  var x2js = new X2JS();
				  var jsonObj = x2js.xml2js(file.response);
				  console.log(jsonObj);
				  let ossUrl = jsonObj.PostResponse.Location;
				  this.imageUrl2 = ossUrl;
				}, 500); 					
			},
			initImg(){
				this.countryKey=[]
				for(var pl in this.dictionary.country){
					this.countryKey.push(this.dictionary.country[pl])
				}
				if(this.formListQuery.backgroundImage){
						let str = this.ossInfo.ossHost.slice(0,this.ossInfo.ossHost.length-1)
						this.imageUrl=str+this.formListQuery.backgroundImage
				}else{
					this.imageUrl=''
				}
				if(this.formListQuery.icon){
					let str1  = this.ossInfo.ossHost.slice(0,this.ossInfo.ossHost.length-1)
					this.imageUrl2=str1+this.formListQuery.icon
				}else{
					this.imageUrl2=''
				}	
			},
			//提交
			onSubmit(formName) {
				this.formListQuery.brandCode = this.$tenantBrand.getBrand();
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm(this.$i18n.t('common.Whethertosubmitdata'), this.$i18n.t('common.prompt'), {
							confirmButtonText: this.$i18n.t('common.confirm1'),
							cancelButtonText: this.$i18n.t('common.cancel'),
							type: 'warning'
						}).then(() => {
							let fileUrl=''
							let zi='com'
							if(this.imageUrl){
								// 云测5411 处理提交显示加载失败
								let backgroundImageByFrom = this.imageUrl
								if (this.imageUrl.indexOf(zi) !== -1) {
								//   this.imageUrl = this.imageUrl.substr(this.imageUrl.lastIndexOf(zi)+zi.length)
									backgroundImageByFrom= this.imageUrl.substr(this.imageUrl.lastIndexOf(zi)+zi.length)
								}
								// this.formListQuery.backgroundImage=this.imageUrl
								this.formListQuery.backgroundImage=backgroundImageByFrom
							}
							if(this.imageUrl2){
								// 云测5411 处理提交显示加载失败
								let iconByFrom = this.imageUrl2
								if (this.imageUrl2.indexOf(zi) !== -1) {
								//   this.imageUrl2 = this.imageUrl2.substr(this.imageUrl2.lastIndexOf(zi)+zi.length)
									iconByFrom = this.imageUrl2.substr(this.imageUrl2.lastIndexOf(zi)+zi.length)
								}
								// this.formListQuery.icon=this.imageUrl2
								this.formListQuery.icon=iconByFrom
							}
							this.formListQuery.bannerCountriesList? this.formListQuery.countryCode=this.formListQuery.bannerCountriesList.join('|'):"" 						
							this.formListQuery.compId=0	
							if (this.isEdit) {												
								this.$store
								  .dispatch("upClassify",this.formListQuery)
								  .then((res) => {
									  this.$message({
									  	message: this.$i18n.t('common.Successfullymodified'),
									  	type: 'success',
									  	duration: 1000
									  });
									this.closeDialog()
									this.handleSearchList()
								});								  
							} else {
								this.$store
								  .dispatch("addAdClassify",this.formListQuery)
								  .then((res) => {
									  this.$message({
									  	message: this.$i18n.t('common.Successfullysubmitted'),
									  	type: 'success',
									  	duration: 1000
									  });
									this.closeDialog()
									this.handleSearchList() 			
								});							
							}
						}).catch((err) => {
							this.formListQuery.bannerCountriesList=bannerCountriesList
						});	
					} else {
						this.$message({
							message: this.$i18n.t('common.Verificationfailed'),
							type: 'error',
							duration: 1000
						});
						return false;
					}
				});
			},
			//关闭窗口
			closeDialog(){
				this.$emit("closeDialog")			
			},
			handleSearchList(){
				this.$emit("handleSearchList")
			}
		},
		mounted(){
			let arr=this.dictionary.country.map((item)=>{
					return item.code
			})
			if(arr.length === this.formListQuery.bannerCountriesList.length){
				this.isIndeterminate = false
				this.checkAll =	true
			}else if(this.formListQuery.bannerCountriesList.length){
				this.isIndeterminate = true
			}		
			this.initImg();
			this.getOssSignature();
			this.getGoodsClassifyTree()
		}
	};
</script>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 178px !important;
		height: 178px !important;
	}
	
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	/deep/ .avatar-uploader .el-upload--picture-card{
		width: 178px !important;
		height: 178px !important;
	}
	/deep/ .avatar-uploader .el-progress--circle{
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	/deep/ .avatar-uploader .el-progress{
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	/deep/ .avatar-uploader .el-progress-circle{
		width: 176px !important;
		height: 176px !important;
		margin-top: 0px !important;
	}
	
	.avatar {
		height: 178px;
		display: block;
	}
	.uploadTitle{
		width: 120px;
		text-align: end;
		padding-right: 12px;
		box-sizing: border-box;
	}
	/* .uploadTitle:before {
	    content: '*';
	    color: #F56C6C;
	    margin-right: 4px;
	} */
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}

	.prpa {
		display: flex;
		justify-content: center;
		align-items: center;	
	}

	.prpa-sub {
		border: 1px solid rgba(234, 234, 234, 1);
		width: 80%;
	}
	.content1 {
		width: auto;
		height: 100%;
		overflow-y: scroll;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.content-sub-left {
		cursor: pointer;
		position: relative;
		height: 100%;
		background-color: #ececec;
		padding: 0px 0px 0px 10px;
		width: 40%;
	}

	.content-sub-right {
		width: 60%;
	}

	.content-sub-item {
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		padding: 10px 0px;
	}

	.active::before {
		content: '';
		position: absolute;
		border-left: 4px solid #55aaff;
		height: 30px;
		left: 0px;
	}

	.active {
		font-weight: 400;
		font-style: normal;
		font-size: 20px;
		background-color: #55aaff;
		color: white;
	}
	.content2 {
		width: auto;
	}

	.content2-sub {
		font-size: 15px;
		line-height: 15px;
		margin: 10px 0px;
		color: #464646;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
