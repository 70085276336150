<template>
    <div style="height: 100%;min-height:350px;">
        <el-card class="filter-container" shadow="never">
            <div style="margin-top: 15px;margin-left: 10px">
                <el-form :inline="true" :model="searchData" size="small" label-width="140px">
                    <el-form-item>
                        <el-input v-model="searchData.title" clearable :placeholder="$t('common.ActivityName')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="searchData.countryCode"
                                clearable
                                :codeType="'countrys'"
                                :placeholder='$i18n.t("common.country")'
                        ></dataSelect>
                    </el-form-item>
<!--                    <el-form-item>
                        <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="searchData.brandCode"
                                clearable
                                :codeType="'brand'"
                                :placeholder='$i18n.t("common.brand") '
                        ></dataSelect>
                    </el-form-item>-->
                    <el-form-item>
                        <dataSelect
                            style="width: 180px;"
                            class="filter-item"
                            v-model="searchData.userType"
                            clearable
                            :codeType="'user_type'"
                            :placeholder="$t('common.ApplicableUserType')"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="searchData.activityStatus"
                                clearable
                                :codeType="'activity_status'"
                                :placeholder='$i18n.t("common.Activitystatus") '
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px;"
                            class="filter-item"
                            v-model="searchData.status"
                            clearable
                            :codeType="'system_bool'"
                            :placeholder='$i18n.t("common.Enableornot") '

                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <dataSelect
                            style="width: 180px;"
                            class="filter-item"
                            v-model="searchData.homeShow"
                            clearable
                            :codeType="'system_bool'"
                            :placeholder='$i18n.t("common.Isthehomepagedisplayed") '
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-button style="float:right" @click="handleResetSearch()" size="small">
                            {{ $i18n.t("common.Reset")  }}
                        </el-button>
                        <el-button style="float:right;margin-right: 15px" type="primary" @click="loadlist()"
                                   size="small">

                                   {{ $i18n.t("common.QuerySearch")  }}


                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card
        >
        <div style="margin: 10px 0px 10px 20px;">
            <el-button @click="add()" type="success" size="small">     {{ $i18n.t("common.Add1") }}</el-button>
        </div>
        <el-table v-loading="listloading" ref="pagedata" height="calc(100% - 180px)" :data="pageData.list" border style="width: 100%">
            <el-table-column prop="activityId" label="ID" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.ActivityName") ' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="imgUrl" :label=' $i18n.t("common.picture")' align="center">
                <template slot-scope="scope">
<!--                    <el-image style="height: 100px" v-if="scope.row.imgUrl"-->
<!--                              :src="ossHost +scope.row.imgUrl" fit="fill"></el-image>-->
                    <listImage :imgUrl="scope.row.imgUrl"></listImage>
                </template>
            </el-table-column>


            <el-table-column prop="description" :label=' $i18n.t("common.explain")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
					<div v-html="scope.row.description"></div>
				</template>

            </el-table-column>

            <el-table-column prop="activityGoods.length" :label=' $i18n.t("common.Numberofproducts")'  align="center" show-overflow-tooltip></el-table-column>

<!--            <el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <dataText v-model="scope.row.brandCode" :codeType="'brand'"></dataText>
                </template
                >

            </el-table-column>-->

            <el-table-column prop="brandCode" :label="$t('common.ApplicableUserType')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.userType == 'ALL'">
                        {{ $dict.getDictValue('user_type', 'NEW') + ' , ' + $dict.getDictValue('user_type', 'OLD') }}
                    </span>
                    <span v-else> {{ $dict.getDictValue('user_type', scope.row.userType) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <dataText v-model="scope.row.countryCode" :codeType="'countrys'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" :label='$i18n.t("common.Creationtime")' align="center" show-overflow-tooltip
                             :formatter="formatDate">
            </el-table-column>
            <el-table-column prop="beginTime"  :label='$i18n.t("common.starttime")' align="center" show-overflow-tooltip
                             :formatter="formatDate">
            </el-table-column>
            <el-table-column prop="endTime" :label='$i18n.t("common.Endtime")' align="center" show-overflow-tooltip
                             :formatter="formatDate">
            </el-table-column>
            <el-table-column prop="theme" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText class='filter-item' v-model='scope.row.activityStatus' clearable
                              :codeType="'activity_status'"
                    ></dataText
                    >
                </template>
            </el-table-column>
            <el-table-column prop="status" :label='$i18n.t("common.Enableornot")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-switch :disabled="true" v-model="row.status" active-color="#13ce66"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="status" :label='$i18n.t("common.Whethertodisplayonthehomepage")'  align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-switch :disabled="true" v-model="row.homeShow" active-color="#13ce66"></el-switch>
                </template>
            </el-table-column>

            <el-table-column prop="updateName" :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span>{{ row.updateUserName }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.updateTime | formatDate}}</span>
                </template>
            </el-table-column>

            <el-table-column :label='$i18n.t("common.operation")'  align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row, 'dialogFormVisible')" type="text" size="small">     {{ $i18n.t("common.edit") }}</el-button>
                    <el-button @click="edit(scope.row, 'dialogFormTableVisible2')" type="text" size="small">  {{ $i18n.t("common.commodity") }}
                    </el-button>
                    <el-button @click="addGoods(scope.row)" type="text" size="small">  {{ $i18n.t("common.Addproduct") }}</el-button>
                    <el-button @click="openSEO(scope.row)" type="text" size="small">SEO</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small"> {{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="searchData.page"
                    :page-size="searchData.size"
                    layout="total, prev, pager, next, jumper"
                    :total="pageData.total"
            ></el-pagination>
        </div>
        <el-dialog :title="title" :visible.sync="dialogFormVisible" v-if="dialogFormVisible"
                   :close-on-click-modal="false">
            <add-update-dialog @closeDialog="closeWindow" :formItem="formItem" :isEdit="isEdit"/>
        </el-dialog>
        <el-dialog :title="title" :visible.sync="dialogFormTableVisible" :close-on-click-modal="false">
            <FormTableDialog>
                <template v-slot:tableSlot>
                    <div style="margin: 20px 0px 20px 20px;">
                        <el-button @click="add()" type="success" size="small">   {{ $i18n.t("common.Add1") }}</el-button>
                    </div>
                    <el-table
                            ref="pagedata"
                            :data="tableData"
                            @selection-change="selectionChange"
                            border
                            class="tableGoods"
                            style="width: 100%"
                    >
                        <el-table-column type="selection" width="55"/>
                        <el-table-column prop="id" :label='$i18n.t("common.ProductID1")' align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="code" :label='$i18n.t("common.barcode") ' align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="imgs" :label=' $i18n.t("common.picture")' align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="category" :label='$i18n.t("common.Category")' align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="country" :label='$i18n.t("common.SalesCountry")' align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="activityStatus" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <dataText class='filter-item' v-model='scope.row.activityStatus' clearable
                                          :codeType="'activity_status'"
                                          :placeholder='$i18n.t("common.brand")'></dataText
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="updateTime"
                                :render-header="renderRuHeader"
                                align="center"
                                show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="updateTime" :render-header="renderSortHeader" align="center"
                                         show-overflow-tooltip>
                            <el-input v-model="input" :placeholder='$i18n.t("common.Pleaseentercontent")'></el-input>
                        </el-table-column>
                        <el-table-column prop="status" :label='$i18n.t("common.Enableornot")'  align="center" show-overflow-tooltip>
                            <el-switch v-model="enabled"></el-switch>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button @click="delete scope.row" type="text" size="small">{{ $i18n.t("common.delete") }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </FormTableDialog>
        </el-dialog>
        <!-- <el-dialog :title="title" :visible.sync="dialogFormTableVisible2" :close-on-click-modal="false">
            <FormTableDialog>
                <template v-slot:tableSlot>
                    <el-table
                        ref="pagedata"
                        :data="tableData"
                        @selection-change="selectionChange"
                        border
                        class="tableGoods"
                        style="width: 100%"
                    >
                        <el-table-column type="selection" width="55" />
                        <el-table-column prop="id" label="商品ID" align="center" show-overflow-tooltip> </el-table-column>
                        <el-table-column prop="code" label="条码" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="imgs" label="图片" align="center" show-overflow-tooltip> </el-table-column>
                        <el-table-column prop="category" label="所属类目" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="country" label="销售国家" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column
                            prop="updateTime"
                            label="入库时间"
                            :render-header="renderRuHeader"
                            align="center"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="updateTime" :render-header="renderSortHeader" align="center" show-overflow-tooltip>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </el-table-column>
                        <el-table-column prop="status" label="是否启用" align="center" show-overflow-tooltip>
                            <el-switch v-model="enabled"></el-switch>
                        </el-table-column>
                        <el-table-column label="操作" align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button @click="delete scope.row" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </FormTableDialog>
        </el-dialog> -->
        <el-dialog :title="title" :visible.sync="dialogSEOVisible" :close-on-click-modal="false">
            <div>
                <dataTabs
                        class="filter-item"
                        v-model="formItem.countryCode"
                        :codeType="'countrys'"
                        :maxDic="[formItem.countryCode]"
                ></dataTabs>
            </div>
            <el-form :model="seoFrom" ref="variantFrom">
                <el-form-item :label='$i18n.t("common.PageTitle")' prop="title" :label-width="formLabelWidth">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" :placeholder='$i18n.t("common.Pleaseentercontent")'
                              maxlength="500"
                              show-word-limit
                              v-model="seoFrom.title"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Pagekeywords")' prop="keyword" :label-width="formLabelWidth">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" :placeholder='$i18n.t("common.Pleaseentercontent")'
                              maxlength="500"
                              show-word-limit
                              v-model="seoFrom.keyword"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.PageDescription")' prop="description" :label-width="formLabelWidth">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" :placeholder='$i18n.t("common.Pleaseentercontent")'
                              v-model="seoFrom.description"
                              maxlength="1000"
                              show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button style="width: 20%;" @click="closeWindow(false)"> {{ $i18n.t("common.cancel") }}</el-button>
                <el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
            </div>
        </el-dialog>
        <goods
                ref="goods"
                v-if="dialogFormTableVisible2"
                :activityId="formItem.activityId"
                :visible.sync="dialogFormTableVisible2"
                :countryCode="countryCode"
                :brandCode="brandCode"
                :close-on-click-modal="false"
                @close="closeWindow(true)"
        />
        <goods-dialog ref="goodsDialog" v-if="goodsDialogVisible" :close-on-click-modal="false"/>
    </div>
</template>
<script>
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import dataTabs from '@/components/common/dataTabs.vue';
import moment from 'moment';
import goods from './components/goods.vue';
import GoodsDialog from '@/components/common/GoodsDialog.vue';
import listImage from '@/components/common/ListImage.vue';

export default {
    components: {AddUpdateDialog, FormTableDialog, dataSelect, dataText, dataTabs, goods, GoodsDialog, listImage},
    name: 'special',
    data() {
        return {
            formLabelWidth: '120px',
            closeFooter: true,
            isEdit: true,
            //查询项
            searchData: {
                countryCode: '',
                title: '',
                brandCode: '',
                status: '',
                page: 1,
                limit: 10
            },
            seoFrom: {
                title: '',
                keyword: '',
                description: '',
                activityId: ''
            },
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            dialogFormTableVisible: false,
            dialogFormTableVisible2: false,
            dialogSEOVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE,
            countryCode: '',
            brandCode: '',
            goodsDialogVisible: false,
            listloading: false
        };
    },

    created() {
        this.loadlist();
    },
    methods: {
        closeWindow(status) {
            this.dialogSEOVisible = false;
            this.dialogFormVisible = false;
            if (status) {
                this.loadlist();
            }
        },
        renderRuHeader() {
            let WarehousingTime= this.$i18n.t("common.WarehousingTime")
            return (
                    <div>
                        <el-button type="text" size="small" on-click={() => this.showRufilters()}>
                        <span class="ruku">
                            {WarehousingTime} <span class="el-icon-sort"></span>
                        </span>
                        </el-button>
                    </div>
            );
        },
        renderSortHeader() {
            let sort =  this.$i18n.t("common.sort")
            return (
                    <div>
                    <span class="sort-span">
                        sort <span class="el-icon-sort"></span>
                    </span>
                    </div>
            );
        },
        showRufilters() {
            let sortState = 0;
            if (sortState === 0) {
                this.pageData.list = this.pageData.list.sort((a, b) => {
                    return b - a;
                });
                sortState = 1;
            } else {
                this.pageData.list = this.pageData.list.sort((a, b) => {
                    return a - b;
                });
                sortState = 0;
            }
        },
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
        openSEO(row) {
            this.title = this.$i18n.t("common.SpecialSEO")
            this.formItem = Object.assign({}, row);
            this.$store.dispatch('getActivitySeo', {activityId: this.formItem.activityId}).then(res => {
                this.seoFrom = res;
                if (!this.seoFrom) {
                    this.seoFrom = {
                        title: '',
                        keyword: '',
                        description: '',
                        activityId: this.formItem.activityId
                    };
                }
            });
            this.dialogSEOVisible = true;
        },
        handleResetSearch() {
            this.searchData = {
                countryCode: '',
                title: '',
                brandCode: '',
                status: '',
                page: 1,
                limit: 10
            };
            this.loadlist();
        },
        loadlist() {
            this.listloading = true;
            var _this = this;
            this.$store.dispatch('getActivityPage', this.searchData).then(res => {
                _this.pageData = res;
            }).finally(() => {
                this.listloading = false;
            });
        },
        async addGoods(row) {
            this.goodsDialogVisible = true;
            this.$nextTick(async () => {
                if (this.$refs.goodsDialog) {
                    const goodsList = await this.$refs.goodsDialog.open(null, true, row.activityId, row.countryCode, row.brandCode,'DESC',false)
                        .catch(function(error){
                            console.log(error)
                        });
                    if (goodsList && goodsList.length > 0) {
                        this.$store.dispatch('addActivityGoods', {
                            activityId: row.activityId,
                            goodsIds: goodsList.map(x => x.goodsId)
                        }).then(res => {
                            this.loadlist();
                        });
                    }
                    this.goodsDialogVisible = false
                }
            })
        },
        add() {
            this.title = this.$i18n.t("common.Add1");
            this.formItem = {
                activityId: '',
                beginTime: '',
                endTime: '',
                description: '',
                status: true,
                userType: [],
                imgUrl: '',
                activityType: 'Special',
                selectTime: [],
                countryCode: null,
                brandCode: null,
                homeShow: false,
            };
            if (this.$refs['variantFrom'] !== undefined) {
                this.$refs['variantFrom'].resetFields();
            }
            this.isEdit = false;
            this.dialogFormVisible = true;
        },
        edit(row, visible) {
            this.title =  this.$i18n.t("common.edit");
            this.isEdit = true;
            this.formItem = Object.assign({}, row);
            if (this.formItem.beginTime && this.formItem.endTime) {
                let type = 'yyyy-MM-dd hh:mm:ss';
                let beginTime = this.$options.filters['formatDate'](this.formItem.beginTime, type);
                let endTime = this.$options.filters['formatDate'](this.formItem.endTime, type);
                this.formItem.selectTime = [beginTime, endTime];
            }
            this.brandCode = row.brandCode;
            this.countryCode = row.countryCode;
            //处理适用用户类型复选框
            if (this.formItem.userType == 'ALL') {
                this.formItem.userType = ['NEW', 'OLD'];
            } else if (this.formItem.userType == 'NEW') {
                this.formItem.userType = ['NEW'];
            } else if (this.formItem.userType == 'OLD') {
                this.formItem.userType = ['OLD'];
            } else {
                this.formItem.userType = [];
            }
            console.log(this.formItem)
            this[visible] = true;
        },
        del(row) {
            var _this = this;
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                    .then(() => {
                        this.$store.dispatch('delActivity', {id: row.activityId}).then(res => {
                            let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
                            let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                            this.searchData.page = currentPage < 1 ? 1 : currentPage
                            this.$message.success(this.$i18n.t("common.Successfullydeleted"));
                            this.loadlist();
                        });
                    })
                    .catch(() => {
                    });
        },
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        //提交
        onSubmit(formName) {
            console.log(this.seoFrom);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),   this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('updateActivitySeo', this.seoFrom).then(response => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullymodified"),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeWindow(true);
                        });
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped>
.ruku {
    color: #909399;
}

.filter-container /deep/ .el-card__body{
    padding: 10px;
}
</style>
