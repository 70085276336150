<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="180px">
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="false"
                        :placeholder='$i18n.t("common.country")'
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.postCode"
                        :placeholder='$i18n.t("common.PostalCode")'
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >  {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" icon="el-icon-edit" @click="handleCreate">

                {{ $i18n.t("common.Add1") }}

            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" type="index" align="center" width="50px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.country")'  prop="countryCode" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
				    <div>{{$dict.getDictValue('countrys',scope.row.countryCode)}}</div>
				</template>	
            </el-table-column>
            <el-table-column :label='$i18n.t("common.PostalCode")' prop="postCode" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column :label='$i18n.t("common.Addtime")' prop="createTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">  {{ $i18n.t("common.edit") }}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">  {{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addRestricted"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item :required="true"  :label='$i18n.t("common.country")' prop="countryCode">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addRestricted.countryCode"
                        clearable
                        :codeType="'countrys'"
                        :placeholder='$i18n.t("common.country")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item :required="true" :label='$i18n.t("common.PostalCode")' prop="postCode">
                    <el-input v-model="addRestricted.postCode" style="width: 100%"></el-input>
                </el-form-item>
                <!-- </div> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="handleSubmit()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import X2JS from 'x2js'; //解析xml

function initAddBean() {
    const addBean = {
        areaId: null,
        countryCode: null,
        postCode: null
    };
    return addBean;
}

export default {
    name: 'RestrictedArea',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error( this.$i18n.t("common.Pleaseselectatimerange")));
            }
        };
        var isTrue = (rule, value, callback) => {
            var pattern = /^(([\*])|(\w{1,8}[\*]?))$/;
            if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.Pleaseenterthepostalcodecorrectly")));
            } else {
                return callback();
            }
        };
        return {
            formTitle: '',
            old: initAddBean(),
            addRestricted: initAddBean(),
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            query: {
                page: 1,
                limit: 10,
                postCode: null,
                countryCode:null,
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,

            countryLang: [],
            //字典取的语言
            langs: [],
            formRules: {
                postCode: [
                    { required: true, message: this.$i18n.t("common.Pleasefillinthepostalcode"), trigger: 'blur' },
                    { validator: isTrue, trigger: 'blur'}
                ],
                countryCode: [
                    { required: true, message: this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }
                ]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            this.listLoading = true;
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }

            this.$store.dispatch('getRestrictedPage', this.query).then((res) => {
                this.list = res;
            }).finally(() => {
                this.listLoading = false;
            });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteRestricted', { id: row.areaId }).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message:   this.$i18n.t("common.Successfullydeleted"),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle =   this.$i18n.t("common.edit");
            this.old = row;
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addRestricted[key] = this.old[key];
            }
            this.addVisible = true;
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.addRestricted = initAddBean();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.addRestricted = initAddBean();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle =   this.$i18n.t("common.Add1");
            this.resetForm();
            this.addVisible = true;
            //默认选中
            this.addRestricted.countryCode = 'ES';
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {

            console.log(this.addRestricted);

            if (this.formTitle ==   this.$i18n.t("common.Add1")) {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addRestricted', this.addRestricted).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullysaved"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.Savefailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.addRestricted);
                        this.$store.dispatch('editRestricted', this.addRestricted).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message:this.$i18n.t("common.Successfullyedited"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message:this.$i18n.t("common.Editingfailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query.postCode = null;
            this.query.countryCode = null;
            this.doQueryList({ page: 1 });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
