<template>
	<div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :defaultListQuery="defaultListQuery" :dictionary="dictionary" :placeholder="placeholder" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 20px 0px 20px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">{{ $i18n.t('common.Add') }}</el-button>
		</div>
		<el-table v-loading="listloading" ref="pagedata" :data="pageData.list" style="width: 100%"  height="calc(100% - 185px)" border :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
			<el-table-column prop="goodsClassifyRelatedPrivatelyId" :label="$i18n.t('common.PrimaryKeyID')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="goodClassifyId" :label="$i18n.t('common.CatalogID')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="title" :label="$i18n.t('common.Categoryname1')" align="center" show-overflow-tooltip></el-table-column>			
			<!--
      <el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>
			-->
			<!-- <el-table-column prop="platforms" label="显示平台" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-for="s in scope.row.platforms" :key="s"> {{ s }}<br /> </span>
				</template>
			</el-table-column> -->
			<el-table-column prop="createTime" :label="$i18n.t('common.Addtime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.createTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="updateTime" :label="$i18n.t('common.Enableornot')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<el-switch
						@change="updateStatus(scope.row)"
						v-model="scope.row.status1"
						active-color="#13ce66"
						inactive-color="#ff4949">
					</el-switch>
				</template>			
			</el-table-column>
            <el-table-column prop="opByName" :label="$i18n.t('common.Operator')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="opTime" :label='$i18n.t("common.Updatetime")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.opTime | formatDate}}</span>
                </template>
            </el-table-column>
			<el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right">
				<template slot-scope="scope">
					<el-button @click="handleSearchList2(scope.row)" type="text" size="small">{{ $i18n.t('common.Relatedcategories') }}</el-button>
					<el-button @click="deletePrivately(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{ $i18n.t('common.delete') }}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
				:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
			</el-pagination>
		</div>
		<div class="block">
		<el-dialog :title="$i18n.t('common.Add')" :visible.sync="addRelationVisible" @close="closeRelation" width="35%" append-to-body>
			<div v-if="addRelationVisible">
				<el-form ref="form" :model="addRelationForm" label-width="120px">
					<el-form-item :label="$i18n.t('common.Selectmaincategory')">
					  <!-- <el-tree ref="tree"
					  	:data="treeData"
					  	check-strictly 
					  	show-checkbox node-key="module_id" 
					  	:props="defaultProps"
					  >
					  </el-tree> -->
					  <el-tree :props="defaultProps" :data="treeData" node-key="goodsClassifyId" ref="treeForm"
						accordion  :expand-on-click-node="false">
							<span class="show-hide custom-tree-node" slot-scope="{ node, data }" >
								<span>
										<el-radio v-model="checkedKeys" :label="data.goodsClassifyId"><br></el-radio>
										{{ node.label }}
								</span>
							</span>
						</el-tree>
					</el-form-item>
					<el-form-item :label="$i18n.t('common.Enableornot')">
						<el-switch
							v-model="addRelationStatus"
							active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
					</el-form-item>
				</el-form>					
				<span slot="footer" class="dialog-footer">
					<el-button style="width: 20%;" @click="closeRelation">{{ $i18n.t('common.cancel') }}</el-button>
					<el-button style="width: 20%;" type="primary" @click="postCheckedKeys">{{ $i18n.t('common.confirm1') }}</el-button>
				</span>
			</div>
			
		</el-dialog>	
		</div>
		<el-dialog :title="isEdit?$i18n.t('common.edit'):$i18n.t('common.Add1')" :before-close="closeDialog" :visible.sync="dialogCategoryVisible" :close-on-click-modal="false">
			<div v-if="dialogCategoryVisible">
				<category-dialog :pageData="pageData2" :treeData="treeData" :rowInfo="rowInfo" @closeDialog="closeDialog"/>
			</div>    
		</el-dialog>
	</div>
</template>
<script>
	import config from '@/utils/config';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import CategoryDialog from './components/CategoryDialog.vue';
    import dictionaryUtil from '@/utils/dictionaryUtil';
	const defaultDictionary={
		brandCode:dictionaryUtil.getDict('brand')
	};
	export default {
		components: {
			FilterContainer,
			CategoryDialog
		},
		name: 'variant',
		data() {
			return {
				module_code:config.module_code.categoryAssociation,
				checkedKeys:"",
				listQuery:{
					"relatedClassifyId": '',
					"title": "",
					//"brandCode": "",
					"page": 1,
					"limit": 10
				},
				listQuery2:{
					"relatedClassifyId": '',
					"title": "",
					//"brandCode": "",
					"page": 1,
					"limit": 10
				},
				defaultListQuery:{
				  "relatedClassifyId": "",
				  "title": "",
				  //"brandCode": "",
				  "page": 1,
				  "limit": 10
				},
				treeData:[],
				defaultProps:{
					label(data, node) {
					  return data.title
					},
					id(data, node) {
					  return data.goodsClassifyId
					},
					children(data, node) {
					  return data.children
					}
				},
				goodsClassifyRelatedPrivatelyId:"",
				addRelationForm:{
					goodClassifyId:"",
					relatedClassifyId:""
				},
				//新增主类目的状态
				addRelationStatus:true,	   
				//组件标签是否显示
				arg:{
					name:true,
					//brand:true
				},
				placeholder:{
					name:this.$i18n.t('common.Categoryname1'),
					date:{
						start:this.$i18n.t('common.Updatetime'),
						end:this.$i18n.t('common.Updatetime')
					}
				},
				dictionary:Object.assign({}, defaultDictionary),
				isEdit:false,
				pageData: {},
				pageData2: {},
				ossSignature: {
					host: ''
				},
				dialogCategoryVisible:false,
				addRelationVisible:false,
				fullscreenLoading: false,
				goodsHomeRecomandTableLoading: false,
				listloading: false
			};
		},
		mounted() {
			this.handleSearchList()
			this.getTreeList()
		},
		methods: {
			//搜索列表
			handleSearchList(listQuery){
				this.listloading = true;
				if(listQuery){
					this.listQuery.page=1
					this.listQuery={
						"title": listQuery.title,
						//"brandCode": listQuery.brand,
						"page": this.listQuery.page,
						"limit": this.listQuery.limit
					}				
				}				
				this.$store
					.dispatch('getCategoryAssociation', this.listQuery)
					.then(res => {					
						this.pageData=res
						this.pageData.list.forEach((item,index)=>{
							//bannerType
							if(item.status=='0'){
								this.pageData.list[index].status1=false
							}else if(item.status=='1'){
								this.pageData.list[index].status1=true						
							}
						})
						
					}).finally(() => {
					this.listloading = false;
				});
			},
			handleCurrentChange(val) {
				this.listQuery.page = val;
				this.handleSearchList();
			},
			handleSearchList2(row){
				this.dialogCategoryVisible=true
				this.rowInfo=row
			},
			handleResetSearch(){
				this.listQuery = Object.assign({}, this.defaultListQuery);
				this.handleSearchList()
			},
			closeRelation(){
				this.addRelationStatus=true
				this.addRelationVisible = false
			},
			//搜索列表
			getTreeList(){			
				this.$store
					.dispatch('getClassTree',{})
					.then(res => {				
						// let arr=[]
						// res.forEach((item,index)=>{
						// 	arr.push({
						// 		id: item.goodsClassifyId,
						// 		label: item.title,
						// 		children:item.children
						// 	})					
						// })
						// arr.forEach((item,index)=>{
						// 	item.children.forEach((item2,index2)=>{
						// 		arr[index].children[index2]={
						// 			id: item2.goodsClassifyId,
						// 			label: item2.title
						// 		}
						// 	})
						// })
						this.treeData=res
					});
			},
			//打开新增
			add(){
				this.isEdit=false
				this.addRelationVisible=true
			},
			closeDialog(){
				this.dialogCategoryVisible=false
			},
			//新增主类目
			postCheckedKeys(){
				if(!this.checkedKeys){
					this.$message({
						  message: this.$i18n.t('common.Pleaseselectacategoryfirst1'),
						  type: 'warning'
					});
					return					
				}
				this.$store.dispatch('postPrivately', {
					status:this.addRelationStatus==true?1:0,
					goodClassifyId:this.checkedKeys,
					relatedClassifyId:"0"
				}).then(res => {
					if(res==""){
						this.$message({
							message: this.$i18n.t('common.Successfullyadded'),
							type: 'success'
						});
					}
					this.addRelationVisible=false
					this.handleSearchList()
				}).catch((err) => {
						this.$message({
							type: 'info',
							message: err.message
						});
				});					
			},
			updateStatus(row){
				let status=""
				row.status=="0"?status="1":status="0"
				this.$store.dispatch('statusPUT', {
					status,
					id:row.goodsClassifyRelatedPrivatelyId,
				}).then(res => {
					this.handleSearchList()
				})
			},
			deletePrivately(row){
				this.$confirm(this.$i18n.t('common.confirmdeletion')+`?`, this.$i18n.t('common.prompt'), {
						confirmButtonText: this.$i18n.t('common.confirm1'),
						cancelButtonText: this.$i18n.t('common.cancel'),
						type: 'warning'
					})
					.then(() => {
						this.$store.dispatch('deletePrivately', {
							id:row.goodsClassifyRelatedPrivatelyId,
						}).then(res => {
							this.handleSearchList()
						})
					})		
			},
			deletegoodsHomeRecomand(row) {
				this.$confirm(this.$i18n.t('common.confirmdeletion')+`?`, this.$i18n.t('common.prompt'), {
						confirmButtonText: this.$i18n.t('common.confirm1'),
						cancelButtonText: this.$i18n.t('common.cancel'),
						type: 'warning'
					})
					.then(() => {
						this.$store.dispatch('deleteGoodsHomeRecomand', {
							recomandId: row.recomandId
						}).then(res => {
							this.getTableList();
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: this.$i18n.t('common.Cancelleddeletion')
						});
					});
			}
		},
		updated() {}
	};

	function getElementTop(element) {
		var actualTop = element.offsetTop;
		var current = element.offsetParent;

		while (current !== null) {
			actualTop += current.offsetTop;
			current = current.offsetParent;
		}
		return actualTop;
	}
</script>
<style type="text/css">
	.node-title {
	  display: inline-block;
	  width: 70%;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  margin-left: 6px;
	}
	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}
</style>
