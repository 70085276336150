<template>
    <div>
        <el-dialog
            :title='$i18n.t("common.Nationalconfiguration")'
            :visible.sync="dialogFormVisible"
            width="1000px"
            height="100%"
            overflow="auto"
            :close-on-click-modal="false"
        >
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="item in editableTabs" :key="item.title" :label="item.title" :name="item.name"> </el-tab-pane>
            </el-tabs>
            <el-form :model="form" :rules="formRules" ref="dataForm">
                <el-form-item :label='$i18n.t("common.WebsiteAddress")' prop="url">
                    <el-input show-word-limit maxlength="200" v-model="form.url" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Contactinformation")'  prop="contact">
                    <el-input show-word-limit maxlength="500" type="textarea" v-model="form.contact" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Customerserviceemail")' prop="email">
                    <el-input show-word-limit maxlength="50" v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Paymentmethod")' prop="payMethodList">
                    <el-checkbox-group v-model="payMethodList">
                        <el-checkbox v-for="item in payMethod" :key="item.name" :label="item.code"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Socialmedia")' border>
                    <el-button type="primary" @click="openSocial()">      {{ $i18n.t("common.Add1") }}</el-button>

                    <el-table :data="form.countryParameterSocialList" style="width: 100%">
                        <el-table-column prop="countryParameterId" :label='$i18n.t("common.Serialnumber")' width="150px"> </el-table-column>
                        <el-table-column prop="name"  :label='$i18n.t("common.name")'  width="120px"> </el-table-column>
                        <el-table-column prop="icon" :label='$i18n.t("common.icon")' width="120px">
                            <template slot-scope="scope">
                                <el-image style="width: 150px; height: 150px" :src="ossHost + scope.row.icon" fit="fit"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="url" :label='$i18n.t("common.link")' width="120px"> </el-table-column>
                        <el-table-column prop="sort" :label='$i18n.t("common.sort")' width="300px">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.$index != 0"
                                    type="text"
                                    size="medium"
                                    @click="moveUp(scope.$index, scope.row)"
                                    class="table-inner-button"
                                    >⬆</el-button
                                >
                                <el-button
                                    v-if="scope.$index != countryParameterList[activeName].countryParameterSocialList.length - 1"
                                    type="text"
                                    size="medium"
                                    @click="moveDown(scope.$index, scope.row)"
                                    class="table-inner-button"
                                    >⬇</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.operation")' width="100px">
                            <template slot-scope="scope">
                                <el-button type="text" @click="editCountryParmaeterSocial(scope.row)" size="small">{{ $i18n.t("common.edit") }}</el-button>
                                <el-button type="text" @click="deleteCountryParmaeterSocial(scope.row)" size="small">{{ $i18n.t("common.delete") }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false"> {{ $i18n.t("common.cancel") }}</el-button>
                <el-button type="primary" @click="updateCountryParameter()"> {{ $i18n.t("common.confirm") }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title='$i18n.t("common.Socialmediainformation") ' :visible.sync="socialVisible"
                   :close-on-click-modal="false" v-if="socialVisible">
            <el-form :model="socialForm" :rules="rules" ref="socialForm">
                <el-form-item  :label='$i18n.t("common.name")'  prop="name">
                    <el-input show-word-limit maxlength="20" v-model="socialForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.link")' prop="url">
                    <el-input show-word-limit maxlength="200" v-model="socialForm.url" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.icon")'>
                    <el-col :span="8">
                        <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl" class="imgUpload"></imgUpload>
                    </el-col>
                    <el-col :span="10" style="margin-top: 120px">
                        <span>{{ $i18n.t("common.Suggestedsize") }}:100*100 <=1MB</span>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="socialVisible = false"> {{ $i18n.t("common.cancel") }}</el-button>
                <el-button type="primary" @click="addSocial(socialForm)"> {{ $i18n.t("common.confirm") }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import imgUpload from '@/views/country/imgUpload';
export default {
    components: {
        imgUpload
    },
    data() {
        var payMethodList = (rule, value, callback) => {
            if (this.payMethodList.length == 0) {
                callback(new Error(this.$i18n.t("common.Pleasechooseapaymentmethod") ));
            } else {
                callback();
            }
        };
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            dialogFormVisible: false,
            socialVisible: false,
            form: {},
            activeName: '0',
            countryParameterList: [],
            editableTabs: [],
            payMethod: [],
            payMethodList: [],
            socialForm: {
                name: '',
                url: ''
            },
            countryId: '',

            imageUrl: '',
            rules: {
                name: [
                    { required: true, message: this.$i18n.t("common.Pleaseenteraname") , trigger: 'blur' },
                    { min: 0, max: 20, message:  this.$i18n.t("common.Lengthgreaterthan20characters"), trigger: 'blur' }
                ],
                url: [
                    { required: true, message:  this.$i18n.t("common.Pleaseenteralink"), trigger: 'blur' },
                    { min: 0, max: 100, message: this.$i18n.t("common.Lengthgreaterthan100characters"), trigger: 'blur' }
                ]
            },
            formRules: {
                contact: [{ required: true, message:this.$i18n.t("common.Pleaseenteraname"), trigger: 'blur' }],
                payMethodList: [{ validator: payMethodList, trigger: 'change' }]
            }
        };
    },

    methods: {
        editCountryParmaeterSocial(row) {
            this.socialForm.name = row.name;
            this.socialForm.url = row.url;
            this.socialForm.icon = row.icon;
            this.imageUrl = row.icon;
            this.socialVisible = true;
            console.log(row);
            this.socialForm.countryParameterSocialId = row.countryParameterSocialId;
        },
        changeUrl(data) {
            this.socialForm.icon = data;
        },
        deleteCountryParmaeterSocial(row) {
            let params = {
                id: row.countryParameterSocialId
            };
            this.$store.dispatch('deleteCountryParameterSocial', params).then((res) => {
                this.$message.success(this.$i18n.t("common.Successfullydeletedinformation"));
                this.restOpen();
            });
        },
        addSocial(socialForm) {
            console.log(this.socialForm);
            if (this.socialForm.icon == undefined) {
                this.$message({
                    showClose: false,
                    message: this.$i18n.t("common.Iconmustbeuploaded"),
                    type: 'warning'
                });
                return;
            }
            //链接校验
            var urlTest =
                /^((https?|ftp):\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#@=~_-]+))*$/;
            if (!urlTest.test(this.socialForm.url)) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseenterthelinkcorrectly"),
                    type: 'warning'
                });
                return;
            } else {
                //http协议拼接
                if (!this.socialForm.url.startsWith('http')) {
                    var text1 = 'https://';
                    this.socialForm.url = text1.concat('', this.socialForm.url);
                }
            }

            this.$refs.socialForm.validate((valid) => {
                if (valid) {
                    if (this.socialForm.countryParameterSocialId == null) {
                        this.socialForm.countryParameterId = this.countryParameterList[this.activeName].countryParameterId;
                        if (this.countryParameterList[this.activeName].countryParameterSocialList == null) {
                            this.socialForm.sort = 0;
                        } else {
                            this.socialForm.sort = this.countryParameterList[this.activeName].countryParameterSocialList.length;
                        }

                        this.$store.dispatch('addCountryParamSocial', this.socialForm).then((res) => {
                            this.$message.success(this.$i18n.t("common.Successfullyaddedinformation"));
                            this.listSocial(this.socialForm.countryParameterId);
                        });
                    } else {
                        this.socialForm.countryParameterId = this.countryParameterList[this.activeName].countryParameterId;
                        this.$store.dispatch('updateCountryParameterSocial', this.socialForm).then((res) => {
                            this.$message.success(this.$i18n.t("common.Successfullyeditedinformation"));
                            this.listSocial(this.socialForm.countryParameterId);
                        });
                    }
                }
            });
        },
        listSocial(countryParameterId) {
            this.$store.dispatch('listCountryParamSocial', { id: countryParameterId }).then((res) => {
                this.form.countryParameterSocialList = res;
                this.socialVisible = false;
            });
        },
        restOpen() {
            this.$emit('restOpen', this.countryId);
        },
        openSocial() {
            this.imageUrl = '';
            this.socialForm = {};
            this.socialForm.countryParameterSocialId = null;
            this.socialVisible = true;
        },
        updateCountryParameter() {
            console.log(this.countryParameterList);
            console.log(this.payMethodList);

            //链接校验
            var urlTest =
                /^((https?|ftp):\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
            if (!urlTest.test(this.countryParameterList[0].url)) {
                this.$message({
                    message:this.$i18n.t("common.Pleaseenterthelinkcorrectly"),
                    type: 'warning'
                });
                return;
            } else {
                //http协议拼接
                if (!this.countryParameterList[0].url.startsWith('http')) {
                    var text1 = 'https://';
                    this.countryParameterList[0].url = text1.concat('', this.countryParameterList[0].url);
                }
            }

            //邮箱校验
            var emailTest = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
            if (!emailTest.test(this.countryParameterList[0].email)) {
                this.$message({
                    message:this.$i18n.t("common.Pleaseentertheemailcorrectly"),
                    type: 'warning'
                });
                return;
            }
            this.form.payMethodList = this.payMethodList;
            this.$refs['dataForm'].validate().then((validate) => {
                if (validate) {
                    this.$store.dispatch('updateCountryParams', this.countryParameterList).then((res) => {
                        this.$message.success(this.$i18n.t("common.Successfullyeditedinformation"));
                        this.dialogFormVisible = false;
                    });
                }
            });
        },
        handleClick(tab, event) {
            this.form = this.countryParameterList[this.activeName];
            this.payMethodList = this.countryParameterList[this.activeName].payMethodList;
        },
        moveUp(index, row) {
            console.log(index);
            if (index > 0) {
                this.$store.dispatch('moveUp', { id: row.countryParameterSocialId }).then((res) => {
                    this.$message.success(this.$i18n.t("common.Successfullyedited"));
                    this.listSocial(row.countryParameterId);
                });
            } else {
                this.$message({
                    message:this.$i18n.t("common.Thisisalreadythefirstitemmoveupfailed"),
                    type: 'warning'
                });
            }
        },
        moveDown(index, row) {
            if (index + 1 == this.countryParameterList[this.activeName].countryParameterSocialList.length) {
                this.$message({
                    message:this.$i18n.t("common.Thisisalreadythelastitemmovedownfailed"),
                    type: 'warning'
                });
            } else {
                this.$store.dispatch('moveDown', { id: row.countryParameterSocialId }).then((res) => {
                    this.$message.success(this.$i18n.t("common.Successfullyedited"));
                    this.listSocial(row.countryParameterId);
                });
            }
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'pay_method' }).then((res) => {
                _this.payMethod = res;
                console.log(res);
            });
        }
    },
    mounted() {
        this.getLangDictItems();
    }
};
</script>


<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
