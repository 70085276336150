var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "180px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t(
                        "common.PromotionalActivityNameID"
                      ),
                      oninput: "value=value.replace(/[%]/g,'')"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.promotionExtendSearchText,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "promotionExtendSearchText", $$v)
                      },
                      expression: "query.promotionExtendSearchText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Enableornot"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.yes"), value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t(
                        "common.ProductPromotionBarcode"
                      ),
                      oninput: "value=value.replace(/[%]/g,'')"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.promotionSearchText,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "promotionSearchText", $$v)
                      },
                      expression: "query.promotionSearchText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      codeType: "activity_status",
                      placeholder: _vm.$i18n.t("common.Activitystatus")
                    },
                    model: {
                      value: _vm.query.activityStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "activityStatus", $$v)
                      },
                      expression: "query.activityStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": _vm.$i18n.t("common.to"),
                      "start-placeholder": _vm.$i18n.t("common.Startdate"),
                      "end-placeholder": _vm.$i18n.t("common.Enddate"),
                      "picker-options": _vm.pickerOptions,
                      "default-time": ["00:00:00", "23:59:59"]
                    },
                    model: {
                      value: _vm.createPicker,
                      callback: function($$v) {
                        _vm.createPicker = $$v
                      },
                      expression: "createPicker"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$i18n.t("common.query")) +
                          "\n                "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: { click: _vm.handleAdd }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Add1")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list.list, border: "", stripe: "" },
          on: { "cell-dblclick": _vm.copyText }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              align: "center",
              "show-overflow-tooltip": "",
              prop: "promotionExtendId"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.PromotionalActivityName"),
              prop: "name",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ProductPromotionIDCopy"),
              prop: "promotionIds",
              align: "center",
              "show-overflow-tooltip": "",
              width: "170px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.handleLongString(row.promotionIds)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ProductBarcode"),
              prop: "barCodes",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.handleLongString(row.barCodes)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$i18n.t("common.EnabledStatus"),
              "show-overflow-tooltip": "",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: "" },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Activitystatus"),
              prop: "activityStatus",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.activityStatus == "NotStarted"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.begininaminute")))
                        ])
                      : _vm._e(),
                    row.activityStatus == "UnderWay"
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Inprogress")))
                        ])
                      : _vm._e(),
                    row.activityStatus == "Complete"
                      ? _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Ended")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.starttime"),
              prop: "beginTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Endtime"),
              prop: "endTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              formatter: _vm.formatDate,
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$i18n.t("common.edit"))
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteSingle(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$i18n.t("common.delete"))
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.addVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Selectproduct"),
                        "label-width": "150px"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.selectPromotion()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "common.SelectPromotionalActivity"
                                  )
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "umar-b10 upload-btn",
                              attrs: { type: "primary" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$i18n.t("common.ImportFile")) +
                                  "\n                        "
                              ),
                              _c("div", { attrs: { id: "clone_input" } }, [
                                _c("input", {
                                  ref: "file",
                                  staticClass: "upload-btn-file",
                                  attrs: { type: "file" },
                                  on: { change: _vm.importClick }
                                })
                              ])
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { plain: "" },
                              on: {
                                click: function($event) {
                                  return _vm.downloadImportTpl()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("common.DownloadImportTemplate"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.promotionVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.promotionTitle,
                visible: _vm.promotionVisible,
                "close-on-click-modal": false,
                width: "1000px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.promotionVisible = $event
                },
                close: function($event) {
                  return _vm.closePromotionForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.promotionQuery,
                    inline: true,
                    size: "small",
                    "label-width": "180px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$i18n.t(
                            "common.PromotionName_ProductID_Barcode"
                          ),
                          oninput: "value=value.replace(/[%]/g,'')"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handlePromotionQuery.apply(
                              null,
                              arguments
                            )
                          }
                        },
                        model: {
                          value: _vm.promotionQuery.title,
                          callback: function($$v) {
                            _vm.$set(_vm.promotionQuery, "title", $$v)
                          },
                          expression: "promotionQuery.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            multiple: true,
                            placeholder: _vm.$i18n.t("common.PromotionCountry")
                          },
                          model: {
                            value: _vm.promotionQuery.countryCodes,
                            callback: function($$v) {
                              _vm.$set(_vm.promotionQuery, "countryCodes", $$v)
                            },
                            expression: "promotionQuery.countryCodes"
                          }
                        },
                        _vm._l(_vm.countrysDicts, function(cc) {
                          return _c("el-option", {
                            key: cc.code,
                            attrs: {
                              label: cc.name + "(" + cc.code + ")",
                              value: cc.code
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "activity_status",
                          placeholder: _vm.$i18n.t("common.Activitystatus")
                        },
                        model: {
                          value: _vm.promotionQuery.activityStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.promotionQuery, "activityStatus", $$v)
                          },
                          expression: "promotionQuery.activityStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.Enableornot"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.promotionQuery.enableStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.promotionQuery, "enableStatus", $$v)
                            },
                            expression: "promotionQuery.enableStatus"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t(
                                "common.NewUserPromotionEnabled"
                              ),
                              value: "1"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t(
                                "common.NewUserPromotionDisabled"
                              ),
                              value: "2"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t(
                                "common.OldUserPromotionEnabled"
                              ),
                              value: "3"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t(
                                "common.OldUserPromotionDisabled"
                              ),
                              value: "4"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "180px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.Isitaflashkill"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.promotionQuery.seckill,
                            callback: function($$v) {
                              _vm.$set(_vm.promotionQuery, "seckill", $$v)
                            },
                            expression: "promotionQuery.seckill"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Yes"),
                              value: "true"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.No"),
                              value: "false"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.handlePromotionQuery }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.query")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.cleanPromotionQuery }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "tableData",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.pageData.list,
                    border: "",
                    "row-key": "promotionId"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", "reserve-selection": true }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "promotionId",
                      label: "ID",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: _vm.$t("common.PromotionName"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "EAN",
                      label: _vm.$t("common.barcode"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "EAN: " +
                                    _vm._s(
                                      scope.row.goodsMergeListMgrDto.barCode
                                    ) +
                                    " "
                                ),
                                _c("br")
                              ]),
                              _c("span", [
                                _vm._v(
                                  "SKU: " +
                                    _vm._s(scope.row.goodsMergeListMgrDto.sku) +
                                    " "
                                ),
                                _c("br")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4005182050
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bannerFiles",
                      label: _vm.$t("common.Productimage"),
                      align: "center",
                      width: "130px"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("listImage", {
                                attrs: {
                                  imgUrl:
                                    scope.row.goodsMergeListMgrDto.imageUrl
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3461517227
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsMergeListMgrDto.title",
                      label: _vm.$t("common.Productname1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "theme",
                      label: _vm.$t("common.PromotionCountry"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(
                              _vm.distinctPromotionCountry(
                                scope.row.promotionRuleCountrys
                              ),
                              function(s, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "countrys",
                                          s.countryCode
                                        )
                                      )
                                  ),
                                  _c("br")
                                ])
                              }
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      2406961583
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: _vm.$t("common.EnabledStatus"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("common.NewUserPromotion")) +
                                    ":\n                        "
                                ),
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: scope.row.newUserStatus
                                        ? "green"
                                        : "red"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          scope.row.newUserStatus
                                            ? _vm.$t("common.Enable")
                                            : _vm.$t("common.Disabled")
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("br")
                              ]),
                              _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t("common.RegularUserPromotion")
                                    ) +
                                    ":\n                        "
                                ),
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: scope.row.status ? "green" : "red"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          scope.row.status == 1
                                            ? _vm.$t("common.Enable")
                                            : _vm.$t("common.Disabled")
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("br")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      453476195
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateName",
                      label: _vm.$t("common.Operator"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: _vm.$t("common.OperatingTime"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(scope.row.updateTime)
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4056194380
                    )
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      background: "",
                      "current-page": _vm.promotionQuery.page,
                      "page-size": _vm.promotionQuery.size,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.pageData.total
                    },
                    on: { "current-change": _vm.handleCurrentPromotionChange }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "large",
                        "margin-left": "75%"
                      }
                    },
                    [_vm._v("已选择：" + _vm._s(_vm.multipleSelection.length))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.submitPromotion()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.updateVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.updateVisible,
                "close-on-click-modal": false,
                width: "1000px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.updateVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.addPromotionExtend,
                    "label-position": "left",
                    size: "small",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      key: "name",
                      attrs: {
                        required: true,
                        label: _vm.$i18n.t("common.PromotionalCollectionName"),
                        prop: "name"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.addPromotionExtend.name,
                          callback: function($$v) {
                            _vm.$set(_vm.addPromotionExtend, "name", $$v)
                          },
                          expression: "addPromotionExtend.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: true,
                        label: _vm.$i18n.t("common.SelectPromotionalList")
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.selectPromotion()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.SelectPromotionalList"))
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.selectData.length > 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.SelectedPromotionalActivity"
                            )
                          }
                        },
                        [
                          _c(
                            "el-table",
                            { attrs: { data: _vm.selectData, border: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "promotionId",
                                  label: "ID",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "title",
                                  label: _vm.$t("common.PromotionName"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "EAN",
                                  label: _vm.$t("common.barcode"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "EAN: " +
                                                _vm._s(
                                                  scope.row.goodsMergeListMgrDto
                                                    .barCode
                                                ) +
                                                " "
                                            ),
                                            _c("br")
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "SKU: " +
                                                _vm._s(
                                                  scope.row.goodsMergeListMgrDto
                                                    .sku
                                                ) +
                                                " "
                                            ),
                                            _c("br")
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4005182050
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bannerFiles",
                                  label: _vm.$t("common.Productimage"),
                                  align: "center",
                                  width: "130px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("listImage", {
                                            attrs: {
                                              imgUrl:
                                                scope.row.goodsMergeListMgrDto
                                                  .imageUrl
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3461517227
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "goodsMergeListMgrDto.title",
                                  label: _vm.$t("common.Productname1"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "theme",
                                  label: _vm.$t("common.PromotionCountry"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return _vm._l(
                                          _vm.distinctPromotionCountry(
                                            scope.row.promotionRuleCountrys
                                          ),
                                          function(s, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$dict.getDictValue(
                                                      "countrys",
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          }
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  442249647
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "status",
                                  label: _vm.$t("common.EnabledStatus"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common.NewUserPromotion"
                                                  )
                                                ) +
                                                ":\n                                "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  color: scope.row.newUserStatus
                                                    ? "green"
                                                    : "red"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      scope.row.newUserStatus
                                                        ? _vm.$t(
                                                            "common.Enable"
                                                          )
                                                        : _vm.$t(
                                                            "common.Disabled"
                                                          )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("br")
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common.RegularUserPromotion"
                                                  )
                                                ) +
                                                ":\n                                "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  color: scope.row.status
                                                    ? "green"
                                                    : "red"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      scope.row.status == 1
                                                        ? _vm.$t(
                                                            "common.Enable"
                                                          )
                                                        : _vm.$t(
                                                            "common.Disabled"
                                                          )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("br")
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1849298275
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "updateName",
                                  label: _vm.$t("common.Operator"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "updateTime",
                                  label: _vm.$t("common.OperatingTime"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.updateTime
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4056194380
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$i18n.t("common.operation"),
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "table-inner-button",
                                              attrs: {
                                                type: "danger",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleDeletePromotion(
                                                    row
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$i18n.t("common.delete")
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  731629196
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Enableornot"),
                        prop: "enableStatus"
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949"
                        },
                        model: {
                          value: _vm.addPromotionExtend.status,
                          callback: function($$v) {
                            _vm.$set(_vm.addPromotionExtend, "status", $$v)
                          },
                          expression: "addPromotionExtend.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Promotiontime"),
                        prop: "startTime"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t("common.starttime"),
                          "end-placeholder": _vm.$i18n.t("common.Endtime"),
                          "picker-options": _vm.pickerOptions,
                          "default-time": ["00:00:00", "23:59:59"]
                        },
                        model: {
                          value: _vm.valuePicker,
                          callback: function($$v) {
                            _vm.valuePicker = $$v
                          },
                          expression: "valuePicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$i18n.t("common.TypesPerOrder")) +
                        ":1\n            "
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }