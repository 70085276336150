<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="180px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.promotionExtendSearchText"
                        :placeholder="$i18n.t('common.PromotionalActivityNameID')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        :placeholder="$i18n.t('common.Enableornot')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.promotionSearchText"
                        :placeholder="$i18n.t('common.ProductPromotionBarcode')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.activityStatus"
                        clearable
                        :codeType="'activity_status'"
                        :placeholder="$i18n.t('common.Activitystatus')"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="createPicker"
                        type="datetimerange"
                        :range-separator="$i18n.t('common.to')"
                        :start-placeholder="$i18n.t('common.Startdate')"
                        :end-placeholder="$i18n.t('common.Enddate')"
                        :picker-options="pickerOptions"
                        :default-time="['00:00:00', '23:59:59']"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        {{ $i18n.t('common.query') }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                        {{ $i18n.t('common.Reset') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" icon="el-icon-edit" @click="handleAdd">
                {{ $i18n.t('common.Add1') }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px" @cell-dblclick="copyText">
            <el-table-column label="ID" align="center" show-overflow-tooltip prop="promotionExtendId"> </el-table-column>
            <el-table-column :label="$i18n.t('common.PromotionalActivityName')" prop="name" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('common.ProductPromotionIDCopy')"
                prop="promotionIds"
                align="center"
                show-overflow-tooltip
                width="170px"
            >
                <template slot-scope="{ row }">
                    <span>{{ handleLongString(row.promotionIds) }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.ProductBarcode')" prop="barCodes" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span>{{ handleLongString(row.barCodes) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" :label="$i18n.t('common.EnabledStatus')" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Activitystatus')" prop="activityStatus" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.activityStatus == 'NotStarted'" style="color: red">{{ $i18n.t('common.begininaminute') }}</span>
                    <span v-if="row.activityStatus == 'UnderWay'" style="color: green">{{ $i18n.t('common.Inprogress') }}</span>
                    <span v-if="row.activityStatus == 'Complete'" style="color: gray">{{ $i18n.t('common.Ended') }}</span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('common.starttime')"
                prop="beginTime"
                align="center"
                show-overflow-tooltip
                :formatter="formatDate"
            >
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Endtime')" prop="endTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                :label="$i18n.t('common.Updatetime')"
                align="center"
                :formatter="formatDate"
                show-overflow-tooltip
            ></el-table-column>

            <el-table-column prop="updateName" :label="$i18n.t('common.Operator')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">
                        {{ $i18n.t('common.edit') }}</el-button
                    >
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">
                        {{ $i18n.t('common.delete') }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 添加表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form>
                <el-form-item :label="$i18n.t('common.Selectproduct')" label-width="150px">
                    <div>
                        <el-button type="success" @click="selectPromotion()">{{ $i18n.t('common.SelectPromotionalActivity') }}</el-button>
                        <el-button class="umar-b10 upload-btn" type="primary"
                            >{{ $i18n.t('common.ImportFile') }}
                            <div id="clone_input">
                                <input class="upload-btn-file" type="file" ref="file" @change="importClick" />
                            </div>
                        </el-button>
                        <el-button @click="downloadImportTpl()" plain>{{ $t('common.DownloadImportTemplate') }}</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 选择促销活动 -->
        <el-dialog
            :title="promotionTitle"
            :visible.sync="promotionVisible"
            @close="closePromotionForm()"
            v-if="promotionVisible"
            :close-on-click-modal="false"
            width="1000px"
        >
            <el-form :model="promotionQuery" :inline="true" size="small" label-width="180px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="promotionQuery.title"
                        :placeholder="$i18n.t('common.PromotionName_ProductID_Barcode')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handlePromotionQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="promotionQuery.countryCodes"
                        :multiple="true"
                        :placeholder="$i18n.t('common.PromotionCountry')"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="promotionQuery.activityStatus"
                        clearable
                        :codeType="'activity_status'"
                        :placeholder="$i18n.t('common.Activitystatus')"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="promotionQuery.enableStatus"
                        :placeholder="$i18n.t('common.Enableornot')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.NewUserPromotionEnabled')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.NewUserPromotionDisabled')" value="2"></el-option>
                        <el-option :label="$i18n.t('common.OldUserPromotionEnabled')" value="3"></el-option>
                        <el-option :label="$i18n.t('common.OldUserPromotionDisabled')" value="4"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select
                        v-model="promotionQuery.seckill"
                        :placeholder="$i18n.t('common.Isitaflashkill')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.Yes')" value="true"></el-option>
                        <el-option :label="$i18n.t('common.No')" value="false"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="handlePromotionQuery"
                        style="margin-left: 10px"
                    >
                        {{ $i18n.t('common.query') }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanPromotionQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                        {{ $i18n.t('common.Reset') }}
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table
                ref="tableData"
                :data="pageData.list"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange"
                row-key="promotionId"
            >
                <el-table-column type="selection" :reserve-selection="true"></el-table-column>
                <el-table-column prop="promotionId" label="ID" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="title" :label="$t('common.PromotionName')" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="EAN" :label="$t('common.barcode')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>EAN: {{ scope.row.goodsMergeListMgrDto.barCode }} <br /></span>
                        <span>SKU: {{ scope.row.goodsMergeListMgrDto.sku }} <br /></span>
                    </template>
                </el-table-column>
                <el-table-column prop="bannerFiles" :label="$t('common.Productimage')" align="center" width="130px">
                    <template slot-scope="scope">
                        <listImage :imgUrl="scope.row.goodsMergeListMgrDto.imageUrl"></listImage>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsMergeListMgrDto.title" :label="$t('common.Productname1')" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="theme" :label="$t('common.PromotionCountry')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(s, index) in distinctPromotionCountry(scope.row.promotionRuleCountrys)" :key="index">
                            {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="status" :label="$t('common.EnabledStatus')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>
                            {{ $t('common.NewUserPromotion') }}:
                            <span :style="{ color: scope.row.newUserStatus ? 'green' : 'red' }">
                                {{ scope.row.newUserStatus ? $t('common.Enable') : $t('common.Disabled') }} </span
                            ><br />
                        </span>
                        <span>
                            {{ $t('common.RegularUserPromotion') }}:
                            <span :style="{ color: scope.row.status ? 'green' : 'red' }">
                                {{ scope.row.status == 1 ? $t('common.Enable') : $t('common.Disabled') }} </span
                            ><br />
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="updateName" :label="$t('common.Operator')" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" :label="$t('common.OperatingTime')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.updateTime | formatDate }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                    style="margin-top: 10px"
                    background
                    @current-change="handleCurrentPromotionChange"
                    :current-page="promotionQuery.page"
                    :page-size="promotionQuery.size"
                    layout="total, prev, pager, next, jumper"
                    :total="pageData.total"
                ></el-pagination>
            </div>
            <div>
                <span style="font-size: large; margin-left: 75%">已选择：{{ multipleSelection.length }}</span>
                <el-button type="primary" @click="submitPromotion()" size="medium" style="margin-left: 30px">确定</el-button>
            </div>
        </el-dialog>
        <!-- 编辑表单 -->
        <el-dialog
            :title="formTitle"
            :visible.sync="updateVisible"
            @close="closeForm()"
            v-if="updateVisible"
            :close-on-click-modal="false"
            width="1000px"
        >
            <el-form ref="dataForm" :rules="formRules" :model="addPromotionExtend" label-position="left" size="small" label-width="120px">
                <el-form-item :required="true" :label="$i18n.t('common.PromotionalCollectionName')" prop="name" key="name">
                    <el-input v-model="addPromotionExtend.name" style="width: 100%" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item :required="true" :label="$i18n.t('common.SelectPromotionalList')">
                    <el-button type="success" @click="selectPromotion()">{{ $i18n.t('common.SelectPromotionalList') }}</el-button>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.SelectedPromotionalActivity')" v-if="selectData.length > 0">
                    <el-table :data="selectData" border>
                        <el-table-column prop="promotionId" label="ID" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column
                            prop="title"
                            :label="$t('common.PromotionName')"
                            align="center"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="EAN" :label="$t('common.barcode')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>EAN: {{ scope.row.goodsMergeListMgrDto.barCode }} <br /></span>
                                <span>SKU: {{ scope.row.goodsMergeListMgrDto.sku }} <br /></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bannerFiles" :label="$t('common.Productimage')" align="center" width="130px">
                            <template slot-scope="scope">
                                <listImage :imgUrl="scope.row.goodsMergeListMgrDto.imageUrl"></listImage>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="goodsMergeListMgrDto.title"
                            :label="$t('common.Productname1')"
                            align="center"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column prop="theme" :label="$t('common.PromotionCountry')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in distinctPromotionCountry(scope.row.promotionRuleCountrys)" :key="index">
                                    {{ $dict.getDictValue('countrys', s.countryCode) }}<br />
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="status" :label="$t('common.EnabledStatus')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>
                                    {{ $t('common.NewUserPromotion') }}:
                                    <span :style="{ color: scope.row.newUserStatus ? 'green' : 'red' }">
                                        {{ scope.row.newUserStatus ? $t('common.Enable') : $t('common.Disabled') }} </span
                                    ><br />
                                </span>
                                <span>
                                    {{ $t('common.RegularUserPromotion') }}:
                                    <span :style="{ color: scope.row.status ? 'green' : 'red' }">
                                        {{ scope.row.status == 1 ? $t('common.Enable') : $t('common.Disabled') }} </span
                                    ><br />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="updateName"
                            :label="$t('common.Operator')"
                            align="center"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="updateTime" :label="$t('common.OperatingTime')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{ scope.row.updateTime | formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$i18n.t('common.operation')" align="center" show-overflow-tooltip>
                            <template slot-scope="{ row }">
                                <el-button type="danger" size="mini" @click="handleDeletePromotion(row)" class="table-inner-button">
                                    {{ $i18n.t('common.delete') }}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Enableornot')" prop="enableStatus">
                    <el-switch v-model="addPromotionExtend.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Promotiontime')" prop="startTime">
                    <el-date-picker
                        v-model="valuePicker"
                        type="datetimerange"
                        :range-separator="$i18n.t('common.to')"
                        :start-placeholder="$i18n.t('common.starttime')"
                        :end-placeholder="$i18n.t('common.Endtime')"
                        :picker-options="pickerOptions"
                        :default-time="['00:00:00', '23:59:59']"
                    >
                    </el-date-picker>
                </el-form-item>
                <div>
                    {{ $i18n.t('common.TypesPerOrder') }}:1
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
                <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm') }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import listImage from '@/components/common/ListImage.vue';
import { distinctPromotionCountry } from '@/utils/promotion';
import X2JS from 'x2js'; //解析xml
import { read, utils } from 'xlsx';
import da from '../../i18n/langs/da';

function initAddBean() {
    const addBean = {
        promotionExtendId: null,
        name: null,
        status: true,
        beginTime: null,
        endTime: null,
        promotionIdList: []
    };
    return addBean;
}

export default {
    name: 'PromotionList',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        listImage
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error(this.$i18n.t('common.Pleaseselectatimerange')));
            }
        };
        var isTrue = (rule, value, callback) => {
            var pattern = /^(([\*])|(\w{1,8}[\*]?))$/;
            if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t('common.Pleaseenterthepostalcodecorrectly')));
            } else {
                return callback();
            }
        };
        return {
            formTitle: '',
            promotionTitle: '',
            old: initAddBean(),
            addPromotionExtend: initAddBean(),
            list: [],
            pageData: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            createPicker: [],
            multipleSelection: [],
            selectData: [],
            valuePicker: [],
            query: {
                page: 1,
                limit: 10,
                promotionExtendSearchText: null,
                promotionSearchText: null,
                status: null,
                activityStatus: null,
                beginTime: null,
                endTime: null
            },
            promotionQuery: {
                page: 1,
                limit: 10,
                title: null,
                enableStatus: null,
                activityStatus: null,
                beginTime: null,
                endTime: null,
                newUserStatus: null,
                countryCodes: [],
                seckill: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,
            updateVisible: false,
            promotionVisible: false,
            formLoading: false,

            countryLang: [],
            //字典取的语言
            langs: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t('common.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Lastweek'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Thismonth'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Lastmonth1'),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.thisyear'),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            formRules: {
                name: [{ required: true, message: this.$i18n.t('common.Pleaseenteraname'), trigger: 'blur' }]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        handleCurrentPromotionChange(val) {
            this.promotionQuery.page = val;
            this.handlePromotionQuery({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.updateVisible = false;
            this.doQueryList({ page: 1 });
        },
        closePromotionForm() {
            this.promotionVisible = false;
            this.$refs.tableData.clearSelection();
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.createPicker != null && this.createPicker.length > 0) {
                this.query.beginTime = moment(this.createPicker[0]).format('yyyy-MM-DD HH:mm:ss');
                this.query.endTime = moment(this.createPicker[1]).format('yyyy-MM-DD HH:mm:ss');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            this.listLoading = true;
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }

            this.$store
                .dispatch('getPromotionExtend', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm(this.$i18n.t('common.confirmdeletion'), this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deletePromotionExtend', { id: row.promotionExtendId }).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: this.$i18n.t('common.Successfullydeleted'),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        //删除编辑表单促销活动
        handleDeletePromotion(row) {
            this.$confirm(this.$i18n.t('common.confirmdeletion'), this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.selectData = this.selectData.filter((x) => x.promotionId != row.promotionId);
                })
                .catch(() => {});
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle = this.$i18n.t('common.edit');
            this.old = row;
            this.resetForm();
            for (const key in initAddBean()) {
                this.addPromotionExtend[key] = this.old[key];
            }
            this.valuePicker[0] = moment(row.beginTime).format('yyyy-MM-DD HH:mm:ss');
            this.valuePicker[1] = moment(row.endTime).format('yyyy-MM-DD HH:mm:ss');
            var promotionIds = [];
            promotionIds = row.promotionIds.split(',');
            console.log(promotionIds);
            this.$store.dispatch('getPromotionByPromotions', promotionIds).then((res) => {
                console.log(res);
                this.selectData = res;
            });

            this.updateVisible = true;
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.addPromotionExtend = initAddBean();
            this.selectData = [];
            this.valuePicker = [];
            this.createPicker = [];
            this.multipleSelection = [];
            console.log(this.addPromotionExtend);
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = this.$i18n.t('common.Add1');
            this.resetForm();
            this.addVisible = true;
        },
        selectPromotion() {
            this.promotionTitle = this.$i18n.t('common.SelectPromotionalActivity');
            this.promotionVisible = true;
            this.promotionQuery.page = 1;
            this.$nextTick(() => {
                if (this.selectData && this.selectData.length > 0) {
                    this.selectData.forEach((item) => {
                        this.$refs.tableData.toggleRowSelection(item, true);
                    });
                    this.multipleSelection = Object.assign([], this.selectData);
                }
            });
            this.cleanPromotionQuery();
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            //时间检验
            if (this.valuePicker == null || this.valuePicker.length == 0) {
                this.$message({
                    message: this.$i18n.t('common.Pleaseselectatime'),
                    type: 'error'
                });
                return false;
            }
            //促销活动是否已选择
            if(this.selectData == null || this.selectData.length == 0){
                this.$message({
                    message: this.$i18n.t('common.PromotionalActivity'),
                    type: 'error'
                });
                return false;
            }
            console.log(this.addPromotionExtend);
            this.addPromotionExtend.beginTime = moment(this.valuePicker[0]).unix() * 1000;
            this.addPromotionExtend.endTime = moment(this.valuePicker[1]).unix() * 1000;
            this.addPromotionExtend.promotionIdList = this.selectData.map((x) => x.promotionId);
            console.log(this.addPromotionExtend);
            console.log(this.selectData);

            if (this.formTitle == this.$i18n.t('common.Add1')) {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addPromotionExtend', this.addPromotionExtend).then((data) => {
                            //判断促销活动是否有被添加过到别的促销活动合集中，已有时提示：“促销名称”已有促销合集活动，请前往编辑；
                            if (data) {
                                this.$message({
                                    message: data + ':' + this.$i18n.t('common.PromotionalCollectionActivit'),
                                    type: 'error'
                                });
                                return false;
                            }
                            this.addVisible = false;
                            this.updateVisible = false;
                            this.promotionVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t('common.Successfullysaved'),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t('common.Savefailed'),
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.addPromotionExtend);
                        this.$store.dispatch('updatePromotionExtend', this.addPromotionExtend).then((data) => {
                            //判断促销活动是否有被添加过到别的促销活动合集中，已有时提示：“促销名称”已有促销合集活动，请前往编辑；
                            if (data) {
                                this.$message({
                                    message: data + ':' + this.$i18n.t('common.PromotionalCollectionActivit'),
                                    type: 'error'
                                });
                                return false;
                            }
                            this.addVisible = false;
                            this.updateVisible = false;
                            this.promotionVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t('common.Successfullyedited'),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t('common.Editingfailed'),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query.promotionExtendSearchText = null;
            this.query.promotionSearchText = null;
            this.query.status = null;
            this.query.activityStatus = null;
            this.query.beginTime = null;
            this.query.endTime = null;
            this.createPicker = [];
            this.doQueryList({ page: 1 });
        },
        cleanPromotionQuery() {
            this.promotionQuery = {
                page: 1,
                limit: 10,
                title: null,
                enableStatus: null,
                activityStatus: null,
                beginTime: null,
                endTime: null,
                newUserStatus: null,
                countryCodes: [],
                seckill: null
            };
            this.handlePromotionQuery({ page: 1 });
        },
        handlePromotionQuery({ page, limit }) {
            if (page) {
                this.promotionQuery.page = page;
            }
            let nowTime = Date.now();
            //进行中
            if (this.promotionQuery.activityStatus === 'UnderWay') {
                this.promotionQuery.beginTime = moment().unix() * 1000;
                this.promotionQuery.endTime = moment().unix() * 1000;
                //即将开始
            } else if (this.promotionQuery.activityStatus === 'NotStarted') {
                this.promotionQuery.beginTime = moment().unix() * 1000;
                this.promotionQuery.endTime = null;
                //已结束
            } else if (this.promotionQuery.activityStatus === 'Complete') {
                this.promotionQuery.beginTime = null;
                this.promotionQuery.endTime = moment().unix() * 1000;
            } else {
                this.promotionQuery.beginTime = null;
                this.promotionQuery.endTime = null;
            }
            let status = null;
            let newUserStatus = null;
            //启用状态
            if (this.promotionQuery.enableStatus === '1') {
                //新用户启用
                newUserStatus = 'true';
            } else if (this.promotionQuery.enableStatus === '2') {
                //新用户禁用
                newUserStatus = 'false';
            } else if (this.promotionQuery.enableStatus === '3') {
                //老用户启用
                status = 'true';
            } else if (this.promotionQuery.enableStatus === '4') {
                //老用户禁用
                status = 'false';
            }
            this.promotionQuery.status = status;
            this.promotionQuery.newUserStatus = newUserStatus;
            console.log(this.promotionQuery);

            this.$store.dispatch('getPromotion', this.promotionQuery).then((res) => {
                this.pageData = res;
                this.pageData.list.forEach((item) => {
                    let newUserPromotionRuleCountrys = [];
                    let oldUserPromotionRuleCountrys = [];
                    item.promotionRuleCountrys.forEach((item2) => {
                        //产品连涵韬提新要求不按库存判断状态，只按时间
                        if (item2.beginTime > nowTime) {
                            item2.activeStatus = this.$i18n.t('common.Upcoming');
                            item2.activeStatusColor = 'red';
                        } else if (item2.endTime < nowTime) {
                            item2.activeStatus = this.$i18n.t('common.Ended');
                            item2.activeStatusColor = 'grey';
                        } else if (item2.endTime > nowTime && item2.beginTime <= nowTime) {
                            item2.activeStatus = this.$i18n.t('common.InProgress');
                            item2.activeStatusColor = 'green';
                        }
                        //处理新老用户数据
                        if (item2.userType == 'NEW') {
                            newUserPromotionRuleCountrys.push(item2);
                        } else {
                            oldUserPromotionRuleCountrys.push(item2);
                        }
                    });
                    item.newUserPromotionRuleCountrys = newUserPromotionRuleCountrys;
                    item.oldUserPromotionRuleCountrys = oldUserPromotionRuleCountrys;
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        submitPromotion() {
            this.selectData = Object.assign([], this.multipleSelection);
            console.log(this.selectData);
            if (this.selectData.length == 0) {
                this.$message({
                    message: this.$i18n.t('common.PromotionalActivity'),
                    type: 'error'
                });
                return;
            }
            this.promotionVisible = false;
            this.updateVisible = true;
            console.log(this.addPromotionExtend);
        },
        //下载导入模板
        downloadImportTpl() {
            let fileName = 'aigo_promotion_activity_list_tpl.xlsx';
            this.$store.dispatch('downloadTemplatePromotionExtend', { fileName: fileName }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t('common.Downloadsuccessful')
                });
            });
        },
        //导入
        importClick() {
            console.log(111);
            let file = this.$refs.file.files[0];
            let ext = this.getSuffix(file.name);
            let accept = ['.xls', '.xlsx'];
            if (accept.indexOf(ext) < 0) {
                this.$message.error(this.$i18n.t('common.OnlySupportsFilesInFormat', { xxx: accept }));
                return;
            }
            const fileReader = new FileReader();
            this.formLoading = true;
            fileReader.onload = (ev) => {
                try {
                    const data = ev.target.result;
                    const workbook = read(data, {
                        type: 'binary'
                    });
                    const wsname = workbook.SheetNames[0];
                    const ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                    console.log('ws', ws);
                    const promotionIdList = [];
                    for (let promotion of ws) {
                        console.log(promotion);
                        for (let item of Object.values(promotion)) {
                            promotionIdList.push(item);
                        }
                    }
                    console.log(promotionIdList);

                    this.formTitle = this.$i18n.t('common.Add1');
                    this.resetForm();
                    this.$store.dispatch('getPromotionByPromotions', promotionIdList).then((res) => {
                        console.log(res);
                        this.selectData = res;
                    });

                    this.updateVisible = true;
                } catch (e) {
                    this.formLoading = false;
                    this.$refs.file.value = null;
                    return false;
                }
            };
            fileReader.readAsBinaryString(file);
        },

        //复制列表
        copyText(row, column, cell, event) {
            console.log(row);
            console.log(column);
            if (column.property === 'promotionIds') {
                this.copyToClipboard(row.promotionIds);
            }
            if (column.property === 'barCodes') {
                this.copyToClipboard(row.barCodes);
            }
        },
        async copyToClipboard(text) {
            // navigator.clipboard.writeText需要再Https环境下使用才行
            if (window.isSecureContext && navigator.clipboard) {
                try {
                    await navigator.clipboard.writeText(text);
                    this.$message({ message: '复制成功', type: 'success' }); // 提示
                } catch (err) {
                    this.$message({ message: '复制失败' + err, type: 'error' }); // 提示
                }
            } else {
                this.unsecuredCopyToClipboard(text);
            }
        },
        unsecuredCopyToClipboard(text) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                this.$message({ message: '复制成功', type: 'success' }); // 提示
            } catch (err) {
                this.$message({ message: '复制失败' + err, type: 'error' }); // 提示
            }
            document.body.removeChild(textArea);
        },
        //显示前5个id，每个id之间用逗号分隔，超过5个id以末尾以省略号“...”结尾
        handleLongString(text) {
            let arr = text.split(',');
            if (arr.length < 5) {
                return arr.join(',\n\r').replace(/["']/g, '');
            } else {
                arr = arr.slice(0, 5);
                return arr.join(',\n\r').replace(/["']/g, '') + '...';
            }
        },
        //促销国家去重
        distinctPromotionCountry
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
::v-deep(.el-table .cell) {
    white-space: pre-line;
}
</style>
