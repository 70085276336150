var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formItem", attrs: { model: _vm.formItem, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Combinationname"),
                prop: "palletName",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "350px" },
                attrs: {
                  placeholder: _vm.$i18n.t("common.Combinationname"),
                  maxlength: "100",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formItem.palletName,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "palletName", $$v)
                  },
                  expression: "formItem.palletName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "180px" },
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$i18n.t("common.country")
                  },
                  model: {
                    value: _vm.formItem.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "countryCode", $$v)
                    },
                    expression: "formItem.countryCode"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Combinationtime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "-",
                  "start-placeholder": _vm.$i18n.t("common.starttime"),
                  "end-placeholder": _vm.$i18n.t("common.Endtime"),
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.formItem.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "time", $$v)
                  },
                  expression: "formItem.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Combinationquantity"),
                prop: "palletQuantity",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                attrs: {
                  placeholder: _vm.$i18n.t("common.Combinationquantity")
                },
                model: {
                  value: _vm.formItem.palletQuantity,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "palletQuantity", $$v)
                  },
                  expression: "formItem.palletQuantity"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.CanIusecoupons"),
                prop: "isUseCoupon",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formItem.isUseCoupon,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "isUseCoupon", $$v)
                    },
                    expression: "formItem.isUseCoupon"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                  ]),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.yes")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.CombinationIcon"),
                prop: "imgUrl",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("imgUpload", {
                ref: "imageUpload",
                attrs: { img: _vm.formItem.imgUrl },
                on: { event: _vm.changeUrl }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Selectproduct"),
                "label-width": _vm.formLabelWidth,
                required: true
              }
            },
            [
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success" },
                    on: { click: _vm.changeGoods }
                  },
                  [_vm._v(_vm._s(_vm.$i18n.t("common.Selectproduct")))]
                )
              ]
            ],
            2
          ),
          _c(
            "el-table",
            {
              ref: "tableList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.sort"),
                  align: "center",
                  width: "60",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              staticStyle: { width: "40px" },
                              on: {
                                blur: function($event) {
                                  return _vm.blurSort()
                                }
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "SKU/EAN",
                  prop: "sku",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v("SKU:" + _vm._s(scope.row.sku))]),
                        _c("br"),
                        _c("span", [
                          _vm._v("EAN:" + _vm._s(scope.row.barCode))
                        ]),
                        _c("br")
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Mainimage"),
                  prop: "imageUrl",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("listImage", {
                          attrs: { imgUrl: scope.row.imageUrl }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$i18n.t("common.Countrieslisted"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.goodsSpecialsShelf, function(
                        s,
                        index
                      ) {
                        return _c("span", { key: s.countryCode }, [
                          index < scope.row.goodsSpecialsShelf.length - 1
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(s.countryCode) +
                                    "/\n                            "
                                )
                              ])
                            : _vm._e(),
                          index == scope.row.goodsSpecialsShelf.length - 1
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(s.countryCode) +
                                    "  \n                            "
                                )
                              ])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shelf",
                  label: _vm.$i18n.t("common.state"),
                  width: "60",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.shelf == true
                          ? _c("span", { staticStyle: { color: "#46a8a0" } }, [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Listed")))
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$i18n.t("common.Notlisted")))
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ecomRemainQty",
                  label: _vm.$i18n.t("common.inventory"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.goodsSpecials, function(
                        s,
                        index
                      ) {
                        return _c("span", { key: index }, [
                          s.countryCode &&
                          s.countryCode == _vm.formItem.countryCode
                            ? _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(s.ecomRemainQty)
                                ),
                                _c("br")
                              ])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "packageQuantity",
                  label: _vm.$i18n.t("common.PackingQuantity"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedPrice",
                  label: _vm.$i18n.t("common.Fixedprice1"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.prices, function(s, index) {
                        return _c("span", { key: index }, [
                          s.countryCode == _vm.formItem.countryCode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(Number(s.fixedPrice).toFixed(2)) +
                                    _vm._s(
                                      _vm.$dict.getDictCurrencyValue(
                                        s.countryCode
                                      )
                                    )
                                ),
                                _c("br")
                              ])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "resultPrice",
                  label: _vm.$i18n.t("common.fixedprice"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.prices, function(s, index) {
                        return _c("span", { key: index }, [
                          s.resultPrice &&
                          s.countryCode == _vm.formItem.countryCode
                            ? _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(s.resultPrice.toFixed(2)) +
                                    _vm._s(
                                      _vm.$dict.getDictCurrencyValue(
                                        s.countryCode
                                      )
                                    )
                                ),
                                _c("br")
                              ])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "salePriceEbp",
                  label: _vm.$i18n.t("common.price1"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.prices, function(s, index) {
                        return _c("span", { key: index }, [
                          (s.salePriceEbp || s.salePriceEbp >= 0) &&
                          s.countryCode == _vm.formItem.countryCode
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        s.salePriceEbp || s.salePriceEbp >= 0,
                                      expression:
                                        "s.salePriceEbp || s.salePriceEbp>=0"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(s.countryCode) +
                                      ":" +
                                      _vm._s(s.salePriceEbp.toFixed(2)) +
                                      _vm._s(
                                        _vm.$dict.getDictCurrencyValue(
                                          s.countryCode
                                        )
                                      )
                                  ),
                                  _c("br")
                                ]
                              )
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Setcombinationdiscount"),
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              on: {
                                change: function($event) {
                                  return _vm.calculatePalletValueChar(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              },
                              model: {
                                value: scope.row.palletValueChar,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "palletValueChar", $$v)
                                },
                                expression: "scope.row.palletValueChar"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: _vm.$i18n.t("common.Combinationprice"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", { staticStyle: { color: "#fa3434" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.formItem.countryCode) +
                              ":" +
                              _vm._s(
                                Number(scope.row.palletValueDiscount).toFixed(2)
                              ) +
                              _vm._s(
                                _vm.$dict.getDictCurrencyValue(
                                  _vm.formItem.countryCode
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.operation"),
                  fixed: "right",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.deleteGoods(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer-Price",
          staticStyle: { "margin-top": "20px" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm._v(
            "\n           " +
              _vm._s(_vm.$i18n.t("common.Combinationprice")) +
              "："
          ),
          _c("span", { staticStyle: { color: "#fa3434" } }, [
            _vm._v(_vm._s(Number(_vm.palletTotalPriceDiscount).toFixed(2)))
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer-Price",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "text-decoration": "line-through",
                color: "#9da1a7"
              }
            },
            [_vm._v(_vm._s(Number(_vm.palletTotalPrice).toFixed(2)))]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.onSubmit("formItem")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _vm.goodsDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.goodsDialogVisible, "append-to-body": "" },
              on: {
                "update:visible": function($event) {
                  _vm.goodsDialogVisible = $event
                },
                close: _vm.closeGooodsDialog
              }
            },
            [
              _c("goods-dialog", {
                ref: "goodsDialog",
                attrs: { dictionary: _vm.dictionary, tableList: _vm.tableList },
                on: { handleSelected: _vm.handleSelected }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }