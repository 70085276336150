var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("同步按钮")])]
          ),
          _c(
            "el-row",
            _vm._l(_vm.buttonInfos, function(item, index) {
              return _c("sendSyncMessageButton", {
                attrs: { buttonInfo: item }
              })
            }),
            1
          )
        ],
        1
      ),
      this.showHideUserIds.includes(this.userId)
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("隐藏同步按钮")])]
              ),
              _c(
                "el-row",
                _vm._l(_vm.hideButtonInfos, function(item, index) {
                  return _c("sendSyncMessageButton", {
                    attrs: { buttonInfo: item }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }