var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.typeName + _vm.$i18n.t("common.name"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  "show-word-limit": "",
                  maxlength: "50",
                  placeholder: _vm.$i18n.t("common.Pleaseentercontent")
                },
                on: { input: _vm.changeCheckList },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c("el-form-item", {
            attrs: {
              label: _vm.$i18n.t("common.Selectproduct"),
              "label-width": _vm.formLabelWidth
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.changeGoods }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.Selectproduct")))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.formListQuery.pageDataSelectList &&
          _vm.formListQuery.pageDataSelectList.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": _vm.formLabelWidth } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "pageDataSelect",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.formListQuery.pageDataSelectList,
                            border: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsId",
                              label: _vm.$i18n.t("common.ProductID"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsCode",
                              label: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v("SKU:" + _vm._s(scope.row.sku))
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "EAN:" + _vm._s(scope.row.barCode)
                                        )
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$i18n.t("common.coding")) +
                                            ":" +
                                            _vm._s(scope.row.goodsCode)
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1138614848
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "imageUrl",
                              label: _vm.$i18n.t("common.Productimage1"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.imageUrl
                                        ? _c("el-image", {
                                            staticStyle: { height: "100px" },
                                            attrs: {
                                              src:
                                                _vm.ossHost +
                                                scope.row.imageUrl,
                                              fit: "fill"
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              183490098
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: _vm.$i18n.t("common.ProductName"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsSpecials",
                              label: _vm.$i18n.t("common.Countrieslisted"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(
                                      scope.row.goodsSpecials,
                                      function(s, index) {
                                        return _c(
                                          "span",
                                          { key: s.countryCode },
                                          [
                                            s.shelf == true
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$dict.getDictValue(
                                                          "countrys",
                                                          s.countryCode
                                                        )
                                                      )
                                                  ),
                                                  _c("br")
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              756755268
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$i18n.t("common.inventory"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(
                                      scope.row.goodsSpecials,
                                      function(s, index) {
                                        return _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(s.countryCode) +
                                              ":" +
                                              _vm._s(s.ecomRemainQty)
                                          ),
                                          _c("br")
                                        ])
                                      }
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              590014020
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packageQuantity",
                              label: _vm.$t("common.PackingQuantity"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$i18n.t("common.Fixedprice1"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.fixedPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    Number(
                                                      s.fixedPrice
                                                    ).toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              2945861441
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$i18n.t("common.Lowestprice"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.lowestPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    Number(
                                                      s.lowestPrice
                                                    ).toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              3335674497
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "brand",
                              label: _vm.$i18n.t("common.fixedprice"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.resultPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    s.resultPrice.toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              881677955
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "brand",
                              label: _vm.$i18n.t("common.price1"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  s.salePriceEbp ||
                                                  s.salePriceEbp >= 0,
                                                expression:
                                                  "s.salePriceEbp || s.salePriceEbp>=0"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(s.countryCode) +
                                                ":" +
                                                _vm._s(
                                                  s.salePriceEbp.toFixed(2)
                                                ) +
                                                _vm._s(
                                                  _vm.$dict.getDictCurrencyValue(
                                                    s.countryCode
                                                  )
                                                )
                                            ),
                                            _c("br")
                                          ]
                                        )
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              3124635391
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shelf",
                              label: _vm.$i18n.t("common.state"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.shelf == true
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#46a8a0" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$i18n.t("common.Listed")
                                                )
                                              )
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$i18n.t("common.Notlisted")
                                              )
                                            )
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2074187132
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "must",
                      attrs: {
                        label: _vm.typeName + _vm.$i18n.t("common.country"),
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _vm.typeName == _vm.typeNameDistribution
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { input: _vm.handleTabCoded },
                                  model: {
                                    value: _vm.checkCountrys.countryCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.checkCountrys,
                                        "countryCode",
                                        $$v
                                      )
                                    },
                                    expression: "checkCountrys.countryCode"
                                  }
                                },
                                _vm._l(
                                  _vm.formListQuery.pageDataSelectList[0]
                                    .goodsSpecials,
                                  function(item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: { label: item.countryCode }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$dict.getDictValue(
                                              "countrys",
                                              item.countryCode
                                            )
                                          ) + "\n                            "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        : _vm.typeName == "促销"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.countryCode,
                                    callback: function($$v) {
                                      _vm.countryCode = $$v
                                    },
                                    expression: "countryCode"
                                  }
                                },
                                _vm._l(
                                  _vm.formListQuery.pageDataSelectList[0]
                                    .goodsSpecials,
                                  function(item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: { label: item.countryCode },
                                        on: {
                                          change: function(val) {
                                            return _vm.handleTabCode(val, item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$dict.getDictValue(
                                              "countrys",
                                              item.countryCode
                                            )
                                          ) + "\n                            "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm.typeName == "促销"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Enableornot"),
                            prop: "status",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.formListQuery.status,
                              callback: function($$v) {
                                _vm.$set(_vm.formListQuery, "status", $$v)
                              },
                              expression: "formListQuery.status"
                            }
                          })
                        ],
                        1
                      )
                    : _vm.typeName == _vm.typeNameDistribution
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Enableornot"),
                            prop: "status",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.formListQuery.status,
                              callback: function($$v) {
                                _vm.$set(_vm.formListQuery, "status", $$v)
                              },
                              expression: "formListQuery.status"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.typeName == _vm.typeNameDistribution &&
                  _vm.promotionRuleCountrysList &&
                  _vm.promotionRuleCountrysList.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "variantFrom2",
                              attrs: { model: _vm.promotionRuleCountrysList[0] }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.typeName + _vm.$i18n.t("common.time"),
                                    prop: "time",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      clearable: "",
                                      type: "datetimerange",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "range-separator": _vm.$i18n.t(
                                        "common.to"
                                      ),
                                      "start-placeholder": _vm.$i18n.t(
                                        "common.starttime"
                                      ),
                                      "end-placeholder": _vm.$i18n.t(
                                        "common.Endtime"
                                      ),
                                      "default-time": ["00:00:00", "23:59:59"]
                                    },
                                    on: { input: _vm.changeCheckList },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0].time,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "" +
                                      _vm.typeName +
                                      _vm.$t("common.price"),
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _vm.promotionRuleCountrysList.length > 0
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          on: {
                                            change: function(val) {
                                              return _vm.handlePriceTyped(
                                                val,
                                                _vm.promotionRuleCountrysList[0]
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.promotionRuleCountrysList[0]
                                                .promotionType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm
                                                  .promotionRuleCountrysList[0],
                                                "promotionType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "promotionRuleCountrysList[0].promotionType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "Discount" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$i18n.t("common.discount")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "Price" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$i18n.t("common.price")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.promotionRuleCountrysList[0].promotionType ==
                              "Discount"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "promotionValue",
                                        "label-width": _vm.formLabelWidth
                                      }
                                    },
                                    [
                                      _c("span", [_vm._v(" - ")]),
                                      _c("el-input", {
                                        style: { width: _vm.inputWidth },
                                        attrs: {
                                          oninput:
                                            "value=value.replace(/[^0-9.]/g,'')",
                                          placeholder: _vm.$i18n.t(
                                            "common.discount"
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.handleDiscount(
                                              _vm.promotionRuleCountrysList[0]
                                            )
                                          },
                                          input: _vm.changeCheckList
                                        },
                                        model: {
                                          value:
                                            _vm.promotionRuleCountrysList[0]
                                              .promotionValue,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promotionRuleCountrysList[0],
                                              "promotionValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promotionRuleCountrysList[0].promotionValue"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                            % off\n                        "
                                      )
                                    ],
                                    1
                                  )
                                : _vm.promotionRuleCountrysList[0]
                                    .promotionType == "Price"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "promotionValue",
                                        "label-width": _vm.formLabelWidth
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        style: { width: _vm.inputWidth },
                                        attrs: {
                                          oninput:
                                            "value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\\.[0-9]{2})[0-9]*/,'$1'))",
                                          placeholder: _vm.$t(
                                            "common.PleaseEnterThePrice"
                                          )
                                        },
                                        on: {
                                          input: _vm.changeCheckList,
                                          blur: function($event) {
                                            return _vm.handlePriced(
                                              _vm.promotionRuleCountrysList[0]
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.promotionRuleCountrysList[0]
                                              .promotionValue,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promotionRuleCountrysList[0],
                                              "promotionValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promotionRuleCountrysList[0].promotionValue"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.promotionRuleCountrysList[0]
                                        .promotionType == "Discount"
                                        ? "" +
                                          _vm.$t("common.Distributionprice1")
                                        : _vm.typeName + "优惠",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _vm.promotionRuleCountrysList[0]
                                    .promotionValue ||
                                  _vm.promotionRuleCountrysList[0]
                                    .promotionValue == 0
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType ==
                                                  "Discount",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Discount'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.Discount) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType == "Price",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Price'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.Price) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType ==
                                                  "Discount",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Discount'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.discountTip) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType == "Price",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Price'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                     " +
                                                _vm._s(_vm.priceTip) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("common.Grossmargin"),
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _vm.promotionRuleCountrysList[0]
                                    .promotionValue ||
                                  _vm.promotionRuleCountrysList[0]
                                    .promotionValue == 0
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.promotionRuleCountrysList[0]
                                                .profit
                                            ) +
                                            "%\n                            "
                                        )
                                      ])
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType ==
                                                  "Discount",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Discount'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.discountTip) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm
                                                    .promotionRuleCountrysList[0]
                                                    .promotionType == "Price",
                                                expression:
                                                  "promotionRuleCountrysList[0].promotionType == 'Price'"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.priceTip) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "common.Distributionquantity"
                                    ),
                                    prop: "promotionCount",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^0-9]/g,'')",
                                      placeholder: _vm.$t(
                                        "common.PleaseEnterTheQuantity"
                                      )
                                    },
                                    on: {
                                      input:
                                        _vm.promotionCountAndLockQuantityChangeCheckList,
                                      blur: _vm.handleCountd
                                    },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0]
                                          .promotionCount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "promotionCount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].promotionCount"
                                    }
                                  })
                                ],
                                1
                              ),
                              this.isEdit
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "common.SoldDistributionQuantity"
                                        ),
                                        prop: "soldQuantity",
                                        "label-width": _vm.formLabelWidth
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "input-width",
                                        attrs: {
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          disabled: true
                                        },
                                        on: { input: _vm.changeCheckList },
                                        model: {
                                          value:
                                            _vm.promotionRuleCountrysList[0]
                                              .soldQuantity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promotionRuleCountrysList[0],
                                              "soldQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promotionRuleCountrysList[0].soldQuantity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "common.DistributionQuantityLock"
                                    ),
                                    prop: "lockQuantity",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^0-9]/g,'')",
                                      placeholder: _vm.$t(
                                        "common.PleaseEnterTheQuantity"
                                      )
                                    },
                                    on: {
                                      input:
                                        _vm.promotionCountAndLockQuantityChangeCheckList
                                    },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0]
                                          .lockQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "lockQuantity",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].lockQuantity"
                                    }
                                  }),
                                  _c("br"),
                                  this.showLockQuantityTip
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "common.InsufficientDstributionQuantityRemaining"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "common.NumberOfUserPurchases"
                                    ),
                                    prop: "userBuy",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct",
                                        { Any: _vm.typeName }
                                      ),
                                      disabled: true
                                    },
                                    on: { input: _vm.changeCheckList },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0]
                                          .userBuy,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "userBuy",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].userBuy"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "common.Freeshippingornot"
                                    ),
                                    prop: "freeDelivery",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.changeCheckList },
                                      model: {
                                        value:
                                          _vm.promotionRuleCountrysList[0]
                                            .freeDelivery,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promotionRuleCountrysList[0],
                                            "freeDelivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promotionRuleCountrysList[0].freeDelivery"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$i18n.t("common.yes"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$i18n.t("common.no"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "common.Doyousupporttheuseofcoupons"
                                    ),
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.changeCheckList },
                                      model: {
                                        value:
                                          _vm.promotionRuleCountrysList[0]
                                            .coupon,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promotionRuleCountrysList[0],
                                            "coupon",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promotionRuleCountrysList[0].coupon"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$i18n.t("common.yes"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$i18n.t("common.no"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "common.Purchaserestrictionsforthesameuser"
                                    ),
                                    prop: "userPurchaseLimit",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)",
                                      placeholder: _vm.$i18n.t(
                                        "common.Purchaserestrictionsforthesameuser"
                                      )
                                    },
                                    on: { blur: _vm.handleOrderPurchaseLimitd },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0]
                                          .userPurchaseLimit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "userPurchaseLimit",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].userPurchaseLimit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "common.Singleorderlimit"
                                    ),
                                    prop: "orderPurchaseLimit",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)",
                                      placeholder: _vm.$i18n.t(
                                        "common.Singleorderlimit"
                                      )
                                    },
                                    on: { blur: _vm.handleOrderPurchaseLimitd },
                                    model: {
                                      value:
                                        _vm.promotionRuleCountrysList[0]
                                          .orderPurchaseLimit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionRuleCountrysList[0],
                                          "orderPurchaseLimit",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionRuleCountrysList[0].orderPurchaseLimit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("common.OrderRemarkLabel"),
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$i18n.t(
                                          "common.Pleaseselect"
                                        ),
                                        clearable: "",
                                        filterable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.promotionRuleCountrysList[0]
                                            .remarkTag,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promotionRuleCountrysList[0],
                                            "remarkTag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promotionRuleCountrysList[0].remarkTag"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        key: "InternetCelebrity",
                                        attrs: {
                                          label: _vm.$t(
                                            "common.OrdersWorkWithInfluencers"
                                          ),
                                          value: "InternetCelebrity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.typeName == "促销" &&
                      _vm.promotionRuleCountrysList &&
                      _vm.promotionRuleCountrysList.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.changeCountrys },
                              model: {
                                value: _vm.activeName,
                                callback: function($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName"
                              }
                            },
                            _vm._l(_vm.promotionRuleCountrysList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-tab-pane",
                                {
                                  key: index,
                                  attrs: {
                                    label: _vm.$dict.getDictValue(
                                      "countrys",
                                      item.countryCode
                                    ),
                                    name: item.countryCode
                                  }
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "variantFrom2",
                                      refInFor: true,
                                      attrs: { model: item, rules: _vm.rulesP }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.Promotiontime"
                                            ),
                                            prop: "time",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              clearable: "",
                                              type: "datetimerange",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              format: "yyyy-MM-dd HH:mm:ss",
                                              "range-separator": _vm.$i18n.t(
                                                "common.to"
                                              ),
                                              "start-placeholder": _vm.$i18n.t(
                                                "common.starttime"
                                              ),
                                              "end-placeholder": _vm.$i18n.t(
                                                "common.Endtime"
                                              ),
                                              "default-time": [
                                                "00:00:00",
                                                "23:59:59"
                                              ]
                                            },
                                            on: { input: _vm.changeCheckList },
                                            model: {
                                              value: item.time,
                                              callback: function($$v) {
                                                _vm.$set(item, "time", $$v)
                                              },
                                              expression: "item.time"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.Displaypromotionalprices"
                                            ),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: function(val) {
                                                  return _vm.handlePriceTypep(
                                                    val,
                                                    item,
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  item.flagShowPromotionPrice,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "flagShowPromotionPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.flagShowPromotionPrice"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.no")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.yes")
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.PromotionPrice"
                                            ),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: function(val) {
                                                  return _vm.handlePriceTypep(
                                                    val,
                                                    item,
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.promotionType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "promotionType",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.promotionType"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: { label: "Discount" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "common.discount"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "Price" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t(
                                                        "common.price"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      item.promotionType == "Discount"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "promotionValue",
                                                "label-width":
                                                  _vm.formLabelWidth
                                              }
                                            },
                                            [
                                              _c("span", [_vm._v(" - ")]),
                                              _c("el-input", {
                                                style: {
                                                  width: _vm.inputWidth
                                                },
                                                attrs: {
                                                  oninput:
                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                  placeholder: _vm.$i18n.t(
                                                    "common.discount"
                                                  )
                                                },
                                                on: {
                                                  input: _vm.changeCheckList,
                                                  blur: function($event) {
                                                    return _vm.handleDiscount(
                                                      item
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: item.promotionValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "promotionValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.promotionValue"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                                    % off\n                                "
                                              )
                                            ],
                                            1
                                          )
                                        : item.promotionType == "Price"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "promotionValue",
                                                "label-width":
                                                  _vm.formLabelWidth
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                style: {
                                                  width: _vm.inputWidth
                                                },
                                                attrs: {
                                                  oninput:
                                                    "value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\\.[0-9]{2})[0-9]*/,'$1'))",
                                                  placeholder: _vm.$t(
                                                    "common.PleaseEnterThePrice"
                                                  )
                                                },
                                                on: {
                                                  input: _vm.changeCheckList,
                                                  blur: function($event) {
                                                    return _vm.handlePricep(
                                                      item
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: item.promotionValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "promotionValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.promotionValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              item.promotionType == "Discount"
                                                ? "" +
                                                  _vm.$t(
                                                    "common.PromotionPrice"
                                                  )
                                                : _vm.typeName + "优惠",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.promotionValue,
                                                  expression:
                                                    "item.promotionValue"
                                                }
                                              ]
                                            },
                                            [
                                              _vm.isEdit
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.promotionType ==
                                                              "Discount",
                                                            expression:
                                                              "item.promotionType == 'Discount'"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t    " +
                                                            _vm._s(
                                                              item.discount
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.promotionType ==
                                                              "Discount",
                                                            expression:
                                                              "item.promotionType == 'Discount'"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        \t    " +
                                                            _vm._s(
                                                              _vm.Discount
                                                                ? _vm.Discount
                                                                : 0
                                                            ) +
                                                            "\n                                        \t"
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.promotionType ==
                                                        "Price",
                                                      expression:
                                                        "item.promotionType == 'Price'"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(item.Price) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !item.promotionValue,
                                                  expression:
                                                    "!item.promotionValue"
                                                }
                                              ]
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.promotionType ==
                                                        "Discount",
                                                      expression:
                                                        "item.promotionType == 'Discount'"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(_vm.discountTip) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.promotionType ==
                                                        "Price",
                                                      expression:
                                                        "item.promotionType == 'Price'"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(_vm.priceTip) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("common.Grossmargin"),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.promotionValue,
                                                  expression:
                                                    "item.promotionValue"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item.profit) +
                                                  "%\n                                    "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !item.promotionValue,
                                                  expression:
                                                    "!item.promotionValue"
                                                }
                                              ]
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.promotionType ==
                                                        "Discount",
                                                      expression:
                                                        "item.promotionType == 'Discount'"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(_vm.discountTip) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.promotionType ==
                                                        "Price",
                                                      expression:
                                                        "item.promotionType == 'Price'"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(_vm.priceTip) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.Promotionquantity"
                                            ),
                                            prop: "promotionCount",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "input-width",
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/[^0-9]/g,'')",
                                              placeholder: _vm.$t(
                                                "common.PleaseEnterThePrice"
                                              )
                                            },
                                            on: {
                                              input: _vm.changeCheckList,
                                              blur: function($event) {
                                                return _vm.handleCountp(
                                                  item,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.promotionCount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "promotionCount",
                                                  $$v
                                                )
                                              },
                                              expression: "item.promotionCount"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("el-form-item", {
                                        attrs: {
                                          label: _vm.$i18n.t("common.discount"),
                                          prop: "freeDelivery",
                                          "label-width": _vm.formLabelWidth
                                        }
                                      }),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.NumberOfUserPurchases"
                                            ),
                                            prop: "userBuy",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "common.AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct",
                                                { Any: _vm.typeName }
                                              ),
                                              disabled: true
                                            },
                                            on: { input: _vm.changeCheckList },
                                            model: {
                                              value: item.userBuy,
                                              callback: function($$v) {
                                                _vm.$set(item, "userBuy", $$v)
                                              },
                                              expression: "item.userBuy"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "common.Freeshippingornot"
                                            ),
                                            prop: "freeDelivery",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: _vm.changeCheckList
                                              },
                                              model: {
                                                value: item.freeDelivery,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "freeDelivery",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.freeDelivery"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.yes")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.no")
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$i18n.t(
                                              "common.Doyousupporttheuseofcoupons"
                                            ),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: item.coupon,
                                                callback: function($$v) {
                                                  _vm.$set(item, "coupon", $$v)
                                                },
                                                expression: "item.coupon"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.yes")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.no")
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$i18n.t(
                                              "common.Isitaflashkill"
                                            ),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: _vm.changeCheckList
                                              },
                                              model: {
                                                value: item.seckill,
                                                callback: function($$v) {
                                                  _vm.$set(item, "seckill", $$v)
                                                },
                                                expression: "item.seckill"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.yes")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$i18n.t("common.no")
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.seckill,
                                              expression: "item.seckill"
                                            }
                                          ],
                                          attrs: {
                                            label: _vm.$i18n.t(
                                              "common.Secondkillsorting"
                                            ),
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "input-width",
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)",
                                              placeholder: _vm.$t(
                                                "common.Topwithlargenumbers"
                                              )
                                            },
                                            model: {
                                              value: item.sort,
                                              callback: function($$v) {
                                                _vm.$set(item, "sort", $$v)
                                              },
                                              expression: "item.sort"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$i18n.t(
                                              "common.Purchaserestrictionsforthesameuser"
                                            ),
                                            prop: "userRestrictions",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "input-width",
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)",
                                              placeholder: _vm.$i18n.t(
                                                "common.Purchaserestrictionsforthesameuser"
                                              )
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.handleOrderPurchaseLimit(
                                                  item
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.userRestrictions,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "userRestrictions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.userRestrictions"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$i18n.t(
                                              "common.Purchaserestrictionsforthesameuser"
                                            ),
                                            prop: "orderRestrictions",
                                            "label-width": _vm.formLabelWidth
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "input-width",
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)",
                                              placeholder: _vm.$i18n.t(
                                                "common.Purchaserestrictionsforthesameuser"
                                              )
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.handleOrderPurchaseLimit(
                                                  item
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.orderRestrictions,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "orderRestrictions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.orderRestrictions"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit1("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _vm.dialogFormTableVisible2
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("common.Selectproduct"),
                visible: _vm.dialogFormTableVisible2,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormTableVisible2 = $event
                },
                close: _vm.closeDialogGoods
              }
            },
            [
              _c(
                "div",
                [
                  _c("FormTableDialog", {
                    attrs: {
                      argFrom: _vm.argFrom2,
                      dictionary: _vm.dictionary,
                      placeholderFrom: _vm.placeholderFrom2,
                      pageDataSelectList: _vm.formListQuery.pageDataSelectList,
                      typeName: _vm.typeName
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList2,
                      handleResetSearch: _vm.handleResetSearch2,
                      handleSelected: _vm.handleSelected
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.pageData2.list,
                                    "tree-props": {
                                      children: "packageQtyList",
                                      hasChildren: "hasChildren"
                                    },
                                    id: "goodsTable",
                                    border: "",
                                    "row-key": "goodsId",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    },
                                    "default-expand-all": ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "55",
                                      align: "center",
                                      type: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "seRadio" },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          scope.row.goodsId,
                                                        disabled: !scope.row
                                                          .checkboxStatus
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectionChange(
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.selectionId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectionId = $$v
                                                        },
                                                        expression:
                                                          "selectionId"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s("") +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4216621667
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantityType",
                                      label: "",
                                      width: "50px",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsId",
                                      label: _vm.$i18n.t("common.ProductID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsCode",
                                      label: _vm.$i18n.t(
                                        "common.SKU_EAN_Encoding"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  "SKU:" + _vm._s(scope.row.sku)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "EAN:" +
                                                    _vm._s(scope.row.barCode)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t("common.coding")
                                                  ) +
                                                    ":" +
                                                    _vm._s(scope.row.goodsCode)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1138614848
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label: _vm.$i18n.t(
                                        "common.Productimage1"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.imageUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      height: "100px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossHost +
                                                        scope.row.imageUrl,
                                                      fit: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      183490098
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageQuantity",
                                      label: _vm.$t("common.PackingQuantity"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: _vm.$i18n.t("common.ProductName"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsSpecials",
                                      label: _vm.$i18n.t(
                                        "common.Countrieslisted"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.goodsSpecials,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.shelf
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$dict.getDictValue(
                                                                "countrys",
                                                                s.countryCode
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2013868671
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$t(
                                        "common.Promotionprice_unitprice"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "NEW"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.NewUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "NEW"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "OLD"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.RegularUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "OLD"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              !scope.row
                                                .promotionRuleCountrys ||
                                              scope.row.promotionRuleCountrys
                                                .length == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.ThereAreCurrentlyNoProductPromotions"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      119494656
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$i18n.t("common.fixedprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$dict.getDictValue(
                                                            "countrys",
                                                            s.countryCode
                                                          )
                                                        ) +
                                                        ":"
                                                    ),
                                                    s.resultPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.resultPrice.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3281174452
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$i18n.t("common.Fixedprice1"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.fixedPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.countryCode
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                Number(
                                                                  s.fixedPrice
                                                                ).toFixed(2)
                                                              ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2945861441
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$i18n.t("common.Lowestprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.lowestPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.countryCode
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                Number(
                                                                  s.lowestPrice
                                                                ).toFixed(2)
                                                              ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3335674497
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "shelf",
                                      label: _vm.$i18n.t("common.state"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.shelf == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#46a8a0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "common.Listed"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.Notlisted"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2074187132
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.listQuery.page,
                                      "page-size": _vm.listQuery.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.pageData2.total
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      378300803
                    )
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.SubmitConfirmation"),
            visible: _vm.dialogGetPricingVisible,
            width: "30%",
            "append-to-body": "",
            "before-close": _vm.handleGetPricingClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogGetPricingVisible = $event
            }
          }
        },
        [
          _vm.dialogGetPricingVisible
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.getPricing, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "common.CurrentactivitypricesinSpain",
                                    {
                                      countryCode: _vm.$dict.getDictValue(
                                        "countrys",
                                        item.countryCode
                                      )
                                    }
                                  )
                                )
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.profit.toFixed(2)) + "%")
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.dialogGetPricingVisible = false
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function($event) {
                            return _vm.getPricingSubmit(_vm.isEdit)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Prompt"),
            visible: _vm.dialogLowestPriceVisible,
            width: "20%",
            "append-to-body": "",
            "before-close": _vm.handleLowestPriceClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLowestPriceVisible = $event
            }
          }
        },
        [
          _vm.dialogLowestPriceVisible
            ? _c("div", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.typeName)),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common.ThePriceCannotBeLowerThanTheLowestPrice")
                      )
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.checkLowestPrice, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    item.countryCode
                                  )
                                ) +
                                ":"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.price.toFixed(2)))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.dialogLowestPriceVisible = false
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }