<template>
    <div style="height: 100%;min-height:350px;">
		<div class="filter-container">
            <el-form :inline="true">
                
				<el-form-item>
					<el-select v-model="searchData.countryCode"  clearable class="filter-item" style="width: 180px;"  :placeholder='$i18n.t("common.country")'>
						<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code"></el-option>	
					</el-select>
                </el-form-item>
				<el-form-item>
					<el-select v-model="searchData.rebateDimension"  clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.RebateDimension")'>
						<el-option v-for="item in dictionary.rebateDimension" :key="item.id" :label="item.name" :value="item.code"></el-option>	
					</el-select>
                </el-form-item>
				<el-form-item>
					<el-input v-model="searchData.idFuzzy" :placeholder="'SKU/EAN/'+$i18n.t('common.coding')+'/ID'" style="width: 180px; "  clearable></el-input>
				</el-form-item>

				<el-form-item>
					<el-select v-model="searchData.rates"  clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.Rebateratio")'  >
						<el-option v-for="item in dictionaryRates" :key="item.id" :label="item.name" :value="item.value"></el-option>	
					</el-select>
                </el-form-item>

				<el-form-item>
					<el-date-picker v-model="timePicker" clearable  type="datetimerange" range-separator="-"
						 
						:start-placeholder='$i18n.t("common.FromTheEventStartTime")'
						:end-placeholder='$i18n.t("common.UntilTheEventStartTime")'
						format="yyyy-MM-dd HH:mm:ss"  value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00','23:59:59']"
                    />
				</el-form-item>


				<el-form-item>
					<el-select v-model="searchData.rebateGoodsPoolStatus"  clearable class="filter-item" style="width: 180px;  " :placeholder='$i18n.t("common.Activitystatus")'>
						<el-option v-for="item in dictionary.rebateGoodsPoolStatus" :key="item.id" :label="item.name" :value="item.code"></el-option>	
					</el-select>
                </el-form-item>
			 
                <el-form-item>
					<el-select v-model="searchData.status"  clearable class="filter-item" style="width: 180px;  " :placeholder='$i18n.t("common.Enableornot")'  >
						<el-option v-for="item in dictionary.status" :key="item.id" :label="item.name" :value="item.value"></el-option>	
					</el-select>
                </el-form-item>

                <el-form-item>
					<el-select v-model="searchData.updateBy"  clearable class="filter-item" style="width: 180px;  " :placeholder='$i18n.t("common.RecentOperator")' >
						<el-option v-for="item in dictionaryUpdateBy" :key="item.user_id" :label="item.username" :value="item.user_id"></el-option>	
					</el-select>
                </el-form-item>

                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleSearchList"
                               style="margin-left: 10px">
							   {{ $i18n.t("common.query") }} 
                    </el-button>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery"
                               style="margin-left: 10px" size="small">
							   {{ $i18n.t("common.Reset") }} 
                    </el-button>
                </el-form-item>

            </el-form>
        </div>


        <div style="margin: 0px 0px 10px 20px;">
            <el-button @click="add" type="success" size="small"  > {{ $i18n.t("common.Add") }}  </el-button>
        </div>

 

        <el-table ref="pagedata" height="calc(100% - 200px)" :data="pageData.list" border v-loading="loading" style="width: 100%">
            <el-table-column prop="shoppingRebateGoodsPoolId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="countryCode" :label="$i18n.t('common.country')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dict.getDictValue('countrys', scope.row.countryCode) }}
                </template>
            </el-table-column>

            <el-table-column prop="rebateDimension" :label="$i18n.t('common.RebateDimension')"  align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.rebateDimension == 'country'" >{{ $i18n.t("common.country")   }}</span>
					<span v-if="scope.row.rebateDimension == 'classify'" >{{ $i18n.t("common.Class")  }}</span>
					<span v-if="scope.row.rebateDimension == 'goods'"  >{{ $i18n.t("common.commodity")   }}</span>
				</template>       
            </el-table-column>  

  
            <el-table-column prop="rebateObject" :label="$i18n.t('common.RebateObject')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
					<span v-if="scope.row.rebateDimension == 'country'" >{{ $dict.getDictValue('countrys', scope.row.rebateObject) }}</span>
					<span v-if="scope.row.rebateDimension != 'country'" >{{ scope.row.rebateObject }}</span>
				</template>
            </el-table-column>
	 
            <el-table-column  :label="$i18n.t('common.RebateObjectNumber')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
					<span v-if="scope.row.rebateDimension == 'country'" >{{  scope.row.rebateObjectCountry }}</span>
					<span v-if="scope.row.rebateDimension == 'classify'" >{{ scope.row.rebateObjectClassifyId }}</span>
					<span v-if="scope.row.rebateDimension == 'goods'" >SKU:{{ scope.row.sku }}</span>
					<br   v-if="scope.row.rebateDimension == 'goods'" />
                    <span v-if="scope.row.rebateDimension == 'goods'" >EAN:{{ scope.row.barCode }}</span>
					<br   v-if="scope.row.rebateDimension == 'goods'" />
                    <span v-if="scope.row.rebateDimension == 'goods'" >{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>

				</template>
            </el-table-column>


            <el-table-column prop="rates" :label="$i18n.t('common.Rebateratio')" align="center" show-overflow-tooltip>
                <template slot-scope="scope"> {{ scope.row.rates }}% </template>
            </el-table-column>

            <el-table-column prop="startTime" :label="$i18n.t('common.EventStartTime')"    align="center" show-overflow-tooltip :formatter="formatDate">
               
            </el-table-column>
            <el-table-column prop="endTime" :label="$i18n.t('common.EventEndTime')"    align="center" show-overflow-tooltip :formatter="formatDate">
               
            </el-table-column>
	 
			<el-table-column prop="rebateGoodsPoolStatus" :label="$i18n.t('common.state')"  align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.rebateGoodsPoolStatus == 'NotStarted'" style="color: black">{{ $i18n.t("common.begininaminute")   }}</span>
					<span v-if="scope.row.rebateGoodsPoolStatus == 'UnderWay'" style="color: green">{{ $i18n.t("common.Inprogress") }}</span>
				    <span v-if="scope.row.rebateGoodsPoolStatus == 'Complete'" style="color: gray">{{ $i18n.t("common.Ended")  }}</span>
				</template>       
			</el-table-column>

			<el-table-column prop="status" :label="$i18n.t('common.Enableornot')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope"> 
					<el-switch
					  	v-model="scope.row.status"
					  	active-color="#13ce66"
                    	inactive-color="#ff4949"
					  	disabled>
					</el-switch>
                </template>
            </el-table-column>

 
            <el-table-column prop="updateTime" :label="$i18n.t('common.RecentOperationTime')" align="center" show-overflow-tooltip :formatter="formatDate" >
			
            </el-table-column> 

            <el-table-column prop="updateUserName" :label="$i18n.t('common.RecentOperator')"  align="center" show-overflow-tooltip></el-table-column>
          
            <el-table-column :label="$i18n.t('common.operation')"  align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                 
					<el-button :disabled="scope.row.buttonEdit"  @click="edit(scope.row)" type="text" size="small"  > {{ $i18n.t("common.edit") }}</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small"  > {{ $i18n.t("common.delete") }}</el-button>

                </template>
            </el-table-column>
        </el-table>

        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="searchData.page"
                :page-size="searchData.limit"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            >
            </el-pagination>
        </div>
       

		<el-dialog :title="isEdit ?  $i18n.t('common.edit') : $i18n.t('common.New')" :visible.sync="dialogAddVisible" :before-close="closeDialog" :close-on-click-modal="false">
            <div v-if="dialogAddVisible">
                <add-dialog :isEdit="isEdit" :formItem="formItem" :dictionary="dictionary" @closeDialog="closeDialog" />
            </div>
        </el-dialog>

    </div>
</template>
<script>
	 
	import AddDialog from './components/AddDialog.vue';
	import moment from 'moment';
	import i18n from '@/i18n/i18n';
    import dictionaryUtil from '@/utils/dictionaryUtil';

	const defaultFormItem= {
		shoppingRebateId: null,
		status: 1, //0禁用 1启用
		countryCode: '',
		rebateDimension: null,
		time: [],
        startTime: null,
        endTime: null,
		rebateObjectCountry: null,
		rebateObjectClassifyId: null,
		rebateObjectGoodsId: null
    }
	const defaultDictionary={
		// 国家
		country:dictionaryUtil.getDict('countrys'),
		//返利维度
		rebateDimension: [
			{
			id: '1',
			name: i18n.t('common.country') ,
			code: 'country'
			},
			{
			id: '2',
			name: i18n.t('common.Class') ,
			code: 'classify'
			},
			{
			id: '3',
			name: i18n.t('common.commodity') ,
			code: 'goods'
			}
		],
		// 满返利活动状态
		rebateGoodsPoolStatus: [
			{
			id: '1',
			name:  i18n.t('common.begininaminute') ,
			code: 'NotStarted'
			},
			{
			id: '2',
			name:  i18n.t('common.Inprogress') ,
			code: 'UnderWay'
			},
			{
			id: '3',
			name:  i18n.t('common.Ended') ,
			code: 'Complete'
			}
		],
		// 满减活动是否启用
		status: [
			{
				id: '1',
				name:  i18n.t('common.yes') ,
				value: true
			},
			{
				id: '2',
				name:  i18n.t('common.no') ,
				value: false
			}
		]
	};

	export default {
		components: {
			AddDialog
		},
		name: 'rebateGoodsPool',
		data() {
			return {
				 
				//编辑or新增
				isEdit: false,
				dictionary: Object.assign({}, defaultDictionary),
				//返利比例
				dictionaryRates: [],
				//最近操作人
				dictionaryUpdateBy: [],
				//时间选择器
				timePicker: [],
				//查询项
				searchData: {
					countryCode: null,
					rebateDimension: null,
					idFuzzy: null,
					rates: null,
					startTime: null,
					endTime: null,
					rebateGoodsPoolStatus: null,
					status: null,
					updateBy: null,
					page: 1,
					limit: 10
				},
				defaultSearchData: {
					countryCode: null,
					rebateDimension: null,
					idFuzzy: null,
					rates: null,
					startTime: null,
					endTime: null,
					rebateGoodsPoolStatus: null,
					status: null,
					updateBy: null,
					page: 1,
					limit: 10
				},
				loading: false,
				dialogAddVisible:false,
				formItem: Object.assign({}, defaultFormItem),	
				pageSizes: [10, 20, 30, 50],
      			pageData: {}
			};
		},
		mounted() {
			this.operatorList();
			this.ratesList();
			this.handleSearchList();
		},
		watch: {
			$route(to, from) { 
				// console.log('to',to)
				if (to.path === '/b2c/rebateGoodsPool/index') {
					this.ratesList();		 
				}
			}
		},
		methods: {
			//搜索列表
			handleSearchList(){											
				this.searchData.page = this.defaultSearchData.page
				this.searchData.limit = this.defaultSearchData.limit 
				this.loadlist();
			},
			// 重置
			cleanQuery() {
				this.timePicker = []
				this.searchData = Object.assign({}, this.defaultSearchData)
				this.loadlist();
			},
			// 点击页数查询
			handleCurrentChange(val) {
				this.searchData.page = val;
				this.loadlist();
			},
			loadlist(){
			    this.loading = true;
				var _this = this;
				if (this.timePicker != null && this.timePicker.length > 0) {
					this.searchData.startTime = new Date(this.timePicker[0]).getTime();
					this.searchData.endTime =   new Date(this.timePicker[1]).getTime();
				} else {
					this.searchData.startTime = null;
					this.searchData.endTime = null;
				}

				let temp = JSON.stringify(this.searchData, (key, value) => {
					if (value !== null && value !== '') {
						return value;
					}
				});
				let data = JSON.parse(temp);
				this.$store.dispatch('getRebateGoodsPoolPage', data).then((res) => {
					_this.pageData = res;
				}).finally(() => {
                    this.loading = false;
                });
			},	
			add(){
				this.isEdit=false
				this.dialogAddVisible=true
				this.formItem = Object.assign({}, defaultFormItem)
			},
			edit(row){
				this.isEdit=true
				let data = Object.assign({}, row)
				let type='yyyy-MM-dd hh:mm:ss'
				data.time=[]
				if(data.startTime){
					data.time.push(this.$options.filters['formatDate'](data.startTime,type))
				}
				if(data.endTime){
					data.time.push(this.$options.filters['formatDate'](data.endTime,type))
				}
				// console.log(data)
				data.status=row.status==true?1:0
				this.formItem=Object.assign({}, data)
				this.dialogAddVisible=true							
			},
			del(row){
				this.$confirm(this.$i18n.t("common.DeleteOrNot")+'?' , this.$i18n.t("common.prompt") , {
				  confirmButtonText: this.$i18n.t("common.confirm1") ,
				  cancelButtonText: this.$i18n.t("common.cancel")   ,
				  type: 'warning'
				}).then(() => {
					this.$store.dispatch("deleteRebateGoodsPool", {
						id:row.shoppingRebateGoodsPoolId,
					}).then((res) => {
						let totalPage = Math.ceil((this.searchData.total - 1) / this.searchData.limit)
						let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
						this.searchData.page = currentPage < 1 ? 1 : currentPage
						this.$message.success( this.$i18n.t("common.Successfullydeleted"));
						this.loadlist();					 
					});
				});
			},
 
			//关闭窗口
			closeDialog(status,isEdit){
				this.operatorList(); 
				this.dialogAddVisible=false
				this.formItem=Object.assign({}, defaultFormItem)
				if(status){
					if(isEdit){
						this.loadlist();
					}else{
						this.handleSearchList();					
					}   
				}				
			},

			// 查询 返利商品池的操作人信息
			operatorList(){
				this.dictionaryUpdateBy = [];
				this.$store.dispatch("getOperatorList", {}).then((res) => {
					this.dictionaryUpdateBy=res;
					console.log('this.dictionaryUpdateBy',this.dictionaryUpdateBy)
				}); 
			},
			//  获取返利比例集合 拥有 返利商品池查询条件 返利比例业务字段
			ratesList(){
				this.dictionaryRates = [];
				this.$store.dispatch("getRatesList", {}).then((res) => {
					res.forEach((item,index) => {
						let data = {
							id: index,
							name: item+'%',
							value: item
						};
						this.dictionaryRates.push(data);
					});	
				}); 
			},
			/**
			 * 时间戳转码
			 */
			formatDate(row, column) {
				// 获取单元格数据
				let data = row[column.property];
				if (!data) {
					return '';
				} else {
					return moment(data).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}
	};
</script>
<style scoped>
 
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}


.input-width {
    width: 203px;
}

 
 
</style>
