<template> 
	<div>
		<!--
		<el-radio-group v-model="brandCode" size="mini" @change="changebrandCode">
		      <el-radio label="AigoStar" border>Aigostar</el-radio>
		      <el-radio label="Nobleza" border>Nobleza</el-radio>
			  <el-radio label="TaylorSwoden" border>TaylorSwoden</el-radio>
		</el-radio-group>
		-->
		<el-radio-group v-model="homeTextConfigModuleType" size="medium" @change="changeHomeTextConfigModuleType">
			<el-radio-button label="HomePageColumn">{{$i18n.t("common.HomePageColumn")}}</el-radio-button>
			<el-radio-button label="ColumnDescription">{{$i18n.t("common.ColumnDescription")}}</el-radio-button>
		</el-radio-group>
 

		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane v-for="(item,index) in list" :key="index" :label="item.name" :name="item.enum">		
				<el-form ref="titleFrom" label-width="100px">
					<el-form-item v-if="falg" v-for="(item,index) in languageList" :key="index" :label="item.name">
						<el-input  show-word-limit maxlength="300" v-model="item.content"  @input="forceUpdate($event)" :placeholder='$i18n.t("common.HomepageConfiguration")'></el-input>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="submit"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>
	</div>
</template>
<script>
	import cache from '@/utils/cache';
	export default {
		props: {
			// pageData:{
			// 	type: Object,
			// 	default: () => {
			// 		return {}
			// 	}
			// },
			// listQuery:{
			// 	type: Array,
			// 	default: () => {
			// 		return []
			// 	}
			// },
			langList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				falg:false,

				homeTextConfigId: '',
				HomeTextConfigRecommendModule:"",
				homeTextConfigModuleType:"HomePageColumn",

				formLabelWidth: '120px',
				activeName: "seckill",
				list: [],
				columnDescriptionList: [{
					id: "1",
					name: this.$i18n.t("common.NewProductZone"),
					enum:'NewProducts',
					value: '',
				}, {
					id: "2",
					name: this. $i18n.t("common.HotSellingZone"),
					enum:'TopRankings',
					value: '',
				}, {
					id: "3",
					name: this. $i18n.t("common.Recommendedforyou1"),
					enum:'RecommendationsForYou',
					value: '',
				}],
				homePageColumnList: [{
					id: "1",
					name: this.$i18n.t("common.FlashSaleArea"),
					enum:'seckill',
					value: '',
				}, {
					id: "2",
					name: this.$i18n.t("common.NewProductZone"),
					enum:'NewProducts',
					value: '',
				}, {
					id: "3",
					name: this.$i18n.t("common.HotSellingZone"),
					enum:'TopRankings',
					value: '',
				}, {
					id: "4",
					name: this.$i18n.t("common.Recommendedforyou"),
					enum:'RecommendationsForYou',
					value: '',
				}, {
					id: "5",
					name: this.$i18n.t("common.SciencePopularizationZone"),
					enum:'science',
					value: '',
				}, {
					id: "6",
					name: this.$i18n.t("common.BrandStory"),
					enum:'story',
					value: '',
				}, {
                    id: "7",
                    name: this.$i18n.t("common.UGCZone"),
                    enum:'ugc',
                    value: '',
                }],
				languageList: []
			};
		},
		methods: {
			submit() {
				const data={
					homeTextConfigId: this.homeTextConfigId,
					recommendModule:this.HomeTextConfigRecommendModule,
					homeTextConfigModuleType:this.homeTextConfigModuleType,
					homeTextLangDtoList:this.languageList
				}
				this.$store.dispatch("postNaviConfig", data).then(res => {
					this.$message({
						type: 'success',
						message: this. $i18n.t("common.Successfullysubmitted"),
					});
					this.closeDialog()
					this.handleSearchList()
				});
			},
			forceUpdate(e) {
				this.$forceUpdate()
			},
			getNaviConfig(){
				this.languageList =  JSON.parse(JSON.stringify(this.langList)), 
				this.falg=false
				const data={
					recommendModule:this.HomeTextConfigRecommendModule,
					homeTextConfigModuleType:this.homeTextConfigModuleType
				}
				this.$store.dispatch("getNaviConfig", data).then(res => {
					this.$nextTick(()=>{
						if(res && res.homeTextLangList && res.homeTextLangList.length>0){
							res.homeTextLangList.forEach((item,index)=>{
								this.languageList.forEach((item2,index2)=>{
									if(item.content && item.lang==item2.lang){
										this.languageList[index2].content=res.homeTextLangList[index].content
									}															
								})									
							})
						} 
						if(res && res.homeTextConfigId ){
							this.homeTextConfigId = res.homeTextConfigId
						} 
						this.falg=true
					})				
				});
			},
			handleSearchList(){
				this.$emit('handleSearchList')
			},
			closeDialog(){
				this.$emit('closeDialog')
			},
			handleClick(val){
				// console.log('val',val)
				this.HomeTextConfigRecommendModule=val.name
				this.getNaviConfig()
			},
			changeHomeTextConfigModuleType(){				
				if(this.homeTextConfigModuleType == 'ColumnDescription'){
					this.activeName="NewProducts"
					this.list = this.columnDescriptionList
					this.HomeTextConfigRecommendModule='NewProducts'
				}else{
					this.activeName="seckill"
					this.list = this.homePageColumnList
					this.HomeTextConfigRecommendModule='seckill'
				}
				this.getNaviConfig()
			}
			// changebrandCode(){
			// 	this.getNaviConfig()
			// }
		},
		mounted(){
			this.langList.forEach((item,index)=>{
				this.langList[index].lang=this.langList[index].code
			})
			this.changeHomeTextConfigModuleType();
			// this.getNaviConfig()		
		}
	};
</script>
<style scoped>
	.input-width {
		width: 70%;
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tree-container {
		border: 1px solid #EBEEF5;
		padding: 20px 10px;
		min-height: 400px;
		overflow-y: scroll;
	}

 
</style>
