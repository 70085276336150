var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleActiveClick },
          model: {
            value: _vm.active_Name,
            callback: function($$v) {
              _vm.active_Name = $$v
            },
            expression: "active_Name"
          }
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$i18n.t("common.Basicinformation"),
                name: "1"
              }
            },
            [
              _c(
                "div",
                { staticClass: "descriptions" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { column: 3 } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.ProductID1") } },
                        [_vm._v(_vm._s(_vm.formData.goodsId))]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "SKU" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formData.sku))])
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "EAN" } }, [
                        _vm._v(_vm._s(_vm.formData.barCode))
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.number") } },
                        [_vm._v(_vm._s(_vm.formData.goodsCode))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("common.Numberofitems") } },
                        [_vm._v(_vm._s(_vm.formData.amount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Creationtime") }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(_vm.formData.createTime)
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Sizeinformation")
                          }
                        },
                        [_vm._v(_vm._s(_vm.formData.goodsSize1))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.weight") } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-start",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "inputNumber",
                                attrs: {
                                  disabled: "",
                                  type: "number",
                                  oninput:
                                    "if(value>1000)value=1000;if(value<0)value=0;if(value.length>13)value=value.slice(0,13)value = value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,5})?.*$/,'$1')",
                                  "show-word-limit": "",
                                  placeholder: _vm.$t(
                                    "common.PleaseEnterTheWeight"
                                  ),
                                  size: "mini"
                                },
                                on: { input: _vm.handleInput },
                                model: {
                                  value: _vm.formListQuery.peso,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formListQuery, "peso", $$v)
                                  },
                                  expression: "formListQuery.peso"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-left": "5px"
                                  }
                                },
                                [_vm._v("kg")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.volume") } },
                        [_vm._v(_vm._s(_vm.formData.cbm))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Salesmultiples") }
                        },
                        _vm._l(_vm.formData.goodsSpecials, function(
                          item,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "countrys",
                                  item.countryCode
                                )
                              ) +
                                "/" +
                                _vm._s(item.saleDoubleEbpNew) +
                                "  "
                            )
                          ])
                        }),
                        0
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Numberofitems") }
                        },
                        [_vm._v(_vm._s(_vm.formData.amount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("common.PackingQuantity") } },
                        [_vm._v(_vm._s(_vm.formData.packageQuantity))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "from-item" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.$i18n.t("common.country")) +
                    ":\n\t\t\t\t"
                )
              ]),
              _c(
                "div",
                { staticClass: "from-item" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formListQuery.countryCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.formListQuery, "countryCodes", $$v)
                        },
                        expression: "formListQuery.countryCodes"
                      }
                    },
                    _vm._l(_vm.dictionary.country, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: item.code, attrs: { label: item.code } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "from-item" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.$i18n.t("common.Category")) +
                    ":\n\t\t\t\t"
                )
              ]),
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  props: _vm.defaultProps,
                  data: _vm.dictionary.classifyTree,
                  "node-key": "goodsClassifyId",
                  "default-checked-keys": _vm.checkedKeys,
                  "show-checkbox": ""
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$i18n.t("common.inventory"), name: "2" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.openStockLimitDialog }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.scene.isNonSelf
                        ? _vm.$t("common.edit")
                        : _vm.$t("common.EditBottomLineInventory")
                    )
                  )
                ]
              ),
              _c(
                "el-table",
                {
                  ref: "pagedata",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.formData.goodsSpecials, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "countryCode",
                      label: _vm.$i18n.t("common.country"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$dict.getDictValue(
                                      "countrys",
                                      scope.row.countryCode
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ecomRemainQty",
                      label: _vm.$i18n.t("common.inventory"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.ecomRemainQty
                                      ? scope.row.ecomRemainQty
                                      : 0
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stockLimit",
                      label: _vm.$t("common.Inventorybottomline"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.stockLimit >= 0 &&
                                      !_vm.isStockLimit,
                                    expression:
                                      "scope.row.stockLimit >= 0 && !isStockLimit"
                                  }
                                ]
                              },
                              [_vm._v(" " + _vm._s(scope.row.stockLimit) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$t("common.Cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
          )
        ],
        1
      ),
      _vm.dialogfixedPriceVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.dialogfixedPriceVisible,
                width: "30%",
                "append-to-body": "",
                "before-close": _vm.fixedPriceClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogfixedPriceVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    { ref: "titleFrom", attrs: { "label-width": "100px" } },
                    _vm._l(_vm.formData.prices, function(item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.countryCode,
                          attrs: {
                            label: _vm.$dict.getDictValue(
                              "countrys",
                              item.countryCode
                            )
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("common.PleaseEnterThePrice")
                            },
                            on: {
                              input: function($event) {
                                return _vm.check_num(item)
                              },
                              blur: function($event) {
                                return _vm.check_blurNum(item)
                              }
                            },
                            model: {
                              value: item.fixedPrice,
                              callback: function($$v) {
                                _vm.$set(item, "fixedPrice", $$v)
                              },
                              expression: "item.fixedPrice"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.fixedPriceClose } }, [
                        _vm._v(_vm._s(_vm.$t("common.Cancel")))
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.fixedPriceLoading
                          },
                          on: { click: _vm.postFixedPrice }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Prompt"),
            visible: _vm.dialogLowestPriceVisible,
            width: "20%",
            "append-to-body": "",
            "before-close": _vm.handleLowestPriceClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLowestPriceVisible = $event
            }
          }
        },
        [
          _vm.dialogLowestPriceVisible
            ? _c("div", [
                _c("h4", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "common.FixedPriceCannotBeLowerThanTheLowestPrice"
                        )
                      ) + "："
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.checkLowestPrice, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    item.countryCode
                                  )
                                ) +
                                ":"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.price.toFixed(2)))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c("el-button", { on: { click: _vm.updateLowestPrice } }, [
                      _vm._v(_vm._s(_vm.$t("common.Confirm")))
                    ])
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.scene.isNonSelf
              ? _vm.$t("common.edit")
              : _vm.$t("common.BottomInventory"),
            visible: _vm.dialogStockLimitVisible,
            "append-to-body": "",
            width: "30%",
            "before-close": _vm.closeStockLimitDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogStockLimitVisible = $event
            }
          }
        },
        [
          _vm.dialogStockLimitVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "stockLimitForm",
                      attrs: { "label-width": "120px" }
                    },
                    [
                      _vm.scene.isNonSelf
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "stock-table",
                                  attrs: { data: _vm.goodsSpecials }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("common.country"),
                                      align: "center",
                                      prop: "countryCode"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$dict.getDictValue(
                                                      "countrys",
                                                      scope.row.countryCode
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1851856162
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("common.inventory"),
                                      align: "center",
                                      prop: "ecomRemainQty"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("el-input", {
                                                staticStyle: { width: "80px" },
                                                attrs: {
                                                  placeholder: "请输入库存",
                                                  oninput:
                                                    "value=value.replace(/\\D/g,'')"
                                                },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.tableInputBlur(
                                                      scope.row
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    scope.row.ecomRemainQty,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "ecomRemainQty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.ecomRemainQty"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1162588598
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t(
                                        "common.Inventorybottomline"
                                      ),
                                      align: "center",
                                      prop: "stockLimit"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("el-input", {
                                                staticStyle: { width: "80px" },
                                                attrs: {
                                                  placeholder: "请输入底线库存",
                                                  oninput:
                                                    "value=value.replace(/\\D/g,'')"
                                                },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.tableInputBlur(
                                                      scope.row
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: scope.row.stockLimit,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "stockLimit",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.stockLimit"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      871193453
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm._l(_vm.goodsSpecials, function(item) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: item.countryCode,
                                    attrs: {
                                      label: _vm.$dict.getDictValue(
                                        "countrys",
                                        item.countryCode
                                      )
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        oninput:
                                          "value=value.replace(/\\D/g,'')",
                                        placeholder: "请输入底线库存"
                                      },
                                      model: {
                                        value: item.stockLimit,
                                        callback: function($$v) {
                                          _vm.$set(item, "stockLimit", $$v)
                                        },
                                        expression: "item.stockLimit"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "common.Synchronizetoothertenants"
                                    ),
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.curTenantList,
                                        callback: function($$v) {
                                          _vm.curTenantList = $$v
                                        },
                                        expression: "curTenantList"
                                      }
                                    },
                                    _vm._l(_vm.tenantList, function(item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.tenant_id,
                                          attrs: { label: item.tenant_id }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(item.name) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeStockLimitDialog } }, [
                _vm._v(_vm._s(_vm.$t("common.Cancel")))
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.fullscreenLoading },
                  on: { click: _vm.saveStockLimit }
                },
                [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogfixedPriceVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.dialogfixedPriceVisible,
                width: "30%",
                "append-to-body": "",
                "before-close": _vm.fixedPriceClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogfixedPriceVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    { ref: "titleFrom", attrs: { "label-width": "100px" } },
                    _vm._l(_vm.formData.prices, function(item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.countryCode,
                          attrs: {
                            label: _vm.$dict.getDictValue(
                              "countrys",
                              item.countryCode
                            )
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("common.PleaseEnterThePrice")
                            },
                            on: {
                              input: function($event) {
                                return _vm.check_num(item)
                              },
                              blur: function($event) {
                                return _vm.check_blurNum(item)
                              }
                            },
                            model: {
                              value: item.fixedPrice,
                              callback: function($$v) {
                                _vm.$set(item, "fixedPrice", $$v)
                              },
                              expression: "item.fixedPrice"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.fixedPriceClose } }, [
                        _vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.fixedPriceLoading
                          },
                          on: { click: _vm.postFixedPrice }
                        },
                        [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.prompt"),
            visible: _vm.dialogLowestPriceVisible,
            width: "20%",
            "append-to-body": "",
            "before-close": _vm.handleLowestPriceClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLowestPriceVisible = $event
            }
          }
        },
        [
          _vm.dialogLowestPriceVisible
            ? _c("div", [
                _c("h4", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "common.FixedPriceCannotBeLowerThanTheLowestPrice"
                        )
                      ) + "："
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.checkLowestPrice, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    item.countryCode
                                  )
                                ) +
                                ":"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.price.toFixed(2)))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c("el-button", { on: { click: _vm.updateLowestPrice } }, [
                      _vm._v(_vm._s(_vm.$i18n.t("common.confirm")))
                    ])
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }