<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="200px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.idFuzz"
                        :placeholder="$i18n.t('common.SKU_EAN_Encoding')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.giftActivityName"
                        :placeholder="$i18n.t('common.ActivityName')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.pageName"
                        :placeholder="$i18n.t('common.LandingPageActivityName')"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="false"
                        :placeholder="$i18n.t('common.country')"
                        style="width: 180px"
                        class="filter-item"
                        clearable
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="query.startTime" clearable type="datetime" :placeholder="$i18n.t('common.Startdate')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="query.endTime"
                        clearable
                        type="datetime"
                        :picker-options="pickerOptions"
                        :placeholder="$i18n.t('common.Enddate')"
                        default-time="['23:59:59']"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.activityStatus"
                        clearable
                        :codeType="'activity_status'"
                        :placeholder="$i18n.t('common.Activitystatus')"
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        clearable
                        class="filter-item"
                        style="width: 180px"
                        :placeholder="$i18n.t('common.Enableornot')"
                    >
                        <el-option :label="$i18n.t('common.Enable')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.Disabled')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.isOverlay"
                        clearable
                        class="filter-item"
                        style="width: 180px"
                        :placeholder="$i18n.t('common.SuperimposedEvents')"
                    >
                        <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        {{ $i18n.t('common.query') }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                        {{ $i18n.t('common.Reset') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" icon="el-icon-edit" @click="handleCreate">
                {{ $i18n.t('common.Add1') }}
            </el-button>

            <el-button
                class="filter-item"
                style="margin: 10px 0px 10px 20px"
                type="success"
                icon="el-icon-edit"
                @click="handleGiftActivitySort"
            >

                {{ $i18n.t('common.ActivitySorting') }}
            </el-button>

            <el-button
                class="filter-item"
                style="margin: 10px 0px 10px 20px"
                type="success"
                icon="el-icon-edit"
                @click="handleGiftActivityPublicityChart"
            >
                {{ $i18n.t('common.GiftsTopicManagement') }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" height="calc(100% - 230px)" :data="list.list" border stripe style="width: 100%; margin-top: 10px" >
            <el-table-column :label="$i18n.t('common.GiveawayEventID')" align="center" width="160px" prop="giftActivityId">
            </el-table-column>
            <el-table-column :label="$i18n.t('common.GiveawayEventName')" prop="giftActivityName" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.LandingPageActivityName')" prop="pageName" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.ProductCategory')" prop="productNum" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.country')" prop="countryCode" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>{{ $dict.getDictValue('countrys', scope.row.countryCode) }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.ActivityMode')" prop="activityType" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>{{ $dict.getDictValue('gift_activity_type', scope.row.activityType) }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.TotalNumberOfActivities')" prop="allActivityNum" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.GiftsGivenQuantity')" prop="allQuantityGiven" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('common.starttime')"
                prop="startTime"
                align="center"
                show-overflow-tooltip
                :formatter="formatDate"
            >
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Endtime')" prop="endTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Activitystatus')" prop="activityStatus" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.activityStatus == 2" style="color: red">{{ $i18n.t('common.Upcoming') }}</span>
                    <span v-if="row.activityStatus == 1" style="color: green">{{ $i18n.t('common.Inprogress') }}</span>
                    <span v-if="row.activityStatus == 0" style="color: gray">{{ $i18n.t('common.Ended') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('common.EnabledStatus')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        <span :style="{ color: scope.row.status ? 'green' : 'red' }">
                            {{ scope.row.status ? $t('common.Enable') : $t('common.Disabled') }}
                        </span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.SuperimposedEvents')" prop="isOverlay" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.isOverlay == 1" >{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.isOverlay == 0" >{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.updateTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="updateByName" :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.updateByName">{{ row.updateByName }}</span>
                    <span v-else>{{ $i18n.t("common.SystemAutoUpdate") }}</span>
                </template>
            </el-table-column>		
            <el-table-column :label="$i18n.t('common.operation')" align="center" show-overflow-tooltip width="250px">
                <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="handleUpdate(row)" class="table-inner-button">
                        <span v-if="row.activityStatus == 0">{{ $i18n.t('common.check') }}</span>
                        <span v-else>{{ $i18n.t('common.edit') }}</span>
                    </el-button>
                    <el-button type="text" size="small" @click="handlePageSetting(row)" class="table-inner-button">
                        {{ $i18n.t('common.ThemeConfiguration') }}</el-button
                    >
                    <el-button type="text" size="small" @click="handleGiftSort(row)" class="table-inner-button">
                        {{ $i18n.t('common.GiftSorting') }}</el-button
                    >
                    <el-button
                        type="text"
                        size="small"
                        @click="handleDeleteSingle(row)"
                        class="table-inner-button"
                        :disabled="row.activityStatus != 2"
                    >
                        {{ $i18n.t('common.delete') }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addGiftActivity"
                label-position="right"
                size="small"
                label-width="120px"
                style="width: 710px; margin-left: 50px"
            >
                <el-form-item :required="true" :label="$i18n.t('common.Enableornot')" prop="status">
                    <el-switch v-model="addGiftActivity.status" active-color="#13ce669"></el-switch>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.ActivityName')" prop="giftActivityName">
                    <el-input
                        v-model="addGiftActivity.giftActivityName"
                        style="width: 100%"
                        show-word-limit
                        maxlength="100"
                        :placeholder="$i18n.t('common.ActivityName')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.LandingPageActivityName')" prop="pageName">
                    <el-input
                        v-model="addGiftActivity.pageName"
                        style="width: 100%"
                        show-word-limit
                        maxlength="100"
                        :placeholder="$i18n.t('common.LandingPageActivityName')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Activitytime')" prop="startTime" :required="true">
                    <el-date-picker
                        v-model="addGiftActivity.startTime"
                        clearable
                        type="datetime"
                        :disabled="formTitle == $i18n.t('common.edit') && addGiftActivity.activityStatus == 1"
                        :placeholder="$i18n.t('common.Startdate')"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span> - </span>
                    <el-date-picker
                        v-model="addGiftActivity.endTime"
                        clearable
                        type="datetime"
                        :picker-options="pickerOptions"
                        :placeholder="$i18n.t('common.Enddate')"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        default-time="['23:59:59']"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item :required="true" :label="$i18n.t('common.country')" prop="countryCode">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        :disabled="formTitle == $i18n.t('common.edit') && addGiftActivity.activityStatus == 1"
                        v-model="addGiftActivity.countryCode"
                        clearable
                        :codeType="'countrys'"
                        :placeholder="$i18n.t('common.country')"
                        @change="countryChange"
                    ></dataSelect>
                </el-form-item>
                <el-form-item :required="true" :label="$i18n.t('common.ActivityMode')" prop="activityType">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addGiftActivity.activityType"
                        clearable
                        :codeType="'gift_activity_type'"
                        :placeholder="$i18n.t('common.ActivityMode')"
                    ></dataSelect>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.ActivityRating')" prop="activityRating">
                    <el-input-number
                        class="input_number"
                        v-model="addGiftActivity.activityRating"
                        @keydown.native="channelInputLimit"
                        @change="handleRatingChange"
                        :disabled="formTitle == $i18n.t('common.edit') && addGiftActivity.activityStatus == 1"
                        :min="1"
                        :max="10"
                    ></el-input-number>
                    <div class="my-form">
                        <el-form v-for="(num, index) in addGiftActivity.activityRating" :key="num">
                            <el-form-item class="my-form-item" :prop="String(num)" :rules="ratingRules.activityRating">
                                <span style="margin-right: 10px" v-html="indexIcon(num)"></span>
                                <el-input
                                    class="my-input"
                                    oninput="value=value.replace(/[^0-9.]/g,'')"
                                    v-model="addGiftActivity.giftActivityRatings[num - 1].orderAmt"
                                    :placeholder="$i18n.t('common.Orderamount')"
                                ></el-input>
                                -
                                <el-input
                                    class="my-input"
                                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                    v-model="addGiftActivity.giftActivityRatings[num - 1].giftNum"
                                    :placeholder="$i18n.t('common.GiftsQuantity')"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.SuperimposedEvents')" :required="true">
                    <el-switch v-model="addGiftActivity.isOverlay" active-color="#13ce669"></el-switch>
                </el-form-item>
                <el-form-item :label="$i18n.t('common.Gifts')" :required="true">
                    <el-button type="primary" @click="chooseGift">{{ $i18n.t('common.SelectAGift') }}</el-button>
                    <el-table :data="addGiftActivity.giftActivityDetails">
                        <el-table-column :label="$i18n.t('common.Serialnumber')" type="index"></el-table-column>
                        <el-table-column :label="$i18n.t('common.barcode')" prop="barCode" width="120px"></el-table-column>
                        <el-table-column
                            :label="$i18n.t('common.ProductName')"
                            prop="title"
                            width="100px"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column :label="$i18n.t('common.CurrentInventory')" prop="ecomRemainQty"></el-table-column>
                        <el-table-column :label="$i18n.t('common.TotalNumberOfActivities')" prop="activityNum">
                            <template slot-scope="scope">
                                <el-input-number
                                    v-model="scope.row.activityNum"
                                    size="small"
                                    @change="changeValue()"
                                    :controls="false"
                                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                    style="width: 60px"
                                    :min="1"
                                ></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="formTitle == $i18n.t('common.edit')"
                            :label="$i18n.t('common.GiftsGivenQuantity')"
                            prop="quantityGiven"
                        ></el-table-column>
                        <el-table-column :label="$i18n.t('common.state')" prop="enableStatus">
                            <template slot-scope="scope">
                                <span>
                                    <span :style="{ color: scope.row.enableStatus ? 'green' : 'red' }">
                                        {{ scope.row.enableStatus ? $t('common.Enable') : $t('common.Disabled') }}
                                    </span>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <!-- </div> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()" v-if="formTitle != $i18n.t('common.check')"> {{ $i18n.t('common.cancel') }} </el-button>
                <el-button
                    type="primary"
                    @click="handleSubmit()"
                    :disabled="formTitle == $i18n.t('common.check')"
                    v-if="formTitle != $i18n.t('common.check')"
                >
                    {{ $i18n.t('common.confirm') }}
                </el-button>
                <el-button @click="closeForm()" v-if="formTitle == $i18n.t('common.check')">{{ $i18n.t('common.close') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="giftActivityPublicityChartTitle"
            :visible.sync="giftActivityPublicityChartVisible"
            :close-on-click-modal="false"
            v-if="giftActivityPublicityChartVisible"
        >
            <giftActivityPublicityChart ref="giftActivityPublicityChart" @closeGiftActivityPublicityChart="closeGiftActivityPublicityChart">
            </giftActivityPublicityChart>
        </el-dialog>

        <el-dialog
            :title="giftActivitySortTitle"
            :visible.sync="giftActivitySortVisible"
            :close-on-click-modal="false"
            v-if="giftActivitySortVisible"
        >
            <giftActivitySort ref="giftActivitySort" @closeGiftActivityPublicityChart="closeGiftActivitySort"> </giftActivitySort>
        </el-dialog>
        <el-dialog
            :title="giftActivitySortTitle"
            :visible.sync="giftActivitySortVisible"
            :close-on-click-modal="false"
            v-if="giftActivitySortVisible"
        >
            <giftActivitySort ref="giftActivitySort" :langsArr="langsArr" @closeGiftActivitySort="closeGiftActivitySort">
            </giftActivitySort>
        </el-dialog>

        <el-dialog
            :title="$i18n.t('common.Pleaseselectaproduct')"
            :visible.sync="giftVisible"
            @close="closeGiftForm()"
            v-if="giftVisible"
            :close-on-click-modal="false"
            width="1200px"
        >
            <GiftDialog ref="giftDialog" @getGiftList="getGiftList" @closeGiftForm="closeGiftForm"></GiftDialog>
        </el-dialog>

        <el-dialog
            :title="$i18n.t('common.EventGiftsSorting')"
            :visible.sync="giftSortVisible"
            @close="closeGiftSort()"
            v-if="giftSortVisible"
            :close-on-click-modal="false"
            width="700px"
        >
            <giftSort ref="giftSort" @closeGiftSort="closeGiftSort"></giftSort>
        </el-dialog>

        <el-dialog
            :title="$i18n.t('common.ThemeConfiguration')"
            :visible.sync="giftPageSettingVisible"
            @close="closeGiftPageSetting()"
            v-if="giftPageSettingVisible"
            :close-on-click-modal="false"
            width="700px"
        >
            <giftPageSetting ref="giftPageSetting" @closeGiftPageSetting="closeGiftPageSetting"></giftPageSetting>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import GiftDialog from '@/views/giftActivity/giftDialog';
import giftActivityPublicityChart from './giftActivityPublicityChart.vue';
import giftActivitySort from './giftActivitySort.vue';
import giftSort from '@/views/giftActivity/giftSort';
import giftPageSetting from '@/views/giftActivity/giftPageSetting';
import X2JS from 'x2js'; //解析xml

function initAddBean() {
    const addBean = {
        giftActivityId: null,
        status: false,
        isOverlay:true,
        giftActivityName: null,
        pageName: null,
        startTime: null,
        endTime: null,
        countryCode: null,
        activityType: 'Order_Gift',
        activityRating: 1,
        giftActivityRatings: [],
        giftActivityDetails: [],
        activityStatus: null
    };
    return addBean;
}

export default {
    name: 'GiftActivity',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        giftActivityPublicityChart,
        GiftDialog,
        giftActivitySort,
        giftSort,
        giftPageSetting
    },
    data() {
        var timeRequired = (rule, value, callback) => {
            if (
                this.addGiftActivity.startTime == null ||
                this.addGiftActivity.endTime == null ||
                Object.is(this.addGiftActivity.startTime, NaN) ||
                Object.is(this.addGiftActivity.endTime, NaN)
            ) {
                return callback(new Error(this.$i18n.t('common.PleaseChooseATimeForTheEvent')));
            } else if (this.addGiftActivity.startTime > this.addGiftActivity.endTime) {
                return callback(new Error(this.$i18n.t('common.Pleaseselectatimerange')));
            } else {
                return callback();
            }
        };
        //动态表单校验
        var numRequired = (rule, value, callback) => {
            //序号
            var index = rule.field - 1;
            if (
                this.addGiftActivity.giftActivityRatings[index].orderAmt == null ||
                this.addGiftActivity.giftActivityRatings[index].orderAmt == ''
            ) {
                return callback(new Error(this.$i18n.t('common.PleaseCompleteTheActivityRating')));
            } else if (
                this.addGiftActivity.giftActivityRatings[index].giftNum == null ||
                this.addGiftActivity.giftActivityRatings[index].giftNum == ''
            ) {
                return callback(new Error(this.$i18n.t('common.PleaseCompleteTheActivityRating')));
            }
            //订单金额和赠品数量不能小于序号排在前的分级
            else if (
                this.addGiftActivity.giftActivityRatings[index - 1] &&
                (Number(this.addGiftActivity.giftActivityRatings[index].orderAmt) <= Number(this.addGiftActivity.giftActivityRatings[index - 1].orderAmt) ||
                    Number(this.addGiftActivity.giftActivityRatings[index].giftNum) <= Number(this.addGiftActivity.giftActivityRatings[index - 1].giftNum))
            ) {
                return callback(new Error(this.$i18n.t('common.TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier')));
            } else {
                return callback();
            }
        };
        return {
            // 活动结束时间 只能选择当前时间之后
            pickerOptions: {
                disabledDate: (time) => {
                    const date = new Date(this.addGiftActivity.startTime);
                    return time.getTime() < date.getTime();
                }
            },
            formTitle: '',
            old: initAddBean(),
            addGiftActivity: initAddBean(),
            list: [],
            chooseGiftData: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            activityTypeDicts: [],
            query: {
                page: 1,
                limit: 10,
                idFuzz: null,
                giftActivityName: null,
                activityStatus: null,
                status: null,
                isOverlay:null,
                pageName: null,
                startTime: '',
                endTime: '',
                countryCode: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,
            giftVisible: false,
            giftSortVisible: false,
            giftPageSettingVisible: false,

            formLabelWidth: '200px',

            countryLang: [],
            langsArr: [],
            //字典取的语言
            langs: [],
            giftActivityPublicityChartVisible: false,
            giftActivityPublicityChartTitle: '',
            giftActivitySortVisible: false,
            giftActivitySortTitle: '',
            formRules: {
                startTime: [{ validator: timeRequired, trigger: 'blur' }],
                countryCode: [{ required: true, message: this.$i18n.t('common.Pleaseselectacountry'), trigger: 'change' }],
                giftActivityName: [{ required: true, message: this.$i18n.t('common.PleaseEnterTheNameOfTheEvent'), trigger: 'blur' }],
                activityType: [{ required: true, message: this.$i18n.t('common.PleaseSelectTheActivityType'), trigger: 'change' }],
                pageName: [
                    { required: true, message: this.$i18n.t('common.PleaseProvideTheNameOfTheLandingPageActivity'), trigger: 'blur' }
                ]
            },
            ratingRules: {
                activityRating: [{ validator: numRequired, trigger: 'blur' }]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    // watch: {
    //     'addGiftActivity.countryCode': {
    //         handler(newVla, oldVal) {
    //             if (
    //                 oldVal != null &&
    //                 newVla != oldVal &&
    //                 this.addGiftActivity.giftActivityDetails != null &&
    //                 this.addGiftActivity.giftActivityDetails.length > 0
    //             ) {
    //                 this.addGiftActivity.giftActivityDetails = [];
    //             }
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        changeValue() {
            console.log(this.addGiftActivity.giftActivityDetails);
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        closeGiftForm() {
            this.giftVisible = false;
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            if (this.query.startTime) {
                this.query.startTime = moment(this.query.startTime).unix() * 1000;
            }
            if (this.query.endTime) {
                this.query.endTime = moment(this.query.endTime).unix() * 1000;
            }

            console.log(this.query);
            this.listLoading = true;
            this.$store
                .dispatch('getGiftActivityPage', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm(this.$i18n.t('common.confirmdeletion'), this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteGiftActivity', { id: row.giftActivityId }).then(() => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: this.$i18n.t('common.Successfullydeleted'),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            //已结束的只能查看
            if (row.activityStatus == 0) {
                this.formTitle = this.$i18n.t('common.check');
            } else {
                this.formTitle = this.$i18n.t('common.edit');
            }

            this.old = row;
            console.log(row);
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addGiftActivity[key] = this.old[key];
            }
            console.log(this.addGiftActivity)
            this.addGiftActivity.startTime = moment(row.startTime).format('yyyy-MM-DD HH:mm:ss');
            this.addGiftActivity.endTime = moment(row.endTime).format('yyyy-MM-DD HH:mm:ss');
            this.addVisible = true;
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.addGiftActivity = initAddBean();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.addGiftActivity = initAddBean();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = this.$i18n.t('common.Add1');
            this.resetForm();
            var activityRating = { orderAmt: null, giftNum: null, sort: 1 };
            this.addGiftActivity.giftActivityRatings.push(activityRating);
            console.log(this.addGiftActivity.giftActivityRatings);
            this.addVisible = true;
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            console.log(this.addGiftActivity);
            //校验分级
            for (var index in this.addGiftActivity.giftActivityRatings) {
                //订单金额必填
                if (
                    this.addGiftActivity.giftActivityRatings[index].orderAmt == null ||
                    this.addGiftActivity.giftActivityRatings[index].orderAmt == ''
                ) {
                    this.$message({
                        message: this.$i18n.t('common.PleaseCompleteTheActivityRating'),
                        type: 'error'
                    });
                    return;
                }
                //赠品数必填
                if (
                    this.addGiftActivity.giftActivityRatings[index].giftNum == null ||
                    this.addGiftActivity.giftActivityRatings[index].giftNum == ''
                ) {
                    this.$message({
                        message: this.$i18n.t('common.PleaseCompleteTheActivityRating'),
                        type: 'error'
                    });
                    return;
                }
                //订单金额和赠品数量不能小于序号排在前的分级
                if (
                    this.addGiftActivity.giftActivityRatings[index - 1] &&
                (Number(this.addGiftActivity.giftActivityRatings[index].orderAmt) <= Number(this.addGiftActivity.giftActivityRatings[index - 1].orderAmt) ||
                    Number(this.addGiftActivity.giftActivityRatings[index].giftNum) <= Number(this.addGiftActivity.giftActivityRatings[index - 1].giftNum))
                ) {
                    this.$message({
                        message: this.$i18n.t('common.TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier'),
                        type: 'error'
                    });
                    return;
                }
            }

            //商品详情不能为空
            if (this.addGiftActivity.giftActivityDetails == null || this.addGiftActivity.giftActivityDetails.length == 0) {
                this.$message({
                    message: this.$i18n.t('common.YouHaveNotSelectedAGift'),
                    type: 'error'
                });
                return;
            }

            this.addGiftActivity.startTime = moment(this.addGiftActivity.startTime).unix() * 1000;
            this.addGiftActivity.endTime = moment(this.addGiftActivity.endTime).unix() * 1000;

            console.log(this.addGiftActivity);

            if (this.formTitle == this.$i18n.t('common.Add1')) {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addGiftActivity', this.addGiftActivity).then(() => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t('common.Successfullysaved'),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t('common.Savefailed'),
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        //校验商品详情
                        for (var detail of this.addGiftActivity.giftActivityDetails) {
                            //活动数量不能少于已赠数
                            if (detail.activityNum < detail.quantityGiven) {
                                this.$message({
                                    // message: '活动数量不能少于已赠数',
                                    message: this.$i18n.t('common.TheQuantityOfActivityShouldExceedTheAmountGivenAway'),
                                    type: 'error'
                                });
                                return;
                            }
                        }
                        console.log(this.addGiftActivity);
                        this.$store.dispatch('editGiftActivity', this.addGiftActivity).then(() => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t('common.Successfullyedited'),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t('common.Editingfailed'),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
                this.initLangsArr();
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'gift_activity_type' }).then((res) => {
                if (res != null) _this.activityTypeDicts = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query = {
                page: 1,
                limit: 10,
                idFuzz: null,
                giftActivityName: null,
                activityStatus: null,
                status: null,
                isOverlay:null,
                pageName: null,
                startTime: '',
                endTime: '',
                countryCode: null
            };
            console.log(this.query);
            this.doQueryList({ page: 1 });
        },
        /**
         * 序号
         */
        indexIcon(index) {
            return `&#${9311 + index};`;
        },
        /**
         * 活动分级数变化处理
         */
        handleRatingChange(now, old) {
            console.log(now, old);
            //框减少
            if (now < old) {
                this.addGiftActivity.giftActivityRatings = this.addGiftActivity.giftActivityRatings.slice(0, now);
            }
            //加一个值
            else {
                var giftActivityRating = { orderAmt: null, giftNum: null, sort: now };
                this.addGiftActivity.giftActivityRatings.push(giftActivityRating);
            }
        },
        /**
         * 计数器禁止输入
         */
        channelInputLimit(e) {
            e.returnValue = '';
            e.target.value = e.target.value;
        },
        handleGiftActivityPublicityChart() {
            this.giftActivityPublicityChartTitle = this.$i18n.t('common.GiveawayEvent');
            this.giftActivityPublicityChartVisible = true;
        },
        closeGiftActivityPublicityChart() {
            this.giftActivityPublicityChartVisible = false;
        },
        handleGiftActivitySort() {
            this.giftActivitySortTitle = this.$i18n.t('common.ActivitySorting');
            this.giftActivitySortVisible = true;
        },
        closeGiftActivitySort() {
            this.giftActivitySortVisible = false;
        },
        chooseGift() {
            console.log(this.addGiftActivity.countryCode);
            if (this.addGiftActivity.countryCode == null || this.addGiftActivity.countryCode == '') {
                this.$message({
                    message: this.$i18n.t('common.Pleaseselectacountry'),
                    type: 'error'
                });
                return;
            }
            var alreadySelection = [];
            if (this.formTitle == this.$i18n.t('common.edit') || this.formTitle == this.$i18n.t('common.check')) {
                alreadySelection = this.addGiftActivity.giftActivityDetails;
            }
            this.giftVisible = true;
            setTimeout(() => {
                this.$refs.giftDialog.handleQueryByCountryCode(this.addGiftActivity, alreadySelection);
            }, 200);
        },
        //获取子组件值
        getGiftList(data) {
            this.addGiftActivity.giftActivityDetails = data;
            console.log(this.addGiftActivity.giftActivityDetails);
            this.giftVisible = false;
        },
        closeGiftSort() {
            this.giftSortVisible = false;
        },
        //打开赠品排序表单
        handleGiftSort(row) {
            console.log(row);

            this.$store.dispatch('getGiftSort', { giftActivityId: row.giftActivityId }).then((res) => {
                this.giftSortVisible = true;
                setTimeout(() => {
                    this.$refs.giftSort.openCustomDialog(res);
                }, 100);
            });
        },
        initLangsArr() {
            this.langsArr = [];
            for (let item of this.countrysDicts) {
                let param = {
                    lang: item.code,
                    langName: item.name,
                    activityType: 'Order_Gift',
                    tableData: []
                };
                this.langsArr.push(param);
            }
        },
        handlePageSetting(row) {
            this.giftPageSettingVisible = true;
            setTimeout(() => {
                this.$refs.giftPageSetting.init(row);
            }, 100);
        },
        closeGiftPageSetting() {
            this.doQueryList({ page: 1 });
            this.giftPageSettingVisible = false;
        },
        countryChange(val) {
            if (this.addGiftActivity.giftActivityDetails != null && this.addGiftActivity.giftActivityDetails.length > 0) {
                this.addGiftActivity.giftActivityDetails = [];
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.my-form {
    max-height: 145px;
    overflow: auto;
}
.my-form-item {
    margin-top: 10px;
}
.my-input {
    width: 200px;
}

.input_number .el-input__inner {
    pointer-events: none;

    user-select: none;
}
</style>
