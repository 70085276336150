import esLocal from 'element-ui/lib/locale/lang/es';
const es = {
    ...esLocal,
    common: {
        datastatistics: 'Estadísticas',
        Transactionstatistics: 'Estadísticas de transacciones',
        LabelOptions: 'Opciones de etiqueta',
        Orderdatastatistics: 'Estadísticas de pedidos',
        Hourlystatistics: 'Por hora',
        Countbyday: 'Por día',
        Spain: 'España',
        Italy: 'Italia',
        Germany: 'Alemania',
        France: 'Francia',
        poland: 'Polonia',
        Netherlands: 'Países Bajos',
        Britain: 'Gran Bretaña',
        UnitedStates: 'Estados Unidos de América',
        Portugal: 'Portugal',
        SalesCountry: 'País de Venta',
        query: 'Consulta',
        Placinganorder: 'Cantidad del pedido',
        Orderamount: 'Importe del pedido',
        Numberofusersplacingorders: 'Número de usuarios que realizan pedidos',
        Ordertime: 'Hora del pedido',
        time: 'Hora',
        usermanagement: 'Gestión de usuarios',
        UserID_loginemail_registrationinviterID_phonenumber: 'ID de usuario/ correo eléctronico de acceso/ ID de invitado registrado/ número de teléfono móvil',
        country: 'País',
        Whethertosubscribe: 'Suscribirse o no',
        Loginstatus: 'Estado de la conexión',
        normal: 'Normal',
        Disabled: 'Desactivar',
        Createdon: 'Fecha de creación',
        Lastlogintime: 'Última hora de conexión',
        Reset: 'Restablecer',
        export: 'Exportar',
        UserID: 'ID de usuario',
        Registeredcountry: 'País de registro',
        mailbox: 'Correo electrónico',
        Mobilephonenumber: 'Número de teléfono móvil',
        Patrioticcoin: 'Monedas Aigo',
        RegistrationInviterID: 'ID de invitado registrado',
        brand: 'Marca',
        Creationtime: 'Tiempo de creación',
        operation: 'Operación',
        check: 'Ver',
        orderform: 'Pedidos',
        consultingservice: 'Consulta',
        address: 'Dirección',
        LoginLog: 'Registro de inicio de sesión',
        Rechargepatrioticcoins: 'Recarga de monedas Aigo',
        Returnsandexchanges: 'Devoluciones y cambios',
        evaluate: 'Tarifa',
        Shippingaddress: 'Dirección de recepción',
        shoppingcart: 'Cesta de la compra',
        collection: 'Recogida',
        invoice: 'Factura',
        nickname: 'Nickname',
        nothing: 'Ninguno',
        close: 'Cerrar',
        source: 'Fuente',
        Subscriptiontime: 'Hora de la suscripción',
        Thirdpartylogin: 'Inicio de sesión de terceros',
        Bound: 'Vinculado',
        Unbound: 'Desvinculado',
        Invitationcode: 'Código de invitación',
        Inviteduser: 'Usuarios invitados',
        Invitetoreceivepatrioticcoins: 'Invitar para conseguir monedas Aigo',
        Isthefirstorderfreeofshipping: 'Envío gratuito o no en su primer pedido',
        shopping: 'Comprar',
        Collectproducts: 'Artículos favoritos',
        ShoppingCartItems: 'Artículos de la cesta de la compra',
        PaymentOrder: 'Pedidos pagados',
        Ordercumulativeamount: 'Importe total del pedido',
        Lastordertime: 'Fecha del último pedido',
        Returnandexchangeorders: 'Devoluciones y cambios de pedidos',
        Consultationfrequency: 'Frecuencia de consulta',
        Numberofevaluations: 'Número de reseñas',
        Numberofapprovedevaluations: 'Número de revisiones aprobadas',
        ShowCountry: 'Mostrar país',
        name1: 'Nombre',
        TaxID: 'Código fiscal',
        province: 'Provincia',
        city: 'Ciudad',
        Detailedaddress: 'Dirección completa',
        Housenumber: 'Número de casa',
        AddressAdditionalContent: 'Dirección adicional',
        RecipientCompany: 'Empresa destinataria',
        Defaultshippingaddress: 'Dirección de entrega por defecto',
        Defaultinvoiceaddress: 'Dirección de facturación predeterminada',
        ProductID1: 'ID del producto',
        Productname1: 'Nombre del producto',
        CollectionPrice: 'Precio de la colección',
        Currentprice: 'Precio actual',
        Collectiontime1: 'Tiempo de recogida',
        Additionalpurchaseprice: 'Precio de los artículos añadidos a la lista de deseos',
        Additionalpurchasetime: 'Fecha de los artículos añadidos a la lista de deseos',
        Listingstatus: 'Estado de la estantería',
        Listed: 'Poner en la estantería',
        Notlisted: 'No poner en la estantería',
        Numberofpatrioticcoins: 'Número de monedas Aigo',
        Enteraquantitybetween0and1000: 'Introducir una cantidad entre 0 y 100',
        Remarks: 'Observaciones',
        cancel: 'Cancelar',
        UserDetails: 'Detalles de usuario',
        Areyousuretoexportthefile: '¿Quieres confirmar la exportación del archivo o quieres continuar?',
        prompt: 'Preguntar',
        Exportsuccessful: 'Exportar con éxito',
        today: 'Hoy',
        Lastweek: 'última semana',
        Thismonth: 'Este mes',
        Lastmonth1: 'último mes',
        thisyear: 'Este año',
        Inputcannotbeempty: 'La entrada no puede estar vacía',
        Pleaseenterapositiveinteger: 'Por favor, introduzca un número entero positivo',
        Pleaseenterthequantityofpatrioticcoins: 'Introduzca el número de monedas Aigo',
        Pleaseenteracomment: 'Escriba un comentario',
        Pleaseselectacountry: 'Por favor, selecciona un país',
        Successfullysubmitted: 'Enviado con éxito',
        Nopatrioticcoinrulesconfigured: 'No se han configurado las reglas de la moneda Aigo',
        Rechargefailed: 'No recargar',
        Savefailed: 'Fallo al guardar',
        ordermanagement: 'Gestión de pedidos',
        OrderList: 'Lista de pedidos',
        Ordernumber: 'Número de pedido',
        Paymentcode: 'Código del pago',
        barcode: 'Código de barras',
        Pleaseselectasource: 'Por favor, seleccione la fuente',
        OrderStatus: 'Estado del pedido',
        Unpaid: 'Sin pagar',
        Paid: 'Pagado',
        Shippable: 'Disponible para su entrega',
        Shipped: 'Enviado',
        complete: 'Completo',
        Pleaseselectpaymentmethod: 'Seleccione la forma de pago',
        Shipmentstatus: 'Estado del envío',
        Notshipped: 'No enviado',
        Partialshipment: 'Parcialmente enviado',
        Returned: 'Devuelto',
        Remittanceconfirmation: 'Remesa confirmada',
        Notremitted: 'Sin remesas',
        Unconfirmed: 'Sin confirmar',
        adopt: 'Pasar',
        Notpassed: 'No pasar',
        Whethertomanuallyship: 'Entrega manual o no',
        yes: 'Sí',
        no: 'No',
        Originalordertype: 'Tipo de pedido original',
        Presaleorder: 'Pedidos anticipados',
        Officialorder: 'Pedido oficial',
        Preorderstatus: 'Estado de pedido anticipado',
        Deposittobepaid: 'Depósito a pagar',
        Depositpaid: 'Depósito pagado',
        Finalpaymenttobemade: 'Saldo a pagar',
        Finalpaymentpaid: 'Saldo pagado',
        Presalepaymentmethod: 'Forma de pago de la preventa',
        Finaldeposit: 'Pago final del depósito',
        Fullpayment: 'Pago completo',
        Whetherthepresaleorderisconvertedtoformal: 'Pedido anticipado oficial o no',
        Combinedorders: 'Pedido combinado',
        to: 'A',
        Startdate: 'Fecha de inicio',
        Enddate: 'Fecha final',
        Paymenttime: 'Tiempo de pago',
        Pleaseenterthesource: 'Por favor, introduce la fuente',
        platform: 'Plataforma',
        Positiveconversionsingle: 'Transferir al pedido oficial',
        Numberofproductmodels: 'Número de artículos',
        activity: 'Actividad',
        money: 'Importe',
        Paymentmethod: 'Forma de pago',
        Deliverytime: 'Tiempo de entrega',
        Whethertotransfertoformal: 'Transferir oficialmente o no',
        Tobereplied_consulted: 'A responder/consultar',
        detailed: 'Detalles',
        Manualshipping: 'Entrega manual',
        Manuallogisticsshipment: 'Entrega logística manual',
        logisticscompany: 'Empresa de logística',
        Pleaseselectalogisticscompany: 'Seleccione una empresa de logística, por favor',
        Logisticstrackingnumber: 'Número de seguimiento logístico',
        SelectDateTime: 'Selecciona fecha y hora',
        Shippingremarks: 'Observaciones sobre el envío',
        Pleasenote: 'Tenga en cuenta que',
        confirm1: 'Confirmar',
        state: 'estado',
        Pleaseselect: 'Por favor, seleccione',
        Pleaseselectastatus: 'Por favor, seleccione el estado',
        Homepagerecommendation: 'Recomendación de la página de inicio',
        Pleaseenterthelogisticstrackingnumber: 'Introduzca número de seguimiento',
        Pleaseselecttheshippingtime: 'Seleccione un plazo de entrega',
        edit: 'Editar',
        Successfullyshippedmanually: 'Entrega manual con éxito',
        Remittanceconfirmationsuccessful: 'Confirmación de pago con éxito',
        confirmdeletion: 'Confirmar para borrar',
        Successfullydeleted: 'Borrado con éxito',
        Pleaseselectapresaleorder: 'Seleccione el pedido de preventa, por favor',
        Confirmtoconverttheorderintoaformalorder: 'Confirmacioón de conversión de pedido a pedido normal',
        success: 'Éxito',
        OrderData: 'Datos de los pedidos',
        Areyousuretoexportthefileandcontinue: 'Confirmar exportar archivo o no, continuar o no',
        Basicinformationoftheorder: 'Información básica del pedido',
        Useremail: 'Correo electrónico del usuario',
        Documentremarks: 'Notas sobre el pedido',
        combination: 'Combinación',
        PostalCode: 'Código postal',
        RecipientCompanyAddressAdditionalContent: 'Empresa receptora, dirección de contenido adicional',
        Invoiceaddress: 'Dirección de facturación',
        InvoiceStatus: 'Estado de la factura',
        Paymentinformation: 'Información del pago',
        Paymentstatus: 'Estado del pago',
        coupon: 'Cupón',
        freight: 'Transporte',
        CouponName: 'Nombre del cupón',
        Couponcode: 'Código del cupón',
        Paymentamount: 'Importe del pago',
        Trackingnumber: 'Número de seguimiento del pedido',
        Manualshippingoperator: 'Operador de envío manual',
        Manualshippingoperationtime: 'Tiempo de operación de entrega manual',
        Bankremittance: 'Remesa bancaria',
        Remittancestatus: 'Estado de las remesas',
        Remittanceconfirmationoperator: 'Operador de confirmación de remesas',
        Remittanceconfirmationoperationtime: 'Hora de confirmación de la remesa',
        Preorderinformation: 'Información de pedido de preventa',
        Converttoformaldocument: 'Transferir a pedido ofical o no',
        Positiveconversionsinglemode: 'La forma de transferir al pedido oficial',
        automatic: 'Automático',
        Manual: 'Manual',
        Positiveconversionsingletime: 'El momento de transferir al pedido oficial',
        category1: 'Categoría',
        Subordernumber: 'Número de subpedido',
        Paymentdeadline: 'Fecha límite de pago',
        Addremarks: 'Añadir comentarios',
        ProductInformation: 'Información de producto',
        Serialnumber: 'Número de serie',
        name: 'Nombre',
        VariantInformation: 'Información sobre las variantes',
        Pricing_unitprice: 'Precios (precio unitario)',
        Sellingprice_unitprice: 'Precio de venta (precio unitario)',
        quantity: 'Cantidad',
        Totalprice: 'Precio total',
        MerchandiseSubtotal: 'Subtotal de productos',
        total: 'Total',
        CancelOrder: 'Cancelar el pedido',
        Areyousuretocancelthisorder: 'Estás seguro de cancelar el pedido?',
        reason: 'Razón',
        Pleaseselectabrand: 'Por favor, selecciona una marca',
        Pleaseselectacategory: 'Por favor, selecciona una categoría',
        Successfullyadded1: 'Añadido con éxito',
        Addfailed: 'Fallo al añadir',
        Whethertosubmitdata: 'Enviar datos o no',
        Canceledsuccessfully: 'Cancelado con éxito',
        Onlypendingorderscanbeshipped: 'Solo se pueden enviar los pedidos pendientes',
        Existinglogisticstrackingnumber: 'El pedido tiene un número de seguimiento logístico y no se puede enviar',
        Thereareonlytworemittancestatuses: 'El estado de la remesa solo puede ser aprobado o no aprobado',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Solo pueden confirmarse los pedidos remitidos',
        Orderdoesnotexist: 'El pedido no existe',
        Thisordercannotbecancelled: 'El pedido no puede ser cancelado',
        Norulesconfigured: 'El inquilino actual no tiene configuradas reglas de monedas Aigo',
        Parametererror: 'Error de parámetros',
        Countrycodecannotbeempty1: 'El código del país no puede estar vacío',
        Thecurrentcountrypricingisnotconfigured: 'El precio actual del país no está configurado',
        Thepriceconfigurationdoesnotexist: 'La configuración del pedido no existe, por favor configure el precio',
        Reasonforcancellationoforderisrequired: 'Motivo de cancelación del pedido requerido',
        Exportorderinformation: 'Información sobre pedidos de exportación',
        OrderIDmustbepassed: 'Debe introducirse el ID del pedido',
        Youcanonlybatchprocessupto100itemsatatime: 'Procesamiento por lotes de hasta 100 pedidos a la vez',
        Notapresaleorder: 'El pedido número 101 no es un pedido anticipado, por favor, desmárquelo primero',
        Transferredtoofficial: 'El número de pedido 101 ha sido transferido a un pedido oficial, por favor, desmarque primero',
        Ordernotpaid: 'El pedido número 101 no es un pedido pagado, por favor, cancela primero',
        Nonpresaleorderscannotbeoperated: 'Los pedidos sin preventa no están disponibles',
        Transferredtoformalformandcannotbeoperated: 'Los pedidos transferidos no están disponibles',
        Nonpaidorderscannotbeoperated: 'No disponible para pedidos no pagados',
        Orderremarksarerequired: 'Las notas del pedido son obligatorias',
        Upto500wordscanbeentered: 'Máximo 500 caracteres',
        common: 'En total',
        strip: 'Registros',
        goto: 'Ir a',
        page: 'Página',
        Returnandexchangeapplication: 'Solicitud de devolución',
        type: 'Tipo',
        Return: 'Devolución',
        Exchange: 'Intercambio',
        Auditstatus: 'Estado de la revisión',
        Approved: 'Aprobado',
        Auditfailed: 'Fallido',
        Inprogress: 'Está proceso',
        Auditended: 'Fin de la auditoría',
        Processingstatus: 'Estado de tramitación',
        Completed: 'Completado',
        Applicationtime: 'Tiempo de aplicación',
        Aftersalestype: 'Tipo de postventa',
        ApplicationDescription: 'Descripción de la aplicación',
        Numberofexplanatoryimages: 'Descripción del número de imágenes',
        Illustrativeimage: 'Descripción de las imégenes',
        Returnlogisticstrackingcode: 'Código de seguimiento de la logística de devoluciones',
        CanIcontinuetoapply: 'Si desea seguir aplicando',
        Auditing: 'Auditoría',
        details: 'Detalles',
        handle: 'Procesar',
        Add1: 'Añadir',
        ApplicationDetails: 'Solicitar detalles',
        Exchangeitem: 'Intercambiar productos',
        Returnedgoods: 'Devolución de mercancías',
        Audittime: 'Tiempo de revisión',
        Reviewedby: 'Revisado por',
        result: 'Resultado',
        Failed: 'No aprobado',
        Thereplycontentwillbedisplayedtotheuser: 'La respuesta se mostrará a los usuarios',
        Submit: 'Enviar',
        Replycontent: 'Contenido de la respuesta',
        Operator: 'Operadores',
        Pleaseenterthereason: 'Por favor, introduce una razón',
        Pleasechoosewhethertocontinueapplying: 'Por favor, selecciona si puedes continuar con la aplicación',
        Pleaseselectaresult: 'Por favor, selecciona el resultado',
        Successfullymodified: 'Modificación con éxito',
        Verificationfailed: 'Fallo en la verificación',
        Datadoesnotexist: 'Los datos no existen',
        OrderNumber_UserID: 'Número de pedido/ ID de usuario',
        ReplyStatus: 'Estado de la respuesta',
        Replied: 'Respondido',
        Noresponse: 'Sin respuesta',
        MyOrder: 'Mi pedido',
        refund: 'Reembolso',
        Canwedeliverontheagreeddate: '¿se puede entregar en la fecha acordada?',
        Modifyshippingaddress: 'Cambiar la dirección de envío',
        Otherissuesrelatedtotransportation: 'Otras preguntas sobre el envío',
        Receivedincorrectproduct: 'Recibir un producto equivocado',
        Receiveddamagedproductsandproductswithqualityissues: 'Recibir oroductos dañados y con problemas de calidad',
        RegulationsonReturnsandRefunds: 'Normativa sobre devoluciones y reembolsos',
        Applyforinvoice: 'Solicitar una factura',
        productdetails1: 'Detalles del producto',
        Thankyouletter: 'Carta de agradecimiento',
        Otherissues: 'Otras preguntas',
        Respondent: 'Respuesta de',
        Consultationdate: 'Fecha de la consulta',
        content: 'Contenido',
        explain: 'Descripción',
        Consultationtime: 'Tiempo de consulta',
        Replytime: 'Tiempo de respuesta',
        reply: 'Respuesta',
        OrderInformation: 'Información de pedido',
        Consultationcontent: 'Contenido de la consulta',
        Consultationinstructions: 'Intrucciones de la consulta',
        EnterReply: 'Introduce una respuesta',
        Consultationrecords: 'Historial de consultas',
        Replycontentisrequired: 'El contenido de la respuesta es obligatorio',
        Replysuccessful: 'Respuesta con éxito',
        Successfullyedited: 'Editado con éxito',
        Ordernumber_UserID_Taxnumber: 'Número de pedido/ ID de usuario/ código fiscal',
        Unaudited: 'No auditado',
        Passed: 'Aprobado',
        Notuploaded: 'No cargado',
        Uploaded: 'Subiado a',
        AuditDescription: 'Instrucciones de revisión',
        download: 'Descargar',
        Applying: 'Aplicación en progreso',
        Enterthereason: 'Introduzca el motivo, debe rellenar el motivo si no se aprueba la solicitud',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Las razones para no aprobar se mostrarán al usuario',
        Pleaseenterareply: 'Por favor, introduce una respuesta',
        Auditsuccessful: 'Revisión exitosa',
        ProductManagement: 'Gestión de productos',
        Categorymanagement: 'Gestión de categorías',
        Categoryname1: 'Nombre de la categoría',
        Isthehomepagedisplayed: 'Visualización o no de la página de inicio',
        Enableornot: 'Activar o no',
        search: 'Buscar',
        Directoryhierarchy: 'Nivel de catálogo',
        Category: 'Categoría',
        sort: 'Ordenar por',
        picture: 'Imagen',
        Updatetime: 'Tiempo de actualización',
        Multilingualconfiguration: 'Configuración multilingüe',
        Relatedimportantparameters: 'Parámetros importantes relacionados',
        Associatesearchparameters: 'Parámetros de búsqueda asociados',
        delete: 'Eliminar',
        SelectAll: 'Seleccionar todo',
        Imagesize: 'Tamaño de imagen',
        icon: 'Iconos',
        intotal25: '25 artículos en total',
        Gotopage1: 'Ir a la página 1',
        Chinese: 'Chino',
        English: 'Inglés',
        German: 'Alemán',
        Polishlanguage: 'Polaco',
        Italian: 'Italiano',
        French: 'Francés',
        Dutchlanguage: 'Holandés',
        Spanish: 'Español',
        Pleaseenteraname: 'Por favor, introduce un nombre',
        Areyousureyouwanttodeleteit: 'Quieres eliminar?',
        Categoryassociation: 'Asociación de categorías',
        PrimaryKeyID: 'Clave primaria ID',
        CatalogID: 'Catálogo ID',
        Addtime: 'Añadir tiempo',
        Relatedcategories: 'Categoría asociada',
        Selectmaincategory: 'Seleccione la categoría principal',
        addassociations: 'Añadir asociación',
        Maincategory: 'Categoría principal',
        ProductCatalogID: 'ID del catálogo de productos',
        RelatedCategoryName: 'Nombre de la categoría asociada',
        AddAssociatedCategory: 'Añadir una categoría asociada',
        Pleaseselectacategoryfirst1: 'Por favor, seleccione una primero una categoría',
        Productvariants: 'Variante del producto',
        ID: 'ID',
        Add: 'Añadir',
        Productquantity: 'Número de productos',
        Selectvariant: 'Seleccionar una variante',
        Selectparameters: 'Seleccionar parámetros',
        commodity: 'Producto',
        Selectproduct: 'Seleccionar producto',
        Colortemperature: 'Temperatura de color',
        frequency: 'Frecuencia',
        power: 'Poder',
        style: 'Estilo',
        MoveDown: 'Desplazar hacia abajo',
        MoveUp: 'Subir',
        Selectvariantparameters: 'Seleccionar parámetros de variante',
        SKU_EAN_Encoding: 'SKU/EAN/code',
        Pleaseselectparameters: 'Por favor, selecciona parámetros',
        Countrieslisted: 'Países en los que se comercializan los productos',
        ProductID: 'Identificación del producto',
        Productimage1: 'Imagen del producto',
        ProductName: 'Nombre del producto',
        VariantManagement: 'Gestión de variantes',
        Class: 'Categoría',
        Selected: 'Selección',
        Thereiscurrentlynodataavailable: 'Sin datos disponibles',
        Variantparameters: 'Parámetros variantes',
        VariantName: 'Nombre variante',
        CustomName: 'Nombre personalizado',
        Batchlisting: 'Colocación del lote en la estantería',
        Pleaseselectaproduct: 'Seleccione los productos',
        Batchdelisting: 'Supresión masiva',
        Batchsearch: 'Búsqueda por lotes',
        Enterproductbarcodeandsubmitforaddition: 'Introduzca el código de barras del producto para enviar las adiciones, los códigos de barras múltiples deben introducirse en una nueva línea',
        Enterproductbarcode: 'Introduzca los códigos de barras de los productos, los códigos de barras múltiples deben introducirse en una nueva línea 500',
        Pleaseenterbarcode: 'Introduzca el código de barras',
        Editlistingcountry: 'Editar los países en los que se comercializan los productos',
        Pleasecompletetheproductinformationbeforelistingit: 'Rellene la información del producto antes de colocarlo en la estantería',
        ProductTitle: 'Título del producto',
        ProductLargePicture: 'Imagen del producto',
        commodityprice: 'Precio del producto',
        Confirmtheexecutionofbatchdelisting: 'Confirmar para realizar la supresión masiva',
        EditCategory: 'Editar categoría',
        SynchronizeSKU: 'Sincronizar SKU',
        ConfirmsynchronizationofSKU: 'Confirmar sincronización SKU',
        SynchronizationrequiresdockingwithEBP: 'Dado que la sincronización debe realizarse con EBP, para evitar errores, la sincronización por lotes puede realizarse una vez cada 30 minutos. Tenga paciencia, ya que la sincronización se realizará de forma asíncrona tras la confirmación y las SKU se mostrarán en cuanto estén disponibles',
        Synccompleted: 'Sincronización complete',
        Setlabel: 'Configurar etiquetas',
        label: 'Etiquetas',
        SynchronizeEBPproductinformation: 'Sincronización de la información sobre productos EBP',
        Afterturningonsynchronization: 'Cuando la sincronización está activada, se sincroniza la siguiente información de los productos EBP por SKU de producto',
        Synchronizeproducttitlesbylanguage: 'Título del producto: sincronizar el título del idioma correspondiente',
        Synchronizeproductimagesbylanguage: 'Imagen del producto: sincronice la imagen en el idioma correspondiente, leer la clasificación de imágenes EBP, la primera imagen EBP se establece automáticamente como imagen principal',
        Synchronizeproductimagesandtextbylanguage: 'Detalles de la imagen: sincronización por idioma para  el idioma correspondiente',
        Productkeywords: 'Palabras clave del producto',
        Automaticallysynchronizeornot: 'Sincronización automática',
        Doyouwanttoforcesynchronization: 'Sincronización forzada o no',
        tenant: 'Inquilinos',
        Noinventorydisplay: 'No se muestra el inventario',
        Synchronizetoothertenants: 'Sincronización con otros inquilinos',
        UpdateRecords: 'Actualizar registros',
        Importtime: 'Tiempo de importación',
        number: 'Número',
        file: 'Archivo',
        UpdateResults: 'Actualizar resultado',
        Downloadfiles: 'Descargar archivo',
        Isthereafixedprice: '¿Hay un precio fijo?',
        Whethertopromoteornot: 'A la venta o no',
        Inpromotion: 'A la venta',
        whole: 'Todo',
        Fixedprice1: 'Precio fijo',
        Lowestprice: 'Precio más bajo',
        fixedprice: 'Precio fijo',
        Promotionprice_unitprice: 'Precio promocional (precio unitario)',
        price1: 'Precio de venta',
        inventory: 'Inventario',
        Inventorybottomline: 'Resultado final del inventario',
        AutomaticallysynchronizeEBP: 'Sincronización automática de EBP',
        ForcesynchronizationofEBP: 'Forzar o no la sincronización EBP',
        Presaleornot: 'Preventa o no',
        Evaluation_Shown_total: 'Reseñas (mostradas/total)',
        coding: 'Código',
        ProductDetails: 'Detalles del producto',
        EditProduct: 'Editar producto',
        Basicinformation: 'Información básica',
        price: 'Precio',
        Pleaseentertheproducttitle: 'Por favor, ingrese el título del producto',
        Sizeinformation: 'Información sobre el tamaño',
        weight: 'Peso',
        volume: 'Volumen',
        Salesmultiples: 'Multiplicador de ventas',
        Numberofitems: 'Número de artículos',
        EditFixedPrice: 'Editar precio fijo',
        Largeimage: 'Imagen grande',
        Uploadimages: 'Subir una foto',
        UploadVideo: 'Subir un video',
        Batchdisplay: 'Visualización de lotes',
        BatchCloseDisplay: 'Visualización de cierre de lote',
        Batchdeletion: 'Borrar por lotes',
        Synchronizetootherlanguages: 'Sincronización con otros idiomas',
        Selectfrompicturelibrary: 'Seleccione en la galería de imágenes',
        Pleaseenterthemaintext: 'Introduzca el cuerpo del texto',
        Hideemptyparameters: 'Ocultar parámetros vacíos',
        Detailedcontentofgraphicsandtext: 'Introduce primero los datos gráficos en inglés',
        parameter: 'Parámetro',
        Parametervalue: 'Valor del parámetro',
        ParameterProperties: 'Propiedades del parámetro',
        Pleaseenteraparametername: 'Introduce el nombre del parámetro',
        Pagekeywords: 'Palabras clave de la página',
        PageDescription: 'Descripción de página',
        Pleaseentercontent: 'Por favor,ingrese el contenido',
        PictureLanguage: 'Idioma de la imagen',
        Pictures_Videos: 'Imagen/video',
        ImageID: 'Imagen ID',
        language: 'Idioma',
        size: 'Tamaño',
        Selectapictureorvideo: 'Selecciona primero la imagen o el video en la galería',
        Selectionsuccessful: 'Seleccionar con éxito',
        Isitthemainimage: 'Imagen principal o no',
        Displayornot: 'Mostrar o no',
        Whethertodisplayall: 'Mostrar todo o no',
        Areallclosed: 'Ya sea para cerrar todo',
        Confirmdeletionoftheselectedpicture_video: 'Confirmar para borrar la imagen/Vídeo seleccionado',
        ProductReview: 'Comentarios sobre el producto',
        PromotionName_ProductID_Barcode: 'Nombre de la promoción/ Identificación del producto/ código de barras',
        PromotionCountry: 'País de promoción',
        Activitystatus: 'Estado del evento',
        begininaminute: 'Próximamente',
        Ended: 'Finalizado',
        Isitaflashkill: '¿es un sec-killing?',
        PromotionName: 'Nombre de la promoción',
        CommentID: 'ID de comentario',
        RatingStarRating: 'Estrella de calificación',
        star0: 'estrella',
        star1: '1 estrella',
        stars2: '2 estrellas',
        stars3: '3 estrellas',
        stars4: '4 estrellas',
        stars5: '5 estrellas',
        Commentstartdate: 'Fecha de inicio de los comentarios',
        CommentEndDate: 'Fecha de finalización de los comentarios',
        Batchreview: 'Revisión por lotes',
        Productimage: 'Imágenes de los productos',
        Evaluationcontent: 'Comentar el contenido',
        video: 'Video',
        ReviewerId: 'ID del revisor',
        user: 'Usuario',
        Pendingreview: 'Para ser revisado',
        Evaluationtime: 'Tiempo de comentario',
        ReviewerName: 'Para ser revisado',
        Numberofselectedcomments: 'El número de reseñas seleccionadas',
        Picturepreview: 'Vista previa de la imagen',
        VideoPreview: 'Vista previa del video',
        Lastmonth: 'El mes pasado',
        LastThreeMonths: 'últimos tres meses',
        Areyousuretodelete: 'Confirmar borrar o no',
        Pleaseselectacomment: 'Por favor seleccione una revisión',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'Red de la marca Aigostar',
        marketingmanagement: 'Gestión de marketing',
        Productrecommendations: 'Recomendación de producto',
        Recommendedlocation: 'Recomendar ubicación',
        Categoryrecommendation: 'Recomendación de categoría',
        Recommendationmodule: 'Recomendar módulo',
        NewProductZone: 'Recién llegados',
        HotSellingZone: 'Productos estrella',
        Recommendedforyou1: 'Recomendados para ti',
        Numberofproducts: 'Número de productos',
        Addproduct: 'Añadir producto',
        DirectoryName: 'Nombre del catálogo',
        title: 'Título',
        ContentTitle: 'Título del contenido',
        EnablePlatform: 'Habilitar plataforma',
        DisplayPlatform: 'Plataforma de visualización',
        catalogue: 'Catálogo',
        JumpAddress: 'Ir a la dirección',
        PageTitle: 'Título de la página',
        CategoryName: 'Nombre de la categoría',
        Cancelleddeletion: 'Borrado cancelado',
        Pleaseselectacategoryfirst: 'Selecciona primero una categoría',
        TheEnglishcategorynamecannotbeempty: 'El nombre de la categoría en inglés no puede estar vacío',
        Successfullysaved: 'Guardado correctamente',
        DeleteError: 'Error de eliminación',
        Apieceofcontentalreadyexists: 'El contenido ya existe',
        Pleaseselecttherecommendedlocation: 'Selecciona una ubicación recomendada',
        Pleaseselecttherecommendedmodule: 'Por favor, selecciona un módulo de recomendación',
        remove: 'Eliminar',
        Successfullyadded2: 'Añadido con éxito',
        Areyousuretoremovethisproduct: 'está seguro de eliminar este producto?',
        Successfullyremoved: 'Eliminado con éxito',
        Dataalreadyexists: 'Los datos ya existen',
        PortfolioManagement: 'Gestión de carteras',
        Combinationname: 'Nombre de la combinación',
        Remainingquantity_combinedquantity: 'Cantidad restante/ cantidad restante combinada',
        starttime: 'Hora de inicio',
        Endtime: 'Hora de finalización',
        Creator: 'Creado por',
        NationalSDPDictionary: 'Diccionario nacional de sdp',
        Combinationtime: 'Tiempo de combinación',
        Combinationquantity: 'Cantidad de combinaciones',
        CanIusecoupons: 'Los cupones pueden ser usados o no',
        Mainimage: 'Imagen principal',
        Setcombinationdiscount: 'Fijar descuento combinado',
        Combinationprice: 'Precio combinado',
        Combinedtotalprice: 'Precio total de la combinación',
        Isitontheshelf: '¿Está en la estantería?',
        Selectupto5: 'Selecciona hasta 5',
        Promotionprice1: 'Precio promocional',
        Pleaseenterthecombinationquantity: 'Introduce el número de combinaciones',
        Pleaseenteracombinationname: 'Introduce el nombre de combinación',
        Upto100wordscanbeentered: 'Introduce hasta 100 caracteres',
        Pleaseselectadaterange: 'Selecciona un intervalo de fechas',
        Pleaseselectastartdate: 'Selecciona una fecha de inicio',
        Pleaseselecttheenddate: 'Selecciona una fecha de fin',
        Pleasechoosewhethertousecoupons: 'Selecciona si desea utilizar el cupón',
        Pleaseselect25items: 'Selecciona de 2 a 5 artículos',
        Pleaseselectaproductthathasbeenlisted: 'Selecciona un artículo que se haya colocado en estanterías',
        Inventoryofselecteditems: 'El multiplicador de existencias/ ventas del artículo seleccionado no puede ser inferior al volumen de actividad',
        Combinationpricecannotbeempty: 'El precio combinado no puede estar vacío',
        Caninput0andpositiveintegers: 'Se pueden introducir 0 y números enteros positivos, los símbolos solo se pueden',
        Presaleproductscannotbeadded: 'Este es un artículo en preventa y no puede añadirse como combo',
        Pleaseselectaproductfirst: 'Seleccione primero un artículo',
        Freeshippingonfirstorder: 'Envío gratuito en su primer pedido',
        Firstfreeshippingitem: 'Envío gratuito de productos en su primer pedido',
        SelectedProduct: 'Producto seleccionado',
        Between1and50charactersinlength: 'Longitud entre 1 y 50 caracteres',
        Thisoperationwillremovethefile: 'Esta operación eliminará el archivo ¿desea continuar?',
        Brandandcountrycannotbeempty: 'La marca y el país no pueden estar vacíos',
        Pleaseselectatime: 'Por favor, seleccione una hora',
        Editedsuccessfully: 'Editado con éxito',
        Successfullyadded: 'Añadido con éxito',
        Shoppingrebate: 'Rebajas en las compras',
        Rebateratio: 'Porcentaje de descuento',
        Rebatestarttime: 'Fecha de inicio de la devolución',
        Rebateendtime: 'Fecha de finalización de la devolución',
        Rebatetime: 'Tiempo de la devolución',
        doyouwanttodelete2: 'Eliminar o no eliminar',
        ActivityTopic: 'Tema del evento',
        ActivityName: 'Nombre del evento',
        Whethertodisplayonthehomepage: 'Mostrar o no en portado',
        QuerySearch: 'Buscar',
        TopicName: 'Nombre del tema',
        Activitytime: 'Hora del evento',
        Richtextornot: 'Texto enriquecido o no',
        ActivityDescription: 'Descripción del evento',
        SpecialSEO: 'SEO temático',
        LogisticsPricing: 'Precios de la logística',
        Basicfreight: 'Tarifas básicas de envío',
        Freeshippingprice: 'Precios de envío gratuitos',
        Deliverytimeprompt: 'Trucos para el tiempo de envío',
        Receivingtimeprompt: 'Hora de recepción',
        PricingSettings: 'Configuración de precios',
        Freightstandard: 'Normas de envío',
        Freightallocation: 'Configuración de envío',
        FreightType: 'Tipo de envío',
        Freeshipping: 'Envío gratuito',
        Configureshippingcosts: 'Configurar gastos de envío',
        Fullreductionoffreight: 'Reembolso de flete',
        Orderamountfull: 'El total del pedido llega a',
        Receivingandshippingreminder: 'Recordatorio de recepción y envío',
        link: 'Enlace',
        confirm: 'Confirmar',
        Cannotbe0: 'No puede ser 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Solo se pueden ingresar hasta dos decimales',
        Pleaseinputthenumbercorrectly: 'Introduce el número correcto',
        Pleaseenterthecountry: 'Introduce el país',
        Pleaseselectshippingcost: 'Selecciona el costo de envío',
        Pleasecompletetheshippingcostconfiguration: 'Completa la configuración de envío',
        Pleaseenteradeliveryprompt: 'Ingresa un recordatorio del recibo',
        Pleaseenteralink: 'Introduce un enlace',
        Addlogisticsconfiguration: 'Añadir configuración logística',
        Editlogisticsconfiguration: 'Editar configuración logística',
        Pleaseenterthelogisticscompany: 'Introduce una empresa logística',
        Editingfailed: 'Fallo de edición',
        Addlogisticscompany: 'Añadir compañía logística',
        Pleaseuploadtheicon: 'Sube un icono, por favor',
        Pleaseenterthelinkcorrectly: 'Por favor, introduce el enlace correctamente',
        Editlogisticscompany: 'Editar empresa logística',
        Thisisalreadythefirstitemmoveupfailed: 'Primer artículo, fallo al subir',
        Thisisalreadythelastitemmovedownfailed: 'Último artículo, mover hacia abajo',
        Costprice: 'Precio de coste',
        taxrate: 'Tipo impositivo',
        commission: 'Comisiones',
        Grossmargin: 'Margen bruto',
        doyouwanttodelete: 'El número es demasiado grande',
        Pleaseselectapricetype: 'Seleccione un tipo de precio',
        Pleasecompletethetaxrateconfiguration: 'Por favor, completa la configuración del tipo impositivo',
        Pleasecompletethecommissionconfiguration: 'Por favor, completa la configuración de la comisión',
        PleasecompleteGrossmarginconfiguration: 'Por favor, completa la configuración de la tarifa bruta',
        Weightrange_start_kg: 'Rango de peso (inicio) kg',
        Weightrange_end_kg: 'Rango de peso (final) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Se puede introducir un máximo de cuatro decimales',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'El valor final debe ser mayor o igual que el valor inicial',
        Pleasecompletetheweightrangeconfiguration: 'Por favor, completa la configuración del rango de peso',
        Pleaseentertheshippingcost: 'Introduce los gastos de envío',
        Addshippingstandard: 'Añadir el envío estándar',
        EditFreightStandard: 'Editar norma de envío',
        Allocationofpatrioticcoins: 'Configuración de monedas Aigo',
        RuleConfiguration: 'Configuración de las reglas',
        ObtainingPatrioticCoinObject: 'Objetos para conseguir monedas Aigo',
        Rewardconditionsobtained: 'Condiciones para conseguir recompensas',
        Obtainpatrioticcoinallocation: 'Conseguir monedas Aigo Configuración',
        Inputquantity: 'Escribir cantidad',
        EnterName: 'Escribir nombre',
        Registrationsuccessfulnot: 'Registrado correctamente (no usar código de invitación )',
        Inviteduserplacesanorder: 'Usuario invitado que realiza el pedido',
        Registrationsuccessful: 'Registrado correctamente (utilizar código de invitación)',
        Registeredaccount: 'Cuenta registrada (país registrado)',
        Registrationsuccessfulnot1: 'La cuenta registrada correctamente sin código de invitación',
        Numberoflocalcurrencies: 'Las cantidades configuradas a continuación corresponden a la divisa nacional del país',
        Inviteraccount: 'Cuenta que ha hecho la invitación (proporcionar cuenta del código de invitación, país del código de invitación)',
        Provideinvitationcode: 'Proporcionar el código de invitación a otros usuarios, otros usuarios utilizan el código de invitación para registrarse y completar correctamente un pedido (cada usuario invitado solo puede obtener una bonificación)',
        Usinganinvitationcode: 'registre la cuenta correctamente utilizando el código de invitación',
        ProductPromotion: 'Promociones de productos',
        discount: 'Descuentos',
        Freeshippingornot: 'Envío gratuito o no',
        Secondkillsorting: 'clasificación sec-killing',
        surplus: 'Restante',
        Totalnumber: 'Total',
        ImportFile: 'Importar archivos',
        Selectupto50: 'Seleccionar hasta 50',
        Setuppromotions: 'Configurar promoción',
        Nocountrieslisted: 'No hay países donde los productos se colocan en estantes',
        Promotiontime: 'Periodo de promoción',
        Displaypromotionalprices: 'Mostrar el precio promocional o no',
        PromotionPrice: 'Precio promocional',
        Promotionquantity: 'Cantidad promocional',
        Doyousupporttheuseofcoupons: 'Se admite el uso de cupones o no',
        Purchaserestrictionsforthesameuser: 'Restricciones de compra para un mismo usuario',
        Singleorderlimit: 'Limitado a un único pedido',
        CurrentactivitypricesinSpain: 'El margen bruto sobre el precio de actividad actual en {countryCode} es del',
        SubmitConfirmation: 'Enviar confirmación',
        Productdistribution: 'Distribución del producto',
        DistributionName: 'Nombre de distribución',
        DistributionCountry: 'País de distribución',
        Distributionprice1: 'Precio de distribución',
        Distributiontime: 'Periodo de distribución',
        DistributionPrice: 'Precio de distribución',
        Distributionquantity: 'Cantidad de distribución',
        CopyConnection: 'Copiar enlace',
        Successfullycopied: 'Copiado correctamente',
        Countryofuse: 'País de utilización',
        Discounttype: 'Tipo de oferta',
        Fullreduction: 'reembolso',
        Isthereafixedcode: '¿Hay códigos fijos?',
        Effective: 'Válido',
        Comingsoon1: 'Pronto será válido',
        Expired: 'Caducado',
        Overlayornot: 'apilados o no',
        Istheexchangecodefixed: 'se fija el código de canje o no',
        Received: 'recibido',
        Used: 'Utilizado',
        Availableamount: 'importe disponible',
        Maximumdeductionamount: 'Importe máximo de deducción',
        Canitbestacked: 'Apilable o no',
        Redemptioncode: 'Código de canje',
        Fullreductionamount: 'Importe del reembolso',
        Enterredemptioncode: 'Introducir código de canje',
        Numberofredemptioncodes: 'Número de códigos',
        Numberofusesbythesameuser: 'Número de usos para el mismo usuario',
        Effectivetime: 'Periodo de validez',
        Isitpossibletooverlaydistribution: 'Acumulable con o sin distribución',
        Canpromotionsbestacked: 'Acumulable con o sin promoción',
        Entercoupondescription: 'Escriba las instrucciones del vale',
        Pleaseenteradescription: 'Escriba una descripción',
        Pleaseselectadate: 'Seleccione una fecha',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Escriba el número de usos para el mismo usuario',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Escriba la cantidad 0 para indicar que es ilimitado',
        Pleaseentertheavailableamount: 'Escriba el importe disponible',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'El número de códigos de canje solo puede ser superior al original',
        Pleaseenterthenumberofredemptioncodes: 'Introduzca el número de códigos de canje',
        Applicableclassification: 'Categorías aplicables',
        Applicableproducts: 'Productos aplicables',
        Useordernumber: 'Utilice el número de pedido',
        Claimstatus: 'Estado de la recogida',
        Notclaimed: 'No recogido',
        notused: 'No utilizado',
        Usagestatus: 'Estado de uso',
        Collectiontime: 'Hora de recogida',
        Usagetime: 'Tiempo de uso',
        UsingUsers: 'usuarios que lo utilizan',
        BannerManagement: 'Gestión del anuncio',
        position: 'Ubicación',
        JumpType: 'Tipo de salto',
        Nojump: 'Sin salto',
        Homepagerecommendationposition: 'Página de inicio destacada',
        Homebanner: 'Anuncio de inicio',
        Displaytime: 'Hora de visualización',
        JumpLink: 'Enlace de salto',
        Suggestedsize: 'Tamaño sugerido',
        Enterjumplink: 'Escribir enlace de salto',
        Uploadingimagesonlysupports: 'Subir imágenes solo en los formatos jpg, png, gif, jpeg',
        Topwithlargenumbers: 'Por defecto es 0, cuanto mayor sea el número, mayor será la clasificación',
        Pleaseselectatleastonecountry: 'Seleccione al menos un país',
        Boutiquerecommendationspace: 'Espacio de recomendación de tiendas',
        Fullproductrecommendation: 'Recomendación de productos completa',
        popupnotification: 'Ventana emergente',
        Addbanner: 'Añadir anuncio',
        subscriptionmanagement: 'Gestión de suscripciones',
        Emailaddress: 'Correo electrónico',
        Subscribing: 'Suscripción en curso',
        Canceled: 'Cancelado',
        SubscriptionSource: 'Fuente de la suscripción',
        Unsubscribe: 'cancelar suscripción',
        Resubscribe: 'Volver a suscribirse',
        Pleaseselectatimerange: 'Seleccione un intervalo de tiempo',
        WebsiteManagement: 'Gestión del sitio',
        informationservice: 'Servicios de información',
        New: 'Crear',
        Isitdisplayedatthebottomofthewebsite: 'Mostrar en la parte inferior de la página web o no',
        Pleaseselectaninformationservice: 'Seleccione el servicio de información',
        deleteconfirmation: 'Confirmar la supresión del nodo y de toda su información o no',
        Pleaseenteracolumnname: 'Escriba un nombre de columna',
        Thelengthcannotexceed50characters: 'La longitud no puede superar los 50 caracteres',
        ThelengthcannotexceedXcharacters: 'La longitud no puede superar los {num} caracteres',
        Pleaseentersorting: 'Escriba el pedido',
        Selectedcountrycontentisrequired: 'Es obligatorio seleccionar un país',
        TitleName: 'Nombre del título',
        Chinesetitleisrequired: 'El título en chino es obligatorio',
        Selectedcountrytitleisrequired: 'El título en el idioma del país seleccionado es obligatorio',
        EditingInformationServices: 'Editar servicio de información',
        ColumnName: 'Nombre de columna',
        Pleaseenterthecreationtime: 'Escriba la hora de creación',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Escriba si se muestra en la parte inferior de la página web',
        ViewInformationServices: 'Ver Servicio de información',
        Modificationtime: 'Hora de modificación',
        Countrycannotbeempty: 'El país no puede estar vacío',
        Titlecannotbeempty: 'El título no puede estar vacío',
        Richtextcontentonthepagecannotbeempty: 'El contenido de texto enriquecido de la página no puede estar vacío',
        Nationalconfiguration: 'Configuración del país',
        currency: 'Divisa',
        allocation: 'Configuración',
        Statuseditedsuccessfully: 'Estado editado correctamente',
        Areyousuretocontinuedeletingdata: '¿Está seguro de que quiere seguir borrando datos?',
        WebsiteAddress: 'Dirección del sitio web',
        Contactinformation: 'Información de contacto',
        Customerserviceemail: 'Correo electrónico de atención al cliente',
        Socialmedia: 'Redes sociales',
        Socialmediainformation: 'Información sobre redes sociales',
        Pleasechooseapaymentmethod: 'Seleccione un método de pago',
        Lengthgreaterthan20characters: 'Longitud superior a 20 caracteres',
        Lengthgreaterthan100characters: 'Longitud superior a 100 caracteres',
        Successfullydeletedinformation: 'Mensaje borrado correctamente',
        Iconmustbeuploaded: 'El icono debe estar cargado',
        Successfullyaddedinformation: 'Mensaje añadido correctamente',
        Successfullyeditedinformation: 'Mensaje editado correctamente',
        Pleaseentertheemailcorrectly: 'Escriba su dirección de correo electrónico correctamente',
        Thesizeoftheuploadedimagecannotexceed: 'El tamaño de la imagen cargada no debe ser superior a',
        Countryname: 'Nombre del país',
        Pleaseuploadpictures: 'Cargue una imagen',
        NewCountryInformation: 'Crear nueva información del país',
        EditCountryInformation: 'Editar información del país',
        Picturesmustbetransmitted: 'Deben cargarse imágenes',
        NewCountryInformationTableImageAddress: 'Crear nueva hoja de información del país， imagen y dirección',
        Operationsuccessful: 'La operación se ha realizado correctamente',
        Euro: 'Euro',
        pound: 'Libra',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'La marca no puede estar vacía',
        Thewebsiteaddresscannotbeempty: 'La dirección del sitio web no puede estar vacía',
        Contactinformationcannotbeempty: 'La información de contacto no puede estar vacía',
        Thecustomerserviceemailaddresscannotbeempty: 'La dirección de correo electrónico del servicio de atención al cliente no puede estar vacía',
        Medianamecannotbeempty: 'El nombre del medio no puede estar vacío',
        Themediaiconcannotbeempty: 'El icono del medio no puede estar vacío',
        Medialinkcannotbeempty: 'El enlace no puede estar vacío',
        Thecountryiconcannotbeempty: 'El icono del país no puede estar vacío',
        Languagecannotbeempty: 'El idioma no puede estar vacío',
        Currencycannotbeempty: 'La divisa no puede estar vacía',
        Pagedescriptioncannotbeempty: 'La descripción de la página no puede estar vacía',
        Statusenable_disablecannotbeempty: 'El estado Activar/Desactivar no puede estar vacío',
        SEOManagement: 'Gestión SEO',
        Import: 'Importar',
        DownloadImportTemplate: 'Descargar plantilla de importación',
        PageURL: 'URL de la página',
        SEOdata: 'Datos SEO',
        SEOConfigurationTemplate: 'Plantilla de configuración SEO',
        Downloadsuccessful: 'Se ha descargado correctamente',
        SEOManagementImport: 'Importación de gestión SEO',
        DragtheExcelfilehere_orclickonupload: 'Arrastre y suelte el archivo Excel aquí o haga clic en Cargar',
        Fileimportedsuccessfully: 'Archivo importado correctamente',
        Fileimportfailed_pleasecheckthefilecontent: 'Error al importar el archivo, compruebe el contenido',
        Pleaseenterthelanguage: 'Escriba el idioma',
        Thelengthcannotexceed2characters: 'La longitud no puede superar los 2 caracteres',
        PleaseenterthepageURL: 'Introduzca la URL de la página',
        Thelengthcannotexceed100characters: 'No debe superar los 100 caracteres',
        Thelengthcannotexceed20characters: 'No debe superar los 20 caracteres',
        No_Illegalrowdata: 'La línea ? de datos es ilegal',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Error al cargar el archivo, compruebe su contenido',
        Namecannotbeempty: 'El nombre no debe estar vacío',
        PageURLcannotbeempty: 'La URL de la página no debe estar vacía',
        Thepagetitlecannotbeempty: 'El título de la página no debe estar vacío',
        SEOManagementImportTemplate: 'Plantilla de importación de gestión SEO',
        Donotmodify: 'No modifique las tres primeras líneas del título de la plantilla.',
        Required: 'Los campos marcados con ' * ' son obligatorios',
        Brandandlanguagemustbefullycapitalized: 'La marca y el idioma deben estar en mayúsculas',
        SEOManagementExport: 'Exportación de gestión SEO',
        Pagekeywordscannotbeempty: 'Las palabras clave de la página no pueden estar vacías',
        Prohibitedsalesarea: 'Zonas de venta prohibidas',
        Pleaseenterthepostalcodecorrectly: 'Escriba su código postal correctamente',
        Pleasefillinthepostalcode: 'Escriba su código postal',
        Countrycodecannotbeempty: 'El código de país no debe estar vacío',
        Postalcodecannotbeempty: 'El código postal no debe estar vacío',
        Postalcodealreadyexists: 'El código postal ya existe',
        Homepagearticle: 'Artículos de la página de inicio',
        BrandStory: 'Historias de la marca',
        SciencePopularizationZone: 'Zona de divulgación científica',
        Lightstripzone: 'Zona Tiras de luz',
        Pictureandtext: 'imagen y texto',
        Comingsoon: 'próximo lanzamiento',
        Publishinginprogress: 'lanzamiento en curso',
        column: 'Columna',
        Releasetime: 'Fecha de publicación',
        Pleaseenteratitle: 'Escriba un título',
        Pleaseselectacolumn: 'Seleccione una columna',
        Pleaseselectatype: 'Seleccione un tipo',
        Patrioticcoinusagelog: 'Registro para usar monedas Aigo',
        income: 'Ingresos',
        expenditure: 'Gastos',
        mode: 'Método',
        Orderexpenses: 'Gastos del pedido',
        Orderrewards: 'Recompensas por pedidos',
        OrderCloseReturn: 'Cierre y devolución de pedidos',
        other: 'Otros',
        Backendrecharge: 'Recarga de fondo',
        Orderrebate: 'Reembolso de pedidos',
        Numberofchangesinpatrioticcoins: 'Número de monedas Aigo cambiadas',
        Numberofpatrioticcoinsafterchanges: 'Número de monedas Aigo después del cambio',
        CountryCode: 'Código de país',
        Changetime: 'Hora del cambio',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Seleccione el historial de consumo de monedas Aigo del usuario',
        WebsiteNavigation: 'Navegación del sitio',
        HomepageConfiguration: 'Configuración de la página de inicio',
        Navigationcontent: 'Contenido de la navegación',
        Recommendedforyou: 'Recomendado para usted',
        SelectNavigation: 'Seleccionar navegación',
        Pleasecheckfirst: 'Marque primero',
        Logmanagement: 'Gestión de registros',
        UserID_IP_LoginDevice: 'ID/IP/dispositivo de inicio de sesión del usuario',
        logintime: 'Hora de inicio de sesión',
        Loginmethod: 'Método de inicio de sesión',
        IPaddress: 'Dirección IP',
        Logindevice: 'Dispositivo de inicio de sesión',

        //11-22
        AssociatedOrderNumber: 'Número de pedido asociado',

        recordId: 'ID de registro',

        UserAccess: 'Acceso de usuarios',
        PleaseSelectTheStatisticalType: 'Seleccione el tipo de estadística',
        StatisticalByDate: 'Estadística por fecha',
        StatisticalBySource: 'Estadística por fuente',
        Date: 'Fecha',
        Visits: 'Visitas',
        NewVisitors: 'Nuevos visitantes',
        OrderStatistics: 'Estadísticas de pedidos',
        Sales: 'Ventas',
        TotalOrders: 'Pedidos totales',
        AverageOrderAmount: 'Importe medio de los pedidos',
        SynchronizeEBP: 'Sincronizar EBP',
        ParameterName: 'Nombre del parámetro',
        OnTheShelf: 'En la estantería',
        OfflineShoppingCart: 'Cesta de la compra offline',
        AddToCart: 'Añadir a la cesta',
        EnterCheckout: 'Entrar en caja',
        UserLogin: 'Inicio de sesión de usuario',
        UserRegistration: 'Registro de usuario',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Diferentes números de IP para entrar en la página de confirmación del pedido',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Números de IP diferentes para añadir artículos a la cesta de la compra',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Números de IP diferentes para quienes completaron el proceso de inicio de sesión tras ser redirigidos desde la cesta de la compra offline a la página de inicio de sesión.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Números de IP diferentes para los que completaron el proceso de registro tras ser redirigidos desde la cesta de la compra offline a la página de registro.',
        UserBehavior: 'Comportamiento del usuario',
        Display: 'Mostrar',
        DoNotDisplay: 'No mostrar',
        AddToPresale: 'Añadir a preventa',
        CancelPresale: 'Cancelar preventa',
        PresaleConfiguration: 'Configuración preventa',
        OffTheShelf: 'Fuera de la estantería',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Por favor, complete la información del producto antes de configurar la preventa',
        SynchronizedEBPPrompts: 'Debido a la necesidad de sincronización con EBP, la sincronización por lotes sólo puede realizarse una vez en X minutos para evitar errores. Tras la confirmación, la sincronización se realiza de forma asíncrona y la SKU se muestra inmediatamente después de obtenerla. Por favor, tenga paciencia.',
        PleaseWait: 'Por favor, espere',
        PleaseSelectATag: 'Seleccione una etiqueta',
        Updating: 'Actualizando',
        Abortive: 'Error',
        DefaultRule: 'Regla por defecto',
        BottomInventory: 'Inventario inferior',
        SynchronisedAlertsForGraphicDetails: 'Detalles gráficos: Sincronizar las [palabras clave del producto] del idioma correspondiente según el idioma',
        SynchronisedAlertsForLargeProductImages: 'Imagen del producto: Sincronizar la imagen del idioma correspondiente según el idioma, leer la clasificación de imágenes EBP y establecer automáticamente la primera imagen EBP como imagen principal.',
        Account: 'Cuenta',
        Account1: 'Cuenta',
        EnterUpToNumItems: 'Introduzca hasta {num} artículos',
        SynchronizedSuccessfully: 'Sincronizado con éxito',
        SuccessfullyListed: 'Listado correcto',
        SearchSuccessful: 'Búsqueda realizada con éxito',
        EnterNewPassword: 'Introducir nueva contraseña',
        OnlyNumbersAndLettersAreAllowed: 'Sólo se permiten números y letras',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Por favor, complete la configuración de preventa antes de listar el artículo de preventa',
        ProductWeight: 'Peso del producto',
        ConfirmExecution: '¿Confirmar ejecución?',
        ConfirmBatchListing: '¿Confirmar listado de lotes?',
        Template: 'Plantilla',
        PleaseSelectTheProductsToBeSynchronized: 'Por favor, seleccione los productos a sincronizar',
        ForcedSynchronisationDescription: 'La sincronización forzada borrará los datos modificados y los sustituirá por los datos sincronizados. La sincronización ordinaria no sincronizará los datos modificados de los productos.',
        ConfirmSynchronizationExecution: 'Confirme la ejecución de la sincronización',
        SynchronisationHasBeenSentPleaseWait: 'El mensaje de tarea de sincronización ha sido enviado, por favor espere pacientemente a que la sincronización de datos se complete.',
        ProhibitLogin: 'Prohibir inicio de sesión',
        IsTheUserLoggedIn: '¿El usuario ha iniciado sesión?',
        Prohibit: 'Prohibir',
        ProhibitedSuccessfully: 'Prohibido con éxito',
        EnableLogin: 'Habilitar inicio de sesión',
        EnabledSuccessfully: 'Habilitado con éxito',
        OperationCanceled: 'Operación cancelada',
        UserData: 'Datos de usuario.xlsx',
        GraphicDetails: 'Detalles gráficos',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Este producto se ha configurado como producto combinado y no se puede configurar para la preventa. Por favor, elimine primero el producto de la combinación.',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Este producto es un producto promocional y no puede establecerse para preventa. Por favor, cancele primero la promoción.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Este producto es un producto de distribución y no puede ponerse en preventa. Cancele primero la distribución.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Este producto ya tiene una actividad de reducción completa y no se puede añadir',
        ESNoPriceNotAvailableForPresale: 'Este producto no tiene precio en {Any} y no puede añadirse a la preventa. Por favor, elimine primero el país correspondiente o sincronice el precio del país correspondiente antes de añadir productos en preventa.',
        ConfirmSettingThisProductAsAPresaleItem: '¿Confirmar la configuración de este producto como artículo de preventa?',
        PresaleAddedSuccessfully: 'Preventa añadida correctamente',
        ConfirmSettingThisProductAsANonpresaleItem: 'Confirmar la configuración de este producto como artículo no en preventa.',
        CancelPresaleSuccessful: 'Cancelar preventa con éxito',
        Preview: 'Vista previa',
        TheInventoryOfCurrentProducts0Continue: 'El inventario del producto actual {stock} es 0, ¿continuar?',
        TheInputContentCannotExceedCharacters: 'El contenido introducido no puede exceder {x} caracteres',
        PleaseSelectTheActivity: 'Por favor, seleccione la actividad',
        OrderDetails: 'Detalles del pedido',
        LogisticsInformation: 'Información logística',
        CancelRemark: 'Cancelar comentario',
        DepositAmount: 'Importe del depósito',
        FinalPaymentAmount: 'Importe del pago final',
        FullReductionDiscount: 'Descuento total',
        YourBrowserDoesNotSupportVideoPlayback: 'Su navegador no soporta la reproducción de vídeo',
        EditBanner: 'Editar banner',
        AddBanner: 'Añadir banner',
        UpdateTime: 'Actualizar hora',
        HomepageRecommendationPosition: 'Posición de recomendación de la página de inicio',
        ActivityID: 'ID de actividad',
        PleaseSelectThePosition: 'Seleccione la posición',
        PleaseSelectTheJumpType: 'Seleccione el tipo de salto',
        PleaseEnterTheAssociatedID: 'Introduzca el ID asociado',
        TheUploadedImageSizeCannotExceed5MB: 'El tamaño de la imagen cargada no puede superar los {num} MB.',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Sólo se admite el formato mp4 para subir vídeos',
        TheUploadedVideoSizeCannotExceed500MB: 'El tamaño del vídeo cargado no puede superar los {num} MB.',
        PleaseUploadTheVideo: 'Cargue el vídeo',
        PleaseEnterTheJumpLink: 'Por favor, introduzca el enlace de salto',
        PleaseEnterTheCorrectLink: 'Introduzca el enlace correcto',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Error de verificación, por favor, introduzca primero los campos obligatorios',
        AddFailed: 'Fallo al añadir',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Ya es el primero, y la subida falló',
        PleaseEnterTheRebateRatio: 'Por favor, introduzca el ratio de rebaja',
        EditCoupon: 'Editar cupón',
        AddCoupon: 'Añadir cupón',
        IDCouponName: 'ID/nombre del cupón/Código de canje',
        Random: 'Aleatorio',
        Fixed: 'Fijo',
        AboutToTakeeffect: 'A punto de surtir efecto',
        NotStackable: 'No apilable',
        Stackable: 'Apilable',
        Frozen: 'Congelado',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Introduzca el importe máximo de deducción, 0 significa ilimitado',
        UseUserID: 'Utilizar ID de usuario',
        UseOrderNumberExchangeCodeUserID: 'Utilice el número de pedido/código de canje/identificación de usuario',
        PleaseEnterTheDiscount: 'Introduzca el descuento',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Sólo se pueden introducir números del 0 al 100, hasta 1 decimal',
        PleaseEnterTheFullReductionAmount: 'Por favor, introduzca el importe total de la reducción',
        PleaseEnterTheExchangeCode: 'Introduzca el código de cambio',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Sólo se pueden introducir números/letras, al menos 3 dígitos, hasta 20 dígitos',
        PleaseSelectAFixedExchangeCode: 'Seleccione un código de cambio fijo',
        PleaseEnterTheMaximumDeductionAmount: 'Introduzca el importe máximo de deducción',
        PleaseSelectWhetherItCanbeStacked: 'Por favor, seleccione si se puede apilar',
        WarehousingTime: 'Tiempo de almacenamiento',
        EnterActivityDescription: 'Introduzca la descripción de la actividad',
        ActivityTitleCannotBeEmpty: 'El título de la actividad no puede estar vacío',
        TheTitleCannotExceed200Characters: 'El título no puede superar los {num} caracteres',
        PleaseEnterTheActivityDescription: 'Introduzca la descripción de la actividad',
        ActivityTimeCannotBeEmpty: 'El tiempo de la actividad no puede estar vacío',
        ActivityDescriptionLimitOf10000Words: 'La descripción de la actividad tiene un límite de {num} palabras',
        ActivityRecommendation: 'Recomendación de actividad',
        Promotion: 'Promoción',
        EveryFull: 'Cada {num}completo',
        EnterAmount: 'Introducir importe',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Sólo se pueden introducir números positivos, hasta 2 decimales, no puede empezar por 0',
        PleaseEnterTheCorrectAmount: 'Introduzca el importe correcto',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Seleccione las categorías o productos aplicables',
        Rules: 'Reglas',
        RemainingTotal: 'Restante/total',
        CopyLink: 'Copiar enlace',
        DistributionNameProductIDBarcode: 'Nombre de distribución/ID de producto/código de barras',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Esta subfunción no está disponible en este país por el momento, lo estará más adelante',
        PleaseEnterThePrice: 'Introduzca el precio',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Se calcula automáticamente después de introducir el descuento',
        PleaseEnterTheQuantity: 'Introduzca la cantidad',
        OrderRemarkLabel: 'Etiqueta de observación del pedido',
        OrdersWorkWithInfluencers: 'Los pedidos funcionan con influenciadores',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'El margen de beneficio bruto actual de la tarifa actual es de',
        ThePriceCannotBeLowerThanTheLowestPrice: 'El precio no puede ser inferior al precio más bajo:',
        Distribution: 'Distribución',
        IDName: 'ID/nombre',
        ProductSelected: 'Producto seleccionado',
        ProductHasNoPricing: 'El producto no tiene precio',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'El margen de beneficio bruto actual del precio de actividad actual es {rate} %, ¿está seguro de enviarlo?',
        SubscriptionStatus: 'Estado de la suscripción',
        URLClickToCopyAddress: 'URL (haga clic para copiar la dirección)',
        URLSourceManagement: 'URL Gestión de fuentes',
        PleaseEnterTheURL: 'Por favor, introduzca la URL',
        PleaseEnterEnglishOrNumbers: 'Por favor, introduzca inglés o números',
        LengthCannotExceed1000Characters: 'La longitud no puede superar los 1000 caracteres',
        URLFixedAddressIsEmpty: 'La dirección URL fija está vacía',
        cover: 'Portada',
        PleaseEnterOrPasteTheVideoScript: 'Introduzca o pegue el guión del vídeo',
        VideoScript: 'Guión de vídeo',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Por favor, cargue un archivo de vídeo o rellene el guión del vídeo',
        PleaseEnterTheBrand: 'Por favor, introduzca la marca',
        Portuguese: 'Portugués',
        Register: 'Registrar',
        InviteUsers: 'Invitar usuarios',
        OrderPayment: 'Ordenar pago',
        SupportsMP4Forma500MB: 'Soporta formato mp4, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'No hay ningún producto seleccionado, ¿puede cerrarlo?',
        ExitFullScreen: 'Salir a pantalla completa',
        FullScreen: 'Pantalla completa',
        Logout: 'Salir',
        OnlySupportsFilesInFormat: 'Sólo admite archivos en formato {xxx}',
        PleaseSetAllPromotionalProducts: 'Por favor, establezca todos los productos promocionales',
        PleaseEnterTheWeight: 'Por favor, introduzca el peso',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'El precio fijo no puede ser inferior al precio más bajo',
        CloseOthers: 'Cerrar otros',
        CloseAll: 'Cerrar todo',
        ImportSuccessful: 'Importar con éxito',
        BatchRecharge: 'Recarga por lotes',
        RechargeEmail: 'Correo electrónico de recarga',
        RechargeCountry: 'País de recarga',
        RechargeSuccessful: 'Recarga correcta',
        RechargeInformation: 'Información de recarga',
        PleaseDownloadTheRechargeInformationFirst: 'Por favor, descargue primero la información de recarga',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Los datos de recarga por lotes de la moneda AIGO no pueden estar vacíos',
        TheBatchRechargeDataCannotExceed1000Records: 'Los datos de recarga por lotes no pueden superar los 1000 registros',
        ConfirmSubmission: 'Confirme el envío',
        ExportFailed: 'Error de exportación',
        PackingQuantity: 'Cantidad de embalaje',
        Activity: 'Actividad',
        PleaseSelectWhetherToEnable: 'Seleccione si desea activar',
        OnlyOneProductCanBeSynchronized: 'Sólo se puede sincronizar un producto',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU con una cantidad de {num} no existe',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU con una cantidad de {num}no coincide con el múltiplo de ventas',
        SalesMultipleDoesNotMatchCannotBeListed: 'El múltiplo de ventas no coincide, no se puede listar',
        SalesMultipleIsEmptyInSpain: 'El múltiplo de venta está vacío en España',
        SynchronizationOfPackageQuantityIsAbnormal: 'La sincronización de la cantidad de paquetes es anormal',
        CannotContinueToMoveUp: 'No se puede seguir subiendo',
        CannotContinueToMoveDown: 'No puede seguir bajando',
        SynchronizeThePackageQuantity: 'Sincronizar la cantidad de paquetes',
        ProductAssociationIDCannotBeEmpty: 'El ID de la asociación de productos no puede estar vacío',
        PackageQuantityCannotBeEmpty: 'La cantidad de paquetes no puede estar vacía',
        PleaseEnterTheCorrectQuantityFormat: 'Introduzca el formato de cantidad correcto',
        PleaseEnterSeparatedBy: 'Por favor, introduzca (separado por #)',
        PackageQuantity: 'Cantidad del paquete',
        LengthBetween1And140Characters: 'Longitud entre 1 y 140 caracteres',
        PleaseSelectTheVariantParametersFirst: 'Seleccione primero los parámetros de la variante',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'El parámetro está asociado a la variante, por favor tenga cuidado',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Por favor, seleccione datos de la misma categoría, por favor, vuelva a seleccionar',
        UpTo5VariantsCanBeSelected: 'Se pueden seleccionar hasta 5 variantes',
        NoVariant: 'Ninguna variante',
        VerificationFailedPleaseSelectTheVariant: 'Verificación fallida, por favor seleccione la variante',
        VerificationFailedPleaseSelectTheProduct: 'Error de verificación, seleccione el producto',
        VerificationFailedTheProductParameterValueIsEmpty: 'Error de verificación, el valor del parámetro del producto está vacío',
        SetSuccessfully: 'Configurado correctamente',
        UploadingVideo: 'Cargando vídeo',
        SearchParameters: 'Parámetros de búsqueda',
        ImportantParameters: 'Parámetros importantes',
        Select: 'Seleccione',
        ConfirmSettingThisImageAsTheMainImage: 'Confirmar el ajuste de esta imagen como imagen principal',
        ConfirmDisplayDoNotDisplay: 'Confirmar mostrar/no mostrar',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'El límite actual es seleccionar {num1} archivos, esta vez ha seleccionado {num2} archivos, se han seleccionado un total de {num3} archivos',
        ConfirmToRemoveAny: 'Confirmar ¿eliminar {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'El entorno de prueba no puede cargar archivos',
        PleaseEnterTheEnglishTitle: 'Por favor, introduzca el título en inglés',
        CancelSettingAsTheMainImage: '¿Cancelar el ajuste como imagen principal?',
        SetAsTheMainImage: '¿Establecer como imagen principal?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'No se pueden enviar imágenes o vídeos que ya existen',
        SynchronizeAnyImagesToOtherLanguages: '¿Sincronizar imágenes {Any} a otros idiomas?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Seleccione primero el producto que desea sincronizar',
        PleaseSelectTheProductFirst: 'Seleccione primero el producto',
        ConfirmWhetherToDeleteTheSelectedImageVideo: '¿Confirmar si borrar la imagen/vídeo seleccionado?',
        TheMainPictureCannotBeTurnedOff: 'No se puede desactivar la imagen principal',
        CancelDisplay: '¿Cancelar la visualización?',
        ConfirmWhetherToDelete: 'Confirme si desea borrarla.',
        PleaseUploadTheEnglishImage: 'Por favor, cargue la imagen en inglés',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Fallo, por favor, introduzca el título del producto para todos los países de venta',
        PresaleTime: 'Hora de preventa',
        PresalePrice: 'Precio de preventa',
        PleaseEnter: 'Por favor, introduzca',
        PresaleQuantity: 'Cantidad de preventa',
        DepositRatio: 'Porcentaje de depósito',
        AssociatedAGBSOnthewayOrders: 'Pedidos asociados AGBS en camino',
        EstimatedDeliveryTime: 'Plazo estimado de entrega',
        FinalPaymentDeadline: 'Plazo de pago final',
        SelectTime: 'Seleccionar hora',
        FixedPrice: 'Precio fijo',
        ByProportion: 'Por proporción',
        ByFullPayment: 'Por pago total',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Sólo se pueden introducir números mayores que 0, hasta dos decimales',
        PresaleTimeCannotBeEmpty: 'La hora de preventa no puede estar vacía',
        PresalePriceTypeCannotBeEmpty: 'El tipo de precio de preventa no puede estar vacío',
        PresaleQuantityCannotBeEmpty: 'La cantidad de preventa no puede estar vacía',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Sólo se pueden introducir números enteros positivos, no pueden empezar por 0',
        DepositRatioTypeCannotBeEmpty: 'El tipo de ratio de depósito no puede estar vacío',
        DepositRatioCannotBeEmpty: 'El ratio de depósito no puede estar vacío',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Sólo se pueden introducir enteros entre 0 y 100, excluyendo 0 y 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'El plazo de entrega estimado no puede estar vacío',
        FinalPaymentDeadlineCannotBeEmpty: 'El plazo de pago final no puede estar vacío ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'El pago final debe realizarse antes de las 5 horas previas a la finalización de la promoción de preventa',
        TheRemainingAmount: 'Importe restante',
        PresaleStatus: 'Estado de la preventa',
        PresaleStartTime: 'Hora de inicio de la preventa',
        PresaleEndTime: 'Hora de finalización de la preventa',
        OnPresale: 'En preventa',
        DirectVisit: 'Visita directa',
        BackendManagementSystem: 'Sistema de gestión backend',
        Login: 'Inicio de sesión',
        TipsFillInTheUsernameAndPasswordCasually: 'Consejos: Introduzca el nombre de usuario y la contraseña de forma informal.',
        PleaseEnterUserName: 'Introduzca el nombre de usuario',
        PleaseEnterPassword: 'Introduzca la contraseña',
        LoginSuccessful: 'Inicio de sesión correcto',
        PleaseEnterYourAccountNumberAndPassword: 'Por favor, introduzca su número de cuenta y contraseña',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Sólo se puede devolver o cambiar el mismo producto',
        B2CBrandWebsite: 'Sitio web de la marca B2C',
        CommentManagement: 'Gestión de comentarios',
        AIGOCoins: 'Monedas AIGO',
        PleaseEnterThePackageQuantity: 'Por favor, introduzca la cantidad del paquete',
        PresalePriceCannotBeEmpty: 'Precio de preventa no puede estar vacío',
        Product: 'Producto',
        HomePageColumn: 'Columna de la página de inicio',
        ColumnDescription: 'Descripción de la columna',
        FlashSaleArea: 'Área de venta flash',

        //1.2.8之后
        SoldOutOrNot: 'Agotado o no',
        VirtualQuantity: 'Cantidad virtual',
        VirtualQuantityPackageQuantity: 'Cantidad virtual/cantidad de envases',
        CombinationIcon: 'Icono de combinación',
        UploadFailedFileSizeShouldBe3MB: 'La carga falló, el tamaño del archivo debe ser <3 MB',
        PromotionQuantityPackageQuantity: 'Promoción cantidad/paquete cantidad',
        RecommendedSize100: 'Tamaño recomendado: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Total - Restante/Total',
        Verification: 'Verificación',
        VirtualSalesVolume: 'Volumen de ventas virtual',
        VirtualSalesVolumePackageQuantity: 'Volumen de ventas virtual/cantidad de envases',
        DeleteOrNot: '¿Borrar o no?',
        EnabledOrNot: 'Activado o no',
        Cancel: 'Cancelar',
        Code: 'Código',
        Confirm: 'Confirmar',
        RebateDimension: 'Dimensión de la rebaja',
        InProgress: 'En curso',
        ActivityStatus: 'Estado de actividad',
        RebateRatioManagement: 'Gestión del ratio de rappel',
        StartTime: 'Hora de inicio',
        EndTime: 'Hora final',
        RebateAmount: 'Importe de la rebaja',
        NewUser: 'Nuevo usuario',
        RebateObjectNumber: 'Número de objeto de rappel',
        NumberOfCompletedOrders: 'Número de pedidos completados',
        RebateObject: 'Objeto de rappel',
        Operation: 'Operación',
        RecentOperator: 'Operador reciente',
        Country: 'País',
        Prompt: 'Preguntar',
        RebateProducts: 'Productos de rappel',
        NewUserPromotion: 'Promoción para nuevos usuarios',
        RegularUserPromotion: 'Promoción para usuarios habituales',
        Upcoming: 'Próximo',
        RebateRatio: 'Ratio de rappel',
        RebateOrNot: 'Reembolsar o no',
        Rebate: 'Reembolso',
        PleaseSelectARebateDimension: 'Seleccione forma de reembolso',
        PleaseSelectARebateObject: 'Seleccione un objeto de descuento',
        PleaseSelectACountry: 'Seleccione un país',
        PleaseSelectARebateRatio: 'Seleccione un ratio de reembolso',
        RecentOperationTime: 'Tiempo de operación reciente',
        PromotionObjectType: 'Tipo de objeto de promoción',
        ProductPromotionType: 'Tipo de promoción de producto',
        UserType: 'Tipo de usuario',
        ApplicableUserType: 'Tipo de usuario aplicable',
        RegularUser: 'Usuario habitual',
        UseAigoCoinsOrNot: 'utilizar monedas Aigo o no',
        EndDateOfMembership: 'fecha de finalización de la afiliación',
        StartDateOfMembership: 'fecha de inicio de la afiliación',
        memberOrNot: 'miembro o no',
        No: 'No',
        Yes: 'Sí',
        LowInventory: 'Bajo inventario',
        RechargeEmailbox: 'Buzón de recarga',
        SubmittedSuccessfully: 'Enviado con éxito',
        Importing: 'importando',
        InformationOfTheRechargeFailed: 'información de la recarga fallida',
        RechargeFailed: 'Recarga fallida',
        RechargeInBatch: 'Recarga por lotes',
        RechargeSuccessfully: 'Recargar con éxito',
        RechargeAigoCoins: 'Recargar monedas Aigo',
        RequiredField: 'Campo obligatorio',
        PDFFormat: 'Formato PDF',
        FileSize3MB: 'Tamaño del archivo <3 MB',
        UploadFailedPDFFormatRequired: 'La carga falló, formato PDF requerido',
        AuditFailedPleaseUploadInvoiceAttachment: 'Audit failed, please upload invoice attachment',
        PleaseUploadInvoiceAttachment: 'Please upload invoice attachment',
        InvoiceAttachment: 'Invoice attachment',
        Upload: 'Cargar',
        UploadFailed: 'La carga falló',

        HaveAnAccount: '¿No tiene una cuenta? Haga clic aquí para registrarse',
        SoldOut: 'Agotado',
        HaveAnInvitationCode: 'Opcional, deje en blanco si no tiene un código de invitación.',
        RestockedItems: 'Reposición de artículos',

        NotMeetPleaseEnterAgain: 'Su Nick no cumple los requisitos, por favor, introdúzcalo de nuevo.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Este cupón sólo está disponible para nuevos usuarios.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Este cupón sólo está disponible para usuarios existentes.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Sólo se admiten devoluciones o cambios del mismo producto.',
        With365DaysRemaining: '(Caduca el 25/09/2024) Quedan 365 días',

        TheNicknameDoesNotMeet: 'El nombre de usuario no es válido, ingrese uno nuevo',

        GoToView: 'Ir a la vista',
        DoNotRegisterRepeatedly: 'No registrarse repetidamente',
        ValidityPeriod: 'Periodo de validez',
        RegisterSuccessfully: 'Registro correcto',
        MemberRegistration: 'Registro de miembros',
        RegistrationFailed: 'Registro fallido',
        OneYear: 'Un año',
        AddToShoppingCart: 'Añadir a la cesta',
        AddANewAddress: 'Añadir una nueva dirección',
        ZIPCode: 'Código postal*',

        EditYourAddress: 'Edite su dirección',
        Remove: 'Eliminar',
        SetAsDefault: 'Fijar por defecto',
        FailedChangeDefaultAddress: 'No se ha podido cambiar la dirección predeterminada, inténtelo de nuevo más tarde.',
        Fullname: 'Nombre completo (Nombre y Apellido)*',
        PhoneNumber: 'Número de teléfono',
        DefaultAddressHasBeenChanged: 'Se ha cambiado la dirección por defecto',
        ViewComments: 'Ver comentarios',
        ClickToCheckLogisticsInfo: 'Haga clic para consultar la información de logística.',
        RestockingOfOutOfStockItems: 'Reposición de artículos agotados',
        ThisWeekRestockedItems: 'Reposición de artículos de esta semana',
        ProductListInTheShoppingCart: 'lista de productos en la cesta de la compra',
        WarmReminderVideoResources: 'recordatorio: Los recursos de vídeo sólo están disponibles para descarga única.',
        PleaseLeaveYourMessage: 'Por favor, deje su mensaje ',
        InvoiceManagement: 'Gestión de facturas',

        ReadmeFile: 'Archivo para leer',
        HelpCenter: 'Centro de ayuda',
        FileUpload: 'Carga de archivos',
        Globalization: 'Globalización',
        SalespersonProfile: 'Perfil del vendedor',
        BatchSyncPage: 'Página de sincronización por lotes',
        CountryConfigurationPage: 'Página de configuración de países',
        UserConsultation: 'Consulta de usuarios',
        PreSaleItems: 'Artículos de preventa',
        EventManagement: 'Gestión de eventos',
        BlankPage: 'Página en blanco',
        ThereAreCurrentlyNoProductPromotions: 'Actualmente no hay promociones de productos',
        EditInventoryBottomLine: 'Editar fondo de inventario',
        SetAFixedPrice: 'Establecer un precio fijo',
        EnterUpTo500Entries: 'Introducir hasta 500 entradas',
        CantRelateToYourself: 'No puede relacionarse con usted mismo',
        PleaseSelectTheApplicableUserType: 'Seleccione el tipo de usuario aplicable',
        TheActivityDescriptionLimitIs10000Words: 'El límite de descripción de la actividad es de 10.000 palabras',
        EnabledStatus: 'Estado activado',
        Enable: 'Habilitar',
        OperatingTime: 'Tiempo de funcionamiento',
        NewUserPromotionEnabled: 'Promoción de nuevos usuarios activada',
        NewUserPromotionDisabled: 'Promoción de nuevos usuarios desactivada',
        OldUserPromotionEnabled: 'Promoción de usuarios antiguos activada',
        OldUserPromotionDisabled: 'Promoción usuario antiguo desactivada',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'El precio promocional no puede ser inferior al precio más bajo',
        AutomaticallyCalculateAfterEnteringPrice: 'Se calcula automáticamente después de introducir el precio',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'El precio promocional para nuevos usuarios es mayor que el precio promocional para usuarios antiguos. ¿Desea continuar?',
        PleaseSelectPromotionCountry: 'Seleccione el país de la promoción',
        PromotionDiscount: 'Descuento promocional',
        PleaseEnterThePromotionalQuantity: 'Introduzca la cantidad promocional',
        PleaseEnterVirtualQuantity: 'Introduzca la cantidad virtual',
        NumberOfUserPurchases: 'Número de compras del usuario',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Por favor, introduzca el límite de compra para un mismo usuario',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Por favor, introduzca el límite de compra para un mismo pedido',
        QuantityGreaterThan0: 'Cantidad superior a 0',
        GrossProfitMarginCalculationResults: 'Resultados del cálculo del margen bruto',
        FixedPriceGrossMargin: 'Margen bruto del precio fijo',
        SellingPriceGrossProfitMargin: 'Margen de beneficio bruto del precio de venta',
        PromotionalPriceGrossProfitMargin: 'Margen de beneficio bruto del precio promocional',
        PromotionStartTime: 'Hora de inicio de la promoción',
        PromotionEndTime: 'Hora de finalización de la promoción',
        PromotionType: 'Tipo de promoción',
        PleaseSetTheSKUToPromotionalProducts: 'Por favor, establezca el SKU a ${xxx} productos promocionales',
        DistributionOffers: 'Ofertas de distribución',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Después de introducir la cantidad{Any} se calculará automáticamente en función de la cantidad del producto.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'El número de compras del usuario debe ser superior a 0',
        PleaseSelectCountry: 'Por favor, seleccione ${typeName} país',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Después de ingresar la cantidad promocional, se calculará automáticamente en función de la cantidad de productos.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Por favor, cancele primero la asociación en [Bolsa de productos rebajados].',
        EditBottomLineInventory: 'Editar inventario final',
        PleaseConfigureFixedPrice: 'Por favor, configure el precio fijo',
        BalancePayment: 'Pago de saldo',
        Deposit: 'Depósito',

        EndofValidity: 'Introduzca la fecha de caducidad',
        Year: 'Año',
        Month: 'Mes',
        Day: 'Día',
        UnitOfValidity: 'Unidad de validez',
        AIGOCoinDetails: 'Detalles de AIGO Coin',
        AIGOCoinsAreCloseToExpiring: 'Las monedas AIGO están a punto de caducar(Día)',
        CountryOfShipmentAddress: 'Dirección del país de envío',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Tiempo estimado de entrega',
        DataCorrespondingToTheSource: 'Datos correspondientes a la fuente',
        AccountStatus: 'Estado de la cuenta',
        OrderID: 'Solicitar ID',
        RecipientName: 'Nombre del Recipiente',
        RecipientLastName: 'Apellido del destinatario',
        MailsOfUser: 'Correos de usuario',
        StateProvince: 'Provincia del estado',
        CityTown: 'Ciudad/Pueblo',
        County: 'Condado',
        ReceivingCompany: 'Empresa receptora',
        BatchInput: 'Entrada por lotes',
        InputManually: 'Entrada manual',
        ProductCommentsInputTemplate: 'Plantilla de entrada de comentarios del producto',
        UserName: 'Nombre de usuario',
        Comments: 'Comentarios',
        StarRatingOfTheComment: 'Calificación de estrellas del comentario',
        CommentTime: 'Hora de comentar',
        UploadAttachment: 'Subir archivo adjunto',
        NumberOfAttachments: 'Número de archivos adjuntos',
        FileName: 'Nombre del archivo',
        Appendix: 'Apéndice',
        BatchInputOfProductComments: 'Entrada por lotes de comentarios de productos',
        SoldOut1: 'Agotado',
        PreSalePromotionID: 'ID de promoción de preventa',
        PromotionAbnormal: 'Promoción anormal',
        Updater: 'Actualizador',
        AddPreSalePromotion: 'Agregar promoción de preventa',
        EditPreSalePromotion: 'Editar promoción de preventa',
        Barcode: 'Código de barras',
        PreSaleCountries: 'Países de preventa',
        PreSaleDeposit: 'Depósito de preventa',
        PleaseEnterTheDocumentCode: 'Por favor ingrese el código del documento',
        BalancePaymentDeadline: 'Plazo de pago del saldo',
        FixedShippingFee: 'Tarifa de envío fija',
        PreSaleShippingFee: 'Tarifa de envío de preventa',
        FreeShippingSubjectToConditions: 'Envío gratuito sujeto a condiciones.',
        OrderPrice: 'Precio del pedido',
        PleaseEnterPriceConditions: 'Por favor introduce las condiciones del precio',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'La hora de finalización debe ser posterior a la hora de inicio.',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Ya hay promociones parcialmente superpuestas en este país, por favor modifíquelas.',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'La cantidad de preventa solo puede ser un número entero positivo mayor que 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'El tiempo de entrega estimado debe ser posterior a la hora de finalización del evento.',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'La fecha límite de pago del saldo debe ser anterior al tiempo de entrega estimado.',
        PromotionID: 'ID de promoción',
        DistributionPromotionID: 'ID de promoción de distribución',
        CouponID: 'ID de cupón',
        PromotionManagementID: 'ID de gestión de promoción',
        PortfolioManagementID: 'ID de gestión de cartera',
        ExportForFiltering: 'Exportar para filtrar',
        ListDisplay: 'Visualización de lista',
        InventoryItems: 'Artículos de inventario',
        InStock: 'En stock',
        OutOfStock: 'Agotado',
        FieldName: 'Nombre del campo',
        PleaseSelectInventory: 'Por favor seleccione existencias',
        PreSaleEvent: 'Evento de preventa',
        PerPage: 'Cada página muestra',

        // V1.3.3 end

        // V1.3.4 start
        StatisticsOfGiveawayActivities: 'Estadísticas de las actividades de regalo',
        PleaseEnterTheNameOfTheEvent: 'Por favor, ingrese el nombre del evento.',
        PleaseSelectTheActivityType: 'Por favor, elija el tipo de actividad.',
        PleaseCompleteTheActivityRating: 'Por favor, complete la evaluación de la actividad.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Por favor configure la campaña de preventa de {Any} antes de poder configurar el país donde se exhibirán los artículos de preventa.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'La cantidad del pedido y el número de regalos gratis no pueden ser menor que la categoría anterior.',
        SelectImage: 'Seleccione la imagen',
        GiftInformation: 'Información sobre el regalo',
        Gifts: 'Regalos',
        GiftsOfEndOfEvent: 'Regalos de fin de evento',
        CheckOutTheGifts: 'Echa un vistazo a los regalos',
        GiveawayEvent: 'Regalos del evento',
        SelectAGift: 'Selecciona un regalo',
        YouHaveNotSelectedAGift: 'No ha seleccionado ningún regalo',
        NoNeedForGifts: 'No necesita regalos',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Algunos regalos están agotados, vuelva a seleccionarlos',
        ReplaceAGift: 'Sustituir un regalo',
        ThereAreCurrently3FreeGiftsAvailable: 'Actualmente hay 3 regalos gratuitos disponibles',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Actualmente hay 3 regalos gratuitos disponibles, y 4 regalos más por la compra de otros 99',
        GiftList: 'Lista de regalos',
        GiftID: 'ID del regalo',
        CurrentInventory: 'Inventario actual',
        NotEnabled: 'No habilitado',
        GoodsForFree: 'Productos gratis',
        ConfirmToDeleteThisGiftInformation: '¿Confirmar para eliminar la información de este regalo?',
        GiftsSortingManagement: 'Gestión de la clasificación de regalos',
        GiftsTopicManagement: 'Gestión de temas de regalos',
        GiveawayEventID: 'ID del evento de regalo',
        GiveawayEventName: 'Nombre del evento',
        ProductCategory: 'Categoría del producto',
        ActivityMode: 'Modo de actividad',
        TotalNumberOfActivities: 'Número total de actividades',
        GiftsGivenQuantity: 'Cantidad de regalos entregados ',
        ThemeConfiguration: 'Configuración del tema',
        GiftSorting: ' Clasificación de regalos',
        GlobalConfiguration: 'Configuración global',
        ActiveCountry: 'País activo',
        PromotionalPpicture: 'Imagen promocional',
        RecommendedSizeX: 'Tamaño recomendado ${XXX*XXX}',
        FileSize1MB: 'Tamaño del archivo: ≤1MB',
        ActivitySorting: 'Clasificación de actividades',
        DragToSort: 'Arrastre para ordenar',
        ActivityCode: 'Código de actividad',
        GiftsForOrderAmount: 'Regalos por importe de pedido',
        ActivityRating: 'Valoración de la actividad',
        GiftsQuantity: 'Cantidad de regalos',
        EventGiftsSorting: 'Clasificación de regalos por evento',
        DefaultSelectionOfUsers: 'Selección de usuarios por defecto',
        LandingPageDisplay: 'Visualización de la página de destino',
        LandingPageActivityName: 'Nombre de la actividad de la página de destino',
        ConfirmDeleteThisGiftEvent: '¿Confirmar eliminación de este evento de regalo?',
        IsThereAGiftEvent: '¿Existe un evento de regalo?',
        OrderQuantity: 'Cantidad de pedidos',
        NumberOfGiftsIssued: 'Número de regalos emitidos',
        CustomerPrice: 'Precio para el cliente',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'La función de cantidad de eventos virtuales sólo se puede configurar si el tiempo de actividad de preventa es al menos superior a 2 días y el número de eventos es superior a 50',
        PreSaleSwitch: 'Interruptor de preventa',
        PreSaleActivity: 'Actividad de preventa',
        GiftManagement: "Gestión de regalos",

        //V 1.3.4 end

        //V1.3.5 start
        OrderSource: "Origen del pedido",
        NumberOfNewUserRegistrations: "Número de registros de nuevos usuarios",
        RegisteredUsersWhoVsitTheWebsite: "Usuarios registrados que visitan el sitio web",
        SoldDistributionQuantity: "Cantidad de distribución vendida",
        DistributionQuantityLock: "Cantidad de distribución bloqueada",
        InsufficientDstributionQuantityRemaining: "Cantidad de distribución restante insuficiente",
        Previous: "Anterior",
        Next: "Siguiente",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Administración de recetas",
        RecipeDisplayImage: "Imagen de la receta",
        NewRecipeDisplayImage: "Editar/Crear nueva imagen de receta",
        CoverPicture: "Imagen de la cubierta",
        PicturesVideos: "Se pueden subir hasta 10 imágenes + vídeos, cada imagen debe pesar ≤5 MB y el vídeo debe estar en formato .mp4",
        AddPicturesVideos: "Añadir imágenes/vídeos",
        CommenOnPicturesVideos: "Comentar imágenes/vídeos",
        FileSize: "Tamaño del archivo",
        BarcodeAssociated: "Código de barras asociado",
        FirstUploadTime: "Hora de la primera subida",
        EditTime: "Editar hora",
        TagType: "Tipo de etiqueta",
        FileName1: "Nombre del archivo",
        NameLengthISTooLong: "El nombre es demasiado largo",
        ProductDoesNoExist: "El producto no existe",
        PleaseUploadImages: "Suba una imagen en formato jpg/png/jpeg de menos de 5 MB",
        PleaseUploadTheVideoMP4: "Suba el vídeo en formato .mp4",
        PictureOrVideo: "La imagen o el vídeo no pueden estar vacíos",
        allowed: "Permitir todo",
        cookie1: "Administración de cookies",
        collectsordinances: "Aigostar utiliza cookies para mejorar la experiencia de navegación de los visitantes y con fines de marketing. Consulte y administre su configuración de cookies. Para obtener más información sobre cómo utilizamos las cookies, consulte nuestra Política de privacidad.",
        RequiredCookies: "Cookies necesarias",
        cookie2: "Estas cookies son necesarias para poder llevar a cabo funciones básicas como iniciar sesión, utilizar la cesta de la compra y realizar pagos. Las cookies necesarias no pueden desactivarse. Pero no debe preocuparse, estas cookies no guardan ninguna información sobre usted.",
        AnalyticsCookies: "Cookies de marketing y análisis",
        cookie3: "Estas cookies recopilan información como el número de visitas al sitio web, o qué páginas son más visitadas, para ayudarnos a mejorar la experiencia de nuestros clientes. Puede desactivar estas cookies, pero no podremos recopilar la información que necesitamos para mejorar su experiencia en nuestro sitio web.",
        StoragePreferences: "Guardar preferencias",
        BuyItNow: "Comprar ahora",
        PictureVideoSize: "El tamaño o el formato de la imagen o del vídeo no son correctos",
        RecipeConfiguration: "Configuración de recetas",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "¿Confirmar la sincronización de subcódigos de barras?",
        SynchronizationEbpEanSUB: "Debido a la necesidad de conectarse con EBP, se espera que la sincronización por lotes se complete en 3 minutos. Después de la confirmación, la sincronización se realizará de forma asíncrona y los subcódigos de barras se mostrarán inmediatamente al recibirlos. Por favor, sea paciente.",
        Keywords: "Palabras clave",
        SystemAutoUpdate: "Actualización automática del sistema",
        IPAddress: "Dirección IP",
        SearchKeywords: "Buscar palabras clave",
        LoginEmail: "Correo electrónico de inicio de sesión",
        SearchTime: "Tiempo de búsqueda",
        NumbeOfTimes: "Número de veces",
        MyBrowsing: "Mi navegación",
        LoggingOuOfYouAccount: "Cerrar sesión de su cuenta se considerará como su renuncia voluntaria y ya no podrá continuar usando esta cuenta.",
        RequestLogout: "Solicitar cierre de sesión",
        ProductsYet: "Aún no has navegado por ningún producto",
        clearall: "Borrar historial",
        CancellTip1: "Las listas de carrito de compras, los registros de compra de pedidos y los registros de favoritos se eliminarán permanentemente y no se podrán recuperar.",
        CancellTip2: "Toda la información de la cuenta, registros de servicio, datos de consumo, etc., en la plataforma se eliminarán permanentemente y no se podrán recuperar.",
        CancellTip3: "No podrá iniciar sesión ni usar esta cuenta de AigoSmart.",
        CancellTip4: "La información personal y los mensajes históricos (incluidos avatar, apodo, mi moneda Aiguo, notificaciones de la plataforma, mis comentarios, etc.) asociados con su cuenta de AigoSmart no serán recuperables.",
        CancellTip5: "Debe manejar correctamente los fondos de la cuenta (puntos, niveles de membresía, calificaciones, pedidos, cupones, accesorios y otros servicios valiosos) antes de cerrar la sesión, ya que la cancelación de la cuenta se considerará como una renuncia voluntaria y no se podrá revertir. Usted comprende y acepta que AigoSmart no puede ayudarlo a restaurar los servicios mencionados anteriormente.",
        SynchronizeSubBarcodes: "Sincronizar subcódigos de barras",
        PleaseEnterKeywords: "Por favor, ingrese palabras clave",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Estadísticas por hora",
        NumbeOfUsers: "Número de usuarios que realizaron pedidos",
        NumberOfCanceled: "Número de pedidos cancelados",
        RefundAmount: "Monto reembolsado",
        UsersWhoHavePaid: "Usuarios que han pagado (pedidos que pueden ser enviados, enviados, completados)",
        TotalNumber: "Número total de pedidos cancelados por la plataforma",
        AmountOfCanceled: "Monto de pedidos cancelados que han sido pagados pero no enviados, monto de pedidos devueltos que han sido pagados (en tránsito, entregados)",
        RedemptionCode: "ID/Nombre del cupón/Código de canje",
        DeleteAll: "Eliminar",
        AccountIsUnderReview: "La cuenta actual está en período de revisión para desactivación. Continuar iniciando sesión cancelará el proceso de desactivación. ¿Desea continuar usándola?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "El tiempo no puede estar vacío",
        ProductImporTemplate: "Plantilla de importación de productos",
        NormalOrdersExceed: "Los pedidos normales superan:",
        OrderSautomaticallyClosed: "Los pedidos no pagados se cerrarán automáticamente",
        OrdeIsShipped: "Después de que el pedido sea enviado:",
        AutomaticallyComplete: "Completar el pedido automáticamente",
        Minutes: "Minutos",
        Days: "Días",
        OrderSettings: "Configuración de pedidos",
        ClearCache: "Borrar caché",
        PleaseEnterNavigationContent: "Por favor, ingrese el contenido de la navegación",
        WebsiteNavigationConfiguration: "Configuración de la navegación del sitio web",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Tiempo de retraso de la ventana emergente",
        SubscriptionPopup: "Ventana emergente de suscripción",
        MeansNoPopupDelay: "Ingrese el tiempo de solicitud de demora de la ventana emergente",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "área CGU",

        //V1.5.0 end

        //V1.6.0 start
        uploaFilesInJpg: "Sólo subir archivos en formato jpg/png de menos de 2M",
        SynchronizeEbpPrice: "Sincronizar precio EBP",
        ConfirmToSynchronizeEbp: "Confirme la sincronización del precio EBP",
        AsynchronousEbp: "Tras la confirmación, la sincronización es asíncrona. El precio se mostrará inmediatamente después de obtenerse. Por favor, espere pacientemente.",
        CopywritingDisplay: "Visualización de la redacción",
        HomepageCopywriting: "Redacción de la página de inicio",
        EditHomepageCopywriting: "Editar la redacción de la página de inicio",
        SuperimposedEvents: "¿Sobreponer eventos?",
        GiftsEvents: "(Los regalos para este evento no pueden recogerse junto con otros eventos)",
        SmallGiftEvent: "Pequeño regalo",
        SpecialProductEvent: "Evento de producto especial",
        SpecialGiftEvent: "Los obsequios del evento de regalo pequeño y del evento especial no pueden recogerse juntos",
        ProductsWithSKU: "Seleccione productos con SKU",
        LimitWereSelected: "El límite actual es de {limitNum} archivos. Esta vez, se seleccionaron {selectNum} archivos.",
        FileUploadFailed: "¡La carga del archivo falló!",
        CompleteMultiLanguage: "Por favor complete la configuración multilingüe",
        FirstSynchronizeSKU: "Por favor, sincronice primero el SKU de este producto.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Hacer público el cupón",
        RedemptionCode1: "Código para canjear",
        NowOnlyNeed: "Ahora solo necesitas 10€",
        WebLogReporting: "Informes de registros web",
        PageAddress: "Dirección de la página",
        ErrorLogs: "Registro de errores",
        CustomParameter: "Parámetro Personalizado",
        ParameterID: "ID de Parámetro",
        // ParameterName: "Nombre de Parámetro",
        SearchParameter: "Parámetro de Búsqueda",
        EnterParameterName: "Por favor ingrese el nombre del parámetro",
        ParameterType: "Tipo de Parámetro",
        AssociatedProductCount: "Conteo de Productos Asociados",
        MandatoryPortugueseContent: "Contenido en portugués obligatorio",
        MandatoryEnglishContent: "Contenido en inglés obligatorio",
        MandatoryDutchContent: "Contenido en holandés obligatorio",
        MandatoryPolishContent: "Contenido en polaco obligatorio",
        MandatoryFrenchContent: "Contenido en francés obligatorio",
        MandatoryGermanContent: "Contenido en alemán obligatorio",
        MandatoryItalianContent: "Contenido en italiano obligatorio",
        MandatorySpanishContent: "Contenido en español obligatorio",
        MandatoryChineseContent: "Contenido en chino obligatorio",
        ImportTemplate: "Plantilla de importación de productos con parámetros personalizados",
        ConfirmImportFile: "¿Confirmar archivo de importación, continuar?",
        SelectDataRemove: "Seleccionar datos para eliminar",
        ImportTemplateDownload: "Descargar plantilla de importación",
        AssociatedProducts: "Productos asociados",

        //V1.7.0 end

        //V1.8.0 start
        Published: "Publicado",
        CanBeIgnored: "Se puede ignorar",
        Resolved: "Resuelto",
        ActivityType: "Tipo de actividad",
        PromotionActivity: "Actividad promocional",
        DistributionActivity: "Actividad de distribución",
        InputLink: "Ingresar enlace",
        EnterAddress: "Por favor, introduzca la dirección del enlace",
        DetailPageAd: "Espacio publicitario en la página de detalles",

        //V1.8.0 end

        //V1.9.0 start
        ProductPromotionID: "ID de promoción del producto.",
        PromotionalActivityCollection: "Colección de actividades promocionales.",
        PromotionalActivityName: "Nombre de la actividad promocional.",
        ProductPromotionBarcode: "ID de promoción del producto/Código de barras.",
        ProductPromotionIDCopy: "ID de promoción del producto (haga doble clic para copiar todos los ID).",
        ProductBarcode: "Código de barras del producto (haga doble clic para copiar todos los códigos de barras).",
        SelectPromotionalActivity: "Seleccionar actividad promocional.",
        SelectPromotionalList: "Seleccionar lista promocional.",
        TypesPerOrder: "Limitar la cantidad de tipos de artículos por pedido.",
        PromotionalActivity: "Por favor, seleccione una actividad promocional.",
        ProductAlready: "Este producto ya tiene una actividad promocional. Por favor, proceda a editar.",
        PromotionalActivityNameID: "Nombre/ID de la Promoción",
        PromotionalCollectionName: "Nombre de la colección promocional.",
        SelectedPromotionalActivity: "Actividad promocional seleccionada.",
        PromotionalCollectionActivit: "Ya existe una actividad de colección promocional. Por favor, proceda a editar.",

        //V1.9.0 end

    }
};

export default es;