<template>  
    <div>
        <el-form :model="formListQuery" ref="variantFrom" :rules="rules">
            <el-form-item :label='typeName+$i18n.t("common.name")' prop="title" :label-width="formLabelWidth">
                <el-input show-word-limit maxlength="50" @input="changeCheckList" v-model="formListQuery.title"  :placeholder='$i18n.t("common.Pleaseentercontent")'  class="input-width"></el-input>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Selectproduct")' :label-width="formLabelWidth">
                <template slot-scope="scope">
                    <el-button type="success" @click="changeGoods">{{ $i18n.t("common.Selectproduct") }}</el-button>                                    
                </template>
            </el-form-item>
            <div v-if="formListQuery.pageDataSelectList && formListQuery.pageDataSelectList.length > 0">
                <el-form-item :label-width="formLabelWidth">
                    <el-table ref="pageDataSelect" :data="formListQuery.pageDataSelectList" border style="width: 100%">
                        <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>SKU:{{ scope.row.sku }}</span
                                ><br />
                                <span>EAN:{{ scope.row.barCode }}</span
                                ><br />
                                <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imageUrl"  :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-image
                                    style="height: 100px"
                                    v-if="scope.row.imageUrl"
                                    :src="ossHost + scope.row.imageUrl"
                                    fit="fill"
                                ></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="s.countryCode">
                                    <span v-if="s.shelf==true"> {{ $dict.getDictValue('countrys', s.countryCode) }}<br /> </span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="packageQuantity" :label='$t("common.PackingQuantity")'  align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>

                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                        Number(s.fixedPrice).toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.Lowestprice")'  align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                        Number(s.lowestPrice).toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.resultPrice">{{ s.countryCode }}:{{ s.resultPrice.toFixed(2) }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column  prop="brand" :label='$i18n.t("common.price1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-show="s.salePriceEbp || s.salePriceEbp>=0">{{ s.countryCode }}:{{ s.salePriceEbp.toFixed(2) }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>                      
                        <el-table-column prop="shelf" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                                <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item :label='typeName+ $i18n.t("common.country")' class="must" :label-width="formLabelWidth">
                    <div v-if="typeName == typeNameDistribution">    
                        <el-radio-group v-model="checkCountrys.countryCode" @input="handleTabCoded">
                            <el-radio
                                v-for="(item, index) in formListQuery.pageDataSelectList[0].goodsSpecials"
                                :key="index"
                                :label="item.countryCode"
                                >{{ $dict.getDictValue('countrys', item.countryCode) }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div v-else-if="typeName == '促销'">
                        <el-checkbox-group v-model="countryCode">
                            <el-checkbox
                                @change="(val) => handleTabCode(val, item)"
                                v-for="(item, index) in formListQuery.pageDataSelectList[0].goodsSpecials"
                                :key="index"
                                :label="item.countryCode"
                                >{{ $dict.getDictValue('countrys', item.countryCode) }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item v-if="typeName == '促销'" :label='$i18n.t("common.Enableornot")'  prop="status" :label-width="formLabelWidth">
                    <el-switch v-model="formListQuery.status"></el-switch>
                </el-form-item>
                <el-form-item v-else-if="typeName == typeNameDistribution" :label='$i18n.t("common.Enableornot")'  prop="status" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="formListQuery.status"></el-switch>
                </el-form-item>
                <div v-if="typeName == typeNameDistribution && promotionRuleCountrysList && promotionRuleCountrysList.length > 0">

                    <el-form :model="promotionRuleCountrysList[0]" ref="variantFrom2">
                        <el-form-item :label='typeName+$i18n.t("common.time")' prop="time" :label-width="formLabelWidth">

                            <el-date-picker
                                v-model="promotionRuleCountrysList[0].time"
                                clearable
                                @input="changeCheckList"
                                type="datetimerange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                :range-separator='$i18n.t("common.to")'
                                :start-placeholder='$i18n.t("common.starttime")'
                                :end-placeholder='$i18n.t("common.Endtime")'
                                :default-time="['00:00:00','23:59:59']"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item :label="`${typeName}${$t('common.price')}`" :label-width="formLabelWidth">
                            <el-radio-group
                                v-if="promotionRuleCountrysList.length > 0"
                                v-model="promotionRuleCountrysList[0].promotionType"
                                @change="(val) => handlePriceTyped(val, promotionRuleCountrysList[0])"
                            >
                                <el-radio label="Discount">{{ $i18n.t("common.discount") }}</el-radio>
                                <el-radio label="Price">{{ $i18n.t("common.price") }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="promotionRuleCountrysList[0].promotionType == 'Discount'"
                            label=""
                            prop="promotionValue"
                            :label-width="formLabelWidth"
                        >
                            <span> - </span>
                            <el-input
                                @blur="handleDiscount(promotionRuleCountrysList[0])"
                                oninput="value=value.replace(/[^0-9.]/g,'')"
                                @input="changeCheckList"
                                v-model="promotionRuleCountrysList[0].promotionValue"
                                :placeholder=' $i18n.t("common.discount")'
                                :style="{ width: inputWidth }"
                            ></el-input>
                            % off
                        </el-form-item>
                        <el-form-item
                            v-else-if="promotionRuleCountrysList[0].promotionType == 'Price'"
                            label=""
                            prop="promotionValue"
                            :label-width="formLabelWidth"
                        >
                            <el-input
                                @input="changeCheckList"
                                @blur="handlePriced(promotionRuleCountrysList[0])"
                                v-model="promotionRuleCountrysList[0].promotionValue"
                                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                                :placeholder="$t('common.PleaseEnterThePrice')"
                                :style="{ width: inputWidth }"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            :label="promotionRuleCountrysList[0].promotionType == 'Discount' ? `${$t('common.Distributionprice1')}` : `${typeName}优惠`"
                            :label-width="formLabelWidth"
                        >
                            <div v-if="promotionRuleCountrysList[0].promotionValue || promotionRuleCountrysList[0].promotionValue==0">
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Discount'">
                                    {{ Discount }}
                                </div>
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Price'">
                                    {{ Price }}
                                </div>
                            </div>
                            <div v-else>
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Discount'">
                                    {{discountTip}}
                                </div>
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Price'">
                                     {{priceTip}}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('common.Grossmargin')" :label-width="formLabelWidth">
                            <div v-if="promotionRuleCountrysList[0].promotionValue || promotionRuleCountrysList[0].promotionValue==0">
                                {{ promotionRuleCountrysList[0].profit }}%
                            </div>
                            <div v-else>
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Discount'">
                                    {{discountTip}}
                                </div>
                                <div v-show="promotionRuleCountrysList[0].promotionType == 'Price'">
                                    {{priceTip}}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('common.Distributionquantity')" prop="promotionCount" :label-width="formLabelWidth">
                            <el-input
                                @input="promotionCountAndLockQuantityChangeCheckList"
                                oninput="value=value.replace(/[^0-9]/g,'')"
                                v-model="promotionRuleCountrysList[0].promotionCount"
                                @blur="handleCountd"
                                :placeholder="$t('common.PleaseEnterTheQuantity')"
                                class="input-width"
                            ></el-input>
                        </el-form-item>

                        <el-form-item v-if="this.isEdit" :label="$t('common.SoldDistributionQuantity')" prop="soldQuantity" :label-width="formLabelWidth">
                            <el-input
                                    @input="changeCheckList"
                                    oninput="value=value.replace(/[^0-9]/g,'')"
                                    v-model="promotionRuleCountrysList[0].soldQuantity"
                                    class="input-width"
                                    :disabled='true'
                            ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('common.DistributionQuantityLock')" prop="lockQuantity" :label-width="formLabelWidth">
                            <el-input
                                    @input="promotionCountAndLockQuantityChangeCheckList"
                                    oninput="value=value.replace(/[^0-9]/g,'')"
                                    v-model="promotionRuleCountrysList[0].lockQuantity"
                                    :placeholder="$t('common.PleaseEnterTheQuantity')"
                                    class="input-width"
                            ></el-input>
                            <br />
                            <span v-if="this.showLockQuantityTip" style="color: red">{{ $i18n.t("common.InsufficientDstributionQuantityRemaining") }}</span>
                        </el-form-item>


                        <el-form-item  :label="$t('common.NumberOfUserPurchases')" prop='userBuy' :label-width='formLabelWidth' >
                            <el-input
                                @input="changeCheckList"
                                v-model = 'promotionRuleCountrysList[0].userBuy'
                                :placeholder="$t('common.AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct',{'Any':typeName})"
                                :disabled='true'
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item :label='$i18n.t("common.Freeshippingornot")' prop="freeDelivery" :label-width="formLabelWidth">

                            <el-radio-group v-model="promotionRuleCountrysList[0].freeDelivery" @change="changeCheckList">
                                <el-radio :label="true">{{ $i18n.t("common.yes") }}</el-radio>
                                <el-radio :label="false">{{ $i18n.t("common.no") }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label='$i18n.t("common.Doyousupporttheuseofcoupons")' :label-width="formLabelWidth">
                            <el-radio-group v-model="promotionRuleCountrysList[0].coupon" @change="changeCheckList">
                                <el-radio :label="true">{{ $i18n.t("common.yes") }}</el-radio>
                                <el-radio :label="false">{{ $i18n.t("common.no") }}</el-radio>
                            </el-radio-group>
                        </el-form-item> 
                        <el-form-item :label='$i18n.t("common.Purchaserestrictionsforthesameuser")' prop="userPurchaseLimit" :label-width="formLabelWidth">
                            <el-input
                                @blur="handleOrderPurchaseLimitd"
                                v-model="promotionRuleCountrysList[0].userPurchaseLimit"
                                oninput="value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)"
                                :placeholder='$i18n.t("common.Purchaserestrictionsforthesameuser")'
                                class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item :label='$i18n.t("common.Singleorderlimit")' prop="orderPurchaseLimit" :label-width="formLabelWidth">
                            <el-input
                                v-model="promotionRuleCountrysList[0].orderPurchaseLimit"
                                @blur="handleOrderPurchaseLimitd"
                                oninput="value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)"
                                :placeholder='$i18n.t("common.Singleorderlimit")'
                                class="input-width"
                            ></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.OrderRemarkLabel')" :label-width="formLabelWidth">
                            <el-select v-model="promotionRuleCountrysList[0].remarkTag" :placeholder='$i18n.t("common.Pleaseselect")' clearable filterable>
                                <el-option :label="$t('common.OrdersWorkWithInfluencers')" :key="'InternetCelebrity'" value="InternetCelebrity"></el-option>
                            </el-select>
                        </el-form-item> 
                    </el-form>
                </div>
                <div v-else-if="typeName == '促销' && promotionRuleCountrysList && promotionRuleCountrysList.length > 0">
                    <el-tabs v-model="activeName" @tab-click="changeCountrys">
                        <el-tab-pane
                            v-for="(item, index) in promotionRuleCountrysList"
                            :key="index"
                            :label="$dict.getDictValue('countrys', item.countryCode)"
                            :name="item.countryCode"
                        >
                            <el-form :model="item" ref="variantFrom2" :rules="rulesP">
                                <el-form-item :label="$t('common.Promotiontime')" prop="time" :label-width="formLabelWidth">
                                    <el-date-picker
                                        v-model="item.time"
                                        clearable
                                        @input="changeCheckList"
                                        type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        :range-separator='$i18n.t("common.to")'
                                        :start-placeholder='$i18n.t("common.starttime")'
                                        :end-placeholder='$i18n.t("common.Endtime")'
                                        :default-time="['00:00:00','23:59:59']"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item :label='$t("common.Displaypromotionalprices")' :label-width="formLabelWidth">
                                    <el-radio-group v-model="item.flagShowPromotionPrice" @change="(val) => handlePriceTypep(val, item, index)">
                                        <el-radio :label="false">{{ $i18n.t("common.no") }}</el-radio>
                                        <el-radio :label="true">{{ $i18n.t("common.yes") }}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item :label="$t('common.PromotionPrice')" :label-width="formLabelWidth">
                                    <el-radio-group v-model="item.promotionType" @change="(val) => handlePriceTypep(val, item, index)">
                                        <el-radio label="Discount">{{ $i18n.t("common.discount") }}</el-radio>
                                        <el-radio label="Price">{{ $i18n.t("common.price") }}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item
                                    v-if="item.promotionType == 'Discount'"
                                    label=""
                                    prop="promotionValue"
                                    :label-width="formLabelWidth"
                                >
                                    <span> - </span>
                                    <el-input
                                        @input="changeCheckList"
                                        oninput="value=value.replace(/[^0-9.]/g,'')"
                                        @blur="handleDiscount(item)"
                                        v-model="item.promotionValue"
                                        :placeholder='$i18n.t("common.discount") '
                                        :style="{ width: inputWidth }"
                                    ></el-input>
                                    % off
                                </el-form-item>
                                <el-form-item
                                    v-else-if="item.promotionType == 'Price'"
                                    label=""
                                    prop="promotionValue"
                                    :label-width="formLabelWidth"
                                >
                                    <el-input
                                        @input="changeCheckList"
                                        @blur="handlePricep(item)"
                                        v-model="item.promotionValue"
                                        oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                                        :placeholder="$t('common.PleaseEnterThePrice')"
                                        :style="{ width: inputWidth }"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    :label="item.promotionType == 'Discount' ? `${$t('common.PromotionPrice')}` : `${typeName}优惠`"
                                    :label-width="formLabelWidth"
                                >
                                    <div v-show="item.promotionValue">
										<div v-if="isEdit">
											<div v-show="item.promotionType == 'Discount'">
											    {{ item.discount }}
											</div>
										</div>
                                        <div v-else>
                                        	<div v-show="item.promotionType == 'Discount'">
                                        	    {{ Discount?Discount:0 }}
                                        	</div>
                                        </div>
                                        <div v-show="item.promotionType == 'Price'">
                                            {{ item.Price }}
                                        </div>
                                    </div>
                                    <div v-show="!item.promotionValue">
                                        <div v-show="item.promotionType == 'Discount'">
                                            {{discountTip}}
                                        </div>
                                        <div v-show="item.promotionType == 'Price'">
                                            {{priceTip}}
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item :label="$t('common.Grossmargin')" :label-width="formLabelWidth">
                                    <div v-show="item.promotionValue">
                                        {{ item.profit }}%
                                    </div>
                                    <div v-show="!item.promotionValue">
                                        <div v-show="item.promotionType == 'Discount'">
                                            {{discountTip}}
                                        </div>
                                        <div v-show="item.promotionType == 'Price'">
                                            {{priceTip}}
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item :label="$t('common.Promotionquantity')" prop="promotionCount" :label-width="formLabelWidth">
                                    <el-input
                                        @input="changeCheckList"
                                        v-model="item.promotionCount"
                                        oninput="value=value.replace(/[^0-9]/g,'')"
                                        @blur="handleCountp(item,index)"
                                        :placeholder="$t('common.PleaseEnterThePrice')"
                                        class="input-width"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item :label='$i18n.t("common.discount")' prop="freeDelivery" :label-width="formLabelWidth">
                        </el-form-item>
                                <el-form-item :label="$t('common.NumberOfUserPurchases')" prop='userBuy' :label-width='formLabelWidth'>
                                    <el-input
                                        @input="changeCheckList"
                                        v-model = "item.userBuy"
                                        :placeholder="$t('common.AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct',{'Any':typeName})"
                                        :disabled='true'
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$t('common.Freeshippingornot')" prop="freeDelivery" :label-width="formLabelWidth">

                                    <el-radio-group v-model="item.freeDelivery" @change="changeCheckList">
                                        <el-radio :label="true">{{$i18n.t("common.yes")}}</el-radio>
                                        <el-radio :label="false">{{$i18n.t("common.no")}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item :label='$i18n.t("common.Doyousupporttheuseofcoupons")' :label-width="formLabelWidth">
                                    <el-radio-group v-model="item.coupon">
                                        <el-radio :label="true">{{$i18n.t("common.yes")}}</el-radio>
                                        <el-radio :label="false">{{$i18n.t("common.no")}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item :label='$i18n.t("common.Isitaflashkill")' :label-width="formLabelWidth">
                                    <el-radio-group v-model="item.seckill" @change="changeCheckList">
                                        <el-radio :label="true">{{$i18n.t("common.yes")}}</el-radio>
                                        <el-radio :label="false">{{$i18n.t("common.no")}}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item :label='$i18n.t("common.Secondkillsorting")' v-show="item.seckill" :label-width="formLabelWidth">
                                    <el-input
                                        v-model="item.sort"
                                        oninput="value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)"
                                        :placeholder="$t('common.Topwithlargenumbers')"
                                        class="input-width"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item :label='$i18n.t("common.Purchaserestrictionsforthesameuser")'  prop="userRestrictions" :label-width="formLabelWidth">
                                    <el-input
                                        v-model="item.userRestrictions"
                                        @blur="handleOrderPurchaseLimit(item)"
                                        oninput="value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)"
                                        :placeholder='$i18n.t("common.Purchaserestrictionsforthesameuser")'
                                        class="input-width"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item :label='$i18n.t("common.Purchaserestrictionsforthesameuser")' prop="orderRestrictions" :label-width="formLabelWidth">
                                    <el-input
                                        v-model="item.orderRestrictions"
                                        @blur="handleOrderPurchaseLimit(item)"
                                        oninput="value=value.replace(/[^0-9]/g,'');if(value.length>9)value=value.slice(0,9)"
                                        :placeholder='$i18n.t("common.Purchaserestrictionsforthesameuser")'
                                        class="input-width"
                                    ></el-input>
                                </el-form-item>                                
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%" type="primary" @click="onSubmit1('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>

        <el-dialog :title="$t('common.Selectproduct')" v-if="dialogFormTableVisible2" :visible.sync="dialogFormTableVisible2" @close="closeDialogGoods" append-to-body>
            <div>
                <FormTableDialog
                    :argFrom="argFrom2"
                    :dictionary="dictionary"
                    :placeholderFrom="placeholderFrom2"
                    :pageDataSelectList="formListQuery.pageDataSelectList"
                    :typeName="typeName"
                    @handleSearchList="handleSearchList2"
                    @handleResetSearch="handleResetSearch2"
                    @handleSelected="handleSelected"
                >
                    <template v-slot:tableSlot>
                        <el-table
                            ref="tableData"
                            :data="pageData2.list"
                            :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                            id="goodsTable"
                            style="width: 100%"
                            border
                            row-key="goodsId"
                            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                            default-expand-all
                        >
                            <el-table-column width="55" align="center" type='' >
                                <template slot-scope="scope">
                                    <div class="seRadio">
                                        <el-radio v-model="selectionId" :label="scope.row.goodsId" @change="selectionChange(scope.row)" :disabled="!scope.row.checkboxStatus"
                                            >{{ '' }}
                                        </el-radio>
                                    </div>
                                </template>
                            </el-table-column>

                            <!-- 用于显示子表格张开按钮 -->
                            <el-table-column prop="quantityType" label="" width="50px" align="center">
                            </el-table-column>
                            <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>

                                <template slot-scope="scope">
                                    <span>SKU:{{ scope.row.sku }}</span
                                    ><br />
                                    <span>EAN:{{ scope.row.barCode }}</span
                                    ><br />
                                    <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-image
                                        style="height: 100px"
                                        v-if="scope.row.imageUrl"
                                        :src="ossHost + scope.row.imageUrl"
                                        fit="fill"
                                    ></el-image>
                                </template>
                            </el-table-column>

                            <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>

                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                        <span v-if="s.shelf">{{ $dict.getDictValue('countrys', s.countryCode) }}<br /></span>
                                    </span>
                                </template>
                            </el-table-column>

                        
<!--                       <el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1")'align="center" show-overflow-tooltip>

                                <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                                    &lt;!&ndash; <span v-if="typeName=='分销'">
                                        <span v-for="(s, index) in scope.row.prices" :key="index">
                                            {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.distributionPrice">{{
                                                s.distributionPrice.toFixed(2)
                                            }}</span
                                            ><br />
                                        </span>
                                    </span> &ndash;&gt;
                                        <span v-for="(s, index) in scope.row.prices" :key="index">
                                            {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.promotionPrice">{{
                                                s.promotionPrice.toFixed(2)
                                            }}{{$dict.getDictCurrencyValue(s.countryCode)}}</span
                                            ><br />
                                        </span>
                                </template>
                            </el-table-column>-->
                            <el-table-column prop="prices" :label="$t('common.Promotionprice_unitprice')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                                            s.resultPrice.toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}</span
                                        ><br />
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                            Number(s.fixedPrice).toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label='$i18n.t("common.Lowestprice")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                            Number(s.lowestPrice).toFixed(2)
                                            }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                                    <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="block">
                            <el-pagination
                                @current-change="handleCurrentChange"
                                :current-page="listQuery.page"
                                :page-size="listQuery.size"
                                layout="total, prev, pager, next, jumper"
                                :total="pageData2.total"
                            >
                            </el-pagination>
                        </div>
                    </template>
                </FormTableDialog>
            </div>
        </el-dialog>

        <el-dialog
            :title='$i18n.t("common.SubmitConfirmation")'
            :visible.sync="dialogGetPricingVisible"
            width="30%"
            append-to-body
            :before-close="handleGetPricingClose"
        >
            <div v-if="dialogGetPricingVisible">
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                        <div v-for="(item, index) in getPricing" :key="index" style="margin: 10px 10px">
                            {{ $t('common.CurrentactivitypricesinSpain',{countryCode:$dict.getDictValue('countrys', item.countryCode)}) }}<span style="color: red"
                                >{{ item.profit.toFixed(2) }}%</span
                            >
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogGetPricingVisible=false"> {{ $i18n.t("common.cancel") }}</el-button>
                    <el-button type="primary" :loading="loading" @click="getPricingSubmit(isEdit)"> {{ $i18n.t("common.confirm") }}</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('common.Prompt')"
            :visible.sync="dialogLowestPriceVisible"
            width="20%"
            append-to-body
            :before-close="handleLowestPriceClose"
        >
            <div v-if="dialogLowestPriceVisible">
                <h4>{{typeName}}<span>{{ $t('common.ThePriceCannotBeLowerThanTheLowestPrice') }}</span></h4>
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                        <div v-for="(item, index) in checkLowestPrice" :key="index" style="margin: 10px 10px">
                            {{ $dict.getDictValue('countrys', item.countryCode) }}:<span style="color: red"
                                >{{ item.price.toFixed(2) }}</span
                            >
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogLowestPriceVisible=false"> {{ $i18n.t("common.confirm") }}</el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {showSelectOfSelectable} from "@/utils/treeTable";
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import i18n from '@/i18n/i18n.js';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";

export default {
    components: {
        FilterContainer,
        FormTableDialog
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        typeName: {
            //数据
            type: String,
            default: i18n.t("common.Distribution")
        },
        placeholderFrom: {
            type: Object,
            default: () => {
                return {
                    name: i18n.t("common.IDName"),
                    state: i18n.t("common.Activitystatus"),
                    date: {
                        start: i18n.t("common.starttime"),
                        end: i18n.t("common.Endtime")
                    },
                    date2: {
                        start:i18n.t("common.starttime"),
                        end: i18n.t("common.Endtime")
                    }
                };
            }
        },
        proAddData:{
            type: Object,
            default: () => {
                return {};
            }
        },
        preSaleCountryFlag: {
            type: Boolean,
            default: () => {
                return null
            }
        },
        priceSetting:{
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            typeNameDistribution: i18n.t("common.Distribution"),			
            priceTip: i18n.t('common.AutomaticallyCalculateAfterEnteringPrice'),
            discountTip: i18n.t("common.AutomaticallyCalculateAfterEnteringTheDiscount"),
            selectionId:'',
            loading: false,
            Discount: null,
            Price: null,
            profit: null,
            promotionValue1: null,
            promotionValue2: null,
            promotionCount: null,
            userBuy: null,
            promotionType: 'Discount',
            freeDelivery: false,
            promotionRuleCountrysList: [],
            promotionRuleCountrysCopy: [],
            checkLowestPrice: [],
            //分销
            checkCountrys: {
                countryCode: []
            },
            //父级传过来的数据
            busData:{},
            getPricing: [],
            //促销
            countryCode: [],
            tabCountrys: {
                countryCode: []
            },
            arrayDate: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            pageData2: {},
            listQuery: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                page: 1,
                size: 10
            },
            defaultListQuery: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                page: 1,
                size: 10
            },
            changePriceTyped: '',
            activeName: '',
            inputWidth: '70%',
            formLabelWidth: '150px',
            form: {},
            //被选中的商品
            selectedRow: {},
            //组件标签是否显示
            argFrom2: {
                name: true,
                promotion: true,
                useState: true
            },
            placeholderFrom2: {
                name: this.$i18n.t("common.SKU_EAN_Encoding"),
				promotion: this.$i18n.t("common.Whethertopromoteornot"),
                receiveState: this.$i18n.t("common.state")
            },
            dialogFormTableVisible2: false,
            dialogGetPricingVisible: false,
            dialogLowestPriceVisible:false,//校验最低价弹窗
            showLockQuantityTip: false,//显示分销数量不足提示
            remainQty: 0,//剩余分销数量
            //选中数量
            rules: {
                title: [
                    {
                        required: true,
                        message:this.$i18n.t("common.Pleaseenteraname"),
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message:this.$i18n.t("common.Between1and50charactersinlength"),
                        trigger: 'blur'
                    }
                ]
            },
            //促销校验
            rulesP: {
                time: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectadate"),
                        trigger: 'change'
                    }
                ],
                promotionValue: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseentercontent"),
                        trigger: 'blur'
                    }
                ],
                promotionCount: [
                    {
                        required: true,
                        message: this.$i18n.t("common.PleaseEnterTheQuantity"),
                        trigger: 'blur'
                    }
                ],
                userRestrictions: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Purchaserestrictionsforthesameuser"),
                        trigger: 'blur'
                    }
                ],
                orderRestrictions: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Singleorderlimit"),
                        trigger: 'blur'
                    }
                ],
                userBuy: [
                    {
                        required: true, message: this.$i18n.t("common.TheNumberOfUserPurchasesMustBeGreaterThan0"), trigger: 'change'
                    },
                    {
                        pattern: /^[1-9]\d*$/, message: this.$i18n.t("common.TheNumberOfUserPurchasesMustBeGreaterThan0"), trigger: 'change'
                    }

                ]
            },
            //分销校验
            rulesD: {
                time: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectadate"),
                        trigger: 'change'
                    }
                ],
                promotionValue: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseentercontent"),
                        trigger: 'blur'
                    }
                ],
                promotionCount: [
                    {
                        required: true,
                        message: this.$i18n.t("common.PleaseEnterTheQuantity"),
                        trigger: 'blur'
                    }
                ],
                userPurchaseLimit: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Purchaserestrictionsforthesameuser"),
                        trigger: 'blur'
                    }
                ],
                orderPurchaseLimit: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Singleorderlimit"),
                        trigger: 'blur'
                    }
                ],
                userBuy: [
                    {
                        required: true, message: this.$i18n.t("common.TheNumberOfUserPurchasesMustBeGreaterThan0"), trigger: 'change'
                    },
                    {
                        pattern: /^[1-9]\d*$/, message: this.$i18n.t("common.TheNumberOfUserPurchasesMustBeGreaterThan0"), trigger: 'change'
                    }

                ]
            }
        };
    },
    methods: {
        initData() {
            if (this.isEdit == true) {
                this.$nextTick(() => {
                    let goodId = '';
                    if (this.formListQuery.goodsId) {
                        goodId = this.formListQuery.goodsId;
                    } else if (this.formListQuery.goodId) {
                        goodId = this.formListQuery.goodId;
                    }
                    this.handleSearchList3(goodId);					
                });
            }
            this.handleSearchList2();
        },
        getSuffix(filename) {
			  let pos = filename.lastIndexOf(".");
			  let suffix = "";
			  if (pos != -1) {
			    suffix = filename.substring(pos);
			  }
			  return suffix;
		},
		closeDialogGoods(){
			this.dialogFormTableVisible2 = false
			this.listQuery= Object.assign({},this.defaultListQuery)
			this.handleSearchList2();
		},
        changeCheckList(e) {
            this.$forceUpdate();
        },
        promotionCountAndLockQuantityChangeCheckList() {

            this.$forceUpdate();
            this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item) => {
                if (item.countryCode == this.checkCountrys.countryCode) {
                    this.$nextTick(() => {

                        this.calRemainQty();

                        if (this.remainQty < 0) {
                            this.showLockQuantityTip = true;
                        }else {
                            this.showLockQuantityTip = false;
                        }
                    });
                }
            });

        },
        //计算剩余分销数量
        calRemainQty() {

            if (this.promotionRuleCountrysList[0].promotionCount != null && this.promotionRuleCountrysList[0].promotionCount >= 0) {

                var soldQuantity = 0;
                var lockQuantity = 0;
                if (this.promotionRuleCountrysList[0].soldQuantity != null && this.promotionRuleCountrysList[0].soldQuantity >= 0) {

                    soldQuantity = this.promotionRuleCountrysList[0].soldQuantity;
                }

                if (this.promotionRuleCountrysList[0].lockQuantity != null && this.promotionRuleCountrysList[0].lockQuantity >= 0) {

                    lockQuantity = this.promotionRuleCountrysList[0].lockQuantity;
                }

                this.remainQty = this.promotionRuleCountrysList[0].promotionCount - soldQuantity - lockQuantity;
            }
        },
        handleGetPricingClose() {
            this.dialogGetPricingVisible = false;
        },
        handleLowestPriceClose(){
            this.dialogLowestPriceVisible = false;
        },
        handleSearchList3(goodId) {
            const data = [].concat(goodId);
            this.$store.dispatch('postPromotionId', data).then((res) => {
                let arr = [];
                let shelfArr =[]; //上架国家数组
                if (res && res.length > 0 && res[0].goodsSpecials.length > 0) {
                    res[0].goodsSpecials.forEach((item, index) => {
                        if (item.shelf) {
                            shelfArr.push(item);
                        }
                    });
                    res[0].goodsSpecials=shelfArr
                }
                this.$set(this.formListQuery, 'pageDataSelectList', res);
                if (this.typeName == '促销') {
                    if (this.isEdit) {
                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                            arr.push(item.countryCode);
                        });
                        this.countryCode = arr;
                    } else {
                        this.countryCode = [];
                    }
                } else if (this.typeName == this.typeNameDistribution) {
                    this.checkCountrys.countryCode = this.formListQuery.countryCode;
                }
                this.$set(this.tabCountrys, 'countryCode', arr);
                setTimeout(() => {
                    this.promotionRuleCountrysList = this.formListQuery.promotionRuleCountrys;
                    this.$set(this.tabCountrys, 'countryCode', arr);
                    this.activeName = this.tabCountrys.countryCode[0];
                    this.changePriceTyped = this.promotionRuleCountrysList[0].promotionType;
                    if (this.isEdit) {
                        if (this.typeName == '促销') {
                            if (this.promotionRuleCountrysList[0].promotionType == 'Discount') {
                                this.handleDiscount(this.formListQuery.promotionRuleCountrys[0]);
                            } else if (this.promotionRuleCountrysList[0].promotionType == 'Price') {
                                this.handlePricep(this.formListQuery.promotionRuleCountrys[0]);
                            }
                        } else if (this.typeName == this.typeNameDistribution) {
                            if (this.promotionRuleCountrysList[0].promotionType == 'Discount') {
                                this.handleDiscount(this.formListQuery.promotionRuleCountrys[0]);
                            } else if (this.promotionRuleCountrysList[0].promotionType == 'Price') {
                                this.handlePriced(this.formListQuery.promotionRuleCountrys[0]);
                            }
                            this.promotionCountAndLockQuantityChangeCheckList();
                        }
                    }
                    this.$forceUpdate();
                });
            });
        },
        handleSearchList2(listQuery,type) {
            let curSates={
                page: this.listQuery.page,
                size: this.listQuery.size,
            }
            if (this.typeName == '促销') {
                this.argFrom2.promotion = false;
                // this.listQuery.inPromotion="true"
            }

            if (listQuery) {
                if(type!=1){
                    this.listQuery.page = 1;
                }           
                let isOnShelf = listQuery.isUsed;
                let inPromotion = listQuery.promotion;
                this.listQuery = {
                    countryCode: '',
                    classifyId: '',
                    idFuzzy: listQuery.title,
                    brandCode: '',
                    updateUserId: '', //操作人
                    inPromotion: inPromotion, //是否促销
                    effectShelf: isOnShelf, //是否上架
                    beginCreateTime: '',
                    endCreateTime: '',
                    beginUpdateTime: '',
                    endUpdateTime: '',
                    page: this.listQuery.page,
                    size: this.listQuery.size
                };
            }
            
            if(this.preSaleCountryFlag !==null){
                this.listQuery.preSaleCountryFlag = this.preSaleCountryFlag
            }

            let temp = JSON.stringify(this.listQuery, (key, value) => {
                    if (value !== null && value !== '') {
                        return value;
                    }
            });
            curSates = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', curSates).then((res) => {
                this.pageData2 = res;

                // 回显勾选
                if (this.$refs.tebleData) {
                    showSelectOfSelectable(this.$refs.tebleData,  this.pageData2.list)
                }

            });
        },
        //选择国家
        changeCountrys(tab, event) {
            let rowData={}
            let idx = -1
            if (this.isEdit) {
                this.rowCountrys = this.formListQuery.promotionRuleCountrys.filter((item, index) => {
                    if (tab.name == item.countryCode) {
                        idx = index
                        return item;
                    }
                });
                this.changePriceTyped = this.rowCountrys[0].promotionType ? this.rowCountrys[0].promotionType : '';
                rowData=JSON.parse(JSON.stringify(this.rowCountrys[0]))
            }else{
                this.changePriceTyped = this.promotionRuleCountrysList[tab.index].promotionType;
                rowData=JSON.parse(JSON.stringify(this.promotionRuleCountrysList[tab.index]))
            }    
            if (this.typeName == '促销') {
                this.handlePricep(rowData);
                this.handleDiscount(rowData, idx);
            } else if (this.typeName == this.typeNameDistribution) {
                this.handlePriced(rowData);
                this.handleDiscount(rowData);
            }               
			this.$forceUpdate()
        },
        //选择商品
        changeGoods() {
            setTimeout(()=>{
				if(this.typeName == '促销' && this.$refs.tableData){
					if(this.formListQuery.pageDataSelectList && this.formListQuery.pageDataSelectList.length>0){
						this.selectionId=this.formListQuery.pageDataSelectList[0].goodsId
					}
				}else if(this.typeName == this.typeNameDistribution && this.$refs.tableData){
					if(this.formListQuery.pageDataSelectList && this.formListQuery.pageDataSelectList.length>0){
						this.selectionId=this.formListQuery.pageDataSelectList[0].goodsId
					}								
				}
				this.$forceUpdate()
			})
            this.dialogFormTableVisible2 = true;

            // 回显勾选
            if (this.$refs.tebleData) {
                showSelectOfSelectable(this.$refs.tebleData,  this.pageData2.list)
            }
        },
        //促销限制单笔订单value
        handleOrderPurchaseLimit(items){
                if(items.orderRestrictions!=null && items.userRestrictions!=null && Number(items.orderRestrictions)>Number(items.userRestrictions)){
                    items.orderRestrictions=items.userRestrictions
                }    
        },
        //分销限制单笔订单value
        handleOrderPurchaseLimitd(){
            if(this.promotionRuleCountrysList && this.promotionRuleCountrysList.length>0){
                if( this.promotionRuleCountrysList[0].orderPurchaseLimit!=null && this.promotionRuleCountrysList[0].userPurchaseLimit!=null && Number(this.promotionRuleCountrysList[0].orderPurchaseLimit)>Number(this.promotionRuleCountrysList[0].userPurchaseLimit)){
                    this.promotionRuleCountrysList[0].orderPurchaseLimit=this.promotionRuleCountrysList[0].userPurchaseLimit
                }
            }            
        },
        closeDialog() {
            this.formListQuery.pageDataSelectList = [];
            this.$emit('closeDialog');
        },
        closeDialog3() {
            this.dialogFormTableVisible2 = false;
        },
        handleResetSearch2() {
            this.$emit('handleResetSearch2');
        },
        handlePriceTypep(val, row, index) {
            this.handleDiscount(row);
            this.handlePricep(row);
            this.changePriceTyped = val;
        },
        handlePriceTyped(val, row) {
            this.handleDiscount(row);
            this.handlePriced(row);
            this.changePriceTyped = val;
        },
        //处理促销优惠价格
        handlePricep(row) {
            
            this.formListQuery.pageDataSelectList[0].prices.forEach((item, index) => {
                    let computePrice=null;
                    if(item.fixedPrice){
                        computePrice=item.fixedPrice
                    }else if(item.salePriceEbp){
                        computePrice=item.salePriceEbp
                    }else if(item.resultPrice){
                        computePrice=item.resultPrice
                    }
                    if(row.countryCode == item.countryCode){
                        if (row.promotionValue >= computePrice) {
                            this.promotionRuleCountrysList.forEach((item2,index2)=>{
                                if(row.countryCode == item2.countryCode){
                                    if(item2.promotionType=='Price'){
                                        item2.promotionValue = computePrice.toFixed(2);
                                    }
                                    this.Price = computePrice - item2.promotionValue;
                                    this.Price = this.Price.toFixed(2);
                                    item2.Price = computePrice - item2.promotionValue;
                                    this.calculateProfit(row, item, "Price", 0, index2)
                                }
                            })
                        } else if(row.promotionValue < computePrice) {
                            this.promotionRuleCountrysList.forEach((item2,index2)=>{
                                if(row.countryCode == item2.countryCode){
                                    this.Price = computePrice - row.promotionValue;
                                    this.Price = this.Price.toFixed(2);
                                    item2.Price = this.Price;
                                    this.calculateProfit(row, item, "Price", 0, index2)
                                }
                            })
                        }


                    }
            });
			this.$forceUpdate();
        },
        handlePriced(row) {
            this.formListQuery.pageDataSelectList[0].prices.forEach((item, index) => {
                if (this.checkCountrys.countryCode == item.countryCode) {
                    let computePrice=null;
                    if(item.fixedPrice){
                        computePrice=item.fixedPrice
                    }else if(item.salePriceEbp){
                        computePrice=item.salePriceEbp
                    }else if(item.resultPrice){
                        computePrice=item.resultPrice
                    }
                    if (row.promotionValue >= computePrice) {
                        if(this.promotionRuleCountrysList[0].promotionType=='Price'){
                            // this.promotionRuleCountrysList[0].promotionValue = item.resultPrice;
                            this.promotionRuleCountrysList[0].promotionValue = computePrice.toFixed(2);
                        }                 
                        this.Price = computePrice - this.promotionRuleCountrysList[0].promotionValue;				
                        this.Price = this.Price.toFixed(2);				
                        if (this.Price == 0) {
                            this.Price = 0;
                        }
                    } else {
                        this.Price = computePrice - row.promotionValue;
                        this.Price = this.Price.toFixed(2);
                        if (this.Price == 0) {
                            this.Price = 0;
                        }
                    }
                    this.calculateProfit(row, item, "Price")
                }
            });
            this.$forceUpdate();
        },
        //处理折扣价格
        handleDiscount(row, idx) {
			if(this.typeName == this.typeNameDistribution){
				let promotionValue = row.promotionValue - 0;
				row.promotionValue = promotionValue.toFixed(1);
				if (row.promotionValue > 100) {
				    row.promotionValue = 100;
				}
				this.formListQuery.pageDataSelectList[0].prices.forEach((item, index) => {
				    if (this.checkCountrys.countryCode == item.countryCode) {	
                        let computePrice=null;
                        if(item.fixedPrice){
                            computePrice=item.fixedPrice
                        }else if(item.salePriceEbp){
                            computePrice=item.salePriceEbp
                        }else if(item.resultPrice){
                            computePrice=item.resultPrice
                        }		
				        this.Discount = (computePrice - (row.promotionValue / 100) * computePrice).toFixed(2);
                        this.calculateProfit(row, item, "Discount", computePrice);
				    }
				});
			}else if(this.typeName == '促销'){
				let promotionValue = row.promotionValue - 0;
                let resultPrice=null;
				row.promotionValue = promotionValue.toFixed(1);
				if (row.promotionValue > 100) {
				    row.promotionValue = 100;
				}
                this.formListQuery.pageDataSelectList[0].prices.forEach((item, index) => {			    
                            if (row.countryCode == item.countryCode) {
                                let computePrice=null;
                                if(item.fixedPrice){
                                    computePrice=item.fixedPrice
                                }else if(item.salePriceEbp){
                                    computePrice=item.salePriceEbp
                                }else if(item.resultPrice){
                                    computePrice=item.resultPrice
                                }
                                resultPrice=computePrice
                                this.calculateProfit(row, item, "Discount",computePrice, idx);
                             }	        		   
				});	
                this.promotionRuleCountrysList.forEach((item2,index2)=>{
                            if (row.countryCode == item2.countryCode) {
                                this.Discount = (resultPrice - (row.promotionValue / 100) * resultPrice).toFixed(2);                          
                                item2.discount=(resultPrice - (row.promotionValue / 100) * resultPrice).toFixed(2)                              
                             }
				})
			}   
            this.$forceUpdate();
        },
        // 计算毛利率
        calculateProfit(row, priceDto, type, resultPrice, index) {

            //type = Discount、Price
            if(type !== row.promotionType){
                return
            }

            const feightFee = priceDto.feightFee
            const originalPrice = priceDto.originalPriceSaleDouble
            // 最终促销价格
            let promotionValue = row.promotionValue
            if (type === 'Discount') {
                promotionValue = (resultPrice * (1 - row.promotionValue / 100)).toFixed(2);
            }

            this.priceSetting.forEach((ps) => {
                if (ps.countryCode === row.countryCode) {
                    // promotionValue = 0 ，毛利率返回0
                    if (promotionValue > 0) {
                        const taxRate = ps.taxRate / 100
                        const commission = ps.commission / 100
                        // 计算毛利率（和后端公式保持一致）
                        this.profit = (1 - (taxRate / (taxRate + 1)).toFixed(7) - commission - ((feightFee + originalPrice) / promotionValue).toFixed(7)) * 100
                        row.profit = this.profit.toFixed(2)
                    } else {
                        this.profit = 0
                        row.profit = this.profit.toFixed(2)
                    }

                    if(index >= 0){
                        console.error("index:" ,index)
                        this.promotionRuleCountrysList[index].profit = row.profit
                    }
                }
            });
        },
        //促销数量
        handleCountp(row,$index) {
            this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item, index) => {
                if (row.countryCode == item.countryCode) {
                    this.$nextTick(() => {
                        if (row.promotionCount && row.promotionCount >= item.ecomRemainQty) {
                            this.promotionRuleCountrysList[$index].promotionCount = item.ecomRemainQty;
                        }
                        //计算用户购买数
                        if(this.promotionRuleCountrysList[$index].promotionCount != null){
                            this.promotionRuleCountrysList[$index].userBuy = Math.floor(this.promotionRuleCountrysList[$index].promotionCount / item.saleDoubleEbp)
                        }
                        this.$forceUpdate();
                    });
                }
            });
        },
        //分销数量
        handleCountd() {
            this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item) => {
                if (item.countryCode == this.checkCountrys.countryCode) {
                    this.$nextTick(() => {
                        console.log(1111);
                        if (this.promotionRuleCountrysList[0].promotionCount >= item.ecomRemainQty) {
                            this.promotionRuleCountrysList[0].promotionCount = item.ecomRemainQty;
                        }
                        //计算用户购买数
                        if(this.promotionRuleCountrysList[0].promotionCount != null){
                            this.promotionRuleCountrysList[0].userBuy = Math.floor(this.promotionRuleCountrysList[0].promotionCount / item.saleDoubleEbp)
                        }
                        this.$forceUpdate();
                        this.calRemainQty();
                    });
                }
            });
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            let data = {
                promotion:this.listQuery.inPromotion,
                isUsed:this.listQuery.effectShelf
            }
            this.handleSearchList2(data,1);
        },
        //单选获取选中的
        selectionChange(row) {
            this.selectedRow = Object.assign({}, row);
        },
        handleSearchList() {
            this.$emit('handleSearchList');
        },
        //选择商品
        handleSelected() {
            let tableList=[]
            let arr=[]
            if (this.typeName == '促销' && this.isEdit==false) {
                console.log('this.proAddData.tableList',this.proAddData)
                tableList=JSON.parse(JSON.stringify(this.proAddData.tableList))
            }
            if(this.formListQuery.pageDataSelectList.length>0 && !this.selectedRow){
                this.selectedRow=Object.assign({}, this.formListQuery.pageDataSelectList[0])
            }  
            if(!this.selectedRow){
                this.$message({
                        message: this.$i18n.t("common.Pleaseselectaproductfirst"),
                        type: 'warning',
                        duration: 1000
                });
                return
            }
            console.log('this.selectedRow.goodsSpecials',this.selectedRow.goodsSpecials)
            if(!this.selectedRow.goodsSpecials || this.selectedRow.goodsSpecials.length<=0){
                this.$message({
                    message: this.$i18n.t("common.Nocountrieslisted"),
                        type: 'warning',
                        duration: 1000
                });
                return
            }
            if(tableList && tableList.length>0){
                let result = tableList.filter(item =>{return item.goodsId==this.selectedRow.goodsId})
                if(result.length>0){
                    this.$message({
                            message: this.$i18n.t("common.ProductSelected"),
                            type: 'warning',
                            duration: 1000
                    });
                    return
                }
            }
            this.formListQuery.pageDataSelectList = [];
            this.selectedRow.goodsSpecials.forEach((item) => {
                if (item.shelf==true) {
                    arr.push(item)
                }
            });
            this.selectedRow.goodsSpecials=arr
            this.formListQuery.pageDataSelectList.push(this.selectedRow);
            this.countryCode = [];
            this.tabCountrys.countryCode = [];
            // this.$set(this.tabCountrys, 'countryCode', arr);
            if (!this.formListQuery.promotionRuleCountrys || this.formListQuery.promotionRuleCountrys.length <= 0) {
                    this.promotionRuleCountrysList = this.formListQuery.pageDataSelectList[0].goodsSpecials.map((item) => {
                        return {
                            promotionId: '',
                            goodId: this.formListQuery.pageDataSelectList[0].goodsId,
                            beginTime: '',
                            endTime: '',
                            promotionType: 'Discount',
                            promotionValue: null,
                            promotionCount: null,
                            userBuy: null,
                            countryCode: item.countryCode,
                            time: [],
                            freeDelivery: false,
                            status: false,
                            coupon:false,
                            userPurchaseLimit:null,
                            orderPurchaseLimit:null 
                        };
                    });
            }
            this.activeName = this.tabCountrys.countryCode[0];
			if(this.promotionRuleCountrysList.length>0){
				this.checkCountrys.countryCode = this.promotionRuleCountrysList[0].countryCode;
			}else{
				this.checkCountrys.countryCode=''
			}
                
            this.closeDialogGoods()
            if (this.typeName == '促销') {
                this.promotionRuleCountrysList = [];
            }
            if(this.proAddData.index>=0){
                let data={
                        val:this.selectedRow,
                        index:this.proAddData.index
                }
                this.$emit('handChangeSelectList',data)      
            }
        },
        //变更国家Tab
        handleTabCode(val, row) {
            let arr = [];
            this.promotionRuleCountrysList.forEach((item, index) => {
                arr.push(item.countryCode);
            });
            if (val == true) {
                const data = {
                    promotionId: '',
                    goodId: this.formListQuery.pageDataSelectList[0].goodsId,
                    beginTime: '',
                    endTime: '',
                    promotionType: 'Discount',
                    promotionValue: null,
                    promotionCount: null,
                    userBuy: null,
                    sort:0,//秒杀排序
                    countryCode: row.countryCode,
                    time: [],              
                    flagShowPromotionPrice:false,
                    seckill: false,//是否秒杀
                    freeDelivery: false,
                    status: false,
                    coupon:false,
                    userRestrictions:null,
                    orderRestrictions:null               
                };
                if (arr.indexOf(row.countryCode) == -1) {
                    this.promotionRuleCountrysList.push(data);
                }
            } else {
                this.promotionRuleCountrysList.forEach((item, index) => {
                    if (row.countryCode == item.countryCode) {
                        this.promotionRuleCountrysList.splice(index, 1);
                    }
                });
            }
			if(val){
				if(this.promotionRuleCountrysList.length==1){
					this.activeName = this.promotionRuleCountrysList[0].countryCode;
				}else if(this.promotionRuleCountrysList.length>1){
					this.activeName = this.formListQuery.pageDataSelectList[0].goodsSpecials[0].countryCode;
				}
			}else{
				this.activeName = this.promotionRuleCountrysList[0].countryCode;
			}
			
            // this.$set(this.tabCountrys, 'countryCode', val);
            
        },
        handleTabCoded() {
            if(this.checkCountrys.countryCode!=this.promotionRuleCountrysList[0].countryCode){
                this.promotionRuleCountrysList[0].promotionCount = 0;
                this.promotionRuleCountrysList[0].promotionValue = 0;
                this.promotionRuleCountrysList[0].userBuy = 0;
            }
        },
        onSubmit1(formName) {
            if (this.formListQuery.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst"),
                    type: 'warning',
                    duration: 1000
                });
                return;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSubmit('variantFrom2');
                }
            });
        },

        //出现毛利率提交
        getPricingSubmit(type) {
            this.loading=true;
            let url = '';
            type == true ? (url = 'putPromotion') : (url = 'addPromotion');
            if(url == 'putPromotion'){
                this.$store.dispatch(url, this.formListQuery).then((res) => {
                    setTimeout(()=>{
                        this.$message({
                            message: this.$i18n.t("common.Successfullysubmitted"),
                            type: 'success',
                            duration: 1000
                        });
                        this.closeDialog();
                        this.handleSearchList();
                        this.loading=false;
                    },500)    
                }).catch((err)=>{
                    this.loading=false;
                });
            }else{
                this.closeDialog();
                this.loading=false;
                let data={
                        val:this.selectedRow,
                        index:this.proAddData.index,
                        formListQuery:this.formListQuery
                }
                this.$emit('queryPro',data)
            }     
        },   
        //提交
        onSubmit(formName) {
            if (this.typeName == '促销') {
                let validList = [];
                if (this.$refs[formName] && this.$refs[formName].length > 0) {
                    this.$refs[formName].forEach((item, index) => {
                        item.validate((valid) => {
                            if (valid) {
                                validList.push(1);
                            } else {
                                validList.push(0);
                            }
                        });
                    });
                }
                if (validList.indexOf(0) <= -1) {
                    this.$confirm( this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning',
                        beforeClose: (action, ctx, close) => {
                            if (action !== 'confirm') {
                                close();
                                return;
                            }                        
                            ctx.confirmButtonLoading =true
                            console.log('this.promotionRuleCountrysList',this.promotionRuleCountrysList)
                            let checkPrice=this.promotionRuleCountrysList.map(item => {
                                let price=null
                                if(item.promotionType=="Price"){
                                    price=item.promotionValue
                                }else{
                                    price=item.discount
                                }
                                return {
                                    price,
                                    countryCode:item.countryCode
                                }             
                            });
                            let checkQuery={
                                goodsId:this.formListQuery.pageDataSelectList[0].goodsId,
                                priceList:checkPrice
                            }
                            this.$store.dispatch('postCheckLowestPrice', checkQuery).then((resCheck) => {
                                if (resCheck) {
                                    //校验最低价
                                    if(resCheck.result==false){
                                        ctx.confirmButtonLoading =false
                                        this.checkLowestPrice=resCheck.priceList
                                        if(this.checkLowestPrice && this.checkLowestPrice.length>0){
                                            this.dialogLowestPriceVisible=true
                                        }                                  
                                        return
                                    }
                                    if (this.isEdit) {                         
                                        let flagPrice=true
                                        this.formListQuery.goodId = this.formListQuery.pageDataSelectList[0].goodsId;
                                        this.promotionRuleCountrysList.forEach((item, index) => {
                                            if(!item.seckill){
                                                item.sort=null;
                                            }
                                            if (item.time && item.time.length > 0) {
                                                this.promotionRuleCountrysList[index].beginTime = new Date(item.time[0]).getTime();
                                                this.promotionRuleCountrysList[index].endTime = new Date(item.time[1]).getTime();
                                            }
                                        });
                                        this.formListQuery.promotionRuleCountrys = this.promotionRuleCountrysList;
                                        let countryCode = [];

                                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                            countryCode.push(item.countryCode);
                                        });
                                        countryCode = countryCode.join(',');
                                        if (countryCode.length <= 0) {
                                            this.$message({
                                                message: this.$i18n.t("common.PleaseSelectCountry"),
                                                type: 'error',
                                                duration: 1000
                                            });
                                            ctx.confirmButtonLoading =false;
                                            return;
                                        }
                                        //毛利率
                                        const pricingData = [];
                                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {	
                                            let resultPrice=''
                                            if(item.promotionType=='Discount'){
                                                resultPrice=item.discount
                                            }else if(item.promotionType=="Price"){
                                                resultPrice=item.promotionValue 
                                            }
                                            pricingData.push({
                                                countryCodes: item.countryCode,
                                                resultPrice: resultPrice                     
                                            });
                                            
                                        });
                                        pricingData.forEach((item, index) => {
                                            if(this.formListQuery.goodsMergeListMgrDto.prices && this.formListQuery.goodsMergeListMgrDto.prices.length>0){
                                                this.formListQuery.goodsMergeListMgrDto.prices.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.originalPrice = item2.originalPrice;
                                                    }
                                                });
                                            }                          
                                            if(this.formListQuery.goodsMergeListMgrDto.goodsSpecials && this.formListQuery.goodsMergeListMgrDto.goodsSpecials.length>0){
                                                this.formListQuery.goodsMergeListMgrDto.goodsSpecials.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.saleDoubleEbp = item2.saleDoubleEbp;
                                                    }
                                                });
                                            }                              
                                            if (this.formListQuery.goodsMergeListMgrDto.peso) {
                                                item.peso = this.formListQuery.goodsMergeListMgrDto.peso;
                                            }
                                        });
                                        
                                        
                                        setTimeout(()=>{
                                            this.formListQuery.promotionRuleCountrys.forEach((item,index)=>{
                                                this.formListQuery.pageDataSelectList[0].prices.forEach((item2,index2)=>{
                                                    if(item.countryCode==item2.countryCode){
                                                        if(!item2.resultPrice){
                                                            flagPrice=false
                                                        }
                                                    }
                                                })									
                                            })
                                            if(flagPrice==false){
                                                this.$message({
                                                    message: this.$i18n.t("common.ProductHasNoPricing"),
                                                    type: 'error',
                                                    duration: 1000
                                                });
                                                ctx.confirmButtonLoading =false;
                                                return
                                            }
                                            this.$store.dispatch('getPricing', pricingData).then((res) => {
                                                if (res) {
                                                    this.getPricing = res;
                                                    setTimeout(()=>{
                                                        this.dialogGetPricingVisible = true;
                                                        ctx.confirmButtonLoading = false
                                                        close();
                                                    },500)                              
                                                }
                                            }).catch(err => {
                                                    setTimeout(()=>{
                                                        ctx.confirmButtonLoading = false
                                                    },500)
                                            });
                                        },50)					                    
                                    } else {
                                        let flagPrice=true
                                        this.formListQuery.goodId = this.formListQuery.pageDataSelectList[0].goodsId;
                                        this.promotionRuleCountrysList.forEach((item, index) => {
                                            if (item.time && item.time.length > 0) {
                                                this.promotionRuleCountrysList[index].beginTime = new Date(item.time[0]).getTime();
                                                this.promotionRuleCountrysList[index].endTime = new Date(item.time[1]).getTime();
                                            }
                                        });

                                        this.formListQuery.promotionRuleCountrys = this.promotionRuleCountrysList;                        
                                        let countryCode = [];
                                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                            countryCode.push(item.countryCode);
                                        });
                                        if (countryCode.length <= 0) {
                                            this.$message({
                                                message: this.$i18n.t("common.PleaseSelectCountry"),
                                                type: 'error',
                                                duration: 1000
                                            });
                                            ctx.confirmButtonLoading =false;
                                            return;
                                        }
                                        countryCode = countryCode.join(',');
                                        //毛利率
                                        const pricingData = [];
                                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                            pricingData.push({
                                                countryCodes: item.countryCode,
                                                resultPrice: item.promotionType=='Discount'?item.discount:item.promotionValue
                                            });
                                        });
                                        pricingData.forEach((item, index) => {
                                            if(this.formListQuery.pageDataSelectList[0].prices && this.formListQuery.pageDataSelectList[0].prices.length>0){
                                                this.formListQuery.pageDataSelectList[0].prices.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.originalPrice = item2.originalPrice;
                                                    }
                                                });
                                            }
                                            if(this.formListQuery.pageDataSelectList[0].goodsSpecials && this.formListQuery.pageDataSelectList[0].goodsSpecials.length>0){
                                                this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.saleDoubleEbp = item2.saleDoubleEbp;
                                                    }
                                                });
                                            }
                                            
                                            if (this.formListQuery.pageDataSelectList[0].peso) {
                                                item.peso = this.formListQuery.pageDataSelectList[0].peso;
                                            }
                                        });				
                                        setTimeout(()=>{
                                            this.formListQuery.promotionRuleCountrys.forEach((item,index)=>{
                                                this.formListQuery.pageDataSelectList[0].prices.forEach((item2,index2)=>{
                                                    if(item.countryCode==item2.countryCode){
                                                        if(!item2.resultPrice){
                                                            flagPrice=false
                                                        }
                                                    }
                                                })									
                                            })
                                            if(flagPrice==false){
                                                this.$message({
                                                    message: this.$i18n.t("common.ProductHasNoPricing"),
                                                    type: 'error',
                                                    duration: 1000
                                                });
                                                ctx.confirmButtonLoading =false;
                                                return
                                            }
                                            console.log('pricingData',pricingData)
                                            this.$store
                                                .dispatch('getPricing', pricingData)
                                                .then((res) => {
                                                    if (res) {
                                                        this.getPricing = res;                                              
                                                        setTimeout(()=>{
                                                            this.dialogGetPricingVisible = true;
                                                            ctx.confirmButtonLoading = false
                                                            close();
                                                        },500)
                                                    }
                                                }).catch(err => {
                                                    setTimeout(()=>{
                                                        ctx.confirmButtonLoading = false
                                                    },500)
                                                });
                                        },50)                              
                                    }                                  
                                }
                            }).catch(errCheck => {
                                ctx.confirmButtonLoading =false
                            });
                        }
                    })
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            } else if (this.typeName == this.typeNameDistribution) {
                let validList = [];
                this.$refs[formName].validate((valid) => {
                    if (valid && this.remainQty > 0) {
                        this.$confirm( this.$i18n.t("common.Whethertosubmitdata"),   this.$i18n.t("common.prompt"), {
                            // confirmButtonText: '确定',
                            // cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            console.log('this.promotionRuleCountrysList',this.promotionRuleCountrysList)
                            let checkQueryCode=[]
                            let checkQueryPrice=null                              
                            if(this.promotionRuleCountrysList[0].promotionType=="Price"){
                                checkQueryPrice=this.promotionRuleCountrysList[0].promotionValue
                            }else{
                                checkQueryPrice=this.Discount
                            }
                            checkQueryCode.push({
                                price:checkQueryPrice,
                                countryCode:this.checkCountrys.countryCode
                            })             
                            console.log('this.checkCountrys.countryCode',this.checkCountrys.countryCode)
                            let checkQuery={
                                goodsId:this.formListQuery.pageDataSelectList[0].goodsId,
                                priceList:checkQueryCode
                            }
                            this.$store.dispatch('postCheckLowestPrice', checkQuery).then((resCheck) => {
                                if (resCheck) {
                                    //校验最低价
                                    if(resCheck.result==false){
                                        this.checkLowestPrice=resCheck.priceList
                                        if(this.checkLowestPrice && this.checkLowestPrice.length>0){
                                            this.dialogLowestPriceVisible=true
                                        }                                  
                                        return
                                    }
                                    this.formListQuery.remarkTag = this.promotionRuleCountrysList[0].remarkTag?this.promotionRuleCountrysList[0].remarkTag:null;
                                    this.formListQuery.discount = this.promotionRuleCountrysList[0].promotionValue - 0;
                                    this.formListQuery.quantity = this.promotionRuleCountrysList[0].promotionCount - 0;
                                    this.formListQuery.userBuy = this.promotionRuleCountrysList[0].userBuy - 0;
                                    this.formListQuery.lockQuantity = this.promotionRuleCountrysList[0].lockQuantity - 0;
                                    this.formListQuery.promotionType=this.promotionRuleCountrysList[0].promotionType
                                    if (this.formListQuery.pageDataSelectList.length > 0) {
                                        this.formListQuery.barCode = this.formListQuery.pageDataSelectList[0].barCode;
                                    }
                                    if (this.isEdit) {
                                        this.formListQuery.countryCode = this.checkCountrys.countryCode;
                                        this.promotionRuleCountrysList[0].freeDelivery == true
                                            ? (this.formListQuery.isFreeFreight = 1)
                                            : (this.formListQuery.isFreeFreight = 0);
                                        this.formListQuery.goodId = this.formListQuery.pageDataSelectList[0].goodsId;
                                        this.formListQuery.goodsId = this.formListQuery.pageDataSelectList[0].goodsId;				
                                        this.formListQuery.startTime = new Date(this.promotionRuleCountrysList[0].time[0]).getTime();
                                        this.formListQuery.endTime = new Date(this.promotionRuleCountrysList[0].time[1]).getTime();
                                        this.formListQuery.promotionRuleCountrys = this.promotionRuleCountrysList;

                                        this.formListQuery.orderPurchaseLimit = this.promotionRuleCountrysList[0].orderPurchaseLimit;
                                        this.formListQuery.userPurchaseLimit = this.promotionRuleCountrysList[0].userPurchaseLimit;
                                        this.formListQuery.coupon = this.promotionRuleCountrysList[0].coupon;
                                        //毛利率
                                        const pricingData = [];
                                        this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                            if(!item.seckill){
                                                item.sort=0
                                            }
                                            pricingData.push({
                                                countryCodes: this.checkCountrys.countryCode,
                                                resultPrice: item.promotionType == 'Discount' ? this.Discount : item.promotionValue
                                            });
                                        });
                                        pricingData.forEach((item, index) => {
                                            if(this.formListQuery.goodsMergeListMgrDto.prices && this.formListQuery.goodsMergeListMgrDto.prices.length>0){
                                                this.formListQuery.goodsMergeListMgrDto.prices.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.originalPrice = item2.originalPrice;
                                                    }
                                                });
                                            }
                                            if(this.formListQuery.goodsMergeListMgrDto.goodsSpecials && this.formListQuery.goodsMergeListMgrDto.goodsSpecials.length>0){
                                                this.formListQuery.goodsMergeListMgrDto.goodsSpecials.forEach((item2, index2) => {
                                                    if (item.countryCodes == item2.countryCode) {
                                                        item.saleDoubleEbp = item2.saleDoubleEbp;
                                                    }
                                                });
                                            }                               
                                            if (this.formListQuery.goodsMergeListMgrDto.peso) {
                                                item.peso = this.formListQuery.goodsMergeListMgrDto.peso;
                                            }
                                        });				
                                        setTimeout(()=>{
                                            this.$store
                                                .dispatch('getPricing',pricingData)
                                                .then((res) => {
                                                    if (res && res.length>0) {
                                                        let profit=(res[0].profit).toFixed(2)
                                                        this.$confirm(
                                                            this.$i18n.t("common.TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit",{rate:profit}),
                                                            this.$i18n.t("common.SubmitConfirmation"),
                                                            {
                                                                // confirmButtonText: '确定',
                                                                // cancelButtonText: '取消',
                                                                type: 'warning',
                                                                beforeClose: (action, ctx, close) => {
                                                                    if (action !== 'confirm') {
                                                                        close();
                                                                        return;
                                                                    }
                                                                    ctx.confirmButtonLoading =true
                                                                    this.$store.dispatch('putDistribution', this.formListQuery).then((res) => {
                                                                        setTimeout(()=>{
                                                                            this.closeDialog();
                                                                            this.handleSearchList();
                                                                            close()
                                                                            ctx.confirmButtonLoading =false
                                                                            this.$message({
                                                                                message:this.$i18n.t("common.Successfullysubmitted"),
                                                                                type: 'success',
                                                                                duration: 1000
                                                                            });
                                                                        },500)                                                              
                                                                    }).catch(err => {
                                                                        setTimeout(()=>{
                                                                            ctx.confirmButtonLoading = false
                                                                        },500)
                                                                    });                                               
                                                                }
                                                            })
                                                            .catch(() => {
                                                                this.$message({
                                                                    type: 'info',
                                                                    message: this.$i18n.t("common.Canceled")
                                                                });
                                                        });    
                                                    }
                                                });
                                        },500)
                                        
                                    } else {
                                        this.formListQuery.distributionId = '0';
                                        this.formListQuery.goodsId = this.formListQuery.pageDataSelectList[0].goodsId;
                                        this.formListQuery.countryCode = this.checkCountrys.countryCode;
                                        
                                        this.formListQuery.startTime = new Date(this.promotionRuleCountrysList[0].time[0]).getTime();
                                        this.formListQuery.endTime = new Date(this.promotionRuleCountrysList[0].time[1]).getTime();
                                        this.formListQuery.discount = this.promotionRuleCountrysList[0].promotionValue - 0;
                                        this.formListQuery.quantity = this.promotionRuleCountrysList[0].promotionCount - 0;
                                        this.formListQuery.userBuy = this.promotionRuleCountrysList[0].userBuy - 0;
                                        this.formListQuery.lockQuantity = this.promotionRuleCountrysList[0].lockQuantity - 0;
                                        this.formListQuery.orderPurchaseLimit = this.promotionRuleCountrysList[0].orderPurchaseLimit;
                                        this.formListQuery.userPurchaseLimit = this.promotionRuleCountrysList[0].userPurchaseLimit;
                                        this.formListQuery.coupon = this.promotionRuleCountrysList[0].coupon;
                                        this.promotionRuleCountrysList[0].freeDelivery == true
                                            ? (this.formListQuery.isFreeFreight = 1)
                                            : (this.formListQuery.isFreeFreight = 0);
                                        if (this.promotionRuleCountrysList[0].promotionType == 'Discount') {
                                            this.formListQuery.promotionType = 0;
                                        } else if (this.promotionRuleCountrysList[0].promotionType == 'Price') {
                                            this.formListQuery.promotionType = 1;
                                        }
                                        //毛利率
                                        const pricingData = [];
                                        pricingData.push({
                                            countryCodes: this.checkCountrys.countryCode,
                                            resultPrice: this.promotionRuleCountrysList[0].promotionType == 'Discount'? this.Discount : this.promotionRuleCountrysList[0].promotionValue
                                        });    
                                        this.formListQuery.pageDataSelectList[0].prices.forEach((item2, index2) => {
                                            if (pricingData[0].countryCodes == item2.countryCode) {
                                                pricingData[0].originalPrice = item2.originalPrice;
                                            }
                                        });
                                        this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item2, index2) => {
                                            if (pricingData[0].countryCodes == item2.countryCode) {
                                                pricingData[0].saleDoubleEbp = item2.saleDoubleEbp;
                                            }
                                        });
                                        if (this.formListQuery.pageDataSelectList[0].peso) {
                                            pricingData[0].peso = this.formListQuery.pageDataSelectList[0].peso;
                                        }    
                                        this.$store
                                            .dispatch('getPricing',pricingData)
                                            .then((res) => {
                                                if (res && res.length>0) {
                                                    let profit=(res[0].profit).toFixed(2)
                                                    this.$confirm(
                                                        this.$i18n.t("common.TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit",{rate:profit}),
                                                        this.$i18n.t("common.SubmitConfirmation"),
                                                        {
                                                            // confirmButtonText: '确定',
                                                            // cancelButtonText: '取消',
                                                            type: 'warning',
                                                            beforeClose: (action, ctx, close) => {
                                                                    if (action !== 'confirm') {
                                                                        close();
                                                                        return;
                                                                    }
                                                                    ctx.confirmButtonLoading =true
                                                                    this.$store.dispatch('addDistribution', this.formListQuery).then((res) => {                                                              
                                                                        setTimeout(()=>{
                                                                            this.closeDialog();
                                                                            this.handleSearchList();
                                                                            close()
                                                                            ctx.confirmButtonLoading =false
                                                                            this.$message({
                                                                                message: this.$i18n.t("common.Successfullysubmitted"),
                                                                                type: 'success',
                                                                                duration: 1000
                                                                            });
                                                                        },500)  
                                                                    }).catch(err => {
                                                                        setTimeout(()=>{
                                                                            ctx.confirmButtonLoading = false
                                                                        },500)
                                                                    }); 
                                                            }        
                                                        })
                                                        .catch(() => {
                                                                this.$message({
                                                                    type: 'info',
                                                                    message: this.$i18n.t("common.Canceled"),
                                                                });
                                                        });    
                                                }
                                            });
                                    }
                                }
                            }).catch(errCheck => {
                            });        
                        });
                    }else{
                        this.showLockQuantityTip = true;
                        this.$message({
                            message: this.$i18n.t("common.Verificationfailed"),
                            type: 'error',
                            duration: 1000
                        });
                    }
                });
            } else {
                this.$message({
                    message: this.$i18n.t("common.Verificationfailed"),
                    type: 'error',
                    duration: 1000
                });
                return false;
            }
        },
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    },
    mounted() {
        this.initData();
        if(this.isEdit==false && this.typeName == '促销'){
            if(this.proAddData && this.proAddData.tableList[this.proAddData.index] && this.proAddData.tableList[this.proAddData.index].formListQuery){
                this.countryCode=[]
                this.formListQuery.goodsMergeListMgrDto=this.proAddData.val[0]
                this.formListQuery.promotionRuleCountrys=this.proAddData.tableList[this.proAddData.index].formListQuery.promotionRuleCountrys
                this.promotionRuleCountrysList=this.proAddData.tableList[this.proAddData.index].formListQuery.promotionRuleCountrys
                this.formListQuery.title=this.proAddData.tableList[this.proAddData.index].formListQuery.title
                this.formListQuery.status=this.proAddData.tableList[this.proAddData.index].formListQuery.status
                if(this.formListQuery.promotionRuleCountrys && this.formListQuery.promotionRuleCountrys.length>0){
                    this.formListQuery.promotionRuleCountrys.forEach((item) => {
                        this.countryCode.push(item.countryCode);
                    });       
                    if (this.promotionRuleCountrysList[0].promotionType == 'Discount') {
                        this.handleDiscount(this.formListQuery.promotionRuleCountrys[0]);
                    } else if (this.promotionRuleCountrysList[0].promotionType == 'Price') {
                        this.handlePricep(this.formListQuery.promotionRuleCountrys[0]);
                    }
                }
            } 
        }
        this.$nextTick(()=>{
            if(this.promotionRuleCountrysList.length>0){
                this.activeName = this.promotionRuleCountrysList[0].countryCode;
            }else{
                this.activeName = ''
            }
        })      
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/deep/ .seRadio .el-radio__label {
    display: none;
}
</style>
<style>
.must .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
</style>