var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Categoryname1"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  "show-word-limit": "",
                  maxlength: "50",
                  placeholder: _vm.$i18n.t("common.Categoryname1")
                },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Category"),
                prop: "parentId",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-cascader", {
                ref: "myCategory",
                attrs: {
                  placeholder: _vm.$i18n.t("common.Pleaseselectacategory"),
                  options: _vm.treeData,
                  props: {
                    checkStrictly: true,
                    value: "goodsClassifyId",
                    label: "title",
                    children: "children"
                  },
                  "show-all-levels": false
                },
                on: {
                  change: function($event) {
                    return _vm.handleCascader($event)
                  }
                },
                model: {
                  value: _vm.formListQuery.parentId,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "parentId", $$v)
                  },
                  expression: "formListQuery.parentId"
                }
              })
            ],
            1
          ),
          _vm.formListQuery.level
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Directoryhierarchy"),
                    "label-width": _vm.formLabelWidth
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.formListQuery.level))])]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.sort"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  oninput: "value=value.replace(/[^\\d]/g,'')",
                  placeholder: _vm.$i18n.t("common.sort")
                },
                model: {
                  value: _vm.formListQuery.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "sort", $$v)
                  },
                  expression: "formListQuery.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.SelectAll")) + "\n\t\t\t\t\t"
                  )
                ]
              ),
              _c("div", { staticStyle: { margin: "15px 0" } }),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckedCitiesChange },
                  model: {
                    value: _vm.formListQuery.bannerCountriesList,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerCountriesList", $$v)
                    },
                    expression: "formListQuery.bannerCountriesList"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formListQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "status", $$v)
                  },
                  expression: "formListQuery.status"
                }
              })
            ],
            1
          ),
          _vm.scene.isAigoSmartApp
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Isthehomepagedisplayed"),
                    prop: "appStatus",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949"
                    },
                    model: {
                      value: _vm.formListQuery.appStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "appStatus", $$v)
                      },
                      expression: "formListQuery.appStatus"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "flex-start" } },
          [
            _c("div", { staticClass: "uploadTitle" }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$i18n.t("common.picture")) +
                  "\n\t\t\t\t"
              )
            ]),
            _c(
              "el-upload",
              {
                ref: "uploadImg",
                staticClass: "avatar-uploader",
                attrs: {
                  action: _vm.ossInfo.ossSignature.host,
                  "show-file-list": false,
                  "auto-upload": false,
                  "list-type": "picture-card",
                  "on-change": _vm.handleChange,
                  data: _vm.fileDataObj,
                  accept: ".jpg, .jpeg, .png, .gif"
                }
              },
              [
                _vm.imageUrl
                  ? _c("el-image", {
                      staticClass: "avatar",
                      attrs: { src: _vm.imageUrl, fit: "contain" }
                    })
                  : _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon"
                    })
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "20px",
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "flex-end"
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.Imagesize")) + "：1*1024，＜1M"
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-start",
              "margin-top": "20px"
            }
          },
          [
            _c("div", { staticClass: "uploadTitle" }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$i18n.t("common.icon")) +
                  "\n\t\t\t\t"
              )
            ]),
            _c(
              "el-upload",
              {
                ref: "uploadImg2",
                staticClass: "avatar-uploader",
                attrs: {
                  action: _vm.ossInfo.ossSignature.host,
                  "show-file-list": false,
                  "auto-upload": false,
                  "list-type": "picture-card",
                  "on-change": _vm.handleChange2,
                  data: _vm.fileDataObj2,
                  accept: ".jpg, .jpeg, .png, .gif"
                }
              },
              [
                _vm.imageUrl2
                  ? _c("el-image", {
                      staticClass: "avatar",
                      attrs: { src: _vm.imageUrl2, fit: "contain" }
                    })
                  : _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon"
                    })
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "20px",
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "flex-end"
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("common.Imagesize")) + "：1*1024，＜1M"
                  )
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }