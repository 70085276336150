<template>
    <div>
        <div>
            <div class='table-list'>
                <div class='table-div'>
                    <el-divider content-position='left'>{{ $i18n.t("common.Basicinformationoftheorder") }}</el-divider>
                    <el-descriptions title='' :column='3' border>

                        <el-descriptions-item :label='$i18n.t("common.Ordernumber")'><el-link  type="primary" :underline="false" @click="openOrderDetailDialog(orderInfo.orderId)">{{ orderInfo.orderNo }}</el-link></el-descriptions-item>
                        <!--<el-descriptions-item label='品牌'>

                            <dataText class='filter-item' v-model='orderInfo.brandCode' clearable :codeType="'brand'"
                                      :placeholder='$i18n.t("common.brand")'></dataText
                            >

                        </el-descriptions-item>-->
                        <el-descriptions-item :label='$i18n.t("common.country")'>

                            <dataText class='filter-item' v-model='orderInfo.countryCode' clearable
                                      :codeType="'countrys'"
                                      :placeholder='$i18n.t("common.country")'></dataText
                            >
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.UserID")'>{{ orderInfo.userId }}</el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Numberofproductmodels")'>
                            {{
                                orderInfo && orderInfo.orderItemList ? orderInfo.orderItemList.length : 0
                            }}
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.source")'>{{ orderInfo.source }}</el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.money")'>
                            <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                {{ orderInfo.totalAmt }}
                            </dataText>
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.OrderStatus")'>
                            <dataText class='filter-item' v-model='orderInfo.orderStatus' clearable
                                      :codeType="'order_status'"
                            ></dataText
                            >
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Creationtime")'>{{ orderInfo.createTime | formatDate }}
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Paymentmethod")'>
                            {{postSaleOrder.payMethodName}}
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Paymenttime")'>{{ orderInfo.paymentTime | formatDate }}
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Shipmentstatus")'>
                            <dataText class='filter-item' v-model='orderInfo.deliveryStatus' clearable
                                      :codeType="'delivery_status'"></dataText
                            >
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Deliverytime")'>{{ orderInfo.deliverTime | formatDate }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class='table-div'>
                    <el-divider content-position='left'>{{ $i18n.t("common.ApplicationDetails") }}</el-divider>
                    <el-descriptions title='' :column='3'>
                        <el-descriptions-item :label='$i18n.t("common.type") '>
                            <dataText class='filter-item' v-model='postSaleOrder.postSaleType' clearable
                                      :codeType="'post_sale_type'"
                            ></dataText
                            >
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Applicationtime") '>{{ postSaleOrder.createTime | formatDate }}
                        </el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Returnlogisticstrackingcode") '>{{ postSaleOrder.logisticsCode }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class='table-div' v-if='postSaleChangeGoods && postSaleChangeGoods.length > 0'>
                    {{ $i18n.t("common.Exchangeitem") }}:
                    <el-table :data='postSaleChangeGoods' border>
                        <el-table-column :label='$i18n.t("common.Serialnumber") ' align='center' prop='orderItemId'>
                            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                        </el-table-column>

                        <el-table-column :label='$i18n.t("common.name") ' align='center' prop='goodsTitle'></el-table-column>
                        <el-table-column :label='$i18n.t("common.ProductID1") ' align='center' prop='goodsId'></el-table-column>
                        <el-table-column :label='$i18n.t("common.barcode") ' align='center' prop='barCode'></el-table-column>
                        <!--<el-table-column label='品牌' align='center' prop='payType'>
                             <dataText class='filter-item' v-model='orderInfo.brandCode' clearable :codeType="'brand'"
                                       placeholder='品牌'></dataText
                             >
                         </el-table-column>-->
                         <el-table-column  :label='$i18n.t("common.VariantInformation") '  align='center' prop='goodsVariantVal'></el-table-column>
                        <el-table-column :label='$i18n.t("common.Pricing_unitprice") '  align='center' prop='price'>

                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.price }}
                                </dataText>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.Sellingprice_unitprice") '   align='center' prop='salePrice'>
                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.salePrice }}
                                </dataText>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.quantity") '  align='center' prop='buyQty'></el-table-column>
                        <el-table-column :label='$i18n.t("common.Totalprice") '  align='center' prop='payType'>
                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.salePrice * scope.row.buyQty }}
                                </dataText>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class='table-div' v-if='postSaleReturnGoods && postSaleReturnGoods.length > 0'>
                    {{ $i18n.t("common.Returnedgoods")  }}:
                    <el-table :data='postSaleReturnGoods' border>
                        <el-table-column :label='$i18n.t("common.Serialnumber") ' align='center' prop='orderItemId'>
                            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                        </el-table-column>

                        <el-table-column :label='$i18n.t("common.name") '  align='center' prop='goodsTitle'></el-table-column>
                        <el-table-column :label='$i18n.t("common.ProductID1") ' align='center' prop='goodsId'></el-table-column>
                        <el-table-column :label='$i18n.t("common.barcode") ' align='center' prop='barCode'></el-table-column>
                        <!--<el-table-column label='品牌' align='center' prop='payType'>

                            <dataText class='filter-item' v-model='orderInfo.brandCode' clearable :codeType="'brand'"
                            :placeholder='$i18n.t("common.brand") '></dataText
                            >

                        </el-table-column>-->
                        <el-table-column :label='$i18n.t("common.VariantInformation") ' align='center' prop='goodsVariantVal'></el-table-column>
                        <el-table-column :label='$i18n.t("common.Pricing_unitprice") '  align='center' prop='price'>
                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.price }}
                                </dataText>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.Sellingprice_unitprice") '  align='center' prop='salePrice'>
                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.salePrice }}
                                </dataText>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.quantity") '  align='center' prop='buyQty'></el-table-column>
                        <el-table-column  :label='$i18n.t("common.Totalprice") ' align='center' prop='payType'>
                            <template v-slot="scope">
                                <dataText v-model='orderInfo.currencyCode' :codeType="'currency_code'">
                                    {{ scope.row.salePrice * scope.row.buyQty }}
                                </dataText>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class='table-div' v-if="formItem.description">
                    {{ $i18n.t("common.ApplicationDescription")  }}: {{ formItem.description }}
                </div>
                <div class='table-div' v-if="postSaleRecord && postSaleRecord.length >0">
                    <el-divider content-position='left'>   {{ $i18n.t("common.Auditing")  }}</el-divider>
                    <el-descriptions title='' :column='3' border>
                        <el-descriptions-item  :label='$i18n.t("common.result") ' >
                            <dataText class='filter-item'
                                      v-model='checkInfo.auditStatus'
                                      :codeType="'audit_status'"
                            ></dataText></el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Audittime") '>{{ checkInfo.createTime | formatDate }}</el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.Reviewedby") '>{{ checkInfo.createUserName }}</el-descriptions-item>
                        <el-descriptions-item :label='$i18n.t("common.reason") '>{{ checkInfo.description }}</el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class='table-div' v-if="formItem.imgUrls">
                        <el-image :key="index" v-for="(item,index)  in formItem.imgUrls"
                                style="width: 100px; height: 100px;margin: 0 10px 10px 0;"
                                :src="ossHost+item"
                                :preview-src-list="formItem.fullImgUrls"
                                ></el-image>
                </div>
                <div class='table-div'>
                    <el-form :rules="rules" v-if='!postSaleOrder.finish' :model='formBody' ref='variantFrom'>
                        <el-form-item v-if='postSaleOrder.auditStatus === "NOT_APPROVED"' :label='$i18n.t("common.result")' key="auditStatus"
                                      prop='auditStatus'
                                      :label-width='formLabelWidth'>
                            <el-radio-group v-model='formBody.auditStatus'>
                                <el-radio label='ADOPT'>{{ $i18n.t("common.adopt") }}</el-radio>
                                <el-radio label='FAIL'>{{ $i18n.t("common.Failed") }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-else  :label='$i18n.t("common.result") ' prop='finish' :label-width='formLabelWidth'  key="finish">
                            <el-radio-group v-model='formBody.finish'>
                                <el-radio :label='false'>{{ $i18n.t("common.Inprogress") }}</el-radio>
                                <el-radio :label='true'>{{ $i18n.t("common.Completed") }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label='$i18n.t("common.reason") ' prop='description' :label-width='formLabelWidth' key="description">
                            <el-input type='textarea' maxlength="800"  show-word-limit="true" v-model='formBody.description'></el-input>
                            *{{ $i18n.t("common.Thereplycontentwillbedisplayedtotheuser") }}
                        </el-form-item>
                        <el-form-item v-if='formBody.auditStatus==="FAIL"' key="disableOrder"
                                      :label=' $i18n.t("common.Pleasechoosewhethertocontinueapplying") ' prop='disableOrder'
                                      :label-width='formLabelWidth'>
                            <el-radio-group v-model='formBody.disableOrder'>
                                <el-radio label='false'>{{ $i18n.t("common.yes") }}</el-radio>
                                <el-radio label='true'>{{$i18n.t("common.no")}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </div>
                <div class='table-div flex-button' v-if='!postSaleOrder.finish'>
                    <el-button style='width: 20%;' @click='closeDialog(false)'>{{ $i18n.t("common.cancel") }}</el-button>
                    <el-button style='width: 20%;' type='primary' @click="onSubmit('variantFrom')">{{ $i18n.t("common.Submit") }}</el-button>
                </div>
                <div class='table-div' v-if='postSaleRecord && postSaleRecord.length > 0'>
                    <el-table :data='postSaleRecord' border>
                        <el-table-column :label='$i18n.t("common.Serialnumber") ' align='center' prop='postSaleRecordId'></el-table-column>
                        <el-table-column :label='$i18n.t("common.result")' align='center' prop='price'>
                            <template slot-scope='scope'>
                               <dataText v-if='scope.row.recordType==="Audit"' class='filter-item'
                                          v-model='scope.row.auditStatus' clearable
                                          :codeType="'audit_status'"
                                         ></dataText>
                                <span v-else> {{ scope.row.finish ? $i18n.t("common.complete") : $i18n.t("common.result") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.Replycontent") ' align='center' prop='description'></el-table-column>
                        <el-table-column :label='$i18n.t("common.time") ' align='center' prop='createTime'>
                            <template slot-scope='scope'>
                                <span>{{ scope.row.createTime | formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.Operator") '  align='center' prop='createUserName'></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>

        <div slot='footer' class='dialog-footer'>
        </div>
        <!--订单详情对话框-->
        <el-dialog :title='$i18n.t("common.OrderDetails") ' v-if="dialogFormVisible" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   :append-to-body="true">
            <OrderDetailDialog @closeDialog="closeWindow" :formItem="orderFormItem"/>
        </el-dialog>
    </div>
</template>
<script>
import OrderDetailDialog from '@/views/order/components/VaroantDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText';
import moment from 'moment/moment';

import Scene from '@/utils/scene';

export default {
    components: {
        FilterContainer,
        dataSelect, dataText,OrderDetailDialog
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        const checkDesc = (rule, value, callback) => {
            if (this.formBody.finish === true || this.formBody.auditStatus === 'FAIL') {
                if (value === '') {
                    callback(new Error(this.$i18n.t("common.Pleaseenterthereason")));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        const checkDisableOrder = (rule, value, callback) => {
            if (this.formBody.finish === true || this.formBody.auditStatus === 'FAIL') {
                if (!value || value === '') {
                    callback(new Error(this.$i18n.t("common.Pleasechoosewhethertocontinueapplying")));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return {
            orderFormItem: {
            type: Object,
            default: () => {
                return {};
            }
            },
            dialogFormVisible: false,
            formLabelWidth: '130px',
            activeIndex: null,
            count: 1,
            orderInfo: {},
            form: {},
            formBody: {postSaleId: 0, finish: null, description: '', auditStatus: null, disableOrder: ''},
            defaultForm: {postSaleId: 0, finish: null, description: '', auditStatus: null, disableOrder: ''},
            orderItemList: [],
            postSaleOrder: {},
            checkInfo:{},
            postSaleChangeGoods: [],
            postSaleReturnGoods: [],
            postSaleRecord: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            rules: {
                auditStatus: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectaresult"),
                        trigger: 'blur'
                    }
                ],
                finish:[{
                    required: true,
                    message:  this.$i18n.t("common.Pleaseselectaresult"),
                    trigger: 'blur'
                }],
                disableOrder: [
                    {
                        validator: checkDisableOrder,
                        trigger: 'blur'
                    }
                ],
                description: [
                    {
                        validator: checkDesc,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    created() {
        if(Scene.isAigoStar){
            this.ossHost = process.env.VUE_APP_OSS_Head_SDP
            // console.log('ossHostHandler',this.ossHost,process.env.VUE_APP_OSS_Head_SDP)
        }
        this.formBody = Object.assign({}, this.defaultForm);
        this.formBody.postSaleId = this.formItem.postSaleId;
        if(this.formItem.imgUrls && this.formItem.imgUrls.length>0) {
            this.formItem.fullImgUrls = this.formItem.imgUrls.map(value => {
                return this.ossHost + value;
            });
        }
        this.$store.dispatch('getPostSaleDetail', {id: this.formItem.postSaleId}).then(res => {
            this.postSaleOrder = res;
            this.orderInfo = this.postSaleOrder.orderFullInfoDto;
            this.postSaleChangeGoods = this.postSaleOrder.postSaleChangeGoods;
            this.postSaleReturnGoods = this.postSaleOrder.postSaleReturnGoods;
        });

        this.$store.dispatch('getPostSaleRecord', {id: this.formItem.postSaleId}).then(res => {
            this.postSaleRecord = res.list;
            if(this.postSaleRecord.length>0) {
                this.checkInfo = this.postSaleRecord[this.postSaleRecord.length-1];
            }
        });
    },
    methods: {
        closeWindow() {
            this.dialogFormVisible = false;
        },
        openOrderDetailDialog(orderId){
            const params = {};
            params.orderId = orderId;
            this.$store.dispatch('getOrderDetailForBg', params).then(res => {
                this.orderFormItem = Object.assign({}, res);   
                this.dialogFormVisible = true           
            });
        },
        closeDialog(status) {
            if (this.$refs['variantFrom'] !== undefined) {
                this.$refs['variantFrom'].resetFields();
            }
            this.$emit('closeDialog', status);
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        //提交
        onSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$store.dispatch('checkPostSale', this.formBody).then(response => {
                        this.$message({
                            message: this.$i18n.t("common.Successfullymodified"),
                            type: 'success',
                            duration: 1000
                        });
                        this.closeDialog(true);
                    });
                } else {
                    this.$message({
                        message:  this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.table-list {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-direction: column
}

.table-div {
    margin: 10px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.flex-button {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

</style>
