var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              placeholder: _vm.$i18n.t("common.UserID"),
              "controls-position": "right"
            },
            model: {
              value: _vm.query.userId,
              callback: function($$v) {
                _vm.$set(_vm.query, "userId", $$v)
              },
              expression: "query.userId"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "180px" },
            attrs: {
              clearable: "",
              codeType: "platforms",
              placeholder: _vm.$i18n.t("common.platform")
            },
            model: {
              value: _vm.query.platform,
              callback: function($$v) {
                _vm.$set(_vm.query, "platform", $$v)
              },
              expression: "query.platform"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              codeType: "countrys",
              placeholder: _vm.$i18n.t("common.country")
            },
            model: {
              value: _vm.query.countryCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "countryCode", $$v)
              },
              expression: "query.countryCode"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              format: "yyyy-MM-dd HH:mm:ss",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "range-separator": _vm.$i18n.t("common.to"),
              "start-placeholder": _vm.$i18n.t("common.starttime"),
              "end-placeholder": _vm.$i18n.t("common.Endtime"),
              align: "right",
              "default-time": ["00:00:00", "23:59:59"]
            },
            model: {
              value: _vm.query.logintimeStartAndEnd,
              callback: function($$v) {
                _vm.$set(_vm.query, "logintimeStartAndEnd", $$v)
              },
              expression: "query.logintimeStartAndEnd"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              codeType: "problem_status",
              placeholder: "问题状态"
            },
            model: {
              value: _vm.query.status,
              callback: function($$v) {
                _vm.$set(_vm.query, "status", $$v)
              },
              expression: "query.status"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.query")) +
                  "\n        "
              )
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: {
                icon: "el-icon-search",
                type: "primary",
                size: "small "
              },
              on: { click: _vm.cleanQuery }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Reset")) +
                  "\n        "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { icon: "el-icon-search", type: "primary", size: "small" },
              on: { click: _vm.handleExport }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.export")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "", stripe: "" },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.UserID"),
              prop: "userId",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Creationtime"),
              prop: "createTime",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(new Date(row.createTime).toLocaleString()))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.country"),
              prop: "countrycode",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$dict.getDictValue("countrys", row.countryCode)
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.PageAddress"),
              prop: "webAddress",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ErrorLogs"),
              prop: "error",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          width: "300px",
                          "white-space": "nowrap",
                          overflow: "hidden",
                          "text-overflow": "ellipsis"
                        },
                        attrs: { size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.error))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "问题状态", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticStyle: { color: "#1081de" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.New")) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticStyle: { color: "#ff9500" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.InProgress")) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 2
                      ? _c("span", { staticStyle: { color: "#10bb27" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.Resolved")) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 3
                      ? _c("span", { staticStyle: { color: "#333333" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.CanBeIgnored")) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 4
                      ? _c("span", { staticStyle: { color: "#37679f" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.Published")) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.OperatingTime"),
              prop: "updateTime",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(new Date(row.updateTime).toLocaleString()))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Operator"),
              prop: "updateByName",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Remarks"),
              prop: "remark",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$i18n.t("common.reply"))
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticStyle: { "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.query.page,
          "page-size": _vm.query.size,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total
        },
        on: { "current-change": _vm.doQueryList }
      }),
      _vm.diologVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "查看日志详情",
                visible: _vm.diologVisible,
                width: "1000px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.diologVisible = $event
                }
              }
            },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { column: 2, size: "medium", border: "" }
                },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.UserID"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.showItem.userId))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Creationtime"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          new Date(_vm.showItem.createTime).toLocaleString()
                        )
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.IPaddress"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.showItem.ipAddress))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.platform"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.showItem.platform))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.country"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$dict.getDictValue(
                              "countrys",
                              _vm.showItem.countryCode
                            )
                          )
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.PageURL"),
                        labelStyle: { width: "100px" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.showItem.webAddress))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "错误堆栈",
                        labelStyle: { width: "100px" }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.showItem.error) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    model: _vm.addWebLog,
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题状态" } },
                    [
                      _c("dataSelect", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          codeType: "problem_status",
                          placeholder: "问题状态"
                        },
                        model: {
                          value: _vm.addWebLog.status,
                          callback: function($$v) {
                            _vm.$set(_vm.addWebLog, "status", $$v)
                          },
                          expression: "addWebLog.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$i18n.t("common.Remarks") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写备注",
                          type: "textarea",
                          maxlength: "500",
                          rows: "5",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.addWebLog.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.addWebLog, "remark", $$v)
                          },
                          expression: "addWebLog.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }