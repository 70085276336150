import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};
const mutations = {};

const advertisingSpaceUrl = '/b2c/v1.0/advertising/space';
 
const actions = {
    // 广告位 列表
    getAdvertisingSpacePage({ state, commit }, params) {
        let res = httpV2.get2( advertisingSpaceUrl + `/page/search`, params, this);
        return res;
    },
    //广告位 删除
    deleteAdvertisingSpace({ state, commit }, params) {
        let res = httpV2.delete(advertisingSpaceUrl + `/${params.advertisingSpaceId}`, params, this);
        return res;
    },
    //广告位 新增
    addAdvertisingSpace({ state, commit }, params) {
        let res = httpV2.post(advertisingSpaceUrl , params, this);
        return res;
    },
    //广告位 修改
    putAdvertisingSpace({ state, commit }, params) {
        let res = httpV2.put(advertisingSpaceUrl + `/${params.advertisingSpaceId}`, params, this);
        return res;
    }, 

 
};

export default {
    getters,
    state,
    mutations,
    actions
};
