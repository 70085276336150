<template>
    <div>
        <el-container>
            <el-header style="height: auto; margin-top: 10px">
                <el-form :inline="true">
                    <el-form-item>
                        <el-date-picker
                            style="width: 100%"
                            v-model="valuePicker"
                            unlink-panels
                            :type="dateType"
                            :range-separator='$i18n.t("common.to")'
                            :start-placeholder='$i18n.t("common.Startdate")'
                            :end-placeholder='$i18n.t("common.Enddate")'
                            :picker-options="pickerOptions"
                            :clearable="false"
                            :default-time="['00:00:00','23:59:59']"
                        >
                        </el-date-picker
                    ></el-form-item>
                    <el-form-item label="">
                        <el-select v-model="orderstaticRequest.timeType" placeholder="" change="">
                            <el-option :label='$i18n.t("common.Hourlystatistics")' value="false"></el-option>
                            <el-option :label='$i18n.t("common.Countbyday")' value="true"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <dataSelect
                            v-model="orderstaticRequest.countryCode"
                            clearable
                            :codeType="'countrys'"
                            :placeholder='$i18n.t("common.SalesCountry")' 
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" icon="el-icon-search" @click="initEcharts">{{$i18n.t("common.query")}}</el-button>
                    </el-form-item>
                </el-form>
            </el-header>
            <el-main>
                <el-card v-loading="loading">
                    <div class="echart" id="myChart2" :style="{ float: 'left', width: '100%', height: '600px' }"></div>
                </el-card>
            </el-main>
        </el-container>
        <el-card>
            <el-table :data="orderData" border show-summary style="width: 50%; text-align: center; margin: auto">
                <el-table-column :label='$i18n.t("common.Ordertime")'  prop="orderTime" align="center" show-overflow-tooltip :formatter="formatDate">
                </el-table-column>
                <el-table-column prop="orderAmount" sortable :label='$i18n.t("common.OrderQuantity")'> </el-table-column>
                <el-table-column prop="saleAmount" sortable :label="orderAmountLabel"> </el-table-column>
                <el-table-column prop="balancedDiscountAmt" sortable :label='$i18n.t("common.AIGOCoins")'> </el-table-column>
                <el-table-column prop="fullGiftAmount" sortable :label='$i18n.t("common.NumberOfGiftsIssued")'> </el-table-column>
                <el-table-column prop="customUnitPrice" sortable :label='$i18n.t("common.CustomerPrice")'></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import dataSelect from '@/components/common/DataSelect.vue';
import ossUploader from '@/components/page/OssEleUploder.vue';
import dataText from '@/components/common/DataText.vue';
import Schart from 'vue-schart';
import * as echarts from 'echarts';
import moment from 'moment';

var myChart2; //全局变量
export default {
    components: {
        Schart,
        dataSelect,
        ossUploader,
        dataText,
        echarts
    },
    name: 'dashboard',
    data() {
        return {
            name: localStorage.getItem('ms_username'),
            orderAmountLabel: this.$i18n.t("common.Sales"),
            //国家语言字典
            countryCurrency: [],
            dateType: 'datetimerange',
            orderData: [],
            orderstaticRequest: {
                beginTime: moment().add(-1, 'days').format('yyyy-MM-DD 00:00:00'),
                endTime: moment().add(-1, 'days').format('yyyy-MM-DD 23:59:59'),
                countryCode: '',
                timeType: 'false'
            }, //查询项
            valuePicker: [moment().add(-1, 'days').format('yyyy-MM-DD 00:00:00'), moment().add(-1, 'days').format('yyyy-MM-DD 23:59:59')],
            eChartsOptions: {
                title: {
                    text: this.$i18n.t("common.StatisticsOfGiveawayActivities")
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [ this.$i18n.t("common.OrderQuantity"), this.$i18n.t("common.Sales"), this.$i18n.t("common.AIGOCoins"),this.$i18n.t("common.NumberOfGiftsIssued"), this.$i18n.t("common.CustomerPrice")]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {
                          title : ''
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    name: this.$i18n.t("common.time"), // x轴名称
                    boundaryGap: false,
                    data: ['A', 'B', 'C'],
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '订单数',
                        data: [120, 200, 150],
                        type: 'line',
                        stack: 'x'
                    },
                    {
                        data: [12, 20, 15],
                        type: 'line',
                        stack: 'x'
                    }
                ]
            },
            pickerOptions: {
            },
            loading: false
        };
    },
    mounted() {
        this.initEcharts();
        this.getDictItems();
    },
    methods: {
        initEcharts() {

            this.loading = true;
            if (myChart2 != null && myChart2 != '' && myChart2 != undefined) {
                myChart2.dispose(); //销毁
            }
            if (this.orderstaticRequest.timeType == 'false') {
                this.orderstaticRequest.beginTime = moment(this.valuePicker[0]).unix() * 1000;
                this.orderstaticRequest.endTime = moment(this.valuePicker[1]).unix() * 1000;
            } else {
                this.orderstaticRequest.beginTime = moment(this.valuePicker[0]).unix() * 1000;
                this.orderstaticRequest.endTime = moment(this.valuePicker[1]).add(86399999,'milliseconds').unix()*1000;
            }
            if (this.orderstaticRequest.countryCode != '' && this.orderstaticRequest.countryCode != null) {
                for (var item of this.countryCurrency) {
                    if (this.orderstaticRequest.countryCode == item.code) {
                        this.orderAmountLabel = this.$i18n.t("common.Sales") +'(' + item.name + ')';
                    }
                }
            } else {
                this.orderAmountLabel = this.$i18n.t("common.Sales");
            }

            this.eChartsOptions.xAxis.data = [];
            this.eChartsOptions.series = [];
            var a = {
                name: this.$i18n.t("common.OrderQuantity"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var b = {
                name: this.$i18n.t("common.Sales"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var c = {
                name: this.$i18n.t("common.NumberOfGiftsIssued"),
                data: [],
                stack: 'y',
                type: 'line',
                areaStyle: {}
            };
            var d = {
                name: this.$i18n.t("common.CustomerPrice"),
                data: [],
                stack: 'y',
                type: 'line',
                areaStyle: {}
            };
            var e = {
                name: this.$i18n.t("common.AIGOCoins"),
                data: [],
                stack: 'y',
                type: 'line',
                areaStyle: {}
            };
            this.$store.dispatch('getFullGiftOrderSalesStatistic', this.orderstaticRequest).then((res) => {
                this.orderData = res;
                for (var data of res) {
                    if (this.orderstaticRequest.timeType == 'true') {
                        this.eChartsOptions.xAxis.data.push(moment(data.orderTime).format('MM-DD'));
                    }
                    if (this.orderstaticRequest.timeType == 'false') {
                        // 时差八小时
                        this.eChartsOptions.xAxis.data.push(moment(data.orderTime).format('HH:mm'));
                    }
                    a.data.push(data.orderAmount);
                    b.data.push(data.saleAmount);
                    e.data.push(data.balancedDiscountAmt);
                    c.data.push(data.fullGiftAmount);
                    d.data.push(data.customUnitPrice);

                }
                this.eChartsOptions.series.push(a);
                this.eChartsOptions.series.push(b);
                this.eChartsOptions.series.push(e);
                this.eChartsOptions.series.push(c);
                this.eChartsOptions.series.push(d);
                console.log(this.eChartsOptions);
                // 图表初始化
                myChart2 = echarts.init(document.getElementById('myChart2'));
                myChart2.setOption(this.eChartsOptions);
                //随着屏幕大小调节图表
                window.addEventListener('resize', () => {
                    myChart2.resize();
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            if (this.orderstaticRequest.timeType == 'true') {
                let data = row[column.property];
                if (!data) {
                    return '';
                } else {
                    return moment(data).format('YYYY-MM-DD');
                }
            }
            if (this.orderstaticRequest.timeType == 'false') {
                let data = row[column.property];
                if (!data) {
                    return '';
                } else {
                    return moment(data).format('HH:mm');
                }
            }
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'country_currency' }).then((res) => {
                if (res != null) _this.countryCurrency = res;
                console.log(_this.countryCurrency);
            });
        }
    },
    watch: {
        'orderstaticRequest.timeType': {
            handler(newName, oldName) {
                if (newName == 'true') {
                    this.dateType = 'daterange';
                    this.valuePicker[0]= moment().add(-1, 'days').format('yyyy-MM-DD 00:00:00');
                    this.valuePicker[1] = moment().add(-1, 'days').format('yyyy-MM-DD 23:59:59');
                }
                if (newName == 'false') {
                    this.dateType = 'datetimerange';
                    this.valuePicker[0] = moment().add(-1, 'days').format('yyyy-MM-DD 00:00:00');
                    this.valuePicker[1]= moment().add(-1, 'days').format('yyyy-MM-DD 23:59:59');
                }
            }
        }
    }
};
</script>
<style>
.schart {
    width: 100%;
    height: 600px;
}
/*这里是：table的合计*/
.el-table {
    display: flex;
    flex-direction: column;
}
/* order默认值为0，只需将表体order置为1即可移到最后，这样合计行就上移到表体上方 */
.el-table__body-wrapper {
    order: 1;
}
/* 如果你的"价格1或者日期加了fixed,那你需要加上这俩行代码，但是加了以后，这俩行可能会影响到其他页面，所以合计页面的都不加fixed"
.el-table__fixed-body-wrapper {
     top: 86px !important;
}
 */
.el-table__fixed-footer-wrapper {
    z-index: 0;
    top: 46px;
}
</style>
