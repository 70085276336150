<template>
    <div class="app-container" style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.orderIdOrUserIdOrTaxNumber"
                        :placeholder='$i18n.t("common.Ordernumber_UserID_Taxnumber")'
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>

                <!-- <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.brandCode"
                        clearable
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                    ></dataSelect>
                </el-form-item> -->

                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="true"
                        :placeholder='$i18n.t("common.country")'
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.examineStatus"
                        :placeholder='$i18n.t("common.Auditstatus")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Unaudited")' value="0"></el-option>
                        <el-option :label='$i18n.t("common.Passed")' value="1"></el-option>
                        <el-option :label='$i18n.t("common.Failed")' value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.examineAdmin"
                        :placeholder='$i18n.t("common.Reviewedby")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="bobo" value="bobo"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.invoiceStatus"
                        :placeholder='$i18n.t("common.InvoiceStatus")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Notuploaded")' value="2"></el-option>
                        <el-option :label='$i18n.t("common.Uploaded")' value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="valuePicker"
                        type="daterange"
                        :range-separator='$i18n.t("common.to")'
                        :start-placeholder='$i18n.t("common.Applicationtime")'
                        :end-placeholder='$i18n.t("common.Applicationtime")'
                        style="margin-left: 10px"
                    >
                    </el-date-picker>
                </el-form-item>
<!--                <div style="padding: auto;">-->
                    <el-form-item>

                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px;">  {{ $i18n.t("common.query") }}  </el-button>

                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small"
                        >
                            {{ $i18n.t("common.Reset") }}
                        </el-button>
                    </el-form-item>
<!--                </div>-->
            </el-form>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px" size="medium" height="calc(100% - 140px)">
            <el-table-column label="ID" prop="invoiceId" align="center" show-overflow-tooltip> </el-table-column>

            <el-table-column :label='$i18n.t("common.Ordernumber")' prop="orderNo" align="center" width="150px"></el-table-column>
            <!-- <el-table-column prop="brandCode" :label='$i18n.t("common.brand")' show-overflow-tooltip align="center"> </el-table-column> -->
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" width="50px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.UserID")' prop="userId" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="orderPrice" :label='$i18n.t("common.money")' show-overflow-tooltip align="center">

                <template slot-scope="{ row }">
                    <span>€{{ row.orderPrice }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="taxNumber" :label='$i18n.t("common.TaxID")'  show-overflow-tooltip align="center"> </el-table-column>
            <el-table-column prop="invoiceAddress" :label='$i18n.t("common.Invoiceaddress")'  width="200px" align="center"> </el-table-column>
            <el-table-column prop="applyTime" :label='$i18n.t("common.Applicationtime")' width="200px" align="center" :formatter="formatDate"> </el-table-column>
            <el-table-column prop="examineTime" :label='$i18n.t("common.Audittime")' width="200px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.examineStatus == 1 || row.examineStatus == 2">{{ formatTime(row.examineTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="examineStatus" :label='$i18n.t("common.Auditstatus")' show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.examineStatus == 0" style="color: red">{{ $i18n.t("common.Unaudited") }}</span>
                    <span v-if="row.examineStatus == 1" style="color: green">{{ $i18n.t("common.Passed") }}</span>
                    <span v-if="row.examineStatus == 2" style="color: grey">{{ $i18n.t("common.Failed") }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="examineContent" :label='$i18n.t("common.AuditDescription")' show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    {{ row.examineContent.slice(0, 200) }}
                </template>
            </el-table-column>
            <el-table-column prop="examineAdmin" :label='$i18n.t("common.Reviewedby")' show-overflow-tooltip align="center"> </el-table-column>
            <el-table-column prop="invoiceStatus" :label='$i18n.t("common.InvoiceStatus")' show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.invoiceStatus == 0" style="color: red">{{ '' }}</span>
                    <span v-if="row.invoiceStatus == 1" style="color: green">{{ $i18n.t("common.Uploaded") }}</span>
                    <span v-if="row.invoiceStatus == 2" style="color: red">{{ $i18n.t("common.Notuploaded") }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button
                        v-if="row.examineStatus == 0"
                        type="primary"
                        size="mini"
                        @click="examineHandle(row)"
                        class="table-inner-button"
                        >{{ $i18n.t("common.Auditing")  }}</el-button
                    >
                    <el-button
                        v-if="row.examineStatus == 1 && row.invoiceStatus == 1"
                        type="primary"
                        size="mini"
                        @click="showInvoice(row)"
                        class="table-inner-button"
                        >{{ $i18n.t("common.check")  }}</el-button
                    >
                    <el-button
                        v-if="row.examineStatus == 1 && row.invoiceStatus == 1"
                        type="primary"
                        size="mini"
                        @click="downloadHandle(row)"
                        class="table-inner-button"
                        >{{ $i18n.t("common.download")  }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="list.page"
                :page-size="list.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <el-dialog :title='$i18n.t("common.invoice") ' :visible.sync="invoiceVisible" v-if="invoiceVisible">
            <div>
                <pdf v-if="invoiceUrl != null"  ref="pdf" :src="invoiceUrl"></pdf>
            </div>
        </el-dialog>
        <el-dialog :title='$i18n.t("common.Auditing")' :visible.sync="diologVisible" v-if="diologVisible" width="1000px" :close-on-click-modal="false">
            <div>
                <span style="font-size: 125%">{{ $i18n.t("common.OrderInformation")  }}</span>
                <el-table :data="examineList[0].orderDetails" style="margin-top: 10px" size="medium">
                    <el-table-column :label=' $i18n.t("common.Ordernumber") ' width="100px" align="center" prop="orderNo">
                        <template slot-scope="{ row }">
                            <el-link type="primary" @click="orderHandle(row.orderId)">{{ row.orderNo }}</el-link>
                        </template>
                    </el-table-column>

                   <!-- <el-table-column :label=' $i18n.t("common.brand") ' show-overflow-tooltip align="center" prop="brandCode"></el-table-column> -->
                   <el-table-column :label=' $i18n.t("common.country") ' show-overflow-tooltip align="center" prop="countryCode"></el-table-column>
                    <el-table-column :label=' $i18n.t("common.UserID") ' width="150px" align="center" prop="userId"></el-table-column>
                    <el-table-column :label=' $i18n.t("common.Numberofproductmodels") ' show-overflow-tooltip align="center" prop="orderItemList.length"></el-table-column>
                    <el-table-column :label=' $i18n.t("common.source") ' show-overflow-tooltip align="center" prop="source"></el-table-column>
                    <el-table-column :label=' $i18n.t("common.money") '  show-overflow-tooltip align="center" prop="totalAmt">

                        <template slot-scope="{ row }">
                            <span>{{ $dict.getDictValue('country_currency', row.countryCode) }}{{ row.totalAmt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.OrderStatus")' show-overflow-tooltip align="center" prop="orderStatus">
                        <template slot-scope="{ row }">
                            {{ $dict.getDictValue('order_status', row.orderStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label=' $i18n.t("common.Creationtime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="createTime"
                    ></el-table-column>
                    <el-table-column     :label='$i18n.t("common.Paymentmethod")' show-overflow-tooltip align="center">
                        {{examineList[0].payMethodName}}
                    </el-table-column>
                    <el-table-column
                       :label='$i18n.t("common.Paymenttime")' 
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="paymentTime"
                    ></el-table-column>
                    <el-table-column   :label='$i18n.t("common.Shipmentstatus")'  show-overflow-tooltip align="center" prop="deliveryStatus">
                        <template slot-scope="{ row }">
                            {{ $dict.getDictValue('delivery_status', row.deliveryStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label='$i18n.t("common.Deliverytime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="deliverTime"
                    ></el-table-column>
                    <el-table-column   :label='$i18n.t("common.InvoiceStatus")' show-overflow-tooltip align="center">
                        <template>
                            <span v-if="examineList[0].examineStatus == 0 || examineList[0].invoiceStatus == 2">{{ $i18n.t("common.Applying") }}</span>
                            <span v-if="examineList[0].invoiceStatus == 1" style="color: grey">{{  $i18n.t("common.Uploaded")  }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="margin-top: 30px" v-loading="loading">
                <span style="font-size: 125%">{{ $i18n.t("common.Invoiceaddress")}}</span>
                <div style="margin-top: 20px">
                    <span>{{ $i18n.t("common.TaxID")}}:{{ examineList[0].taxNumber }}</span>
                </div>
                <el-table :data="examineList" style="margin-top: 20px" size="medium">
                    <el-table-column :label='$i18n.t("common.name1")' align="center" show-overflow-tooltip prop="firstName">
                        <template>
                            <span>{{ examineList[0].firstName }} {{ examineList[0].lastName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.Mobilephonenumber")' align="center" prop="phoneNumber" width="150px"></el-table-column>
                    <el-table-column :label='$i18n.t("common.country")' show-overflow-tooltip align="center" prop="countryCode">
                        <template slot-scope="{ row }">
                            {{ $dict.getDictValue('countrys', row.countryCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.province")' show-overflow-tooltip align="center" prop="province"></el-table-column>
                    <el-table-column :label='$i18n.t("common.city")' show-overflow-tooltip align="center" prop="city"></el-table-column>
                    <el-table-column :label='$i18n.t("common.PostalCode")' show-overflow-tooltip align="center" prop="postalCode"></el-table-column>
                    <el-table-column :label='$i18n.t("common.Detailedaddress")' align="center" prop="addressContent" width="200px"></el-table-column>
                </el-table>
            </div>
            <div style="margin-top: 20px">

                <span style="font-size: 125%">{{ $i18n.t("common.Auditing") }}:</span>

                <el-form :model="examineRequest" style="margin-top: 20px">
                    <el-form-item :label='$i18n.t("common.result")'>
                        <el-radio-group v-model="examineRequest.examineStatus">
                            <el-radio label="1">{{$i18n.t("common.adopt")}}</el-radio>
                            <el-radio label="2">{{$i18n.t("common.Notpassed")}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="" v-if="examineRequest.examineStatus==1 && scene.isNonSelf">
                      <fileUpload ref="fileUpload" @event="changeUrl"></fileUpload>
                    </el-form-item>
                    <el-form-item :label='$i18n.t("common.reason")'>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 10 }"
                            :placeholder='$i18n.t("common.Enterthereason")'
                            v-model="examineRequest.examineContent"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <span style="color: red">*</span>
                        <span>{{$i18n.t("common.Thereasonfornotpassingwillbedisplayedtotheuser")}}</span>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" size="medium" @click="examine(examineList[0].invoiceId)" class="table-inner-button"

                            >{{ $i18n.t("common.Auditing") }}</el-button

                        >
                        <el-button size="medium" @click="diologVisible = false">{{$i18n.t("common.cancel")}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import pdf from 'vue-pdf';
import fileUpload from './components/fileUpload';
import scene from "@/utils/scene";

export default {
    computed: {
      scene() {
        return scene
      }
    },
    name: 'invoice',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        pdf,
        fileUpload
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error($i18n.t("common.Pleaseselectatimerange")));
            }
        };
        return {
            list: [],
            examineList: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            query: {
                page: 1,
                limit: 10,
                orderIdOrUserIdOrTaxNumber: '',
                brandCode: '',
                countryCode: [],
                examineStatus: '0',
                invoiceStatus: null,
                examineAdmin: '',
                applyStartTime: null,
                applyEndTime: null
            },
            examineRequest: {
                invoiceId: null,
                examineStatus: null,
                examineContent: '',
                invoiceUrl:''
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            valuePicker: [],

            diologTitle: '审核',

            diologVisible: false,
            examineContent: '',
            replyStatus: true,
            invoiceUrl: null,
            invoiceVisible: false,
            loading: false,
            numPages: null, // pdf 总页数
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t("common.today"),
                        onClick(picker) {
                            const end = new Date();
                            const start = moment().format('YYYY-MM-DD');
                            // start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:this.$i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Thismonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Lastmonth1"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.thisyear"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if (to.path === '/b2c/invoice/index') {
                this.routerHandler();
            }
        }
    },
    created() {
        this.getDictItems();
        this.routerHandler();
    },
    mounted() {
        this.getDictItems();
        // this.getNumPages();
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            var userId = this.$route.params.userId;
            if (userId) {
                this.query.orderIdOrUserIdOrTaxNumber = userId;
                this.query.examineStatus = null;
            }
            this.doQueryList({ page: 1 });
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            console.log(val);
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 时间转码
         */
        formatTime(time) {
            return moment(time).format('YYYY-MM-DD HH:mm:ss');
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.valuePicker != null && this.valuePicker.length > 0) {
                this.query.applyStartTime = moment(this.valuePicker[0]).unix() * 1000;
                this.query.applyEndTime = moment(this.valuePicker[1]).add(1, 'days').unix() * 1000;
            } else {
                this.query.applyStartTime = null;
                this.query.applyEndTime = null;
            }
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            if (this.query.orderIdOrUserIdOrTaxNumber != '' || this.query.orderIdOrUserIdOrTaxNumber != null) {
                //百分号校验
                if (this.query.orderIdOrUserIdOrTaxNumber.includes('%')) {
                    this.list = [];
                    return;
                }
            }
            this.listLoading = true;

            this.$store
                .dispatch('getInvoicePage', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 审核操作
         */
        examineHandle(row) {
            this.examineList = [];
            this.examineRequest = {
                invoiceId: null,
                examineStatus: null,
                examineContent: '',
                invoiceUrl:''
            };
            this.examineContent = '';
            var _this = this;
            this.$store.dispatch('getInvoiceDetails', { invoiceId: row.invoiceId }).then((res) => {
                _this.examineList.push(res);
                this.diologVisible = true;
            });

            console.log(_this.examineList);
        },
        /**
         * 审核内容
         */
        examine(invoiceId) {
            //如果没选择结果
            if (this.examineRequest.examineStatus == null) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaresult"),
                    type: 'warning'
                });
                return;
            }
            //选择未通过要输入原因
            if (this.examineRequest.examineStatus == 2) {
                if (this.examineRequest.examineContent == '' || this.examineRequest.examineContent == null) {
                    this.$message({

                        message: this.$i18n.t("common.Pleaseenterthereason"),

                        type: 'warning'
                    });
                    return;
                }
            }
            //长数据截断
            if (this.examineRequest.examineContent != '' || this.examineRequest.examineContent != null) {
                this.examineRequest.examineContent = this.examineRequest.examineContent.slice(0, 9999);
            }
            //如果是非自营的通过时要校验附件必传
            if (this.examineRequest.examineStatus == 1 && !this.examineRequest.invoiceUrl && scene.isNonSelf) {
                this.$message({
                  message: this.$i18n.t("common.AuditFailedPleaseUploadInvoiceAttachment"),
                  type: 'warning'
                });
                return;
            }
            //console.log("invoiceUrl-->",this.examineRequest.invoiceUrl)
            this.examineRequest.invoiceId = invoiceId;
            this.examineRequest.examineStatus = parseInt(this.examineRequest.examineStatus);
            this.loading = true;
            this.$store
                .dispatch('examine', this.examineRequest)
                .then((res) => {
                    this.doQueryList({ page: 1 });
                    this.diologVisible = false;
                    this.$message({
                        message: this.$i18n.t("common.Auditsuccessful"),
                        type: 'success'
                    });
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * 在线预览发票
         */
        showInvoice(row) {
            this.invoiceUrl = null;
            this.invoiceVisible = true;
            this.invoiceUrl = row.invoiceUrl;
            // this.getNumPages();
        },
        // /**
        //  * 计算pdf页码总数
        //  */
        // getNumPages() {
        //     let loadingTask = pdf.createLoadingTask(this.invoiceUrl);
        //     loadingTask.promise
        //         .then((pdf) => {
        //             this.numPages = pdf.numPages;
        //         })
        //         .catch((err) => {
        //             console.error('pdf 加载失败', err);
        //         });
        // },
        /**
         * 发票下载
         */
        downloadHandle(row) {
            window.location.href = row.invoiceUrl;
        },
        /**
         * 点击订单编号跳转
         */
        orderHandle(orderId) {
            this.$router.push({
                name: 'order_list',
                params: {
                    orderId: orderId
                }
            });
            this.diologVisible = false;
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            (this.query.orderIdOrUserIdOrTaxNumber = ''),
                (this.query.brandCode = ''),
                (this.query.countryCode = []),
                (this.query.examineStatus = '0'),
                (this.query.invoiceStatus = null),
                (this.query.examineAdmin = ''),
                (this.query.applyStartTime = null),
                (this.query.applyEndTime = null),
                (this.valuePicker = []);
            this.doQueryList({ page: 1 });
        },
        changeUrl(data) {
          console.log("数据", data)
          this.examineRequest.invoiceUrl = data
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.my-content {
    background: #fde2e2;
}
.pdfPriview {
    height: 100%;
    overflow: hidden;
}
</style>
