var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          attrs: { size: "medium" },
          on: { change: _vm.changeHomeTextConfigModuleType },
          model: {
            value: _vm.homeTextConfigModuleType,
            callback: function($$v) {
              _vm.homeTextConfigModuleType = $$v
            },
            expression: "homeTextConfigModuleType"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "HomePageColumn" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.HomePageColumn")))
          ]),
          _c("el-radio-button", { attrs: { label: "ColumnDescription" } }, [
            _vm._v(_vm._s(_vm.$i18n.t("common.ColumnDescription")))
          ])
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.name, name: item.enum } },
            [
              _c(
                "el-form",
                {
                  ref: "titleFrom",
                  refInFor: true,
                  attrs: { "label-width": "100px" }
                },
                _vm._l(_vm.languageList, function(item, index) {
                  return _vm.falg
                    ? _c(
                        "el-form-item",
                        { key: index, attrs: { label: item.name } },
                        [
                          _c("el-input", {
                            attrs: {
                              "show-word-limit": "",
                              maxlength: "300",
                              placeholder: _vm.$i18n.t(
                                "common.HomepageConfiguration"
                              )
                            },
                            on: {
                              input: function($event) {
                                return _vm.forceUpdate($event)
                              }
                            },
                            model: {
                              value: item.content,
                              callback: function($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }